import { RawDraftContentState } from 'draft-js';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { isString } from 'lodash';

import { ElementEditor } from '../../../elements/elementEditorConstants';
import { ImMNElement } from '../../../elements/elementModelTypes';
import { convertElementDraftContentToTiptap, DocumentType } from './elementConversion';
import convertSimpleStringToTiptapContent from '../../utils/createJsonContentUtils/convertSimpleStringToTiptapContent';
import { getIsTiptapConversionRecentlyPostponed } from '../../../elements/utils/elementPropertyUtils';
import { getEditorType } from '../../utils/editorTypeUtils';
import { asObject, ImMap } from '../../../utils/immutableHelper';
import { TiptapContent, TiptapNodeType } from '../../tiptapTypes';
import { ExperimentId } from '../../../experiments/experimentsConstants';
import { selectIsTiptapConversionEnabledForCurrentUser } from './elementConversionSelector';

/**
 * Gets the Tiptap content of an element, converting it from DraftJS if necessary.
 * If the conversion fails or was postponed recently, returns null (indicating that the element
 * should just be rendered with the draft editor).
 * @returns: tiptap content (if the card should be rendered with tiptap) or null (for draft)
 * TODO-REMOVE-DRAFT: Remove this when draft-js is removed
 */
export const useElementTiptapContent = (
    element: ImMNElement,
    textContent: ImMap<TiptapContent> | ImMap<RawDraftContentState> | string | null,
    // This will cause a different conversion process to be used for different editors.
    //  E.g. Captions will have their paragraphs converted to soft-new-lines first
    documentType?: DocumentType,
): TiptapContent | null => {
    const allowPreview = useSelector(selectIsTiptapConversionEnabledForCurrentUser);

    const isTiptap = getEditorType(textContent) === ElementEditor.Tiptap;
    const isRecentlyPostponed = getIsTiptapConversionRecentlyPostponed(element);

    return useMemo(() => {
        // It's a tiptap card, just return its content
        if (isTiptap) return asObject(textContent as ImMap<TiptapContent>);

        // Preview is switched off, nothing more to do -- return draft
        if (!allowPreview) return null;

        // Don't convert it if it was postponed recently
        if (isRecentlyPostponed) return null;

        // Empty card; return some empty content
        // NOTE: This assumes that all element editors allow paragraphs. Currently, this is correct,
        //  but if this changes we'll need to update this logic.
        if (!textContent) return { type: 'doc', content: [{ type: TiptapNodeType.paragraph }] };

        if (isString(textContent)) return convertSimpleStringToTiptapContent(textContent);

        const data = asObject(textContent as ImMap<RawDraftContentState>) as RawDraftContentState;
        try {
            // Convert draft content to tiptap content without writing it back to the element
            return convertElementDraftContentToTiptap(data, documentType);
        } catch (e) {
            console.error('Error converting to tiptap', data);
            console.error(e);
            return null;
        }
    }, [isTiptap, isRecentlyPostponed, textContent, allowPreview]);
};
