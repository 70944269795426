import { Mark, MarkType, ResolvedPos } from '@tiptap/pm/model';

/*
 This function is distinct from tiptap's getMarkRange in two ways:
 - its return value includes the mark itself, not just the range
 - it looks at the mark when expanding, not the mark type, and so is more robust against consecutive marks of the same type
 (the latter issue is only a hypothetical problem and hasn't been tested)
*/

export const getMarkRange = (
    $pos: ResolvedPos,
    markType: MarkType,
): {
    from: number;
    to: number;
    mark: Mark;
} | null => {
    // based on https://discuss.prosemirror.net/t/expanding-the-selection-to-the-active-mark/478/9
    const { parent, parentOffset } = $pos;
    const start = parent.childAfter(parentOffset);
    if (!start.node) return null;

    // find the mark under the cursor of the appropriate type
    const mark = start.node.marks.find((mark) => mark.type === markType);
    if (!mark) return null;

    const hasMarkAt = (offset: number) => mark.isInSet(parent.child(offset).marks);

    // then iterate along the parent nodes's children to find where it starts & ends
    // (note this is going by node, not character, so it's only a small number of steps
    // unless the paragraph has a lot of other marks in it
    let startIndex = $pos.index();
    let startPos = $pos.start() + start.offset;
    let endIndex = startIndex + 1;
    let endPos = startPos + start.node.nodeSize;
    while (startIndex > 0 && hasMarkAt(startIndex - 1)) {
        startIndex -= 1;
        startPos -= parent.child(startIndex).nodeSize;
    }
    while (endIndex < parent.childCount && hasMarkAt(endIndex)) {
        endPos += parent.child(endIndex).nodeSize;
        endIndex += 1;
    }
    return { from: startPos, to: endPos, mark };
};
