// Utils
import platformSingleton from '../platform/platformSingleton';

// Constants
import { PLATFORMS } from '../../common/users/userConstants';
import { ApplicationType, DeviceFormFactor, OperatingSystem } from '../../common/platform/platformTypes';

/**
 * Gets the appropriate platform string.
 * This is used primarily in analytics, but also occasionally API calls.
 */
// TODO-MOBILE - Investigate whether this will allow us to differentiate between different versions in Amplitude
// TODO-MOBILE - Also potentially use a different function for the "meta" platform on elements
export const getApplicationPlatform = () => {
    if (platformSingleton.appType === ApplicationType.electronMac) return PLATFORMS.MAC_DESKTOP_APP;

    if (platformSingleton.appType === ApplicationType.ipadSwift) return PLATFORMS.IPAD;
    if (platformSingleton.appType === ApplicationType.ipadCapacitor) return PLATFORMS.IPAD;

    if (platformSingleton.appType === ApplicationType.iphoneSwift) return PLATFORMS.IPHONE;
    if (platformSingleton.appType === ApplicationType.iphoneCapacitor) return PLATFORMS.IPHONE;

    if (platformSingleton.appType === ApplicationType.androidFlutter) return PLATFORMS.ANDROID;
    if (platformSingleton.appType === ApplicationType.androidCapacitor) return PLATFORMS.ANDROID;
    if (platformSingleton.appType === ApplicationType.androidTabletCapacitor) return PLATFORMS.ANDROID;

    if (platformSingleton.appType === ApplicationType.pwa) return PLATFORMS.PWA;

    if (platformSingleton.os === OperatingSystem.iOS && platformSingleton.formFactor === DeviceFormFactor.phone) {
        return PLATFORMS.IPHONE;
    }

    if (platformSingleton.os === OperatingSystem.android && platformSingleton.formFactor === DeviceFormFactor.phone) {
        return PLATFORMS.ANDROID;
    }

    return PLATFORMS.WEB;
};
