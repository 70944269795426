import { AnyAction } from 'redux';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { cloneDeep } from 'lodash';
import { HYBRID_ACTION_TYPES, HybridLocationChangeSketchAction } from '../../hybridMiddlewareConstants';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../../common/platform/platformTypes';

// Creating the Action for File Elements

const shouldCreateHybridActionForSketch = (): boolean => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            return true;
        case LegacyHybridUseCase.IPAD_OS:
        default:
            return false;
    }
};

export const createHybridPresentModalSketchAction = (
    originalAction: AnyAction,
    sketchElement: MNElement,
): HybridLocationChangeSketchAction | undefined => {
    if (!shouldCreateHybridActionForSketch()) return;
    const modifiedAction = cloneDeep(originalAction) as HybridLocationChangeSketchAction;
    modifiedAction.type = HYBRID_ACTION_TYPES.PRESENT_MODAL_SKETCH;
    modifiedAction.sketchElement = sketchElement;
    return modifiedAction;
};
