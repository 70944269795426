// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import PopupModeMenuButton from './PopupModeMenuButton';
import PopupModeMenuSearchForm from './PopupModeMenuSearchForm';

// Styles
import './PopupModeMenu.scss';

const PopupModeMenu = (props) => (
    <div className="PopupModeMenu">
        <ul>
            {props.modeList.map(({ Component, ...mode }, i) => (
                <li key={i}>
                    {Component ? <Component {...props} mode={mode} /> : <PopupModeMenuButton {...props} mode={mode} />}
                </li>
            ))}
        </ul>

        <div className="tools">
            {props.tools}
            {props.showSearch && <PopupModeMenuSearchForm {...props} />}
        </div>
    </div>
);

PopupModeMenu.propTypes = {
    popupMode: PropTypes.string,
    setMode: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    clearSearchOnSubmit: PropTypes.bool,
    handleSearchChange: PropTypes.func,
    showSearch: PropTypes.bool,
    modeList: PropTypes.array,
    tools: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default PopupModeMenu;
