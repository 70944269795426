// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import PageLoaderMain from './PageLoaderMain';

// Selectors
import { getIsLocalCacheHydrated } from '../../offline/cache/localCacheSelector';

// Styles
import './PageLoader.scss';

const PageLoader = () => {
    const isLocalCacheHydrated = useSelector(getIsLocalCacheHydrated);

    return (
        <div className="PageLoader">
            <div className="WorkspaceWrapper">
                <header className="AppHeader">
                    <div className="WorkspaceToolsHeader header-tools" />
                </header>
                <div className="Workspace">
                    <div className="WorkspacePositioner">
                        <div id="toolbar" className="Toolbar" />
                        {!isLocalCacheHydrated && <PageLoaderMain />}
                    </div>
                </div>
                <div className="MobileToolbar" />
            </div>
        </div>
    );
};

export default PageLoader;
