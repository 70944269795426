// Types
import { Range } from '@tiptap/core';
import { Selection } from '@tiptap/pm/state';
import { ResolvedPos } from '@tiptap/pm/model';

// Utils
import { getStartOfNextTextNode } from './getStartOfNextTextNode';
import { getEndOfPreviousTextNode } from './getEndOfPreviousTextNode';

/**
 * Shifts the starting position to the start of the text block, if it's within the text block,
 * or to the start of the next text block if it's at the end of the current text block.
 *
 * This is because it's possible to have a selection that invisibly spans to the previous node.
 * In this scenario - the user thinks that they're selecting the entire block, but they're actually
 * selecting the previous block as well - so we adjust it to be at the start of the following text block.
 */
const getAdjustedFromPos = ($from: ResolvedPos): ResolvedPos | null => {
    const isStartAtBlockStart = $from.parentOffset === 0;

    // Already at the start, so just use this position
    if (isStartAtBlockStart) return $from;

    const isStartAtBlockEnd = $from.parentOffset === $from.parent.nodeSize - 2;

    // If the from is within the block, then return the start of the block
    if (!isStartAtBlockEnd) return $from.doc.resolve($from.start());

    // If the from is at the end of the block, then return the start of the next text node
    return getStartOfNextTextNode($from);
};

/**
 * Shifts the starting position to the end of the text block, if it's within the text block,
 * or to the end of the previous text block if it's at the start of the text block.
 *
 * This is because it's possible to have a selection that invisibly spans to the following node (e.g.
 * when triple click selecting).
 * In this scenario - the user thinks that they're selecting the entire block, but they're actually
 * selecting the following block as well - so we adjust it to be at the end of the previous text block.
 */
const getAdjustedToPos = ($to: ResolvedPos): ResolvedPos | null => {
    const isEndAtBlockEnd = $to.parentOffset === $to.parent.nodeSize - 2;

    // Already at the end, so just use this position
    if (isEndAtBlockEnd) return $to;

    const isEndAtBlockStart = $to.parentOffset === 0;

    // If the to is within the block, then return the end of the block
    if (!isEndAtBlockStart) return $to.doc.resolve($to.end());

    // If the to is at the start of the block, then return the end of the previous text node
    return getEndOfPreviousTextNode($to);
};

/**
 * Recursively grows the range (either the start, end, or both) as long as the selection
 * is fully contained within the parent nodes of each side.
 */
const recursivelyExpandToContainingNode = (
    $from: ResolvedPos,
    $to: ResolvedPos,
): { $from: ResolvedPos; $to: ResolvedPos } => {
    const contentSize = $from.doc.content.size;

    const parentStart = $from.depth > 0 ? $from.before() : $from.pos;
    const parentEnd = $to.depth > 0 ? $to.after() : $to.pos;

    const $parentStart = $from.doc.resolve(parentStart);
    const $parentEnd = $to.doc.resolve(parentEnd);

    const canExpandStart =
        // The parent is within the current document
        parentStart >= 0 &&
        // The parent is the direct parent of the current node
        parentStart === $from.pos - 1 &&
        // The end of the parent is before the current end position + 1
        $from.after() <= $to.pos + 1;

    const minEndDepth = canExpandStart ? $parentStart.depth : $from.depth;

    const canExpandEnd =
        // The parent is within the current document
        parentEnd <= contentSize &&
        // The parent is the direct parent of the current node
        parentEnd === $to.pos + 1 &&
        // We can't expand the depth beyond the minimum depth
        $parentEnd.depth >= minEndDepth;

    if (!canExpandStart && !canExpandEnd) return { $from, $to };

    const $nextFrom = canExpandStart ? $parentStart : $from;
    const $nextTo = canExpandEnd ? $parentEnd : $to;

    return recursivelyExpandToContainingNode($nextFrom, $nextTo);
};

/**
 * Finds the highest node that fully contains the start position's node.
 */
const getHighestContainingStart = ($from: ResolvedPos): ResolvedPos => {
    const $to = $from.doc.resolve($from.end());
    const { $from: $newFrom } = recursivelyExpandToContainingNode($from, $to);
    return $newFrom;
};

/**
 * Finds the highest node that fully contains the end position's node.
 */
const getHighestContainingEnd = ($to: ResolvedPos): ResolvedPos => {
    const $from = $to.doc.resolve($to.start());
    const { $to: $newTo } = recursivelyExpandToContainingNode($from, $to);
    return $newTo;
};

/**
 * Expands the range to contain the nodes within the selection.
 *
 * If the selection is within the same parent, it will be expanded to find the highest containing parent.
 * Examples:
 * - If the selection is within a paragraph, but the paragraph is within a list item, then the list item will
 *   be the highest containing parent.
 * - Unless the list item is the only child of the ordered list containing it, in which case the ordered list
 *   will be the highest containing parent.
 *
 * Otherwise, if the selection spans multiple parents, it will be expanded to fully contain the nodes.
 */
const expandRangeToContainNodes = (selection: Selection): Range => {
    const { $from, $to } = selection;

    const $adjustedFrom = getAdjustedFromPos($from) || $from;
    const $adjustedTo = getAdjustedToPos($to) || $to;

    const $highestContainingStart = getHighestContainingStart($adjustedFrom);
    const $highestContainingEnd = getHighestContainingEnd($adjustedTo);

    const { $from: $expandedFrom, $to: $expandedTo } = recursivelyExpandToContainingNode(
        $highestContainingStart,
        $highestContainingEnd,
    );

    return { from: $expandedFrom.pos, to: $expandedTo.pos };
};

export default expandRangeToContainNodes;
