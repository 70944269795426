import React from 'react';
import classNames from 'classnames';

import EmojiImage from '../EmojiImage';
import Button from '../../buttons/Button';

import { getCompleteEmojiCode } from './reactionPopupUtil';
import { Emoji } from '../emojiTypes';

import './ReactionPopupEmojiButton.scss';

const BUTTON_PADDING = 14;

type ReactionPopupEmojiButtonProps = {
    emoji: Emoji;
    selected?: boolean;
    className?: string;
    skinTone?: string;
    gridSize?: number;
    emojiButtonSize?: number;
    onClickFn: () => void;
};

const ReactionPopupEmojiButton = ({
    className,
    emoji,
    selected,
    skinTone,
    emojiButtonSize,
    gridSize = 10,
    onClickFn,
}: ReactionPopupEmojiButtonProps) => {
    const emojiImageSize = emojiButtonSize ? emojiButtonSize - BUTTON_PADDING : gridSize * 2.4;

    const buttonStyle = emojiButtonSize ? { width: emojiButtonSize, height: emojiButtonSize } : {};
    return (
        <Button
            className={classNames('ReactionPopupEmojiButton', className, { selected })}
            onClickFn={onClickFn}
            style={buttonStyle}
        >
            <EmojiImage emojiCode={getCompleteEmojiCode(emoji, skinTone)} size={emojiImageSize} />
        </Button>
    );
};

export default ReactionPopupEmojiButton;
