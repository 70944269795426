// Lib
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Selector
import { getIsFeatureEnabledForCurrentUser } from '../../feature/elementFeatureSelector';

// Actions
import { updateElement, updateElementContentDiff } from '../../actions/elementActions';

// Hooks
import useCardTiptapEditorExtensions from '../../card/tiptap/useCardTiptapEditorExtensions';
import useClientElementTiptapEditor from '../../../components/tiptapEditor/useClientElementTiptapEditor';
import { useSuggestionsManager } from '../../../components/tiptapEditor/useSuggestionsManager';
import useKeepModalEditorScrolledIntoView from './useKeepModalEditorScrolledIntoView';

// Components
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';

// Types
import { EditorContent } from '@tiptap/react';

interface DocumentModalTiptapEditorProps {
    element: ImMNElement;
    elementId: string;
    textContent: TiptapContent | null;
    editorId: string;
    editorKey: string;
    currentEditorId: string;
    isEditable: boolean;
    isEditing: boolean;
    dispatchStartEditing: () => void;
    startEditingOnRender?: boolean;
    isMobile?: boolean;
}

const DocumentModalTiptapEditor = (props: DocumentModalTiptapEditorProps): ReactElement => {
    const {
        element,
        elementId,
        textContent,
        isEditable,
        editorId,
        editorKey,
        currentEditorId,
        isEditing,
        dispatchStartEditing,
        startEditingOnRender,
        isMobile,
    } = props;

    const isDocumentDiffSyncingEnabled = useSelector(
        getIsFeatureEnabledForCurrentUser(ExperimentId.documentDiffSyncing),
    );
    const dispatch = useDispatch();

    const dispatchSaveContent = useCallback(
        (textContent: TiptapContent, transactionId?: number) => {
            isDocumentDiffSyncingEnabled
                ? dispatch(
                      updateElementContentDiff({
                          id: elementId,
                          changes: { textContent },
                          transactionId,
                          silent: false,
                      }),
                  )
                : dispatch(updateElement({ id: elementId, changes: { textContent }, transactionId }));
        },
        [dispatch, elementId, isDocumentDiffSyncingEnabled],
    );

    useEffect(() => {
        if (isEditable && startEditingOnRender) dispatchStartEditing();
    }, [isEditable]);

    const [suggestionsManager, mentionsPopup] = useSuggestionsManager();
    const extensions = useCardTiptapEditorExtensions({
        suggestionsManager,
        isEditable,
        dispatch,
        saveContent: dispatchSaveContent,
        clickableLinksWhenEditable: true,
        universalCardConversion: false,
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: textContent,
        extensions,

        editorId,
        editorKey,
        currentEditorId,

        isEditing,
        isEditable,
        isSingleSelected: true,

        startEditing: dispatchStartEditing,
        saveContent: dispatchSaveContent,

        editorClassname: 'clickable-links-on-edit',
    });

    useKeepModalEditorScrolledIntoView(editor, isEditing, isMobile);

    return (
        <div className="DocumentModalTiptapEditorContainer" onMouseDown={onMouseDown} onClick={onClick}>
            {mentionsPopup}
            <DraftJsConversionIndicator element={element} textContent={getTextContent(element)} />
            <EditorContent className="DocumentModalTiptapEditor" editor={editor} />
        </div>
    );
};

export default DocumentModalTiptapEditor;
