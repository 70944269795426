import { RefObject, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

import { getIsSheetOpen, getSheetActiveSnapPoint } from '../sheetSelectors';
import { closeSheet, openSheet, updateSheetActiveSnapPoint } from '../SheetActions';

import { SheetId, SheetOptions } from '../sheetTypes';

export type UseSheetReturnType = {
    sheetRef: RefObject<HTMLDivElement>;
    sheetContentRef: RefObject<HTMLDivElement>;
    sheetId: SheetId;
    sheetKey: string;
    isSheetOpen: boolean;
    activeSnapPoint: number;
    dispatchOpenSheet: () => void;
    dispatchCloseSheet: () => void;
    dispatchUpdateActiveSnapPoint: (activeSnapPoint: number) => void;
};

const getSheetKey = (sheetId: SheetId, randomId: string) => `${sheetId}-${randomId}`;

const useSheet = (sheetId: SheetId, options?: SheetOptions): UseSheetReturnType => {
    const randomId = useMemo(() => nanoid(5), []);
    const sheetKey = getSheetKey(sheetId, randomId);

    const sheetRef = useRef<HTMLDivElement>(null);
    const sheetContentRef = useRef(null);

    const isSheetOpen = useSelector(getIsSheetOpen(sheetKey));
    const activeSnapPoint = useSelector(getSheetActiveSnapPoint(sheetKey));

    const dispatch = useDispatch();

    const dispatchOpenSheet = () => dispatch(openSheet(sheetId, sheetKey, options));
    const dispatchCloseSheet = () => dispatch(closeSheet(sheetId, sheetKey));

    const dispatchUpdateActiveSnapPoint = (activeSnapPoint: number) =>
        dispatch(updateSheetActiveSnapPoint(sheetId, sheetKey, activeSnapPoint));

    return {
        sheetRef,
        sheetContentRef,
        sheetId,
        sheetKey,
        isSheetOpen,
        activeSnapPoint,
        dispatchOpenSheet,
        dispatchCloseSheet,
        dispatchUpdateActiveSnapPoint,
    };
};

export default useSheet;
