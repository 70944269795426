import {
    ELEMENT_MOVE,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_MOVE_MULTI,
} from '../../../../../../common/elements/elementConstants';
import { DRAG_START } from '../../../../../reducers/draggingConstants';
import { USER_NAVIGATE } from '../../../../../../common/users/userConstants';

const quickNotesPopupStayActivePredicate = (state, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
        case USER_NAVIGATE:
        case ELEMENT_MOVE:
        case ELEMENT_MOVE_AND_UPDATE:
        case ELEMENT_MOVE_MULTI:
        case DRAG_START:
            return true;
        default:
            return false;
    }
};

export default quickNotesPopupStayActivePredicate;
