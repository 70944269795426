import { CommandProps, Extension } from '@tiptap/core';

// Utils
import { isTiptapEditorEmpty } from '../utils/isTiptapEditorEmpty';
import { Editor } from '@tiptap/react';
import { TiptapNodeType } from '../tiptapTypes';
import { listHelpers } from '@tiptap/extension-list-keymap';
import { LIST_CONTAINER_TYPES } from './list/tiptapListUtils';

const WARNING_MESSAGE = `Tiptap.EmptyDelete: The Milanote "EmptyDelete" extension was triggered without a configured handler.

    When using the EmptyDelete extension, it should be configured with an onEmptyDelete handler. E.g:
    
    EmptyDelete.configure({
        onEmptyDelete: () => {
            console.log('Handling onEmptyDelete');
        },
    });
`;

enum BackspaceOperation {
    ignore = 0,
    convertToParagraph = 'convertToParagraph',
    removeBlockquote = 'removeBlockquote',
    joinToBlockquote = 'joinToBlockquote',
    liftList = 'liftList',
}

/**
 * Determine the operation to perform when the backspace key is pressed.
 */
const getBackspaceOperation = ({ tr, state }: CommandProps): BackspaceOperation => {
    const { empty, $from } = tr.selection;

    // If the selection isn't empty, we let the standard backspace handler remove the text
    if (!empty) return BackspaceOperation.ignore;

    // Is the selection at the start of the line
    if ($from.parentOffset !== 0) return BackspaceOperation.ignore;

    // Get current line
    const parentNode = $from.parent;
    const topLevelNode = $from.node(1);

    if (!topLevelNode || !parentNode) return BackspaceOperation.ignore;

    if (topLevelNode.type === state.schema.nodes.paragraph) {
        // The node that we'll be moving into
        const $targetPos = state.doc.resolve(Math.max($from.pos - 2, 1));

        return $targetPos.node().type === state.schema.nodes.blockquote
            ? BackspaceOperation.joinToBlockquote
            : BackspaceOperation.ignore;
    }

    if (topLevelNode.type === state.schema.nodes.blockquote) return BackspaceOperation.removeBlockquote;

    // If not a list node, we can convert it straight to a paragraph
    if (!LIST_CONTAINER_TYPES.has(topLevelNode.type.name as TiptapNodeType))
        return BackspaceOperation.convertToParagraph;

    // For paragraphs in lists - we need to lift it to the top level
    const listItemNode = $from.node(-1);

    if (listHelpers.hasListItemAfter(listItemNode.type.name, state)) return BackspaceOperation.ignore;

    const hasSubList = [...LIST_CONTAINER_TYPES].some((listType) =>
        listHelpers.listItemHasSubList(listType, state, listItemNode),
    );

    if (hasSubList) return BackspaceOperation.ignore;

    return BackspaceOperation.liftList;
};

/**
 * Perform a specific backspace operation.
 */
const performBackspaceOperation = (operation: BackspaceOperation, commandProps: CommandProps) => {
    const { dispatch, commands, tr } = commandProps;

    if (!operation) return false;
    if (!dispatch) return true;

    switch (operation) {
        case BackspaceOperation.convertToParagraph: {
            commands.setParagraph();
            return true;
        }
        case BackspaceOperation.removeBlockquote: {
            commands.unsetBlockquote();
            return true;
        }
        case BackspaceOperation.joinToBlockquote: {
            // Connect the text in this paragraph to the text in the previous blockquote's paragraph
            tr.delete(
                // Logic is, remove from:
                //  - past the start of this paragraph's node (-1)
                //  - past the closing blockquote node (-1)
                //  - past the closing paragraph node (-1)
                tr.selection.$from.pos - 3,
                // to:
                //  - the start of this paragraph's text
                tr.selection.$from.pos,
            );

            return true;
        }
        case BackspaceOperation.liftList: {
            const listItemNode = tr.selection.$from.node(-1);
            commands.liftListItem(listItemNode.type);
            return true;
        }
    }
};

export interface EmptyDeleteOptions {
    /**
     * The function that will get invoked if the editor is empty
     * when the "delete" or "backspace" key is pressed.
     *
     * It should return `true` if it's handled, `false` otherwise.
     */
    onEmptyDelete: (editor: Editor) => boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        emptyDelete: {
            /**
             * Invokes
             * @example editor.commands.checkIfEmptyDelete()
             */
            checkIfEmptyDelete: () => ReturnType;
            removeNodeTypeIfStartOfLine: () => ReturnType;
        };
    }
}

export const EmptyDelete = Extension.create<EmptyDeleteOptions>({
    name: 'emptyDelete',

    addOptions() {
        return {
            onEmptyDelete: (editor: Editor) => {
                console.warn(WARNING_MESSAGE);
                return false;
            },
        };
    },

    addCommands() {
        return {
            checkIfEmptyDelete:
                () =>
                ({ commands, chain, state, editor }) => {
                    const { onEmptyDelete } = this.options;

                    if (!isTiptapEditorEmpty(editor)) return false;

                    return onEmptyDelete(editor);
                },
            removeNodeTypeIfStartOfLine: () => (commandProps) => {
                const operation = getBackspaceOperation(commandProps);
                return performBackspaceOperation(operation, commandProps);
            },
        };
    },

    addKeyboardShortcuts() {
        const onBackspace = () =>
            this.editor.commands.first(({ commands }) => [
                commands.removeNodeTypeIfStartOfLine,
                commands.checkIfEmptyDelete,
            ]);

        const onDelete = () => this.editor.commands.checkIfEmptyDelete();

        const backspaceHandlers = [
            'Backspace',
            'Shift-Backspace',
            'Alt-Backspace',
            'Control-Backspace',
            'Cmd-Backspace',
        ].map((shortcut) => [shortcut, onBackspace]);

        const deleteHandlers = ['Delete', 'Shift-Delete', 'Alt-Delete', 'Control-Delete', 'Cmd-Delete'].map(
            (shortcut) => [shortcut, onDelete],
        );

        return Object.fromEntries([...backspaceHandlers, ...deleteHandlers]);
    },
});
