// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import * as elementRegistry from '../../../common/elements/elementRegistry';

// Selectors
import getGridSize from '../../utils/grid/gridSizeSelector';
import { getElementType } from '../../../common/elements/utils/elementPropertyUtils';
import { isSkeleton } from '../../../common/elements/utils/elementTypeUtils';
import { isElementSelectedSelector } from '../selectors/elementSelector';
import { getWorkspaceLoadIdleTimestamp } from '../../app/initialisation/initialisationSelector';
import { getMilanoteApplicationModeSelector } from '../../platform/platformSelector';

// Actions
import { addSelectedElements } from '../selection/selectionActions';

// Components
import elementModalElementIdSynchroniser from './elementModalElementIdSynchroniser';
import elementModalElementFetcher from './elementModalElementFetcher';
import RouteModalRoot from '../../components/modal/RouteModalRoot';
import NoModalComponentElementModal from './NoModalComponentElementModal';
import NotFoundElementModal from './NotFoundElementModal';
import ModalCloseButton from '../../components/modal/ModalCloseButton';
import ElementAccessDeniedModal from './ElementAccessDeniedModal';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

import './ElementModal.scss';

const getDisplayElementComponent = (props) => {
    const { element } = props;

    if (!element) return { DisplayElement: NotFoundElementModal, isError: true };

    if (isSkeleton(element)) return { DisplayElement: ElementAccessDeniedModal, isError: true };

    const DisplayElement = elementRegistry.getElementModalComponent(getElementType(element));

    if (!DisplayElement) return { DisplayElement: NoModalComponentElementModal, isError: true };

    return { DisplayElement, isError: false };
};

const mapStateToProps = createStructuredSelector({
    isElementSelected: isElementSelectedSelector(),
    workspaceLoadIdleTime: getWorkspaceLoadIdleTimestamp,
    appMode: getMilanoteApplicationModeSelector,
    gridSize: getGridSize,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSelectElements: (ids) => dispatch(addSelectedElements({ ids })),
});

@elementModalElementIdSynchroniser
@elementModalElementFetcher
@connect(mapStateToProps, mapDispatchToProps)
class ElementModal extends React.Component {
    constructor(props) {
        super(props);

        this.closeCallbackRef = React.createRef();
        this.state = { outsideModalChildren: null, elementHasBeenSelected: props.isElementSelected };
    }

    /**
     * The DocumentModal needs to save its text content before the close event happens otherwise the undo / redo
     * order will be incorrect.
     */
    close = (event) => {
        this.closeCallbackRef.current && this.closeCallbackRef.current(event);
        this.props.close(event);
    };

    elementInstanceModalRef = (c) => {
        this.elementInstanceModalComponent = c;
    };

    setOutsideModalChildren = (outsideModalChildren) => this.setState({ outsideModalChildren });

    componentDidUpdate() {
        // When user goes to an element modal page directly, select element if it hasn't been selected yet.
        // This will disable editing in other tabs that belongs to the same user.
        //
        // IMPORTANT: We need to do this after the page is ready, otherwise the the selection action won't be passed
        // through the websocket.
        const isPageReady =
            this.props.isElementFetched && this.props.socketConnected && this.props.workspaceLoadIdleTime;

        if (!this.state.elementHasBeenSelected && !this.props.isElementSelected && isPageReady) {
            this.props.dispatchSelectElements([this.props.elementId]);
            this.setState({ elementHasBeenSelected: true });
        }
    }

    render() {
        const { element, isElementFetched, appMode } = this.props;
        const { outsideModalChildren } = this.state;

        if (!element && !isElementFetched) return null;

        const elementType = getElementType(element);

        const alignTop = elementType === ElementType.CARD_TYPE || elementType === ElementType.DOCUMENT_TYPE;
        const { DisplayElement, isError = false } = getDisplayElementComponent(this.props);

        return (
            <RouteModalRoot
                {...this.props}
                className={classNames('ElementModal', elementType && elementType.toLowerCase())}
                outsideModalChildren={outsideModalChildren}
                close={this.close}
                isError={isError}
                padding={false}
                styled={false}
                alignTop={alignTop}
                autoWidth
                blur
                hideClose
                noTransition
            >
                {appMode === MilanoteApplicationMode.mobileLegacy && <ModalCloseButton close={this.close} />}
                <div className="element-modal-contents">
                    <DisplayElement
                        {...this.props}
                        isModalView
                        closeCallbackRef={this.closeCallbackRef}
                        setOutsideModalChildren={this.setOutsideModalChildren}
                        elementInstanceModalRef={this.elementInstanceModalRef}
                    />
                </div>
            </RouteModalRoot>
        );
    }
}

ElementModal.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,

    workspaceLoadIdleTime: PropTypes.number,

    socketConnected: PropTypes.bool,
    isElementSelected: PropTypes.bool,
    isElementFetched: PropTypes.bool,

    close: PropTypes.func,
    dispatchSelectElements: PropTypes.func,
    appMode: PropTypes.string,
};

export default ElementModal;
