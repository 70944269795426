// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Selectors
import { getElement } from '../element/selectors/elementSelector';

// Components
import Element from '../element/Element';

// Styles
import './ListElement.scss';

const mapStateToProps = () =>
    createStructuredSelector({
        element: getElement,
    });

const ListElementPresentational = (props) => {
    return (
        <div className="ListElement">
            {props?.ElementComponent ? <props.ElementComponent {...props} /> : <Element {...props} />}
        </div>
    );
};

ListElementPresentational.propTypes = {
    currentBoardId: PropTypes.string.isRequired,
    ElementComponent: PropTypes.element,
};

export default connect(mapStateToProps)(ListElementPresentational);
