import { EditorState, Transaction } from '@tiptap/pm/state';

export const isUndoRedo = (transactions: readonly Transaction[]): boolean =>
    transactions.some((transaction) => transaction.getMeta('history$'));

export const hasDocChanges = (
    transactions: readonly Transaction[],
    oldState: EditorState,
    newState: EditorState,
): boolean => transactions.some((transaction) => transaction.docChanged) && !oldState.doc.eq(newState.doc);
