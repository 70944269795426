// Lib
import React, { createContext, ReactNode } from 'react';
import { Provider, ReactReduxContextValue } from 'react-redux';
import { TiptapEditorStoreActions, TiptapEditorStoreState } from './tiptapEditorStoreTypes';
import { Store, UnknownAction } from 'redux';

// FIXME-Tiptap - WEB-11536 discuss with someone who understands TS better to get these types right
//  And also figure out how to initialise this better
export const TiptapEditorStoreContext = createContext<
    ReactReduxContextValue<TiptapEditorStoreState, TiptapEditorStoreActions>
>(undefined as any);

type EditorStoreProviderProps = {
    store: Store<TiptapEditorStoreState, TiptapEditorStoreActions>;
    children: ReactNode;
};

export const TiptapEditorStoreProvider = ({ store, children }: EditorStoreProviderProps) => (
    <Provider
        context={
            TiptapEditorStoreContext as unknown as React.Context<
                ReactReduxContextValue<TiptapEditorStoreState, UnknownAction>
            >
        }
        store={store}
    >
        {children}
    </Provider>
);
