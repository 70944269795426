export enum MimeType {
    PDF = 'application/pdf',
    HTML = 'text/html',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    MARKDOWN = 'text/markdown; charset=UTF-8',
    PLAIN_TEXT = 'text/plain; charset=UTF-8',
    // Images
    GIF = 'image/gif',
    WEBP = 'image/webp',
    JPEG = 'image/jpeg',
    SVG = 'image/svg+xml',
    PNG = 'image/png',
    OCTET_STREAM = 'application/octet-stream',
    ZIP = 'application/zip',
}

export enum DataTransferTypes {
    PLAIN_TEXT = 'text/plain',
    RICH_TEXT = 'text/rtf',
    HTML = 'text/html',
    FILES = 'Files',
    ELEMENTS = 'application/milanote-elements',
    URI_LIST = 'text/uri-list',
    JSON = 'application/json',
}
