import { clamp } from 'lodash';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';
import { getDomElementId } from '../../../element/utils/elementUtil';

const SCROLL_PADDING = 50;
const SCROLL_PADDING_WITH_ELEMENT_PADDING = 75;

export type ScrollFocusClientCoords = { x: number; y: number };
export type ScrollFocus = 'start' | ScrollFocusClientCoords;

export const getContentDomElement = (elementId: string) => {
    const elementDomId = getDomElementId(elementId);

    // If there is a separate editor component open for this element, use that
    const editorElement = document.getElementById(`${elementDomId}-editor`);
    if (editorElement) return editorElement;

    // Otherwise, use the element itself
    return document.getElementById(elementDomId);
};

/**
 * Horizontally centers the element that's being edited.
 */
const getTargetScrollLeft = (
    scrollableElement: HTMLElement,
    scrollableElementRect: Rect,
    elementRect: Rect,
    defaultScrollFocus: ScrollFocus,
) => {
    // If the element is larger than the window, and the user clicked on a TipTap editor, we want to scroll
    // to the position where the user clicked on
    const isElementLargerThanWindow = elementRect.width >= scrollableElementRect.width;
    if (isElementLargerThanWindow) {
        if (defaultScrollFocus === 'start') {
            return elementRect.left - SCROLL_PADDING;
        }

        return clamp(
            scrollableElement.scrollLeft + defaultScrollFocus.x - 100,
            elementRect.right - scrollableElementRect.width + SCROLL_PADDING,
        );
    }

    // Otherwise, we want to scroll to the center of the element
    const centerOffsetX = (scrollableElementRect.width - elementRect.width) / 2;

    return elementRect.left - centerOffsetX;
};

/**
 * Gets the target scrollTop property for the scrollable element.
 */
const getTargetScrollTop = (
    scrollableElement: HTMLElement,
    scrollableElementRect: Rect,
    elementRect: Rect,
    scrollFocus: ScrollFocus,
) => {
    if (scrollFocus === 'start') return elementRect.top - SCROLL_PADDING;

    // If given the exact point to scroll to, we want to scroll to a position slightly above the click position.
    // e.g. When the user click on a TipTap editor, it will pass editorFocusClientCoords, the point the user clicks on
    return (
        scrollableElement.scrollTop + scrollFocus.y - scrollableElementRect.top - SCROLL_PADDING_WITH_ELEMENT_PADDING
    );
};

/**
 * Determines the target scroll position for the scrollable element.
 */
export const getTargetScrollToElement = (
    scrollableElement: HTMLElement,
    elementRect: Rect,
    scrollFocus: ScrollFocus,
) => {
    const scrollableElementRect = scrollableElement.getBoundingClientRect();

    const targetScrollLeft = getTargetScrollLeft(scrollableElement, scrollableElementRect, elementRect, scrollFocus);
    const targetScrollTop = getTargetScrollTop(scrollableElement, scrollableElementRect, elementRect, scrollFocus);

    const maxScrollLeft = scrollableElement.scrollWidth - scrollableElementRect.width;
    const maxScrollTop = scrollableElement.scrollHeight - scrollableElementRect.height;

    // Clamp the target scroll values to the scrollable element's bounds
    return {
        left: clamp(targetScrollLeft, 0, maxScrollLeft),
        top: clamp(targetScrollTop, 0, maxScrollTop),
    };
};
