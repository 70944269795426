// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../../node_module_clones/recompose';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';

// Components
import ElementIcon from '../../../components/elementIcon/ElementIcon';
import BoardStatus from './BoardStatus';
import BoardShortcutIcon from './BoardShortcutIcon';
import BoardImage from './BoardImage';
import BoardIconTag from './BoardIconTag';
import BoardActivityIndicator from '../../activity/BoardActivityIndicator';
import QuickLineCreationTool from '../../quickLine/QuickLineCreationTool';
import ElementFolderIcon from '../../../components/folderIcon/ElementFolderIcon';

// Utils
import { getColorCssValue } from '../../utils/elementColorStyleUtils';
import { getIsLightElementColor } from '../../../../common/colors/colorComparisonUtil';
import { getAttachment, getData } from '../../attachments/attachmentsSelector';
import { getGridSize } from '../../../utils/grid/gridSizeSelector';
import { gridPointsToPixels } from '../../../utils/grid/gridUtils';
import dontUpdateForKeys from '../../../utils/milanoteRecompose/dontUpdateForKeys';
import { getShowQuickLineTool } from '../../quickLine/quickLineUtil';
import { getImageProp, getSecondaryColor } from '../../../../common/elements/utils/elementPropertyUtils';

// Styles
import './BoardIcon.scss';

const getStyle = ({ size, colorHex, gridSize, hasImageData }) => {
    const iconSize = gridPointsToPixels(size, gridSize);

    const style = {
        width: iconSize,
        height: iconSize,
        fontSize: `${size / 7.2 || 1}em`,
    };

    if (colorHex && !hasImageData) {
        style.backgroundColor = colorHex;
    } else if (hasImageData) {
        style.background = 'none';
    }

    return style;
};

const getIconClass = ({ size, showNavigationFeedback }) => ({
    small: size <= 5,
    medium: size > 5 && size <= 6,
    'pre-nav': showNavigationFeedback,
});

const boardIconSelector = createStructuredSelector({
    gridSize: getGridSize,
    attachment: getAttachment,
});

const BoardIcon = (props) => {
    const {
        icon,
        currentBoardId,
        elementId,
        element,
        size,
        gridSize,
        attachment,
        color,
        showActivityIndicator = false,
        isSelected,
        getContextZoomScale,
        getContextZoomTranslationPx,
        isSidebarPrototypeEnabled,
    } = props;

    const hasImageData = getImageProp(element) || getData(attachment);
    const secondaryColor = getSecondaryColor(element);

    const colorHex = getColorCssValue(color);
    const boardIconContentStyle = getStyle({ size, colorHex, gridSize, hasImageData });

    const folderIconStyle = {
        ...boardIconContentStyle,
        backgroundColor: 'none',
        width: '100px',
    };
    const isLightBg = getIsLightElementColor(color);

    const iconElement = hasImageData ? (
        <BoardImage element={element} size={size} gridSize={gridSize} />
    ) : (
        <ElementIcon
            icon={icon}
            sizePx={size * gridSize}
            isSelected={isSelected}
            isDarkBackground={!isLightBg}
            secondaryColor={secondaryColor}
            showLoading
            enableFade
        />
    );

    return (
        <div className={classNames('BoardIcon', getIconClass(props))}>
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            {showActivityIndicator && <BoardActivityIndicator {...props} />}
            <BoardIconTag {...props} />
            <div className="drag-preview-shadow" />
            <div
                className={classNames('board-icon-content drag-handle', { 'color-target': isSelected })}
                style={isSidebarPrototypeEnabled ? folderIconStyle : boardIconContentStyle}
            >
                {isSidebarPrototypeEnabled ? (
                    <ElementFolderIcon
                        gridSize={gridSize}
                        style={{
                            '--ws-board-icon-background-color': boardIconContentStyle?.backgroundColor || colorHex,
                        }}
                        iconElement={iconElement}
                        hasImageData={hasImageData}
                    />
                ) : (
                    iconElement
                )}
            </div>

            <BoardStatus {...props} />
            <BoardShortcutIcon {...props} />
        </div>
    );
};

BoardIcon.propTypes = {
    size: PropTypes.number.isRequired,
    iconName: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.object,
    gridSize: PropTypes.number,
    currentBoardId: PropTypes.string,
    elementId: PropTypes.string,
    element: PropTypes.object,
    attachment: PropTypes.object,
    showNavigationFeedback: PropTypes.bool,
    showActivityIndicator: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    inList: PropTypes.string,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
    isSidebarPrototypeEnabled: PropTypes.bool,
};

const enhance = compose(
    connect(boardIconSelector),
    dontUpdateForKeys(['elementEvents', 'childrenTypes', 'isBoardFetched', 'isBoardFetchError']),
);

export default enhance(BoardIcon);
