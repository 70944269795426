import React, { memo } from 'react';
import classNames from 'classnames';
import ReactionPopupEmojiButton from './ReactionPopupEmojiButton';
import { getCompleteEmojiCode, getUnified, isTitleRow } from './reactionPopupUtil';
import { areEqual } from 'react-window';
import { Emoji, EmojisRowData } from '../emojiTypes';

type ReactionPopupRowProps = {
    style: React.CSSProperties;
    index: number;
    data: {
        rows: EmojisRowData;
        skinTone: string;
        selectedReactionIds: Immutable.List<string>;
        handleClickEmoji: (emoji: Emoji) => void;
        gridSize: number;
        emojiButtonSize?: number;
    };
};

const ReactionPopupRow = memo(function ReactionPopupRow({
    style,
    index,
    data: { rows, skinTone, selectedReactionIds, handleClickEmoji, gridSize, emojiButtonSize },
}: ReactionPopupRowProps) {
    const row = rows[index];

    if (isTitleRow(row)) {
        return (
            <div style={style} className={classNames('emoji-list-row title', { first: index === 0 })}>
                <h2>{row.title}</h2>
            </div>
        );
    }

    return (
        <div style={{ ...style, display: 'flex' }} className="emoji-list-row emojis">
            {row.emojis.map((emoji: Emoji) => (
                <ReactionPopupEmojiButton
                    key={getUnified(emoji)}
                    emoji={emoji}
                    emojiButtonSize={emojiButtonSize}
                    gridSize={gridSize}
                    selected={selectedReactionIds.indexOf(getCompleteEmojiCode(emoji, skinTone)) > -1}
                    skinTone={skinTone}
                    onClickFn={() => handleClickEmoji(emoji)}
                />
            ))}
        </div>
    );
},
areEqual);

export default ReactionPopupRow;
