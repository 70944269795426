import { useDispatch } from 'react-redux';
import { getColorToPersist } from '../../../../../../../../common/colors/elementColorFormatUtil';
import {
    updateSelectedElementsBackgroundColors,
    updateSelectedElementsColors,
    updateSelectedElementsSecondaryColors,
} from '../../colorActions';
import setTemporarySelectedElementsColorStyles from '../../setTemporarySelectedElementsColorStyles';
import setTemporarySelectedElementsBackgroundColorStyles from '../../setTemporarySelectedElementsBackgroundColorStyles';
import { getFirstSelectedSecondaryColor } from '../../colorToolUtils';
import { COLOR_POPUP_MODE } from '../colorPopupConstants';
import { ImMNElement } from '../../../../../../../../common/elements/elementModelTypes';

const useColorUpdateHandlers = (
    isBackgroundMode: boolean,
    selectedElements: Immutable.List<ImMNElement>,
    supportDualColors: boolean,
    actualPopupMode: string,
) => {
    const dispatch = useDispatch();

    // --- Color updates ---

    const setColor = (colorValue: string | null, secondaryColorValue?: string | null) => {
        const color = getColorToPersist(colorValue);
        const secondaryColor = (secondaryColorValue && getColorToPersist(secondaryColorValue)) ?? null;
        dispatch(updateSelectedElementsColors({ color, secondaryColor, useExistingColorSpace: null }));
    };

    const setBackgroundColor = (colorValue: string | null, secondaryColorValue?: string | null) => {
        const color = getColorToPersist(colorValue);
        const secondaryColor = (secondaryColorValue && getColorToPersist(secondaryColorValue)) ?? null;
        dispatch(updateSelectedElementsBackgroundColors(color, secondaryColor));
    };

    const setColorHandler = isBackgroundMode ? setBackgroundColor : setColor;

    // --- Temporary color updates ---

    const secondaryColor = getFirstSelectedSecondaryColor(selectedElements);
    const setSecondaryColor = (secondaryColorValue: string | null) => {
        const secondaryColor = getColorToPersist(secondaryColorValue);
        dispatch(updateSelectedElementsSecondaryColors({ secondaryColor }));
    };

    const setTempColor = isBackgroundMode
        ? setTemporarySelectedElementsBackgroundColorStyles
        : setTemporarySelectedElementsColorStyles;

    const setTempColorHandler = (color: string) => {
        // if there is a secondary color while using the color picker, set it to null on the first input.
        // Needs to happen before setTempColor so that the css for the icon color gets set to black/white
        if (supportDualColors && secondaryColor && actualPopupMode !== COLOR_POPUP_MODE.COLOR_BAR) {
            setSecondaryColor(null);
        }
        setTempColor(color);
    };

    return { setTempColorHandler, setColorHandler };
};

export default useColorUpdateHandlers;
