// Lib
import { createSelector, createStructuredSelector } from 'reselect';

// Utils
import { ImmutableMap } from '../../../common/utils/immutableHelper';
import { getIsFeatureEnabledForCurrentUser } from '../../element/feature/elementFeatureSelector';
import { getElements } from '../../element/selectors/elementsSelector';
import { elementGraphSelector } from '../../element/selectors/elementGraphSelector';
import { getInboxElements } from '../../../common/elements/utils/elementGraphUtils';
import { sortByScoreProperty } from '../../../common/elements/utils/elementSortUtils';
import { getElementId, getLinkedElementId } from '../../../common/elements/utils/elementPropertyUtils';
import popupOpenSelector from '../../components/popupPanel/popupOpenSelector';

// Constants
import { ExperimentId } from '../../../common/experiments/experimentsConstants';
import { PopupIds } from '../../components/popupPanel/popupConstants';

export const getCurrentUserFavoriteBoardsRootId = (state) =>
    state.getIn(['app', 'currentUser', 'favouriteBoardsRootId']);

export const getCurrentUserArchiveRootId = (state) => state.getIn(['app', 'currentUser', 'archiveRootId']);

export const getCurrentUserSharedRootId = (state) => state.getIn(['app', 'currentUser', 'sharedRootId']);

export const getIsSidebarPrototypeEnabled = (state: ImmutableMap) => {
    return getIsFeatureEnabledForCurrentUser(ExperimentId.favouritesSidebarPrototype)(state);
};

export const getArchiveChildIdsSelector = createSelector(
    getElements,
    elementGraphSelector,
    getCurrentUserArchiveRootId,
    (elements, elementGraph, archiveRootId) => {
        const inboxElements = getInboxElements({ elements, elementGraph, parentId: archiveRootId });

        return inboxElements.sort(sortByScoreProperty).toList().map(getElementId).toArray();
    },
);

export const getArchiveBoard = createSelector(getElements, getCurrentUserArchiveRootId, (elements, archiveRootId) =>
    elements.get(archiveRootId),
);

export const getFavoriteBoardsSelector = createSelector(
    getElements,
    elementGraphSelector,
    getCurrentUserFavoriteBoardsRootId,
    (elements, elementGraph, favouriteBoardsRootId) => {
        const inboxElements = getInboxElements({ elements, elementGraph, parentId: favouriteBoardsRootId });

        return inboxElements.sort(sortByScoreProperty).toList();
    },
);

export const archiveElementsSelector = createStructuredSelector({
    elementId: getCurrentUserArchiveRootId,
    childIds: getArchiveChildIdsSelector,
    rootId: getCurrentUserArchiveRootId,
    element: getArchiveBoard,
    popupOpen: popupOpenSelector(PopupIds.ARCHIVE),
});

export const getSharedRootBoard = createSelector(getElements, getCurrentUserSharedRootId, (elements, sharedRootId) =>
    elements.get(sharedRootId),
);

export const getSharedChildIdsSelector = createSelector(
    getElements,
    elementGraphSelector,
    getCurrentUserSharedRootId,
    (elements, elementGraph, sharedRootId) => {
        const inboxElements = getInboxElements({ elements, elementGraph, parentId: sharedRootId });

        return inboxElements.sort(sortByScoreProperty).toList().map(getElementId).toArray();
    },
);

// This selector is used to grab the linked board ids of the child elements of the shared root board
export const getAllSharedBoardsLinkedBoardIdsSelector = createSelector(
    getElements,
    elementGraphSelector,
    getCurrentUserSharedRootId,
    (elements, elementGraph, sharedRootId) => {
        const inboxElements = getInboxElements({ elements, elementGraph, parentId: sharedRootId });

        return inboxElements.toList().map(getLinkedElementId);
    },
);

export const sharedBoardsPopupSelector = createStructuredSelector({
    elementId: getCurrentUserSharedRootId,
    childIds: getSharedChildIdsSelector,
    rootId: getCurrentUserSharedRootId,
    popupOpen: popupOpenSelector(PopupIds.SHARED_WITH_YOU),
    element: getSharedRootBoard,
});
