import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { ImMNUser } from '../../../common/users/userModelTypes';
import { ElementType } from '../../../common/elements/elementTypes';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

export type ToolbarItemHeightConfig = {
    large: number;
    medium: number;
    small: number;
};

export type ElementToolSettings = {
    elementTypeObject: any;
    selectOnCreate?: boolean;
    editOnCreate?: boolean;
    linkType?: string;
};

export type MoreToolsSettings = {
    items: ToolbarItemConfig[];
    groupSize: number;
};

export type EditorToolSettings = {
    styleCommand: string;
};

export type EditorTextStyleToolSettings = {
    showBlockStyleTools: boolean;
};

export type ToolbarItemConfig = {
    id: string;
    name: string | null;
    component: React.ComponentType<any>;
    height: ToolbarItemHeightConfig;

    /**
     * A uid of the tool made up of the tool id and toolList id.
     * */
    uid?: string;

    iconName?: string;
    tooltipText?: string;
    shortcut?: string[];

    /**
     * what platforms is this tool available on?
     */
    appModeAvailability: MilanoteApplicationMode[];

    /**
     * optional component to render on mobile instead of the default component
     * */
    mobileComponent?: React.ComponentType<any>;

    /**
     * optional component to render on desktop instead of the default component
     * */
    desktopComponent?: React.ComponentType<any>;

    /**
     *  Specific tool config — varies by tool
     * */
    toolSettings?: ElementToolSettings | EditorToolSettings | MoreToolsSettings | EditorTextStyleToolSettings;

    /**
     * Order in which the item will be removed from the toolbar not enough space is available.
     * Higher values will be removed first.
     * Value of >= 10000 always collapsed.
     * Value of undefined or 0 means the item will never be collapsed.
     * */
    collapseOrder?: number | ((activeTools: ToolbarItemConfig[]) => number);

    /**
     * Should this tool be collapsed or hidden when there is not enough space?
     * Default is 'collapse'.
     * */
    collapseBehavior?: 'collapse' | 'hide';

    // availability prerequisites

    /**
     * should this tool be available when multiple elements are selected?
     * */
    multiSelect?: boolean;

    /**
     * predicate to determine if the tool should be available based on the selected elements
     */
    availabilityPredicate?: (args: {
        selectedElements: Immutable.List<ImMNElement>;
        selectedChildElements: Immutable.List<ImMNElement>;
        currentUser: ImMNUser;
        permissions: number;
        editorKey: string | undefined;
        isEditingRange: boolean | undefined;
    }) => boolean;

    /**
     * what permission is required for this tool to be available?
     * */
    permissionPredicate?: (selectedElements: Immutable.List<ImMNElement>) => number;
};

export enum ToolbarListType {
    CREATE_ELEMENT = 'create-element', // default toolbar on desktop
    SELECTED = 'selected',
    EDITING = 'editing',
    EDITING_RANGE = 'editing-range',
    // mobile toolbars
    MOBILE_TABS = 'mobile-tabs', // default toolbar on mobile
    MOBILE_UTILS = 'mobile-utils',
}

export type ToolbarList = {
    id: string;
    type: ToolbarListType;
    items: ToolbarItemConfig[];
    elementTypes: Set<ElementType>;
    capturedState?: {
        selectedElements: Immutable.List<ImMNElement>;
        selectedElementIds: Immutable.List<string>;
        currentUser: ImMNUser;
        editorKey: string | undefined;
        hasEditorSelection: boolean;
        permissions: number;
    };
};

export type ToolbarState = {
    focusIndex: number;
    toolbars: ToolbarList[];
};
