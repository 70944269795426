// Types
import { JSONContent, Content } from '@tiptap/react';
import { Transaction } from '@tiptap/pm/state';

/*
 * Represents a node of tiptap content. Can be recursive (eg a bullet list node can contain
 * many bullet list item nodes, which themselves may contain text nodes, etc).
 */
export type TiptapContentNode = JSONContent;

/*
 * Represents a mark on a tiptap content node, eg links and text styling
 */
export type TiptapContentMark<Name extends string = string, Attrs extends Record<string, any> = Record<string, any>> = {
    type: Name;
    attrs?: Attrs;
    [key: string]: any;
};

/*
 * Represents the top level of a tiptap element's content. Technically this is just another
 * TiptapContentNode, but it has a more predictable structure and it's handy to be able to
 * treat it separately.
 */
export type TiptapContent = {
    type: 'doc';
    content: Array<TiptapContentNode>;
};

/**
 * The dispatch function in Tiptap / ProseMirror commands & transactions.
 * Note: This is usually optional, if it doesn't exist the consumer is attempting
 *  a "dry run" of the transaction using `.can()`.
 */
export type TiptapDispatch = ((tr: Transaction) => void) | undefined;

// This is the type that can be used by the Tiptap Editor component or useEditor hook.
// This includes strings or null as well as TiptapContent
export type TiptapEditorContent = Content;

// By default extensions have their parseHTML function run at priority 50
export const DEFAULT_TIPTAP_PARSE_HTML_PRIORITY = 50;
export const DEFAULT_TIPTAP_EXTENSION_PRIORITY = 100;

export enum TiptapNodeType {
    doc = 'doc',
    paragraph = 'paragraph',
    blockquote = 'blockquote',
    smallText = 'smallText',
    codeBlock = 'codeBlock',
    heading = 'heading',
    bulletList = 'bulletList',
    orderedList = 'orderedList',
    listItem = 'listItem',
    text = 'text',
    taskList = 'taskList',
    taskItem = 'taskItem',
    hardBreak = 'hardBreak',
    mention = 'mention',
}

export enum TiptapMarkType {
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
    strike = 'strike',
    link = 'link',
    code = 'code',
    textStyle = 'textStyle',
    highlight = 'highlight',
    textMention = 'textMention',
}

export enum TiptapAlignments {
    left = 'left',
    center = 'center',
}

export const ALIGNMENT_SUPPORTED_NODE_TYPES = [
    TiptapNodeType.heading,
    TiptapNodeType.paragraph,
    TiptapNodeType.smallText,
];
