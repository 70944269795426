// Types
import { Editor } from '@tiptap/core';
import DefaultListKeymap, { listHelpers } from '@tiptap/extension-list-keymap';

/**
 * This simply checks if the selection is not-empty and within a single node, and if so,
 * it won't do anything - otherwise it uses the default handler.
 *
 * If we instead used the default handler, selections that start at the end of the node
 * will result in the selected text *not* being deleted, and the text from the following
 * node being joined to this one.
 *
 * TODO (WEB-12168): This now has firstparty support and can be removed
 */
const customHandleDelete = (editor: Editor, name: string) => {
    const { selection } = editor.state;
    const { $from, $to } = selection;

    if (!selection.empty && $from.sameParent($to)) return false;

    return listHelpers.handleDelete(editor, name);
};

/**
 * Overrides the extension to add the Alt+Delete and Alt+Backspace keyboard shortcuts.
 */
export const ListKeymap = DefaultListKeymap.extend({
    /**
     * Currently the default ListKeymap extension does not provide shortcuts
     * for Alt-Delete and Alt-Backspace
     * The default behaviour of these operations is to remove the paragraph from
     * its own list item, but instead attach it to the previous list item, thus
     * appearing to be a soft-line break, rather than joining the paragraphs.
     */
    addKeyboardShortcuts() {
        const defaultShortcuts = this.parent?.() ?? {};

        /**
         * This is a copy of the OOTB delete shortcut handler from ListKeymap,
         * however it delegates to our customDeleteHandler instead.
         *
         * TODO (WEB-12168): This now has firstparty support and can be removed
         */
        const customDeleteShortcutHandler = ({ editor }: { editor: Editor }) => {
            let handled = false;

            this.options.listTypes.forEach(({ itemName }) => {
                if (editor.state.schema.nodes[itemName] === undefined) {
                    return;
                }

                if (customHandleDelete(editor, itemName)) {
                    handled = true;
                }
            });

            return handled;
        };

        return {
            ...defaultShortcuts,
            Delete: customDeleteShortcutHandler,
            'Mod-Delete': customDeleteShortcutHandler,
            'Alt-Delete': customDeleteShortcutHandler,
            'Alt-Backspace': defaultShortcuts.Backspace,
        };
    },
});
