import { RefObject } from 'react';
import { isNil, isNumber, isString } from 'lodash';
import { MAX_SUFFIX } from '../hooks/useSheetContextState';
import { DragState } from '../hooks/useSheetHandlers';
import safeAreaSingleton from '../../../utils/safeAreaSingleton';

const MAX_DEFAULT_REGEX = /(\d|.)+max$/;
const VELOCITY_MULTIPLIER = 150;

/**
 * Get the height of the area that the sheet can be snapped to.
 * Note that this value is used for calculating the snap height, but the sheet can be dragged past this point.
 */
export const getSheetSnapPointWindowHeight = () => window.innerHeight - safeAreaSingleton.getSafeArea().top;

/**
 * Initialise the snap points array.
 * Add 0 as a snap point to dismiss the sheet, and remove any invalid snap points
 * @param snapPoints
 * @param preventDismiss
 */
export const initialiseSnapPoints = (snapPoints: number[], preventDismiss: boolean): number[] => {
    const points = snapPoints.filter((snapPoint) => snapPoint <= 1 && snapPoint > 0).sort();

    return preventDismiss ? points : [0, ...points];
};

export const getDefaultSnapPointDetails = (
    snapPoint: number | string | undefined,
): { point?: number; suffix?: string } => {
    if (!snapPoint) return {};

    // Check if it has the max suffix
    if (isString(snapPoint) && snapPoint.match(MAX_DEFAULT_REGEX)) {
        return { point: Number(snapPoint.split(MAX_SUFFIX)[0]), suffix: MAX_SUFFIX };
    }

    if (isNumber(snapPoint)) {
        return { point: snapPoint };
    }

    return {};
};

export const setSheetHeight = (sheetRef: RefObject<HTMLDivElement>, height: number | undefined): void => {
    if (!sheetRef.current || isNil(height)) return;

    const heightStyle = `${height}px`;
    if (sheetRef.current.style.height === heightStyle) return;

    sheetRef.current.style.height = heightStyle;
    sheetRef.current.classList.toggle('sheet-covers-screen', height === window.innerHeight);
};

export const getNewSheetTop = (dragState: RefObject<DragState>, highestSnapPosition: number): number | undefined => {
    if (!dragState.current) return;

    const { currentY, sheetTouchOffset } = dragState.current;

    const newSheetTop = currentY - sheetTouchOffset;
    // if the sheet has gone past the top snap point, only use half the distance between finger and highest snap point
    const slowDragAdjustment = newSheetTop < highestSnapPosition ? (highestSnapPosition - newSheetTop) / 2 : 0;
    const intendedSheetTop = newSheetTop + slowDragAdjustment;
    return Math.max(intendedSheetTop, 0);
};

export const getSheetHeightFromTopValue = (top: number): number => window.innerHeight - top;

export const getNewSheetHeight = (dragState: RefObject<DragState>, highestSnapPosition: number): number | undefined => {
    const newSheetTop = getNewSheetTop(dragState, highestSnapPosition);
    if (isNil(newSheetTop)) return;

    return getSheetHeightFromTopValue(newSheetTop);
};

export const getSnapPointSheetHeight = (snapPoint: number): number => snapPoint * getSheetSnapPointWindowHeight();

export const getSnapPointTopValue = (snapPoint: number): number =>
    window.innerHeight - getSnapPointSheetHeight(snapPoint);

export const getNewActiveSnapPoint = (dragState: DragState, snapPointsState: number[]): number => {
    const { currentY, sheetTouchOffset, velocity } = dragState;

    // If user is dragging over a certain velocity, adjust the intended position to be further in that direction
    // Multiplier is just an arbitrary number based on what feels good. Velocity in px/ms
    const velocityAdjustment = VELOCITY_MULTIPLIER * velocity;
    const intendedSheetTop = currentY - sheetTouchOffset - velocityAdjustment;
    const snapDistances = snapPointsState.map((snapPoint: number) =>
        Math.abs(intendedSheetTop - getSnapPointTopValue(snapPoint)),
    );
    const smallestDistance = Math.min(...snapDistances);

    // go to the closest snap point
    const index = snapDistances.indexOf(smallestDistance);
    return snapPointsState[index];
};

/**
 * Set the sheet height as the current computed height and maxHeight to maximum to allow for animation back down to 0
 * @param sheetRef
 */
export const setCurrentHeightForAnimation = (sheetRef: RefObject<HTMLDivElement | null>): void => {
    if (!sheetRef.current) return;

    sheetRef.current.style.height = getComputedStyle(sheetRef.current).height;
    sheetRef.current.style.maxHeight = `100%`;
};
