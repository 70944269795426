import React, { FC, useCallback, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { get } from 'lodash/fp';

interface SuggestDocumentHeightObserverProps {
    editorRef: (c: HTMLElement | null) => void;
    onHeightChange?: (height: number) => void;

    isEditing: boolean;
    isResizing: boolean;
}

export default <DecoratedProps extends SuggestDocumentHeightObserverProps>(DecoratedComponent: FC<DecoratedProps>) => {
    const suggestDocumentHeightObserver = ({ editorRef, ...props }: DecoratedProps) => {
        const { isEditing, isResizing, onHeightChange } = props;

        const editorElement = useRef<HTMLElement | null>(null);
        const wrappedEditorRef = useCallback(
            (c: HTMLElement | null) => {
                editorElement.current = c;
                editorRef?.(c);
            },
            [editorRef],
        );

        useEffect(() => {
            if (!isEditing || !onHeightChange || isResizing) return;

            const observer = new ResizeObserver((entries) => {
                const newEditorHeight = get([0, 'contentRect', 'height'], entries);
                onHeightChange(newEditorHeight);
            });

            // TODO-REMOVE-DRAFT: getTextDomElement is draft-specific; this logic can be removed once that's gone
            const editorContentsElement =
                (editorElement.current as any)?.getTextDomElement?.() || editorElement.current;

            if (!editorContentsElement) {
                // This only happens during a live reload - it's fine to ignore
                // (the next edit of the card will re-run this anyway)
                return;
            }
            observer.observe(editorContentsElement);

            return () => observer.disconnect();
        }, [isEditing, isResizing, onHeightChange]);

        return <DecoratedComponent {...(props as DecoratedProps)} editorRef={wrappedEditorRef} />;
    };

    return suggestDocumentHeightObserver;
};
