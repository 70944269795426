// Lib
import { connect } from 'react-redux';
import { compose, withHandlers } from '../../../node_module_clones/recompose';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import dragHoverDelayedInteractionDecorator from './dragHoverDelayedInteractionDecorator';

// Actions
import { startOperation } from '../../utils/undoRedo/undoRedoActions';

// Selectors
import { getCurrentOperation } from '../../utils/undoRedo/undoRedoSelector';
import { markPerformingLongHoverNavigation } from './dragAndDropStateSingleton';

const mapStateToProps = (state) => ({
    hoverDelay: getCurrentOperation(state) === 'move' ? 400 : 700,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchStartMoveOperation: () => dispatch(startOperation('move')),
});

const navigateOnHoverDecorator = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleHoverInteraction:
            ({ element, dispatchNavigateToBoard, dispatchStartMoveOperation }) =>
            () => {
                markPerformingLongHoverNavigation();

                dispatchStartMoveOperation();
                dispatchNavigateToBoard(getElementId(element), true);
            },
    }),
    dragHoverDelayedInteractionDecorator,
);

export default navigateOnHoverDecorator;
