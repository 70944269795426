import { get, sortBy, groupBy, flatMap, chunk, escapeRegExp, first } from 'lodash/fp';

import { CATEGORIES, DEFAULT_COLUMN_COUNT, PICKER_ROW_TYPES } from './reactionPopupConstants';
import { Emoji, EmojiRow, EmojisRowData, RecentlyUsedEmojis, TitleRow } from '../emojiTypes';

export const getUnified = get('unified');

export const getCompleteEmojiCode = (emoji: Emoji, skinTone = ''): string =>
    get(['skin_variations', skinTone, 'unified'], emoji) || getUnified(emoji);

export const getCategory = get('category');

export const getSkinToneCodes = (emojiData: Emoji[]) => {
    const groupedEmoji = groupBy('category')(emojiData);
    const skinTones =
        groupedEmoji['Component'] || // new category name
        groupedEmoji['Skin Tones'] || // old category name
        []; // fallback: empty array, shouldn't happen

    return skinTones.map(getUnified);
};

export const getEmojiDataForCode = (emojiData: Emoji[], emojiCode: string) =>
    emojiData.find((emoji) => getUnified(emoji) === emojiCode);

type GroupedEmojis = { [key: string]: Emoji[] };

export const getEmojisRowData = (
    emojiData: Emoji[],
    searchFilter = '',
    columnCount = DEFAULT_COLUMN_COUNT,
): EmojisRowData =>
    flatMap(({ name, groups }) => {
        // lodash.Dictionary<T[]> returns string keys, so we need to cast it to the correct type
        const groupedEmojis = groupBy('category')(emojiData) as unknown as GroupedEmojis;

        const searchFilterRegex = new RegExp(escapeRegExp(searchFilter), 'i');

        const matchEmojiFilter = (emoji: Emoji) =>
            !![emoji.name, ...(emoji.short_names || [])].find((string) => string && string.match(searchFilterRegex));

        const emojisInCategory = flatMap((groupName: keyof GroupedEmojis) =>
            sortBy('sort_order', groupedEmojis[groupName]),
        )(groups).filter((emoji: Emoji) => (searchFilter.length ? matchEmojiFilter(emoji) : true));

        const categoryRows = chunk(columnCount, emojisInCategory).map((emojis: Emoji[]) => ({
            type: PICKER_ROW_TYPES.EMOJI,
            emojis,
        }));

        if (!categoryRows.length) return [];

        return [
            {
                type: PICKER_ROW_TYPES.TITLE,
                title: name,
            },
            ...categoryRows,
        ];
    }, CATEGORIES);

export const isTitleRow = (row: EmojiRow | TitleRow): row is TitleRow => row.type === PICKER_ROW_TYPES.TITLE;

export const prepareRecentlyUsedEmojiCodes = (
    emojiCode: string,
    recentlyUsed: RecentlyUsedEmojis,
): RecentlyUsedEmojis => {
    const count = recentlyUsed[emojiCode] || 0;

    return {
        ...recentlyUsed,
        [emojiCode]: count + 1,
    };
};

export const getEmojiPickerRowKey = ({ emojis, title }: { emojis?: Emoji[]; title?: string }) =>
    title || `${getUnified(first(emojis))}${getCategory(first(emojis))}`;
