// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { getMainEditorId } from '../../utils/elementEditorUtils';

// Selectors
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Components
import CardClipperContentsSwitch from './CardClipperContentsSwitch';

// Types
import { CardClipperProps } from './cardClipperTypes';
import { DeviceFormFactor, MilanoteApplicationMode } from '../../../../common/platform/platformTypes';

const CardClipper = (props: CardClipperProps) => {
    const { isEditing, isClipping } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);

    if (platformDetails.appMode !== MilanoteApplicationMode.desktop) return null;
    if (platformDetails.formFactor === DeviceFormFactor.phone) return null;

    if (!isClipping && !isEditing) return null;

    return <CardClipperContentsSwitch {...props} editorId={getMainEditorId(props)} />;
};

export default CardClipper;
