import { DraftInlineStyleType, RawDraftContentBlock, RawDraftContentState, RawDraftEntity } from 'draft-js';
import { nanoid } from 'nanoid';
import { isNumber } from 'lodash';

import { TiptapContent, TiptapContentNode } from '../../../tiptapTypes';
import { collapseInlineStyleRanges, convertMarkToStyle } from './convertTiptapMarksToInlineStyleRanges';
import { AnnotatedNode, FlattenMethod, getFlattenMethod } from './nodeAnnotation';
import { getGlobalData } from './getGlobalData';
import { getMentionMarkAttrs } from '../../../extensions/mention/mentionUtils';

/**
 * Result of a converter function - we include entity and styles here as it's useful for converter
 * functions to be able to return these, even though they don't end up in the same place as the content
 */
type ConversionResult = Partial<RawDraftContentBlock> & {
    entity?: RawDraftEntity;
    styles?: string[];
};

type Converter = (from: TiptapContentNode, parent?: AnnotatedNode) => ConversionResult;

const convertEntityMarks = (node: TiptapContentNode): RawDraftEntity | undefined => {
    const link = node.marks?.find((m) => m.type === 'link');
    if (link) {
        return {
            type: 'LINK',
            mutability: 'MUTABLE',
            data: { url: link.attrs?.href },
        };
    }

    const mentionAttrs = getMentionMarkAttrs(node);
    if (mentionAttrs) {
        return {
            type: 'mention',
            mutability: 'IMMUTABLE',
            data: { mention: { id: mentionAttrs.userId, name: node.text?.replace('@', '') } },
        };
    }

    return undefined;
};

const convertMarks = (node: TiptapContentNode): ConversionResult => {
    const entity = convertEntityMarks(node);
    const styles = node.marks?.map(convertMarkToStyle).filter((x) => x) as string[] | undefined;

    return { entity, styles };
};

// Note that text nodes will usually be subsumed into their parents
const convertText: Converter = (node) => ({
    type: 'unstyled',
    text: node.text || '',
    ...convertMarks(node),
});

// Some converters don't need to do anything except update the type
const simpleTypeConverter =
    (type: string): Converter =>
    () => ({ type });

const convertHeading: Converter = (node) => ({ type: node.attrs?.level === 1 ? 'header-one' : 'header-two' });

const convertListItem: Converter = (node, parent) => ({
    type: parent?.node.type === 'bulletList' ? 'unordered-list-item' : 'ordered-list-item',
});

const convertTaskItem: Converter = (node) => {
    const checked = node.attrs?.checked;
    return {
        type: 'checklist',
        data: checked ? { checked } : {},
    };
};

const convertHardBreak: Converter = () => ({ text: '\n' });

const blockConverters: Record<string, Converter> = {
    doc: convertText,
    text: convertText,
    blockquote: simpleTypeConverter('blockquote'),
    paragraph: simpleTypeConverter('unstyled'),
    smallText: simpleTypeConverter('small-text'),
    taskItem: convertTaskItem,
    listItem: convertListItem,
    heading: convertHeading,
    hardBreak: convertHardBreak,
    codeBlock: simpleTypeConverter('code-block'),
};

const analyseTree = (node: TiptapContentNode, parent?: AnnotatedNode): AnnotatedNode => {
    // determine how this node should be flattened into draft's structure
    const flattenMethod = getFlattenMethod(node, parent);

    // The new depth depends on how we're flattening this node
    const depth = parent?.treeDepth;
    const newDepth = flattenMethod !== FlattenMethod.NEST ? depth : isNumber(depth) ? depth + 1 : 0;

    // Convert the node _most_ of the way (more work might happen during flattening)
    const converter = blockConverters[node.type || ''];
    const { entity, styles, ...converted } = converter?.(node, parent) || {};

    // Create a node info object before recursing, so children can read it
    const nodeInfo: AnnotatedNode = {
        node,
        block: {
            key: nanoid(5),
            entityRanges: [],
            inlineStyleRanges: [],
            text: '',
            type: node.type || 'unstyled',
            data: getGlobalData(node),
            depth: newDepth || 0,
            ...converted,
        },
        entity,
        styles,
        flattenMethod,
        treeDepth: newDepth,
    };

    // Recurse into children and then return the final node info
    return {
        ...nodeInfo,
        children: node.content?.map((n) => analyseTree(n, nodeInfo)) || [],
    };
};

export function convertTiptapContentToDraft(textContent: TiptapContent): RawDraftContentState {
    if (textContent.type !== 'doc') throw new Error('Not a valid tiptap document');

    const blocks: RawDraftContentBlock[] = [];
    const entities: RawDraftEntity[] = [];

    /**
     * Update `parentNode` with the content of `node`, allowing node to be discarded.
     * - Update text content
     * - Add relevant inline styles
     * - Add entity to the top-level entity & set range
     */
    const subsumeInto = (node: AnnotatedNode, parentNode: AnnotatedNode) => {
        // calculate the range before we modify anything
        const range = {
            offset: parentNode.block.text.length,
            length: node.block.text.length,
        };

        // add our text to the parent
        parentNode.block.text += node.block.text;

        // any styling on the subsumed node needs to be moved to the parent
        parentNode.block.inlineStyleRanges.push(
            ...node.block.inlineStyleRanges.map((r) => ({ ...r, offset: r.offset + range.offset })),
        );

        // add any new styling
        if (node.styles) {
            parentNode.block.inlineStyleRanges.push(
                ...node.styles.map((x) => ({ ...range, style: x as DraftInlineStyleType })),
            );
        }
        parentNode.block.inlineStyleRanges = collapseInlineStyleRanges(parentNode.block.inlineStyleRanges);

        // and create an entity if one exists
        if (node.entity) {
            parentNode.block.entityRanges.push({ ...range, key: entities.length });
            entities.push(node.entity);
        }
    };

    // Recurse through the tree, flattening it into blocks
    const flattenNode = (node: AnnotatedNode, parentNode?: AnnotatedNode) => {
        if (node.flattenMethod === FlattenMethod.BLOCK) {
            blocks.push(node.block);
        }

        node.children?.forEach((n) => flattenNode(n, node));

        if (node.flattenMethod !== FlattenMethod.SUBSUME) return;

        if (!parentNode) throw new Error('Missing parent');
        subsumeInto(node, parentNode);
    };

    // Create a plan for how we're going to flatten the tree
    const analysed = analyseTree(textContent);

    // Kick off the recursion (this will mutate blocks and entitymap)
    flattenNode(analysed);

    // Finally assemble our data into a complete Draft content state
    return {
        blocks,
        entityMap: Object.fromEntries(entities.map((e, i) => [i, e])),
    };
}
