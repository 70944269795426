import { SheetId, SheetOptions } from './sheetTypes';

export enum SheetActionTypes {
    SHEET_OPEN = 'SHEET_OPEN',
    SHEET_CLOSE = 'SHEET_CLOSE',
    SHEET_UPDATE_ACTIVE_SNAP_POINT = 'SHEET_UPDATE_ACTIVE_SNAP_POINT',
}

export type SheetOpenAction = {
    type: SheetActionTypes.SHEET_OPEN;
    sheetId: SheetId;
    sheetKey: string;
    options?: SheetOptions;
};

export type SheetCloseAction = {
    type: SheetActionTypes.SHEET_CLOSE;
    sheetId: SheetId;
    sheetKey: string;
};

export type SheetUpdateActiveSnapPointAction = {
    type: SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT;
    sheetId: SheetId;
    sheetKey: string;
    activeSnapPoint: number;
};

export type SheetAction = SheetOpenAction | SheetCloseAction | SheetUpdateActiveSnapPointAction;
