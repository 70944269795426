// Lib
import { Editor, findParentNode, isList } from '@tiptap/react';
import { NodeRange } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { TiptapNodeType } from '../../tiptapTypes';

/**
 * Finds ranges of nodes that are not the specified list type, but can be changed to it.
 */
export const findNodeRangesToChangeToList = (state: EditorState, listType: string): NodeRange[] => {
    const { selection } = state;
    const { from, to } = selection;

    // We want either the parent list node or the type of the top level node
    const nodeRanges: NodeRange[] = [];

    // Iterate through all the nodes in the selection
    state.doc.nodesBetween(from, to, (node, pos) => {
        // If the node is a text node, we don't want to change it
        if (node.isText) return false;

        if (node.type.name === 'blockquote') return true;

        // We're only interested in wrapping text blocks
        if (!node.isTextblock) return true;

        const resolvedPos = state.doc.resolve(pos);

        const parentListItemNodeDetails = findParentNodeClosestToPos(
            resolvedPos,
            (node) => node.type === state.schema.nodes.listItem,
        );

        if (parentListItemNodeDetails?.node) {
            const $from = state.doc.resolve(parentListItemNodeDetails.pos);
            const $to = state.doc.resolve(parentListItemNodeDetails.pos + parentListItemNodeDetails.node.nodeSize);

            const parentListNode = $from.node();

            // This node is already the list type we want
            if (parentListNode.type.name === listType) return false;

            const nodeRange = new NodeRange($from, $to, parentListItemNodeDetails.depth);
            nodeRanges.push(nodeRange);

            return false;
        }

        const $from = resolvedPos;
        const $to = state.doc.resolve(pos + node.nodeSize);

        const nodeRange = new NodeRange($from, $to, $from.depth);
        nodeRanges.push(nodeRange!);

        return false;
    });

    return nodeRanges;
};

const LIST_NODE_TYPES = new Set([
    TiptapNodeType.bulletList,
    TiptapNodeType.orderedList,
    TiptapNodeType.listItem,
    TiptapNodeType.taskList,
    TiptapNodeType.taskItem,
]);

export const LIST_CONTAINER_TYPES = new Set([
    TiptapNodeType.bulletList,
    TiptapNodeType.orderedList,
    TiptapNodeType.taskList,
]);

/**
 * Finds all the content nodes & their ranges, within the selection, that are inside list items.
 */
export const findSelectedListItemContentNodeRanges = (state: EditorState): NodeRange[] => {
    const nodeRanges: NodeRange[] = [];

    // Get all the content nodes that are inside list items within the selection
    state.doc.nodesBetween(state.selection.from, state.selection.to, (node, pos, parent) => {
        const isListNode = LIST_NODE_TYPES.has(node.type.name as TiptapNodeType);

        // Traverse list nodes
        if (isListNode) return true;

        // We only want content nodes that are inside a list item
        if (state.schema.nodes.doc === parent!.type) return false;

        const $from = state.doc.resolve(pos);
        const $to = state.doc.resolve(pos + node.nodeSize);
        const nodeRange = new NodeRange($from, $to, $from.depth);

        nodeRanges.push(nodeRange);

        return false;
    });

    return nodeRanges;
};

/**
 * True if all the nodes within the selection are the specified list type.
 */
export const isAllListType =
    (listTypeName: string) =>
    (editor?: Editor): boolean => {
        if (!editor) return false;

        const { selection } = editor.state;

        let isAllList = true;

        editor.state.doc.nodesBetween(selection.from, selection.to, (node) => {
            isAllList &&= node.type.name === listTypeName;
            return false;
        });

        return isAllList;
    };

/**
 * True if the closest list node to the selection is the specified list type.
 *
 * E.g. If there's a bullet list inside an ordered list, this will return
 *  true for bulletList when the selection is inside a paragraph in the bullet list.
 */
export const isListTypeClosestActive =
    (listTypeName: string) =>
    (editor?: Editor): boolean => {
        if (!editor) return false;

        const { extensions } = editor.extensionManager;
        const { selection } = editor.state;

        // Find the first parent list node of the start of the selection
        const parentList = findParentNode((node) => isList(node.type.name, extensions))(selection);

        // If there's not one, then the list type is not active
        if (!parentList) return false;

        // Otherwise, check if the closest parent list node matches the type we're interested in
        return parentList.node.type.name === listTypeName;
    };
