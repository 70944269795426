/**
 * Return the output format depending on first four hex values of an image file.
 * Uses the image buffer's magic number to determine the image type.
 * See list here: https://en.wikipedia.org/wiki/List_of_file_signatures / https://gist.github.com/leommoore/f9e57ba2aa4bf197ebc5
 * @param fileSignature first 4 bytes of the file as a hex string
 * @returns The output format.
 */
export const lookupImageMagicBytes = (fileSignature: string): string | undefined => {
    switch (fileSignature) {
        case '89504E47':
            return 'image/png';
        case 'FFD8FFDB':
        case 'FFD8FFE0': /* Infer that any file in in FFD8FFE0-EF range is a JPEG (https://gist.github.com/leommoore/f9e57ba2aa4bf197ebc5?permalink_comment_id=3863054#gistcomment-3863054) */
        case 'FFD8FFE1':
        case 'FFD8FFE2':
        case 'FFD8FFE3':
        case 'FFD8FFE4':
        case 'FFD8FFE5':
        case 'FFD8FFE6':
        case 'FFD8FFE7':
        case 'FFD8FFE8':
        case 'FFD8FFE9':
        case 'FFD8FFEA':
        case 'FFD8FFEB':
        case 'FFD8FFEC':
        case 'FFD8FFED':
        case 'FFD8FFEE':
        case 'FFD8FFEF':
            return 'image/jpeg';
        case '52494646':
            return 'image/webp';
        case '49492A00':
        case '4d4d002A':
            return 'image/tiff';
        case '47494638':
            return 'image/gif';
        default:
            return;
    }
};

export const inferFileMimeType = async (file: File): Promise<string | undefined> => {
    // short circuit if the file already has a mime type
    if (file.type && file.type !== '') return file.type;

    // otherwise, read the first 4 bytes of the file and infer the mime type from that
    const fileReader = new FileReader();
    // only read first 4 bytes
    const blob = file.slice(0, 4);

    const fileReaderPromise = new Promise<string>((resolve) => {
        fileReader.onloadend = () => {
            const arr = new Uint8Array(fileReader.result as ArrayBuffer);
            let header = '';

            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }

            resolve(header.toUpperCase());
        };

        // if this fails, we can't infer the mime type, so just return an empty string — this will allow the file to be uploaded with an octet-stream mime type
        fileReader.onerror = () => {
            resolve('');
        };
    });

    fileReader.readAsArrayBuffer(blob);

    const header = await fileReaderPromise;

    // Return the mime format depending on first four hex values of an image file.
    return lookupImageMagicBytes(header);
};

export const getFileContentType = async (file: File): Promise<string> => {
    const { type } = file;
    if (type) return type;

    const inferredType = await inferFileMimeType(file);
    if (inferredType) return inferredType;

    return '';
};

/* 
 * Attempt to get the video file extension from the File object, else infer it.
 * See lists of magic bytes here:
 * https://en.wikipedia.org/wiki/List_of_file_signatures
 * https://www.garykessler.net/library/file_sigs.html
 */
export const getVideoFileExtension = async (file: File): Promise<string> => {
    const { type } = file;
    if (type) return type;

    const inferredType = await inferVideoFileExtension(file);
    if (inferredType) return inferredType;
    return '';
}

export const inferVideoFileExtension = async (file: File): Promise<string | undefined> => {
    // short circuit if the file already has a mime type
    if (file.type && file.type !== '') return file.type;

    const fileReader = new FileReader();
    const blob = file.slice(0, 16);

    const fileReaderPromise = new Promise<string>((resolve) => {
        fileReader.onloadend = () => {
            const arr = new Uint8Array(fileReader.result as ArrayBuffer);
            let header = '';

            for (let i = 0; i < arr.length; i++) {
                if (arr[i] <= 16) {
                    header += '0'
                    header += arr[i].toString(16);
                } else {
                    header += arr[i].toString(16);
                }
            }

            resolve(header.toUpperCase());
        };

        // if this fails, we can't infer the mime type, so just return an empty string — this will allow the file to be uploaded with an octet-stream mime type
        fileReader.onerror = () => {
            resolve('');
        };
    });

    fileReader.readAsArrayBuffer(blob);

    const header = await fileReaderPromise;

    // Return the mime format depending on first four hex values of an image file.
    return lookupVideoFileExtensionFromMagicBytes(header);
}

export const lookupVideoFileExtensionFromMagicBytes = (sixteenByteSignature: string): string | undefined => {
    const firstFourBytes = sixteenByteSignature.substring(0, 8);
    const lastEightBytes = sixteenByteSignature.substring(16, 32);
    const middleEightBytes = sixteenByteSignature.substring(8, 24);
    switch (firstFourBytes) {
        case '000001B0':
        case '000001B1':
        case '000001B2':
        case '000001B3':
        case '000001B4':
        case '000001B5':
        case '000001B6':
        case '000001B7':
        case '000001B8':
        case '000001B9':
        case '000001BA':
        case '000001BB':
        case '000001BC':
        case '000001BD':
        case '000001BE':
        case '000001BF':
            return 'mpg'
        case '1A45DFA3':
            return 'mkv'; // Could also be .webm, apparently.
        case '2E524543':
            return 'ivr';
        case '464C5601':
            return 'flv';
        case '52494646':
            if (lastEightBytes === '415649204C495354') return 'avi';
        default:
            break;
    }
    switch (sixteenByteSignature) {
        case '3026B2758E66CF11A6D900AA0062CE6C':
            return 'wmv'; // Could also be .wma, or .asf, apparently.
    }
    switch (middleEightBytes) {
        case '667479704D345620':
        case '667479706D703432':
            return 'm4v';
        case '667479704D534E56':
        case '6674797069736F6D':
            return 'mp4';
        case '6674797071742020':
            return 'mov';
        case '6674797061766966':
            return 'avif';
        case '6674797068656963':
            return 'heic';
    }
}
