// Tiptap/Draft conversion properties
// TODO-REMOVE-DRAFT - Remove these when we remove draft
import { prop } from '../../utils/immutableHelper';
import { TiptapContent } from '../tiptapTypes';
import { ElementEditor } from '../../elements/elementEditorConstants';
import { EditorContent, ImEditorContent } from '../../elements/elementModelTypes';

const getDraftBlocks = prop('blocks');
const getTiptapContent = prop('content');
export const getEditorType = (textContent: ImEditorContent | EditorContent | string | undefined): ElementEditor => {
    if (getDraftBlocks(textContent)) return ElementEditor.DraftJs;
    if (getTiptapContent(textContent)) return ElementEditor.Tiptap;
    return ElementEditor.None;
};

export const isTiptapContent = (textContent: EditorContent | string | undefined): textContent is TiptapContent =>
    getEditorType(textContent) === ElementEditor.Tiptap;
