import { partition } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { MentionSuggestion } from '../../../../common/tiptap/extensions/mention/MentionSuggestion';
import { CANVAS_VIEWPORT_ID } from '../../../canvas/utils/canvasConstants';
import filterSuggestions from '../../../components/editor/plugins/mentions/filterSuggestions';
import { getUserMentionSuggestions } from '../../../components/editor/plugins/mentions/mentionsSelector';
import MentionSuggestionEntry from '../../../components/editor/plugins/mentions/MentionSuggestionEntry';
import { PopupPosition } from '../../../components/tiptapEditor/useSuggestionsManager';

type Props = {
    currentSuggestionQuery: string;
    position: PopupPosition | null;
    onSelectUser: (data: MentionSuggestion) => void;
    onPreviewUserChanged: (data: MentionSuggestion) => void;
    keyEvents: EventTarget;
};

const getPortalMountPoint = () => document.getElementById('mention-mount-point');

export const TiptapMentionsSuggesterPopup = ({
    currentSuggestionQuery,
    onSelectUser,
    position,
    onPreviewUserChanged,
    keyEvents,
}: Props) => {
    const possibleSuggestions = useSelector(getUserMentionSuggestions);
    const [filteredSuggestions, disabledSuggestions] = useMemo(() => {
        const suggestions = filterSuggestions(currentSuggestionQuery, possibleSuggestions);
        return partition(suggestions, (suggestion) => !suggestion.disabled);
    }, [currentSuggestionQuery, possibleSuggestions]);

    const [focusedIndex, setFocusedIndex] = useState(0);

    // Move popup position when the viewport scrolls
    const [scrollOffset, setScrollOffset] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const canvasViewport = document.getElementById(CANVAS_VIEWPORT_ID);
        if (!canvasViewport) return;
        const initialScroll = { x: canvasViewport.scrollLeft, y: canvasViewport.scrollTop };
        const onViewportScroll = () => {
            const currentScroll = { x: canvasViewport.scrollLeft, y: canvasViewport.scrollTop };
            setScrollOffset({
                x: initialScroll.x - currentScroll.x,
                y: initialScroll.y - currentScroll.y,
            });
        };
        canvasViewport.addEventListener('scroll', onViewportScroll);
        return () => canvasViewport.removeEventListener('scroll', onViewportScroll);
    }, []);

    // Clamp the selected index to the number of suggestions
    useEffect(() => {
        const max = filteredSuggestions.length - 1;
        if (focusedIndex <= max) return;
        if (max < 0) {
            setFocusedIndex(0);
            return;
        }
        setFocusedIndex(max);
    }, [filteredSuggestions]);

    // Attach key event handlers
    useEffect(() => {
        const handleKeyDown = (e: Event) => {
            if (!(e instanceof KeyboardEvent)) return;
            switch (e.key) {
                case 'ArrowDown':
                    setFocusedIndex((i) => Math.min(i + 1, filteredSuggestions.length - 1));
                    break;
                case 'ArrowUp':
                    setFocusedIndex((i) => Math.max(i - 1, 0));
                    break;
            }
        };
        keyEvents.addEventListener('keydown', handleKeyDown);
        return () => keyEvents.removeEventListener('keydown', handleKeyDown);
    }, []);

    // Update the suggestions manager any time there's a new selection (on load, on change)
    useEffect(() => {
        const suggestion = filteredSuggestions[focusedIndex];
        if (!suggestion) return;
        onPreviewUserChanged(suggestion);
    }, [filteredSuggestions, focusedIndex]);

    const portalMountPoint = useMemo(getPortalMountPoint, []);

    // shouldn't be possible for either of these to be null but it helps the type checker
    if (!portalMountPoint || !position) return null;

    const disabledEntries = disabledSuggestions.length ? (
        <div className="MentionSuggestionsDisabled" key="disabled-suggestions">
            <div className="section-title">Not sharing this board</div>
            {disabledSuggestions.map((suggestion: MentionSuggestion, i: number) => (
                <MentionSuggestionEntry key={suggestion.id} mention={suggestion} searchValue={currentSuggestionQuery} />
            ))}
        </div>
    ) : null;

    const verticalPositionStyle =
        position.anchor === 'bottom'
            ? { top: position.point.y + scrollOffset.y }
            : { bottom: `calc(100% - ${position.point.y + scrollOffset.y}px)` };

    return createPortal(
        <div
            className="MentionSuggestions"
            style={{
                transform: 'scale(1)',
                position: 'fixed',

                left: position.point.x + scrollOffset.x,
                ...verticalPositionStyle,
            }}
            onTouchStart={(e) => e.stopPropagation()}
        >
            {filteredSuggestions.map((suggestion: MentionSuggestion, i: number) => (
                <MentionSuggestionEntry
                    key={suggestion.id}
                    mention={suggestion}
                    className={i === focusedIndex ? 'focused' : ''}
                    searchValue={currentSuggestionQuery}
                    onMouseEnter={() => setFocusedIndex(i)}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSelectUser(suggestion);
                        return false;
                    }}
                />
            ))}
            {disabledEntries}
        </div>,
        portalMountPoint,
    );
};
