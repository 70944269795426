import isLinkAnEmail from './isLinkAnEmail';

const DEFAULT_PROTOCOL = 'https';

/*
 * Take a user-entered URL and try to make it something clickable.
 */
export function createUsableUrl(text: string, protocol: string = DEFAULT_PROTOCOL): string {
    try {
        // If the URL is invalid, this will throw an error
        return new URL(text).toString();
    } catch (e) {
        try {
            if (isLinkAnEmail(text)) return new URL(`mailto:${text}`).toString();

            // Try adding a protocol to the URL to fix it - this will cover most cases
            return new URL(`${protocol}://${text}`).toString();
        } catch (e) {
            // If the URL is still invalid, just use the original text
            // It'll probably be a broken link, but we'll leave that to the user to fix
            return text;
        }
    }
}
