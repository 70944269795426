import { RawDraftEntity } from 'draft-js';
import { TiptapContentMark, TiptapMarkType } from '../../../tiptapTypes';
import { mentionSuggestionToAttrs } from '../../../extensions/mention/MentionSuggestion';

type EntityToMarkConverter = (entity: RawDraftEntity, key: string) => TiptapContentMark;

const entityToMarkConverters: Record<string, EntityToMarkConverter> = {
    link: (entity) => ({
        type: 'link',
        attrs: {
            href: entity.data?.url,
            target: '_blank',
            rel: 'noopener noreferrer nofollow',
            class: null,
        },
    }),
    mention: (entity, key) => ({
        type: TiptapMarkType.textMention,
        attrs: mentionSuggestionToAttrs(entity.data.mention, key),
    }),
    default: (entity) => ({
        type: entity.type,
        attrs: entity.data,
    }),
};

export const convertEntityToMark = (entity: RawDraftEntity, key: string): TiptapContentMark => {
    const type = entity.type?.toLowerCase();
    const entityConverter = entityToMarkConverters[type] || entityToMarkConverters.default;
    return entityConverter(entity, key);
};
