// Lib
import { RawCommands } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { getLineStartPositionsAndSpaceCounts, TAB_SPACE_COUNT } from '../textIndentation/indentationUtils';
import detectIndent from 'detect-indent';

const OPENING_BRACES = new Set(['{', '[', '(']);

/**
 * If there's an opening brace at the end of the line, add an extra indentation.
 */
const indentationChange = (state: EditorState) => {
    if (!state.selection.empty) return 0;

    const lastChar = state.selection.$head.nodeBefore?.text?.slice(-1);

    if (!lastChar) return 0;

    if (OPENING_BRACES.has(lastChar)) {
        const text = state.selection.$head.parent.textContent;
        return detectIndent(text).amount || TAB_SPACE_COUNT;
    }

    return 0;
};

/**
 * Overrides the OOTB newlineInCode command to maintain the indentation of the
 * previous line, or add 4 spaces if the previous line ends with an opening brace.
 */
export const newlineInCode: RawCommands['newlineInCode'] =
    () =>
    ({ state, chain, dispatch }) => {
        const { $head, $anchor } = state.selection;

        // We're not within a single code block, so we don't need to do anything
        if (!$head.parent.type.spec.code || !$head.sameParent($anchor)) return false;

        // Get the number of spaces at the start of the line
        const results = getLineStartPositionsAndSpaceCounts(state, state.selection);

        if (!results.length) return false;

        const { count } = results[0];

        const nextIndentation = Math.max(0, count + indentationChange(state));

        const spaces = ' '.repeat(nextIndentation);

        if (dispatch) dispatch(state.tr.insertText(`\n${spaces}`).scrollIntoView());

        return true;
    };
