// Lib
import React, { useEffect, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { throttle } from 'lodash';

// Utils
import { isTitleRow } from './reactionPopupUtil';

// Components
import ReactionPopupRow from './ReactionPopupRow';
import useReactionPopupData from './hooks/useReactionPopupData';
import SheetSearchBar from '../../../mobile/structural/sheet/searchBar/SheetSearchBar';
import SheetContainer, {
    CloseButton,
    Content,
    Header,
    Heading,
    NonDraggableArea,
    Overlay,
    Sheet,
} from '../../../mobile/structural/sheet';
import { UseSheetReturnType } from '../../../mobile/structural/sheet/hooks/useSheet';
import EmojiImage from '../EmojiImage';
import Spinner from '../../loaders/Spinner';

// Types
import { EmojisRowData } from '../emojiTypes';

// Constants
import { DEFAULT_COLUMN_COUNT } from './reactionPopupConstants';

// Styles
import './ReactionPopup.scss';
import './MobileReactionSheet.scss';

const LIST_DEFAULT_SIZE = 300;
const TITLE_ROW_HEIGHT = 53;
const EMOJI_MIN_SIZE = 44;
const COLUMN_GAP = 4;

export type ReactionPopupContentProps = {
    gridSize: number;
    elementIds: string[];
    closePopup: () => void;
    sheetProps: UseSheetReturnType;
};

const MobileReactionSheet = (props: ReactionPopupContentProps) => {
    const { gridSize, sheetProps, elementIds, closePopup } = props;

    const heightControllerRef = useRef<HTMLDivElement>(null);

    const [listWidth, setListWidth] = useState(LIST_DEFAULT_SIZE);
    const [listHeight, setListHeight] = useState(LIST_DEFAULT_SIZE);
    const [columnCount, setColumnCount] = useState(DEFAULT_COLUMN_COUNT);
    const [emojiButtonSize, setEmojiButtonSize] = useState(EMOJI_MIN_SIZE);

    const getRowHeight = (index: number, emojisRowData: EmojisRowData) =>
        isTitleRow(emojisRowData[index]) ? TITLE_ROW_HEIGHT : emojiButtonSize + COLUMN_GAP;

    const {
        emojiDataLoaded,
        emojisRowData,
        handleEmojiListScroll,
        searchFilter,
        setSearchFilter,
        listRef,
        getKeyOfItemAtIndex,
        getHeightOfItemAtIndex,
        handleClickEmoji,
        skinTone,
        selectedReactionIds,
    } = useReactionPopupData(elementIds, closePopup, getRowHeight, columnCount);

    const recalculateWidth = () => {
        if (!sheetProps.isSheetOpen || !heightControllerRef.current) return;

        const width = heightControllerRef.current.clientWidth;
        if (!width) return;

        listRef.current && listRef.current.resetAfterIndex(0);

        setListWidth(width);

        // Get row count from emoji min width
        const newColumnCount = Math.floor(width / EMOJI_MIN_SIZE);
        setColumnCount(newColumnCount);

        // Get exact width for emojis so that they fill the space
        const gapSpace = COLUMN_GAP * (newColumnCount - 1);
        const size = (width - gapSpace) / newColumnCount;
        setEmojiButtonSize(size);
    };

    const recalculateHeight = () => {
        if (!heightControllerRef.current) return;

        const { height } = heightControllerRef.current.getBoundingClientRect();
        if (!height) return;

        listRef.current && listRef.current.resetAfterIndex(0);

        setListHeight(height);
    };

    useEffect(() => {
        if (!sheetProps.isSheetOpen) return;

        recalculateWidth();

        const resizeObserver = new ResizeObserver(throttle(recalculateHeight, 15));
        heightControllerRef.current && resizeObserver.observe(heightControllerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [sheetProps.isSheetOpen]);

    return (
        <SheetContainer className="reaction-tool" {...sheetProps} defaultSnapPoint={0.5} snapPoints={[0.5, 0.9]}>
            <Overlay />
            <Sheet>
                <Header>
                    <Heading>Add reaction</Heading>
                    <CloseButton />
                </Header>
                <SheetSearchBar
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    sheetContentRef={sheetProps.sheetContentRef}
                />
                <Content>
                    <NonDraggableArea>
                        <div className="height-controller" ref={heightControllerRef}>
                            {emojiDataLoaded && emojisRowData.length ? (
                                <List
                                    className="emoji-list"
                                    onScroll={handleEmojiListScroll}
                                    ref={listRef}
                                    width={listWidth}
                                    height={listHeight}
                                    itemCount={emojisRowData.length}
                                    itemSize={getHeightOfItemAtIndex}
                                    itemKey={getKeyOfItemAtIndex}
                                    itemData={{
                                        rows: emojisRowData,
                                        skinTone,
                                        selectedReactionIds,
                                        handleClickEmoji,
                                        gridSize,
                                        emojiButtonSize,
                                    }}
                                    overscanCount={10}
                                >
                                    {ReactionPopupRow}
                                </List>
                            ) : (
                                <div className="no-results">
                                    {emojiDataLoaded ? (
                                        <div className="no-results-text none-found">
                                            No emoji found <EmojiImage emojiCode="1F614" size={24} />
                                        </div>
                                    ) : (
                                        <div className="no-results-text spinner">
                                            <Spinner show />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </NonDraggableArea>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileReactionSheet;
