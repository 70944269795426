import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getSelectedTaskIdSelector } from '../../../selector/selectionToolsSelector';

// Components
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import DesktopReactionPopupContent from '../../../../../components/emoji/popup/DesktopReactionPopupContent';

// Constants
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import { TooltipAlignment } from '../../../../../components/tooltips/tooltipConstants';

const mapStateToProps = createStructuredSelector({
    selectedTaskListId: getSelectedTaskIdSelector,
});

const ReactionPopupContainer = ({ isPopupOpen, selectedElements, selectedTaskListId, ...props }) => {
    const elementIds = selectedElements.map(getElementId).toArray();

    const focusElementIds = selectedTaskListId ? [selectedTaskListId] : elementIds;

    return (
        <ToolbarPopup
            {...props}
            alignment={TooltipAlignment.CENTER}
            popupId={PopupIds.REACTION_TOOL}
            className="ReactionPopup"
        >
            <DesktopReactionPopupContent {...props} closePopup={null} elementIds={focusElementIds} />
        </ToolbarPopup>
    );
};

ReactionPopupContainer.propTypes = {
    isPopupOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
    selectedTaskListId: PropTypes.string,
};

export default connect(mapStateToProps)(ReactionPopupContainer);
