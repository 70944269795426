// Lib
import {
    Connect,
    connect,
    DefaultRootState,
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    MergeProps,
    Options,
} from 'react-redux';

// Constants
import { EditorStoreContext } from './EditorStoreProvider';

const editorStoreConnect = <
    TStateProps = {},
    TDispatchProps = {},
    TOwnProps = {},
    TMergedProps = {},
    State = DefaultRootState,
>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State> | undefined | null = null,
    mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps> | undefined | null = null,
    mergeProps: MergeProps<TStateProps, TDispatchProps, TOwnProps, TMergedProps> | undefined | null = null,
    options: Options<State, TStateProps, TOwnProps, TMergedProps> = {},
) => {
    // @ts-ignore - Redux type definitions are missing some use-cases
    return connect(mapStateToProps, mapDispatchToProps, mergeProps, {
        ...options,
        context: EditorStoreContext,
        getDisplayName: (component) => `EditorStoreConnect(${component})`,
    });
};

export default editorStoreConnect;
