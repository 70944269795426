/**
 * This file is roughly a reimplementation of `elementFeatureSelector.ts` from the client folder.
 * We're reimplementing it here to avoid client dependencies.
 * It's also temporary logic that will be removed when Tiptap is fully rolled out, so there's no
 * point trying to restructure everything else to avoid the dependency.
 */
// Lib
import { createSelector } from 'reselect';

// Utils
import { propIn } from '../../../utils/immutableHelper';
import { isFeatureEnabled } from '../../../users/utils/userPropertyUtils';

// Constants
import { ExperimentId } from '../../../experiments/experimentsConstants';

export const selectIsTiptapConversionEnabledForCurrentUser = createSelector(
    propIn(['app', 'currentUser']),
    (currentUser) => isFeatureEnabled(ExperimentId.tiptapConversion, currentUser) ?? false,
);
