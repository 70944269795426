// Lib
import React, { ReactElement, RefCallback } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Hooks
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../platform/platformSelector';

// Components
import DocumentModalDraftJs from './DocumentModalDraftJs';
import DocumentModalTiptap from './DocumentModalTiptap';
import MobileDocumentSheet from './MobileDocumentSheet';

// Types
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { MilanoteApplicationMode } from '../../../../common/platform/platformTypes';

// Styles
import './DocumentModal.scss';

interface DocumentModalProps {
    elementId: string;
    element: ImMNElement;

    isEditable: boolean;
    spellCheck: boolean;

    isModalView: boolean;
    isPreview: boolean;

    permissions: number;

    elementInstanceModalRef: RefCallback<HTMLDivElement>;
    close: () => void;
}

const DocumentModal = (props: DocumentModalProps): ReactElement | null => {
    const {
        element,
        elementId,
        isEditable,
        spellCheck,
        isModalView,
        isPreview,
        elementInstanceModalRef,
        permissions,
        close,
    } = props;

    const textContent = getTextContent(element);
    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);
    const isMobile = useSelector(getMilanoteApplicationModeSelector) === MilanoteApplicationMode.mobile;

    if (isMobile) {
        // All content should be rendered as tiptap for mini milanote
        const hasDraftContent = !!textContent && !tiptapContent;
        if (hasDraftContent) return null;

        return (
            <MobileDocumentSheet
                close={close}
                element={element}
                textContent={tiptapContent}
                elementId={elementId}
                isEditable={isEditable}
                isPreview={isPreview}
            />
        );
    }

    if (tiptapContent) {
        return (
            <DocumentModalTiptap
                element={element}
                textContent={tiptapContent}
                elementId={elementId}
                permissions={permissions}
                isEditable={isEditable}
                spellCheck={spellCheck}
                isModalView={isModalView}
                isPreview={isPreview}
                elementInstanceModalRef={elementInstanceModalRef}
            />
        );
    }

    return <DocumentModalDraftJs {...props} />;
};

export default DocumentModal;
