import { Node } from '@tiptap/pm/model';
import { TiptapContentNode, TiptapMarkType } from '../../tiptapTypes';
import { MentionAttrs } from './TextMention';
import { ResolvedPos } from '@tiptap/pm/model';

export const getMentionMarkAttrs = (node: TiptapContentNode): MentionAttrs | undefined => {
    const mark = node.marks?.find((mark) => mark.type === TiptapMarkType.textMention);
    return mark?.attrs as MentionAttrs | undefined;
};

// When using node objects (ie from resolved positions rather than JSON content objects)
// the logic is slightly different -- need to check mark.type.name instead of mark.name
export const getMentionMarkAttrsFromNode = (node: Node): MentionAttrs | undefined => {
    const mark = node.marks?.find((mark) => mark.type.name === TiptapMarkType.textMention);
    return mark?.attrs as MentionAttrs | undefined;
};

// Check if the given pos is inside a mention mark (not just adjacent) and return it if so
export const getContainingMention = ($pos: ResolvedPos): MentionAttrs | undefined => {
    const before = $pos.nodeBefore;
    const after = $pos.nodeAfter;
    const mentionBefore = before && getMentionMarkAttrsFromNode(before);
    const mentionAfter = after && getMentionMarkAttrsFromNode(after);
    if (mentionBefore !== mentionAfter) return undefined;
    return mentionBefore || undefined;
};
