import React, { useEffect } from 'react';
import SearchClearButton from '../../../../components/form/SearchClearButton';
import Icon from '../../../../components/icons/Icon';

import './SheetSearchBar.scss';
import {
    animateScrollToResetScrollExcessPadding,
    applyScrollExcessPadding,
} from '../../../boardsPage/editing/scrollExcessUtils';

type SheetSearchBarProps = {
    searchFilter: string;
    setSearchFilter: (value: string) => void;
    sheetContentRef: React.RefObject<HTMLDivElement>;
};

const SheetSearchBar = ({ searchFilter, setSearchFilter, sheetContentRef }: SheetSearchBarProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        inputRef.current?.blur();
    };

    const addKeyboardPadding = () => {
        if (!sheetContentRef.current) return;

        applyScrollExcessPadding(sheetContentRef.current, 'var(--keyboard-height)');
    };
    const removeKeyboardPadding = () => {
        animateScrollToResetScrollExcessPadding();
    };

    useEffect(() => {
        window.addEventListener('keyboardDidShow', addKeyboardPadding);
        window.addEventListener('keyboardWillHide', removeKeyboardPadding);

        return () => {
            window.removeEventListener('keyboardDidShow', addKeyboardPadding);
            window.removeEventListener('keyboardWillHide', removeKeyboardPadding);
        };
    }, []);

    return (
        <div className="SheetSearchBar">
            <form onSubmit={onSubmit}>
                <Icon name="search-magnifying-glass" />
                <input
                    ref={inputRef}
                    type="text"
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                />
                <SearchClearButton
                    show={searchFilter.length > 0}
                    canClear={searchFilter.length > 0}
                    onClick={() => {
                        setSearchFilter('');
                    }}
                />
            </form>
            <div className="search-bar-fade" />
        </div>
    );
};

export default SheetSearchBar;
