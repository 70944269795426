import { getElementType } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getElement } from '../../../../../element/utils/elementStoreUtils';
import { AnyAction } from 'redux';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../../common/platform/platformTypes';
import { ElementType } from '../../../../../../common/elements/elementTypes';

/*  # Stops the propagation of ELEMENTS_SELECTED on certain cases.
 *
 *  In the Android board list hybrid use case, unwanted selections can easily happen when tapping and scrolling the screen.
 *  Thus, the selection is stopped from propagating through the app except for Android-editable elemnts.
 *
 */

interface ElementsSelectedAction extends AnyAction {
    ids: [string];
}

// Utils
const shouldElementSelectedHappenOnAndroidBoardList = (state: any, elementId: string): boolean => {
    const element = getElement(state, elementId);
    switch (getElementType(element)) {
        case ElementType.CARD_TYPE:
        case ElementType.TASK_LIST_TYPE:
        case ElementType.TASK_TYPE:
        case ElementType.IMAGE_TYPE:
        case ElementType.DOCUMENT_TYPE:
            return true;
        default:
            return false;
    }
};

const shouldElementsSelectedHappen = (state: any, action: ElementsSelectedAction): boolean => {
    // A selection with multiple IDs can only happen on the canvas, in which no elements selections are blocked.
    if (action.ids.length > 1) {
        return true;
    }

    const elementId = action.ids[0];

    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            return shouldElementSelectedHappenOnAndroidBoardList(state, elementId);
        case LegacyHybridUseCase.IPAD_OS:
            return true;
        default:
            return true;
    }
};

export const shouldForwardElementsSelectedActionToNextMiddleware = (state: any, action: AnyAction): boolean => {
    return shouldElementsSelectedHappen(state, action as ElementsSelectedAction);
};
