// Lib
import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { getStrokeColor } from '../drawingEditorUtils';
import * as pointLib from '../../../../../common/maths/geometry/point';
import { getPointerEventDetail } from '../drawingEditorEventUtils';
import { getIsLightColor } from '../../../../../common/colors/coreColorUtil';
import { getIsLightElementColor } from '../../../../../common/colors/colorComparisonUtil';

// Components
import DrawingPath from './DrawingPath';
import DrawingEditorCanvasSvg from './DrawingEditorCanvasSvg';

// Utils
import platformSingleton from '../../../../platform/platformSingleton';
import { getWindowHeight } from '../../../../utils/domUtil';
import { getDrawingViewBox } from '../utils/drawingCanvasUtils';
import { getDataUri } from '../../../../../common/utils/dataUriUtils';
import { getThemeIsDarkMode } from '../../../../user/account/accountModal/interface/themeSettings/themeSelector';
import useLastMousePointerEventAge from './useLastMousePointerEventAge';
import ActivePointerEventCache from './ActivePointerEventCache';

// Hooks
import useDocumentPointerEventHandler from './useDocumentPointerEventHandler';

// Constants
import { TIMES } from '../../../../../common/utils/timeUtil';
import { SKETCH_BACKGROUND_COLORS } from '../../../../../common/colors/colorConstants';
import { BrowserEngineType } from '../../../../../common/platform/platformTypes';

// Styles
import './DrawingEditorCanvasDrawMode.scss';

/* eslint-disable max-len,indent */
const cursorAllSvgTemplate = (fillColor, outlineColor) =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <path fill="${outlineColor}" d="M16.52.553l.13.12 1.062 1.063c.8.8.894 2.052.249 2.959l-.108.14-1.682 2.02c-.086.381-.263.747-.53 1.062l-.141.153-5.744 5.643c-.632.631-1.35 1.17-2.13 1.601l-.34.178-2.838 1.42c-.174.086-.358.137-.544.153l-2.472 1.182c-.273.131-.591.131-.864 0-.463-.222-.677-.753-.514-1.226l.045-.108 1.183-2.468c.012-.14.043-.279.095-.413l.058-.133 1.42-2.839c.4-.799.91-1.537 1.514-2.193l.265-.276L10.38 2.95c.293-.294.64-.499 1.01-.616l2.162-1.8c.868-.723 2.124-.705 2.969.02z" transform="translate(-198.000000, -332.000000) translate(198.390000, 332.000000)"/>
    <path fill="${fillColor}" d="M12.76 3.926c-.36-.28-.867-.28-1.226 0l-.095.083-5.744 5.643-.254.266c-.413.453-.773.952-1.072 1.488l-.173.325-1.419 2.839 1 1 2.839-1.42.325-.172c.536-.299 1.035-.659 1.488-1.072l.266-.254 5.744-5.643.084-.094c.28-.36.28-.866 0-1.226l-.084-.094-1.585-1.586-.095-.083z" transform="translate(-198.000000, -332.000000) translate(198.390000, 332.000000)"/>
    <path fill="${fillColor}" d="M14.662 1.7l-.087.062-1.046.871c.09.06.178.127.262.2l.123.116L15.5 4.534c.097.098.185.201.263.31l.86-1.033c.21-.25.215-.607.027-.862l-.07-.081-1.062-1.063c-.23-.23-.585-.268-.856-.105z" transform="translate(-198.000000, -332.000000) translate(198.390000, 332.000000)"/>
</svg>`;
/* eslint-enable max-len,indent */

const getSvg = (strokeColor, hasLightBackground) => {
    const defaultStrokeColor = hasLightBackground ? '#000000' : '#ffffff';
    const strokeFillColor = strokeColor || defaultStrokeColor;

    const cursorOutlineColor = getIsLightColor(strokeFillColor) ? '#000000' : '#ffffff';

    return cursorAllSvgTemplate(strokeFillColor, cursorOutlineColor);
};

const getCursorStyle = (strokeColor, hasLightBackground) => {
    if (platformSingleton.browserEngine === BrowserEngineType.safari) return null;

    const strokeColorHex = getStrokeColor(strokeColor);
    const svg = getSvg(strokeColorHex, hasLightBackground);

    const svgString = encodeURIComponent(svg.replace(/"/g, "'"));
    const svgDataUri = getDataUri({ mediaType: 'image/svg+xml', data: svgString });
    return {
        cursor: `url("${svgDataUri}") 1 17, auto`,
    };
};

const mapStateToProps = createStructuredSelector({
    isDarkMode: getThemeIsDarkMode,
});

const DrawingEditorCanvasDrawMode = (props) => {
    const {
        debugUpdateAllStrokes,
        debugDrawingLastUpdateTime,

        canvasScale = 1,
        zoomScale = 1,
        viewBoxX,
        viewBoxY,

        isCurrentlyDrawing,

        strokeColor,
        strokeSize,
        enableDrawingInkEffect,
        paths,
        beginPath,
        endPath,
        cancelPath,
        appendPointToCurrentPath,

        toolMode,
        saveAndExitDrawingEditor,

        setEditorClassName,

        isPenMode,

        isDarkMode,
        backgroundColor,
    } = props;

    const svgRef = useRef();
    const { recordPointerEvent, getLastMouseEventAge } = useLastMousePointerEventAge();

    const activePointerEventCache = useRef(new ActivePointerEventCache());

    const onDoubleClick = useCallback(
        (event) => {
            // Don't exit on double click in iPad or when using a stylus
            if (getLastMouseEventAge() > TIMES.SECOND / 2) return;

            event.preventDefault();
            event.stopPropagation();

            const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY, zoomScale);

            const doubleClickPoint = pointLib.getPoint(point[0], point[1]);

            let count = 0;
            const pathsToSave = [...paths];

            // Double click might add two paths
            while (count < 2) {
                count++;

                const lastPath = paths[paths.length - count];
                const pathPointArr = lastPath.points[0] || [0, 0];
                const pathPoint = pointLib.getPoint(pathPointArr[0], pathPointArr[1]);

                const distance = pointLib.getDistance(doubleClickPoint, pathPoint);

                if (distance < 5) pathsToSave.pop();
            }

            return saveAndExitDrawingEditor(pathsToSave);
        },
        [paths],
    );

    const onPointerDown = (event) => {
        recordPointerEvent(event);

        // if we have more than two pointers, this is a three-finger gesture, so cancel the current stroke
        if (activePointerEventCache.current.size() > 2) {
            cancelPath();
            return;
        }

        if (!activePointerEventCache.current.shouldRespondTo(event, isPenMode)) return;
        activePointerEventCache.current.add(event);

        event.currentTarget.setPointerCapture(event.pointerId);

        event.preventDefault();

        const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY, zoomScale);

        beginPath({
            type: toolMode,
            size: strokeSize / canvasScale,
            color: strokeColor,
            enableDrawingInkEffect,
            strokeSize,
            point,
        });
    };

    const onPointerMove = (event) => {
        // Only respond to move events from the first simultaneous pointer
        if (!activePointerEventCache.current.shouldRespondTo(event)) return;

        event.preventDefault();

        const windowHeight = getWindowHeight();

        // If we're near the bottom of the window
        if (isCurrentlyDrawing && event.pageY > windowHeight - 120) {
            setEditorClassName('drawing');
        }

        const point = getPointerEventDetail(event, svgRef, canvasScale, viewBoxX, viewBoxY, zoomScale);
        appendPointToCurrentPath(point, strokeColor);
    };

    const onSvgPointerUp = (event) => {
        event.currentTarget.releasePointerCapture(event.pointerId);
    };

    const onPointerUp = (event) => {
        event.preventDefault();

        if (activePointerEventCache.current.shouldRespondTo(event)) {
            endPath();
            setEditorClassName(null);
        }

        // remove pointer event from the multitouch cache
        if (event.pointerId) {
            activePointerEventCache.current.remove(event);
        } else {
            // if no pointer eventId is found on the event, clear the whole thing
            // this is likely the synthetic event dispatched in hybridAppMiddleware.js
            activePointerEventCache.current.clear();
        }
    };

    /**
     * Clear the multitouch cache when the pen mode changes, so that we don't get stuck in an invalid state
     */
    useEffect(() => {
        activePointerEventCache.current.clear();
    }, [isPenMode]);

    // This is used to attach the event to the document rather than the SVG
    useDocumentPointerEventHandler({ onPointerUp });

    const viewBox = getDrawingViewBox(props);

    // determine if the cursor should be light or dark based on the background color
    const defaultBackgroundIsLight = !isDarkMode;
    const hasLightBackground = getIsLightElementColor(
        backgroundColor,
        defaultBackgroundIsLight,
        SKETCH_BACKGROUND_COLORS,
    );

    return (
        <DrawingEditorCanvasSvg
            ref={svgRef}
            viewBox={viewBox}
            style={getCursorStyle(strokeColor, hasLightBackground)}
            className="DrawingEditorCanvasDrawMode"
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onSvgPointerUp}
            onDoubleClick={onDoubleClick}
        >
            {paths.map((path) => {
                const { id, ...rest } = path;

                return (
                    <DrawingPath
                        key={id}
                        {...rest}
                        debugUpdateAllStrokes={debugUpdateAllStrokes}
                        debugDrawingLastUpdateTime={debugDrawingLastUpdateTime}
                    />
                );
            })}
        </DrawingEditorCanvasSvg>
    );
};

DrawingEditorCanvasDrawMode.propTypes = {
    paths: PropTypes.array,
    beginPath: PropTypes.func,
    endPath: PropTypes.func,
    cancelPath: PropTypes.func,
    appendPointToCurrentPath: PropTypes.func,

    toolMode: PropTypes.string,
    strokeColor: PropTypes.string,
    strokeSize: PropTypes.number,
    enableDrawingInkEffect: PropTypes.bool,

    zoomScale: PropTypes.number,
    canvasScale: PropTypes.number,
    viewBoxX: PropTypes.number,
    viewBoxY: PropTypes.number,
    viewBoxWidth: PropTypes.number,
    viewBoxHeight: PropTypes.number,

    closeAllPopups: PropTypes.func,
    saveAndExitDrawingEditor: PropTypes.func,

    isCurrentlyDrawing: PropTypes.bool,
    isPenMode: PropTypes.bool,
    debugUpdateAllStrokes: PropTypes.bool,
    debugDrawingLastUpdateTime: PropTypes.number,

    setEditorClassName: PropTypes.func,

    isDarkMode: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

export default connect(mapStateToProps)(DrawingEditorCanvasDrawMode);
