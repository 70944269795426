import { Node } from '@tiptap/core';

/**
 * This node exists just so that cards created before TextMention was implemented don't  break.
 * It's only a few cards, and they're all owned by staff, so it's fine, there's just a few cards
 * (eg assignments on retros) that it'd be nice to hold onto for a bit. This should be removed
 * early in 2025.
 */
export const OldMention = Node.create({
    name: 'mention',
    group: 'inline',
    inline: true,
    atom: false,

    addAttributes() {
        return {
            label: { default: '' },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', HTMLAttributes, '@' + HTMLAttributes.label];
    },
});
