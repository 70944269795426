// Types
import {
    PLATFORM_DETAILS_UPDATE,
    PlatformDetails,
    PlatformDetailsUpdateAction,
} from '../../common/platform/platformTypes';

/**
 * Allows the platform details to be explicitly updated.
 * This could be useful for testing purposes.
 */
export const updatePlatformDetails = (newPlatform: Partial<PlatformDetails>): PlatformDetailsUpdateAction => ({
    type: PLATFORM_DETAILS_UPDATE,
    details: newPlatform,
});
