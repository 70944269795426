// Lib
import { omit, get, isUndefined, isString } from 'lodash';

// Utils
import { isImageURL } from '../../../common/utils/urlUtil';
import { getCurrentUserId } from '../../user/currentUserSelector';
import { isImage } from '../../../common/elements/utils/elementTypeUtils';
import { getCaption, getShowCaption, getShowMedia } from '../../../common/elements/utils/elementPropertyUtils';
import rawFromText from '../../../common/utils/editor/rawUtils/rawFromText';
import convertSimpleStringToTiptapContent from '../../../common/tiptap/utils/createJsonContentUtils/convertSimpleStringToTiptapContent';

// Services
import getClientConfig from '../../utils/getClientConfig';
import mediaService from '../../utils/services/mediaService';
import { fetchBoard } from '../board/boardService';
import { getSelectedElementIds } from '../selection/selectedElementsSelector';
import { getAttachment, getFetching } from '../attachments/attachmentsSelector';
import fetchRemoteImage from '../../utils/services/image/fetchRemoteImage';

// Selectors
import { getElements } from '../selectors/elementsSelector';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getIsFeatureEnabledForCurrentUser } from '../feature/elementFeatureSelector';

// Actions
import { updateSelectedElements, setElementTypeAndUpdateElement } from '../actions/elementActions';
import { setElementLocalData } from '../local/elementLocalDataActions';
import { finishEditingElement } from '../selection/selectionActions';
import { uploadingElementAttachment, completedUploadingElementAttachment } from '../attachments/attachmentActions';

// Constants
import { MEDIA_TYPES } from '../../../common/links/richMediaConstants';
import { ElementType } from '../../../common/elements/elementTypes';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';
import { EditorContent, ImMNElement } from '../../../common/elements/elementModelTypes';
import AppError from '../../../common/error/AppError';

const environmentFolder = get(getClientConfig(), 'aws.s3Folder');

type LinkData = {
    description: string;
    mediaType: string;
    elementType: string;
    showCaption?: boolean;
    caption?: string;
    linkTo?: string;
    error?: object;
};

export const toggleLinkMedia = (currentState: boolean) => (dispatch: Function, getState: Function) => {
    const state = getState();

    const elementId = getSelectedElementIds(state).first();
    const firstSelectedElement = state.get('elements').get(elementId);

    const currentlyShowingMedia = isUndefined(currentState) ? getShowMedia(firstSelectedElement) : currentState;

    const changes = {
        showMedia: !currentlyShowingMedia,
    };

    dispatch(
        updateSelectedElements({
            id: elementId,
            changes,
        }),
    );
};

/**
 * Retrieves the caption from the link data as EditorContent (e.g. Tiptap or Draft.js content).
 */
const getCaptionContent = (data: LinkData, shouldUseTiptapContent: boolean): EditorContent => {
    const captionData = isUndefined(data.caption) ? data.description : data.caption;

    if (!isString(captionData)) return captionData as EditorContent;

    return shouldUseTiptapContent ? convertSimpleStringToTiptapContent(captionData) : rawFromText(captionData);
};

const getChangesCaption = (element: ImMNElement, data: LinkData, shouldUseTiptapContent: boolean) => {
    if (!isUndefined(getCaption(element))) return;

    let showCaption = getShowCaption(element);

    if (isUndefined(showCaption)) {
        showCaption = isUndefined(data.showCaption)
            ? !!data.description && data.mediaType !== MEDIA_TYPES.VIDEO && data.mediaType !== MEDIA_TYPES.RICH
            : data.showCaption;
    }

    const caption = getCaptionContent(data, shouldUseTiptapContent);

    return {
        caption,
        showCaption,
    };
};

const prepareChanges = (element: ImMNElement, data: LinkData, shouldUseTiptapContent: boolean) => ({
    ...omit(data, ['description', 'elementType', 'caption', 'showCaption']),
    ...getChangesCaption(element, data, shouldUseTiptapContent),
});

const immediatelyChangeToImageElement = async ({
    id,
    url,
    fetchLinkPromise,
    dispatch,
}: {
    id: string;
    url: string;
    fetchLinkPromise: Promise<void>;
    dispatch: Function;
}) => {
    let done = false;

    // If the link finishes fetching before the image is fetched, then don't overwrite the link's result
    fetchLinkPromise.then(() => {
        done = true;
    });

    try {
        const { width, height } = await fetchRemoteImage(url);

        // If the link is already finished downloading then don't overwrite the link's data
        if (done) return;

        dispatch(
            setElementTypeAndUpdateElement({
                id,
                elementType: ElementType.IMAGE_TYPE,
                changes: {
                    image: { height, width, original: url, transparent: true },
                    showCaption: false,
                },
                undoChanges: undefined,
                transactionId: undefined,
            }),
        );
    } catch (err) {
        // Ignore  errors when fetching the image, just treat it like a normal link card
    }
};

export const setLinkElementUrl =
    ({ id, url, transactionId }: { id: string; url: string; transactionId?: number }) =>
    async (dispatch: Function, getState: Function) => {
        const fetching = getFetching(getAttachment(getState(), { elementId: id }));
        if (fetching) return;

        dispatch(uploadingElementAttachment({ id }));
        dispatch(finishEditingElement());

        const initialState = getState();
        const userId = getCurrentUserId(initialState);

        const fetchLinkPromise = mediaService.link.fetchLink({ id, url, userId, environmentFolder });

        const initialElements = getElements(initialState);
        const initialElement = getElement(initialElements, id);

        const shouldUseTiptapContent = getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion)(initialState);

        // Immediately change into an image if we know they're loading an image (and the element isn't already an image)
        if (isImageURL(url) && !isImage(initialElement)) {
            immediatelyChangeToImageElement({ id, url, fetchLinkPromise, dispatch });
        }

        return fetchLinkPromise
            .then(({ data }: { data: LinkData }) => {
                if (!data || data.error) throw new Error('The link details could not be fetched');

                const { elementType } = data;

                dispatch(completedUploadingElementAttachment({ id }));

                const state = getState();
                const elements = getElements(state);
                const element = getElement(elements, id);

                const changes = prepareChanges(element, data, shouldUseTiptapContent);

                dispatch(
                    setElementTypeAndUpdateElement({ id, elementType, changes, transactionId, undoChanges: undefined }),
                );

                if (elementType === ElementType.ALIAS_TYPE) dispatch(fetchBoard({ boardId: data.linkTo! }));
            })
            .catch((err: Error | AppError) => {
                console.error('Failed to resolve link', err);

                const error = {
                    message: err?.message || (err as AppError)?.code || 'Unknown error',
                };

                dispatch(completedUploadingElementAttachment({ id }));
                dispatch(
                    setElementLocalData({
                        id,
                        // @ts-ignore TODO - Unsure if error is allowed to be an object
                        data: { error, url },
                    }),
                );
            });
    };
