// Lib
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Player from 'react-player/lazy';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { throttle } from 'lodash';
import { split, last } from 'lodash/fp';

// Utils
import { stopPropagationOnly } from '../../../utils/domUtil';
import { prop } from '../../../../common/utils/immutableHelper';
import platformSingleton from '../../../platform/platformSingleton';
import { getPaddingForAspectRatio } from '../../resizing/utils/getPaddingForAspectRatio';

import {
    getElementId,
    getFileUrl,
    getMediaOriginalWidth,
    getMediaOriginalHeight,
    getFileMimeType,
    getMediaPlayerLoop,
    getMediaPlayerAutoplay,
} from '../../../../common/elements/utils/elementPropertyUtils';

import {
    getMediaPlayerProgress,
    getMediaPlayerDuration,
    getMediaPlayerPlaying,
    getMediaPlayerVolume,
    getMediaPlayerMute,
    getMediaPlayerError,
    getMediaPlayerDragPreview,
    getMediaPlayerHasBeenPlayed,
} from './mediaPlayerSelector';

import {
    mediaPlayerPlay,
    mediaPlayerPause,
    mediaPlayerSetProgress,
    mediaPlayerSetDuration,
    mediaPlayerSetVolume,
    mediaPlayerSetMute,
    mediaPlayerSetError,
    mediaPlayerSetVideoDragPreview,
    mediaPlayerReset,
} from './mediaPlayerActions';

import { updateElement } from '../../../../common/elements/elementActions';

// Components
import Button from '../../../components/buttons/Button';
import Icon from '../../../components/icons/Icon';
import ProgressBar from './FileMediaPlayerProgressBar';
import Duration from './FileMediaPlayerDuration';
import VolumeControl from './FileMediaPlayerVolume';
import RichMediaPreviewOverlay from '../../richMedia/components/RichMediaPreviewOverlay';
import FilePreviewImage from '../FilePreviewImage';
import ImageReplacementOverlay from '../../image/ImageReplacementOverlay';
import ElementImage from '../../../components/images/ElementImage';

// Constants
import { MEDIA_PLAYER_TYPES } from './mediaPlayerConstants';
import { IMAGE_TYPES } from '../../../../common/media/mediaConstants';
import { FileTypes } from '../../../../common/files/fileTypes';
import { BrowserEngineType } from '../../../../common/platform/platformTypes';

// Styles
import './FileMediaPlayer.scss';

const isNonInteractionError = (error) => error.toString().startsWith('NotAllowedError');

const MEDIA_ERRORS = {
    UNSUPPORTED_TYPE: 'UNSUPPORTED_TYPE',
    PLAYBACK_ERROR: 'PLAYBACK_ERROR',
};

const MediaPlayerError = ({ element, mediaError }) => {
    switch (mediaError) {
        case MEDIA_ERRORS.PLAYBACK_ERROR:
            return <div>Something went wrong while playing this file</div>;
        default:
            return (
                <div>
                    Your browser doesn&apos;t support playback of {last(split('/', getFileMimeType(element)))} files
                </div>
            );
    }
};

MediaPlayerError.propTypes = {
    element: PropTypes.object,
    mediaError: PropTypes.string,
};

const mediaPlayerSelector = createStructuredSelector({
    isPlaying: getMediaPlayerPlaying,
    progress: getMediaPlayerProgress,
    duration: getMediaPlayerDuration,
    volume: getMediaPlayerVolume,
    mute: getMediaPlayerMute,
    mediaError: getMediaPlayerError,
    mediaDragPreview: getMediaPlayerDragPreview,
    hasBeenPlayed: getMediaPlayerHasBeenPlayed,
});

const mapDispatchToProps = (dispatch) => ({
    playMedia: (playerId) => dispatch(mediaPlayerPlay({ playerId })),
    pauseMedia: (playerId) => dispatch(mediaPlayerPause({ playerId })),
    setMediaProgress: (playerId, progress) => dispatch(mediaPlayerSetProgress({ playerId, progress })),
    setMediaDuration: (playerId, duration) => dispatch(mediaPlayerSetDuration({ playerId, duration })),
    setMediaVolume: (playerId, volume) => dispatch(mediaPlayerSetVolume({ playerId, volume })),
    setMediaMute: (playerId, mute) => dispatch(mediaPlayerSetMute({ playerId, mute })),
    setMediaPlayerError: (playerId, error) => dispatch(mediaPlayerSetError({ playerId, error })),
    setMediaDragPreview: (playerId, dragPreview) => dispatch(mediaPlayerSetVideoDragPreview({ playerId, dragPreview })),
    updateElementMediaSize: (elementId, width, height) =>
        dispatch(
            updateElement({
                id: elementId,
                silent: true,
                changes: {
                    media: {
                        originalWidth: width,
                        originalHeight: height,
                    },
                },
            }),
        ),
    resetMediaPlayer: (playerId) => dispatch(mediaPlayerReset({ playerId })),
    setAudioOnly: (elementId) =>
        dispatch(
            updateElement({
                transactionId: -1,
                id: elementId,
                changes: {
                    audioOnly: true,
                },
            }),
        ),
});

const FileMediaPlayer = (props) => {
    const {
        playerId,
        playerType,
        element,
        isDragging,
        isPresentational,
        isPlaying,
        isModalView,
        progress,
        duration,
        volume,
        imageData,
        mediaError,
        mute,
        mediaDragPreview,
        hasBeenPlayed,
        playMedia,
        pauseMedia,
        setMediaProgress,
        setMediaDuration,
        setMediaVolume,
        setMediaMute,
        setMediaPlayerError,
        setMediaDragPreview,
        updateElementMediaSize,
        resetMediaPlayer,
        dispatchNavigateToElement,
        widthPx,
        gridSize,
        cropToGrid,
        showDownloadOption,
        canDrop,
        isHovered,
        audioOnly,
        setAudioOnly,
    } = props;

    const hasInitializedPlayer = useRef(false);
    const playerRef = useRef();
    const isDraggingScrubberRef = useRef(false);

    const url = getFileUrl(element);
    const isVideoPlayer = playerType === MEDIA_PLAYER_TYPES.VIDEO && !audioOnly;

    const [isDraggingScrubber, setIsDraggingScrubber] = useState(false);
    const [volumePopupVisible, setVolumePopupVisible] = useState(false);

    const loopMediaPlayer = getMediaPlayerLoop(element);
    const autoplayMediaPlayer = getMediaPlayerAutoplay(element);

    const hasMediaSize = !!getMediaOriginalWidth(element) && !!getMediaOriginalHeight(element);
    const mediaWidth = getMediaOriginalWidth(element);
    const mediaHeight = getMediaOriginalHeight(element);

    const dimensions = { width: mediaWidth, height: mediaHeight };
    const paddingTop = getPaddingForAspectRatio({
        savedSize: dimensions,
        roundSaved: false,
        cropToGrid,
        widthPx,
        gridSize,
    });

    const isPlayerReady = isVideoPlayer ? !!paddingTop && !mediaError : !mediaError;
    const allowFileDrop = canDrop && isHovered;

    const showPlayButtonOverlay = !hasBeenPlayed && isVideoPlayer;

    const setProgress = (mediaProgress) => setMediaProgress(playerId, mediaProgress);
    const resetPlayer = () => resetMediaPlayer(playerId);
    const setVolume = throttle((mediaVolume) => setMediaVolume(playerId, mediaVolume), 25);
    const setMute = (mediaMute) => setMediaMute(playerId, mediaMute);
    const setError = (error) => {
        if (isNonInteractionError(error)) {
            // just ignore errors thrown by the user not interacting with the browser yet
            console.error(error);
            return;
        }

        const errorCode = hasBeenPlayed ? MEDIA_ERRORS.PLAYBACK_ERROR : MEDIA_ERRORS.UNSUPPORTED_TYPE;
        setMediaPlayerError(playerId, errorCode);
    };
    const setIsPlaying = (setPlaying) => (setPlaying ? playMedia(playerId) : pauseMedia(playerId));

    const setDuration = (mediaDuration) => {
        if (duration) return;
        setMediaDuration(playerId, mediaDuration);
    };

    useEffect(() => {
        if (autoplayMediaPlayer) {
            setMute(true);
        }
    }, []);

    useEffect(() => {
        setIsPlaying(autoplayMediaPlayer);
    }, [autoplayMediaPlayer]);

    // for javascript function+variable scope reasons,
    // the onProgress function isn't able to read the current value of isDraggingScrubber,
    // so on change it's assigned to a ref which can be read correctly
    useEffect(() => {
        isDraggingScrubberRef.current = isDraggingScrubber;
    }, [isDraggingScrubber]);

    const onProgress = ({ played }) => {
        // don't update the progress while we're dragging
        if (isDraggingScrubberRef.current) return;
        if (played === 0) return;
        if (played === 1) return resetPlayer();

        setProgress(played);
    };

    const handlePlayButtonClick = (event) => {
        event && event.stopPropagation();
        setIsPlaying(!isPlaying);
    };

    const playerConfig = {
        file: {
            attributes: {
                onContextMenu: (e) => !showDownloadOption && e.preventDefault(),
                controlsList: !showDownloadOption ? 'nodownload' : undefined,
            },
        },
    };

    const handleSeek = throttle((seekPercent) => {
        if (!playerRef.current) return;

        setProgress(seekPercent);
        playerRef.current.seekTo(seekPercent);
    }, 25);

    const enterFullscreenVideo = () => {
        const player = playerRef.current.getInternalPlayer();
        const fullscreenFn =
            player.requestFullscreen ||
            player.mozRequestFullScreen ||
            player.webkitRequestFullscreen ||
            player.msRequestFullscreen;

        if (fullscreenFn) {
            fullscreenFn.call(player);
        }
    };

    const handleFullscreenRequest = () => {
        // because of limitations in Safari's full screen video handler,
        // it can only support entering full screen from the modal view
        const canHandleFullscreenRequest = isModalView || platformSingleton.browserEngine !== BrowserEngineType.safari;

        if (canHandleFullscreenRequest) {
            return enterFullscreenVideo();
        }

        setIsPlaying(false);
        dispatchNavigateToElement(getElementId(element));
    };

    useEffect(() => {
        // on drop, clear out the drag preview canvas
        if (!isDragging && mediaDragPreview) {
            setMediaDragPreview(playerId, null);
        }

        if (!isDragging) return;

        // when dragging starts, pause the player
        if (isPlaying) {
            setIsPlaying(false);
        }

        const videoPlayer = playerRef.current && playerRef.current.getInternalPlayer();
        if (isVideoPlayer && videoPlayer) {
            const snapshotCanvas = document.createElement('canvas');
            snapshotCanvas.width = videoPlayer.getBoundingClientRect().width;
            snapshotCanvas.height = videoPlayer.getBoundingClientRect().height;

            const ctx = snapshotCanvas.getContext('2d');
            ctx.drawImage(videoPlayer, 0, 0, snapshotCanvas.width, snapshotCanvas.height);

            setMediaDragPreview(playerId, snapshotCanvas);
        }
    }, [isDragging]);

    const onPlayerReady = (player) => {
        if (isModalView) {
            setIsPlaying(true);
        }

        // only perform this if the media size has not been saved previously
        if (hasMediaSize) return;

        // only perform this on the first time the player becomes ready for this element
        if (hasInitializedPlayer.current) return;
        hasInitializedPlayer.current = true;

        const internalPlayer = player.getInternalPlayer();
        if (!internalPlayer || !isVideoPlayer) return;

        const calculatedMediaWidth = player.getInternalPlayer().videoWidth;
        const calculatedMediaHeight = player.getInternalPlayer().videoHeight;

        // if there is no visible media for a video file, show the audio controls
        if (!calculatedMediaWidth && !calculatedMediaHeight) setAudioOnly(getElementId(element));

        if (!calculatedMediaWidth || !calculatedMediaHeight) return;

        updateElementMediaSize(playerId, calculatedMediaWidth, calculatedMediaHeight);
    };

    const mediaPlayerClasses = classNames('FileMediaPlayer', playerType.toLowerCase(), {
        'showing-volume': volumePopupVisible,
        'dragging-scrubber': isDraggingScrubber,
        ready: isPlayerReady,
    });

    const showPreviewInsteadOfError =
        isVideoPlayer && mediaError === MEDIA_ERRORS.UNSUPPORTED_TYPE && imageData && !prop('hasError', imageData);

    if (showPreviewInsteadOfError) return <FilePreviewImage {...props} />;

    const videoPlayer = isPresentational ? (
        <div
            className="video-preview"
            ref={(preview) => {
                if (preview && mediaDragPreview) {
                    preview.innerHTML = '';
                    preview.appendChild(mediaDragPreview);
                }
            }}
        />
    ) : (
        <Player
            ref={(player) => {
                playerRef.current = player;
            }}
            url={url}
            width="100%"
            height={isVideoPlayer ? '100%' : 0}
            playing={isPlaying}
            volume={volume}
            muted={mute}
            loop={loopMediaPlayer}
            progressInterval={250}
            onReady={onPlayerReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onProgress={onProgress}
            onDuration={setDuration}
            onError={setError}
            config={playerConfig}
        />
    );

    return (
        <div className={mediaPlayerClasses}>
            <div className={classNames('media-player-container', { error: !!mediaError })} style={{ paddingTop }}>
                {mediaError ? <MediaPlayerError mediaError={mediaError} element={element} /> : videoPlayer}
                {allowFileDrop && <ImageReplacementOverlay fileType={FileTypes.VIDEO} />}
                {isPlayerReady && showPlayButtonOverlay && (
                    <ElementImage
                        imageType={IMAGE_TYPES.FILE}
                        widthPx={widthPx}
                        forcedSize={{ width: widthPx }}
                        element={element}
                    />
                )}
            </div>

            {isPlayerReady && showPlayButtonOverlay && !allowFileDrop && (
                <RichMediaPreviewOverlay show onClick={handlePlayButtonClick} />
            )}

            {isPlayerReady && !showPlayButtonOverlay && (
                <div className={classNames('media-player-controls', { 'has-fullscreen': isVideoPlayer })}>
                    <Button
                        className="control-button play-button"
                        onClickFn={handlePlayButtonClick}
                        onDoubleClick={stopPropagationOnly}
                    >
                        <Icon name={isPlaying ? 'media-player-pause' : 'media-player-play'} />
                    </Button>

                    <VolumeControl
                        volume={volume}
                        mute={mute}
                        setVolume={setVolume}
                        setMute={setMute}
                        playerId={playerId}
                        volumePopupVisible={volumePopupVisible}
                        setVolumePopupVisible={setVolumePopupVisible}
                    />

                    <ProgressBar
                        isPlaying={isPlaying}
                        progress={progress}
                        handleSeek={handleSeek}
                        isDraggingScrubber={isDraggingScrubber}
                        setIsDraggingScrubber={setIsDraggingScrubber}
                    />

                    <Duration progress={progress} duration={duration} />

                    {isVideoPlayer && (
                        <Button className="control-button fullscreen-button" onClickFn={handleFullscreenRequest}>
                            <Icon name="media-player-fullscreen" />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

FileMediaPlayer.propTypes = {
    playerId: PropTypes.string,
    playerType: PropTypes.string,
    element: PropTypes.object,
    imageData: PropTypes.object,
    isPlaying: PropTypes.bool,
    isModalView: PropTypes.bool,
    canDrop: PropTypes.bool,
    isHovered: PropTypes.bool,
    progress: PropTypes.number,
    duration: PropTypes.number,
    volume: PropTypes.number,
    mediaRatio: PropTypes.number,
    mediaError: PropTypes.string,
    mute: PropTypes.bool,
    isDragging: PropTypes.bool,
    showDownloadOption: PropTypes.bool,
    isPresentational: PropTypes.bool,
    hasBeenPlayed: PropTypes.bool,
    mediaDragPreview: PropTypes.object,
    playMedia: PropTypes.func,
    pauseMedia: PropTypes.func,
    setMediaProgress: PropTypes.func,
    setMediaDuration: PropTypes.func,
    setMediaVolume: PropTypes.func,
    setMediaMute: PropTypes.func,
    setMediaPlayerError: PropTypes.func,
    setMediaDragPreview: PropTypes.func,
    updateElementMediaSize: PropTypes.func,
    resetMediaPlayer: PropTypes.func,
    dispatchNavigateToElement: PropTypes.func,
    attachment: PropTypes.object,

    gridSize: PropTypes.number,
    widthPx: PropTypes.number,
    cropToGrid: PropTypes.bool,
    audioOnly: PropTypes.bool,
    setAudioOnly: PropTypes.func,
};

export default connect(mediaPlayerSelector, mapDispatchToProps)(FileMediaPlayer);
