// Lib
import { isNumber, negate } from 'lodash/fp';
import { isUndefined } from 'lodash';

// Utils
import { asObject } from '../../../../../../common/utils/immutableHelper';
import {
    getAutoColorIndex,
    getCaptionModified,
    getColorSpace,
    getElementId,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { isColorSwatch, isSkeleton } from '../../../../../../common/elements/utils/elementTypeUtils';
import { parseColorObject } from '../../../../../../common/colors/colorObjectUtil';
import { getColorName } from '../../../../../../common/colors/colorNameUtil';
import rawFromText from '../../../../../../common/utils/editor/rawUtils/rawFromText';
import convertSimpleStringToTiptapContent from '../../../../../../common/tiptap/utils/createJsonContentUtils/convertSimpleStringToTiptapContent';
import { elementAllowsDualColors } from './dualColors/dualColorListUtils';

// Actions
import { updateMultipleElements } from '../../../../../element/actions/elementActions';

// Selectors
import { getSelectedElements } from '../../../../../element/selection/selectedElementsSelector';
import { getIsFeatureEnabledForCurrentUser } from '../../../../../element/feature/elementFeatureSelector';

// Constants
import { BACKGROUND_COLORS } from '../../../../../../common/colors/colorConstants';
import { ExperimentId } from '../../../../../../common/experiments/experimentsConstants';
import { ImMNElement, MNElementContent } from '../../../../../../common/elements/elementModelTypes';
import { GenericReduxThunkAction } from '../../../../../../common/actions/actionTypes';

/**
 * Check if secondaryColor should be added to the updates.
 * Allow setting secondary color to null, but skip if no value is provided
 * @param element
 * @param secondaryColor
 * @returns {boolean}
 */
const shouldUpdateSecondaryColor = (element: ImMNElement, secondaryColor?: string | null) =>
    elementAllowsDualColors(element) && !isUndefined(secondaryColor);

export const updateElementsColors =
    ({
        color,
        elements,
        useExistingColorSpace,
        secondaryColor,
    }: {
        color: string | null;
        elements: ImMNElement[];
        useExistingColorSpace: boolean | null;
        secondaryColor: string | null;
    }): GenericReduxThunkAction =>
    (dispatch, getState) => {
        const state = getState();
        const shouldUseTiptapContent = getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion)(state);

        const updates = asObject(
            elements.map((element) => {
                const changes: MNElementContent = { color };

                if (shouldUpdateSecondaryColor(element, secondaryColor)) {
                    changes.secondaryColor = secondaryColor;
                }

                if (isNumber(getAutoColorIndex(element))) {
                    changes.autoColorIndex = null;
                }

                if (isColorSwatch(element)) {
                    const colorSpace = useExistingColorSpace ? getColorSpace(element) : null;
                    const newColor = parseColorObject(color || '#ffffff', colorSpace);
                    if (newColor) {
                        changes.color = newColor;

                        const isCaptionModified = getCaptionModified(element);
                        if (!isCaptionModified) {
                            const colorName = getColorName(newColor);
                            changes.caption = shouldUseTiptapContent
                                ? convertSimpleStringToTiptapContent(colorName || '')
                                : rawFromText(colorName);
                        }
                    } else {
                        delete changes.color;
                    }
                }

                return {
                    id: getElementId(element),
                    changes,
                };
            }),
        );

        return dispatch(updateMultipleElements({ updates }));
    };

export const updateElementsSecondaryColors =
    ({
        secondaryColor,
        elements,
    }: {
        secondaryColor: string | null;
        elements: ImMNElement[];
    }): GenericReduxThunkAction =>
    (dispatch) => {
        const updates = asObject(
            elements.map((element) => {
                if (!shouldUpdateSecondaryColor(element, secondaryColor)) return;

                const changes = { secondaryColor };

                return {
                    id: getElementId(element),
                    changes,
                    transactionId: -1,
                };
            }),
        );

        return dispatch(updateMultipleElements({ updates }));
    };

export const updateElementBackgroundColors =
    ({
        color,
        secondaryColor,
        elements,
    }: {
        color: string | null;
        secondaryColor: string | null;
        elements: ImMNElement[];
    }): GenericReduxThunkAction =>
    (dispatch) => {
        const isTransparent = color === BACKGROUND_COLORS.TRANSPARENT.name;

        const changes: MNElementContent = {
            background: isTransparent ? null : { color },
            transparent: { enabled: isTransparent },
        };

        const updates = asObject(
            elements.map((element) => {
                if (shouldUpdateSecondaryColor(element, secondaryColor)) {
                    changes.secondaryColor = secondaryColor;
                }

                return {
                    id: getElementId(element),
                    changes,
                };
            }),
        );

        return dispatch(updateMultipleElements({ updates }));
    };

export const updateSelectedElementsBackgroundColors =
    (color: string | null, secondaryColor: string | null): GenericReduxThunkAction =>
    (dispatch, getState) => {
        const state = getState();
        const elementsToUpdate = getSelectedElements(state).filter(negate(isSkeleton));

        return dispatch(updateElementBackgroundColors({ color, secondaryColor, elements: elementsToUpdate }));
    };

export const updateSelectedElementsColors =
    ({
        color,
        useExistingColorSpace,
        secondaryColor,
    }: {
        color: string | null;
        useExistingColorSpace: boolean | null;
        secondaryColor: string | null;
    }): GenericReduxThunkAction =>
    (dispatch, getState) => {
        const state = getState();
        const selectedElements = getSelectedElements(state).filter(negate(isSkeleton));

        return dispatch(
            updateElementsColors({ color, secondaryColor, elements: selectedElements, useExistingColorSpace }),
        );
    };

export const updateSelectedElementsSecondaryColors =
    ({ secondaryColor }: { secondaryColor: string | null }): GenericReduxThunkAction =>
    (dispatch, getState) => {
        const state = getState();
        const selectedElements = getSelectedElements(state).filter(negate(isSkeleton));

        return dispatch(updateElementsSecondaryColors({ secondaryColor, elements: selectedElements }));
    };
