import React, { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import SheetPortal from './SheetPortal';
import { SheetContextProvider, useSheetContext } from './SheetContextProvider';
import Button from '../../../components/buttons/Button';
import './SheetContainer.scss';
import { UseSheetReturnType } from './hooks/useSheet';

export type SheetProps = UseSheetReturnType & {
    className?: string;
    children: ReactNode;
    defaultSnapPoint?: number | string; // e.g. null to open at content height, 0.5 to open at 50%, or '0.5max' to open at content height with a max of 50%
    snapPoints?: number[];
    preventDismiss?: boolean;
    onSheetClose?: () => void;
    onCloseTransitionEnd?: () => void;
    onOpenTransitionEnd?: () => void;
    openByDefault?: boolean;
    showToolbar?: boolean;
};

const MountedSheet = ({ children, className }: { children: ReactNode; className?: string }) => {
    const { setIsSheetMounted } = useSheetContext();

    useEffect(() => {
        setIsSheetMounted(true);
    }, []);

    return (
        <div className={classNames('SheetContainer', className)} data-toolbar-transitions-ignore-clicks>
            {children}
        </div>
    );
};

/**
 * The main component that wraps the sheet and its content, and controls the drag functionality.
 * Here we add all the props that determine the drag functionality of the sheet.
 * See docs/sheet-component.md for more details on how to use this component.
 * See sheetSubcomponents folder or index file for the subcomponents that can be used within the sheet.
 */
export const SheetContainer = (props: SheetProps) => {
    const { children, className, openByDefault, dispatchOpenSheet, isSheetOpen, showToolbar } = props;

    const classes = classNames(className, { 'show-toolbar': showToolbar });

    useEffect(() => {
        if (openByDefault && !isSheetOpen) {
            dispatchOpenSheet();
        }
    }, []);

    return (
        <SheetPortal>
            <SheetContextProvider {...props}>
                {(isSheetMounted: boolean) =>
                    (isSheetMounted || isSheetOpen) && <MountedSheet className={classes}>{children}</MountedSheet>
                }
            </SheetContextProvider>
        </SheetPortal>
    );
};

SheetContainer.displayName = 'SheetContainer';

/**********************************************************
 * Subcomponents
 **********************************************************/

/**
 * The overlay that covers the screen when the sheet is open.
 */
export const Overlay = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    const { closeSheetWithTransition } = useSheetContext();

    const onClickHandler = () => {
        onClick?.();
        closeSheetWithTransition();
    };

    return <div className={classNames('Overlay', className)} onClick={onClickHandler} />;
};

/**
 * The sheet itself. This is the area that can be dragged up and down.
 * It has the scrollable content area inside it, as well as an optional drag handle.
 */
export const Sheet = ({ children, className }: { children: ReactNode; className?: string }) => {
    const { sheetRef, handleSheetTouchStart, handleSheetTouchMove, handleSheetTouchEnd, handleSheetTouchCancel } =
        useSheetContext();

    return (
        <div
            className={classNames('Sheet', className)}
            ref={sheetRef}
            onTouchStart={handleSheetTouchStart}
            onTouchMove={handleSheetTouchMove}
            onTouchEnd={handleSheetTouchEnd}
            onTouchCancel={handleSheetTouchCancel}
        >
            {children}
        </div>
    );
};

/**
 * The drag handle that can be used to drag the sheet up and down.
 */
export const Handle = ({ className }: { className?: string }) => <div className={classNames('Handle', className)} />;

/**
 * The header of the sheet. This is the draggable area at the top of the sheet that can contain a handle, heading, close button or any other custom components.
 */
export const Header = ({ children, className }: { children: ReactNode; className?: string }) => (
    <div className={classNames('Header', className)}>{children}</div>
);

/**
 * The heading of the sheet. This is the title of the sheet that appears at the top of the sheet.
 */
export const Heading = ({ children }: { children: ReactNode }) => <div className="Heading">{children}</div>;

/**
 * The close button for the sheet. Has an orange 'Done' by default if no child component is provided.
 */
export const CloseButton = ({
    children,
    className,
    onClick,
}: {
    children?: ReactNode;
    className?: string;
    onClick?: () => void;
}) => {
    const { closeSheetWithTransition } = useSheetContext();

    const onClickHandler = () => {
        onClick?.();
        closeSheetWithTransition();
    };

    const buttonContent = children || <span className="default-content-style">Done</span>;

    return (
        <Button className={classNames('CloseButton', className)} onClick={onClickHandler}>
            {buttonContent}
        </Button>
    );
};

/**
 * The content of the sheet. Here is where the main scrollable content of the sheet should be placed.
 */
export const Content = ({ children }: { children: ReactNode }) => {
    const { sheetContentRef } = useSheetContext();

    return (
        <div className="Content" ref={sheetContentRef} data-scrollable>
            {children}
        </div>
    );
};

/**
 * A non-draggable area that can be used to prevent sheet dragging on certain elements.
 */
export const NonDraggableArea = ({ children }: { children: ReactNode }) => (
    <div className="non-draggable-area" data-sheet-ignore-drags>
        {children}
    </div>
);

export default SheetContainer;
