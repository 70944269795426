import React, { useEffect, useMemo } from 'react';
import ReactionPopupSkinTonePicker from './ReactionPopupSkinTonePicker';
import PopupModeMenu from '../../popupPanel/types/modeMenu/PopupModeMenu';
import { CATEGORIES } from './reactionPopupConstants';
import { Emoji, EmojisRowData } from '../emojiTypes';
import { isTitleRow } from './reactionPopupUtil';

type ReactionPopupMenuProps = {
    emojiData: Emoji[];
    skinTone: string;
    currentCategory: string;
    setCurrentCategory: (category: string) => void;
    setSearchFilter: (filter: string) => void;
    gridSize: number;
    emojisRowData: EmojisRowData;
    listRef: any;
    dispatchUpdateSkinTone: (skinTone: string) => void;
};

const ReactionPopupMenu = ({
    emojiData = [],
    skinTone,
    currentCategory,
    setCurrentCategory,
    setSearchFilter,
    gridSize,
    emojisRowData,
    listRef,
    dispatchUpdateSkinTone,
}: ReactionPopupMenuProps) => {
    const setEmojiCategory = ({ mode }: { mode: string }) => {
        setCurrentCategory(mode);

        const scrollToRowIndex = emojisRowData.findIndex((row) => isTitleRow(row) && row.title === mode);

        if (scrollToRowIndex > -1 && listRef.current) {
            listRef.current.scrollToItem(scrollToRowIndex, 'start');
        }
    };

    const pickerModeList = useMemo(
        () =>
            CATEGORIES.map((category) => ({
                slug: category.name,
                title: category.name,
                disabled: !emojisRowData.find((row) => isTitleRow(row) && row.title === category.name),
            })),
        [emojisRowData],
    );

    useEffect(() => {
        const currentMode = pickerModeList.find((mode) => mode && mode.slug === currentCategory);
        if (currentMode && !currentMode.disabled) return;

        const firstEnabledMode = pickerModeList.find((mode) => mode && !mode.disabled);
        setCurrentCategory((firstEnabledMode && firstEnabledMode.slug) || 'none');
    }, [pickerModeList]);

    return (
        <PopupModeMenu
            tools={
                <ReactionPopupSkinTonePicker
                    emojiData={emojiData}
                    skinTone={skinTone}
                    setSkinTone={dispatchUpdateSkinTone}
                    gridSize={gridSize}
                />
            }
            showSearch
            popupMode={currentCategory}
            setMode={setEmojiCategory}
            modeList={pickerModeList}
            searchPlaceholder="Search emojis..."
            clearSearchOnSubmit={false}
            handleSearchChange={setSearchFilter}
        />
    );
};

export default ReactionPopupMenu;
