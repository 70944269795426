// Lib
import { createSelector } from 'reselect';

// Utils
import { propIn } from '../../../common/utils/immutableHelper';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../platform/platformSelector';

// Constants
import {
    DELETE_TASKS_SUGGESTION_DISMISSED_PROPERTY,
    SHOW_TITLE_PROPERTY,
    TITLE_SUGGESTION_ACK_PROPERTY,
} from '../../../common/taskLists/taskListConstants';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

// Property Accessors
export const getShowTitle = propIn(['content', SHOW_TITLE_PROPERTY]);
export const getTitleSuggestionAck = propIn(['content', TITLE_SUGGESTION_ACK_PROPERTY]);
export const getDeleteTasksSuggestionDismissed = propIn(['content', DELETE_TASKS_SUGGESTION_DISMISSED_PROPERTY]);

/**
 * Determines whether the title suggestion is allowed to be shown for the task list.
 */
export const canShowTitleSuggestionSelector = createSelector(
    getMilanoteApplicationModeSelector,
    (appMode) => appMode !== MilanoteApplicationMode.mobileLegacy,
);
