type SafeArea = {
    top: number;
    bottom: number;
    left: number;
    right: number;
};

const createSafeAreaSingleton = () => {
    let safeArea = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    };

    return {
        setSafeArea: (newSafeArea: SafeArea) => {
            safeArea = newSafeArea;
        },
        getSafeArea: (): SafeArea => safeArea,
    };
};

const safeAreaSingleton = createSafeAreaSingleton();

export default safeAreaSingleton;
