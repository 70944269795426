// Types
import { TiptapContent, TiptapContentNode, TiptapNodeType } from '../../../tiptapTypes';

// most node types will be removed automatically when they become empty, only these ones need additional logic
const EMPTYABLE_NODE_TYPES = [TiptapNodeType.orderedList, TiptapNodeType.bulletList];

/**
 * Determines if the node is a list with empty content inside, and strips the empty content if so.
 */
const trimEmptyContent = (node?: TiptapContentNode): TiptapContentNode[] => {
    if (!node?.content?.length) return [];

    // If the node has more than one child, then it's not empty
    if (node.content.length > 1) return [node];

    // If it's not a list type, then it must not be empty
    if (!EMPTYABLE_NODE_TYPES.includes(node.type as TiptapNodeType)) return [node];

    const listItemContent = node.content[0].content;

    // If the list's only list item doesn't have children, it is empty
    if (!listItemContent?.length) return [];

    const [firstChild, ...rest] = listItemContent;

    // If the first child is not a paragraph then it's another list, which means we have an empty
    // parent list that contains a list. So the parent list is empty and can be stripped
    if (firstChild.type !== TiptapNodeType.paragraph) return [firstChild, ...rest];

    // If the first child is a paragraph then ensure the paragraph has content
    if (!firstChild.content?.length) return rest;

    // If it has content then we want to return the list as is
    return [node];
};

/**
 * When clipping content, the fragment might be preceeded or succeeded by an empty node
 *
 * This node might be in the form of a list with a list item containing only another list,
 * or a list containing an empty paragraph followed by a list.
 * In both of these cases we want to only use the child list, otherwise we'll see an
 * empty parent list entry in the clipping.
 */
const trimEmptySurroundingNodes = <T extends TiptapContent | null | undefined>(editorContent: T): T => {
    const docContent = editorContent?.content;

    if (!docContent) return editorContent;

    return {
        ...editorContent,
        content: [
            // Strip out empty leading node
            ...trimEmptyContent(docContent[0]),
            ...docContent.slice(1, -1),
            // Strip out empty trailing node, if there's at least 2 nodes
            ...(docContent.length > 1 ? trimEmptyContent(docContent[docContent.length - 1]) : []),
        ].filter(Boolean),
    };
};

export default trimEmptySurroundingNodes;
