import { EditorState } from '@tiptap/pm/state';
import { UniversalCardConverterCommand } from './universalCardTypes';
import { ElementType } from '../../../elements/elementTypes';
import { getLinkifyMatchesFromText } from '../../../utils/getLinksFromText';
import isLinkAnEmail from '../hyperlink/isLinkAnEmail';

/**
 * Gets the text of the editor if it's entirely a link.
 */
const getTextStartLinkMatch = (state: EditorState): string | null => {
    if (state.doc.content.childCount !== 1) return null;

    const linkMatches = getLinkifyMatchesFromText(state.doc.textContent);

    if (linkMatches.length === 0) return null;

    const firstMatch = linkMatches[0];

    if (firstMatch.index !== 0) return null;

    if (firstMatch.text !== state.doc.textContent) return null;

    return firstMatch.text;
};

/**
 * Determines if the current editor text is only a link.
 */
export const isOnlyLinkText = (state: EditorState): boolean => {
    const linkMatch = getTextStartLinkMatch(state);
    if (!linkMatch) return false;
    return !isLinkAnEmail(linkMatch);
};

/**
 * Returns an action to dispatch if link conversion is possible.
 */
export const attemptLinkConversion: UniversalCardConverterCommand = ({ state, updateCurrentlyEditingElementType }) => {
    if (!isOnlyLinkText(state)) return null;

    return updateCurrentlyEditingElementType({
        elementType: ElementType.LINK_TYPE,
        data: { url: state.doc.textContent },
    });
};
