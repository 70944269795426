import React from 'react';
import { gridPointsToPixels } from '../../utils/grid/gridUtils';
import PropTypes from 'prop-types';
import randomString from '../../../common/uid/randomString';

const FolderIcon = ({ gridSize, width = 8, height = 7.2 }) => {
    const widthPx = Math.round(gridPointsToPixels(width, gridSize));
    const heightPx = Math.round(gridPointsToPixels(height, gridSize));

    const viewBoxWidth = Math.round(widthPx + 2);
    const viewBoxHeight = Math.round(heightPx + 2);

    // Generate unique IDs
    const gradientId = `folder-gradient-${randomString(10)}`;
    const maskId = `folder-mask-${randomString(10)}`;
    const pathId = `folder-path-${randomString(10)}`;

    // Adjusted paths to start at 1,1 for the selection border space
    const folderBackgroundPath = `M4 1h5.843a4 4 0 0 1 2.829 1.172l1.07 1.07A6 6 0 0 0 17.986 5H30a3 3 0 0 1 3 3v19a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3z`;

    const folderFoldPath = `
    M17.75 1v4H4a3 3 0 0 0-3 3v3.111V1h16.75z
    `;

    return (
        <svg width={viewBoxWidth} height={viewBoxHeight} viewBox="0 0 34 31" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="53.838%" id={gradientId}>
                    <stop stopOpacity=".08" offset="0%" />
                    <stop stopOpacity=".32" offset="100%" />
                </linearGradient>
                <path
                    d={folderBackgroundPath}
                    id={pathId}
                    stroke="var(--ws-document-icon-selection-border-color)"
                    strokeWidth="2"
                    className="selection-stroke"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id={maskId} fill="#fff">
                    <use xlinkHref={`#${pathId}`} />
                </mask>
                <use fill="var(--ws-board-icon-background-color)" xlinkHref={`#${pathId}`} />
                <path d={folderFoldPath} fill={`url(#${gradientId})`} mask={`url(#${maskId})`} />
            </g>
        </svg>
    );
};

FolderIcon.propTypes = {
    gridSize: PropTypes.number.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default React.memo(FolderIcon);
