import { Mark, Range } from '@tiptap/core';
import { PluginKey, TextSelection, EditorState, Transaction } from '@tiptap/pm/state';
import Suggestion from '@tiptap/suggestion';

import { DEFAULT_TIPTAP_PARSE_HTML_PRIORITY, TiptapMarkType } from '../../tiptapTypes';
import { createBreakingPlugin, createDeletingPlugin } from './mentionBreaking';
import { createCursorSkipPlugin, getCursorSkipSelection } from './cursorSkip';
import { findSuggestionMatch } from './findSuggestionMatch';
import { getSpacesAfterCursor, getSpacesBeforeCursor } from '../textIndentation/indentationUtils';
import { CommandProps } from '@tiptap/core/src/types';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        textMention: {
            createMention: (range: Range, label: string, userId: string, mentionKey: string) => ReturnType;
            handleArrowRight: (isSelecting?: boolean) => ReturnType;
            handleAltArrowRight: (isSelecting?: boolean) => ReturnType;
            handleArrowLeft: (isSelecting?: boolean) => ReturnType;
            handleAltArrowLeft: (isSelecting?: boolean) => ReturnType;
        };
    }
}

export type MentionAttrs = {
    mentionKey: string;
    userId: string;
    originalText?: string; // for noticing if a mention has changed
};

export const DefaultTextMentionPluginKey = new PluginKey('textMentionSuggestion');

// This should be kept 1:1 with the fields of MentionAttrs
const ATTRIBUTES = {
    mentionKey: { default: '' },
    userId: { default: '' },
    originalText: { default: '' },
};

const handleKeyCursorSkip = (
    state: EditorState,
    tr: Transaction,
    dispatch: CommandProps['dispatch'],
    defaultPositionShift: number,
    isSelecting: boolean,
): boolean => {
    const $nextPos = state.doc.resolve(state.selection.$head.pos + defaultPositionShift);

    const cursorSkipSelection = getCursorSkipSelection(state, $nextPos, isSelecting);

    if (!cursorSkipSelection) return false;
    if (!dispatch) return true;

    tr.setSelection(cursorSkipSelection);

    return true;
};

export const TextMention = Mark.create({
    name: TiptapMarkType.textMention,
    inclusive: false,
    excludes: '_',
    exitable: true,

    addAttributes() {
        return ATTRIBUTES;
    },

    parseHTML() {
        return [
            {
                tag: 'span',
                getAttrs: (node) => {
                    const mentionKey = node.getAttribute('data-mention-key');
                    const userId = node.getAttribute('data-id');
                    const originalText = node.getAttribute('data-original-text');
                    if (!mentionKey) return false;
                    return { mentionKey, userId, originalText };
                },
                // Ensure this extension is run before the starter kit's parseHTML
                priority: DEFAULT_TIPTAP_PARSE_HTML_PRIORITY + 1,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'span',
            {
                class: 'MentionEntity',
                'data-original-text': HTMLAttributes.originalText,
                'data-id': HTMLAttributes.id,
                'data-mention-key': HTMLAttributes.mentionKey,
            },
            ['span', {}, 0],
        ];
    },

    addCommands() {
        return {
            createMention:
                (range, label, userId, mentionKey) =>
                ({ chain, state }) => {
                    const text = `@${label}`;
                    const markedNode = state.schema
                        .text(text)
                        .mark([state.schema.marks.textMention.create({ mentionKey, userId, originalText: text })]);

                    // add a space after new mentions to avoid a visual bug where
                    // the cursor ends up "inside" the right margin of the mention
                    // (ideally we'd reposition the cursor but that is *complicated*)
                    const padding = state.schema.text(' ');

                    return chain().insertContentAt(range, markedNode).insertContent(padding).run();
                },
            handleArrowRight:
                (isSelecting = false) =>
                ({ state, tr, dispatch }) =>
                    handleKeyCursorSkip(state, tr, dispatch, 1, isSelecting),
            handleAltArrowRight:
                (isSelecting = false) =>
                ({ state, tr, dispatch }) =>
                    handleKeyCursorSkip(state, tr, dispatch, getSpacesAfterCursor(state) + 1, isSelecting),
            handleArrowLeft:
                (isSelecting = false) =>
                ({ state, tr, dispatch }) =>
                    handleKeyCursorSkip(state, tr, dispatch, -1, isSelecting),
            handleAltArrowLeft:
                (isSelecting = false) =>
                ({ state, tr, dispatch }) =>
                    handleKeyCursorSkip(state, tr, dispatch, -1 - getSpacesBeforeCursor(state), isSelecting),
        };
    },

    addOptions() {
        return {
            suggestionsManager: {},
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            ArrowRight: () => this.editor.commands.handleArrowRight(),
            'Shift-ArrowRight': () => this.editor.commands.handleArrowRight(true),
            'Alt-ArrowRight': () => this.editor.commands.handleAltArrowRight(),
            'Shift-Alt-ArrowRight': () => this.editor.commands.handleAltArrowRight(true),
            ArrowLeft: () => this.editor.commands.handleArrowLeft(),
            'Shift-ArrowLeft': () => this.editor.commands.handleArrowLeft(true),
            'Alt-ArrowLeft': () => this.editor.commands.handleAltArrowLeft(),
            'Shift-Alt-ArrowLeft': () => this.editor.commands.handleAltArrowLeft(true),
        };
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                pluginKey: DefaultTextMentionPluginKey,
                findSuggestionMatch,
                ...this.options.suggestionsManager,
            }),
            createDeletingPlugin(),
            createBreakingPlugin(),
            // NOTE: This is still used to account for instances where the cursor handlers
            //  can't be used (e.g. on down arrow, we don't know the position it will end up in the
            //  next line).
            createCursorSkipPlugin(),
        ];
    },
});
