import { MutableRefObject, RefObject } from 'react';
import { getDefaultSnapPointDetails, getSheetSnapPointWindowHeight } from './snapPointUtils';
import { MAX_SUFFIX } from '../hooks/useSheetContextState';

const setInitialSheetHeight = (
    sheetRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    goToSnapPoint: (snapPoint: number) => void,
    setSheetInitialised: (sheetInitialised: boolean) => void,
    cancelInProgressCloseAnimation: MutableRefObject<(() => void) | null>,
    onOpenTransitionEnd?: () => void,
) => {
    if (!sheetRef.current) return;

    const { point, suffix } = getDefaultSnapPointDetails(defaultSnapPoint);
    const defaultSnapIsMax = suffix && point && suffix === MAX_SUFFIX;

    // --- Set the specified default snap point ---

    if (point && !defaultSnapIsMax) {
        sheetRef.current.style.height = `0px`;

        requestAnimationFrame(() => {
            if (!sheetRef.current) return;

            if (cancelInProgressCloseAnimation.current) {
                cancelInProgressCloseAnimation.current();
            }

            addSnapPoint(point);
            goToSnapPoint(point);
            setSheetInitialised(true);

            if (onOpenTransitionEnd) {
                sheetRef.current.addEventListener('transitionend', onOpenTransitionEnd, { once: true });
            }
        });
        return;
    }

    // --- Set the initial snap point based on the content height ---

    sheetRef.current.style.height = 'auto';
    sheetRef.current.style.opacity = '0';

    requestAnimationFrame(() => {
        if (!sheetRef.current) return;

        if (cancelInProgressCloseAnimation.current) {
            cancelInProgressCloseAnimation.current();
        }

        const contentHeightAsDecimal = sheetRef.current.clientHeight / getSheetSnapPointWindowHeight();
        const maxInitialSnapPoint = defaultSnapIsMax ? point : 1;
        const initialSnapPoint = Math.min(maxInitialSnapPoint, contentHeightAsDecimal);
        addSnapPoint(initialSnapPoint);

        sheetRef.current.style.height = `0px`;
        sheetRef.current.style.opacity = '1';

        requestAnimationFrame(() => {
            if (!sheetRef.current) return;

            if (cancelInProgressCloseAnimation.current) {
                cancelInProgressCloseAnimation.current();
            }

            goToSnapPoint(initialSnapPoint);
            setSheetInitialised(true);

            if (onOpenTransitionEnd) {
                sheetRef.current.addEventListener('transitionend', onOpenTransitionEnd, { once: true });
            }
        });
    });
};

export default setInitialSheetHeight;
