import React from 'react';
import { VariableSizeList as List } from 'react-window';

// Components
import EmojiImage from '../EmojiImage';
import Spinner from '../../loaders/Spinner';
import ReactionPopupRow from './ReactionPopupRow';
import ReactionPopupMenu from './ReactionPopupMenu';

// Hooks
import useReactionPopupData from './hooks/useReactionPopupData';

// Utils
import { isTitleRow } from './reactionPopupUtil';

// Constants
import { DEFAULT_COLUMN_COUNT } from './reactionPopupConstants';
import { EmojisRowData } from '../emojiTypes';

import './ReactionPopup.scss';

const ROW_HEIGHT = 34;
const LAST_ROW_IN_SECTION_HEIGHT = 46;

export type ReactionPopupContentProps = {
    gridSize: number;
    elementIds: string[];
    closePopup: () => void;
};

const getRowHeight = (index: number, emojisRowData: EmojisRowData) => {
    const nextRow = emojisRowData[index + 1];

    return nextRow && isTitleRow(nextRow) ? LAST_ROW_IN_SECTION_HEIGHT : ROW_HEIGHT;
};

const DesktopReactionPopupContent = ({ gridSize, elementIds, closePopup }: ReactionPopupContentProps) => {
    const {
        currentCategory,
        setCurrentCategory,
        setSearchFilter,
        emojiData,
        emojiDataLoaded,
        emojisRowData,
        handleEmojiListScroll,
        listRef,
        getKeyOfItemAtIndex,
        getHeightOfItemAtIndex,
        handleClickEmoji,
        skinTone,
        selectedReactionIds,
        dispatchUpdateSkinTone,
    } = useReactionPopupData(elementIds, closePopup, getRowHeight);

    const pickerListWidthGridPoints = ((32 + 4) * DEFAULT_COLUMN_COUNT + 16) / 10;
    const pickerListHeightGridPoints = 505 / 10;
    const pickerListWidth = pickerListWidthGridPoints * gridSize;
    const pickerListHeight = pickerListHeightGridPoints * gridSize;

    return (
        <>
            <ReactionPopupMenu
                emojiData={emojiData}
                skinTone={skinTone}
                currentCategory={currentCategory}
                setCurrentCategory={setCurrentCategory}
                setSearchFilter={setSearchFilter}
                emojisRowData={emojisRowData}
                listRef={listRef}
                dispatchUpdateSkinTone={dispatchUpdateSkinTone}
                gridSize={gridSize}
            />

            {emojiDataLoaded && emojisRowData.length ? (
                <List
                    className="emoji-list"
                    onScroll={handleEmojiListScroll}
                    ref={listRef}
                    width={pickerListWidth}
                    height={pickerListHeight}
                    itemCount={emojisRowData.length}
                    itemSize={getHeightOfItemAtIndex}
                    itemKey={getKeyOfItemAtIndex}
                    itemData={{
                        rows: emojisRowData,
                        skinTone,
                        selectedReactionIds,
                        handleClickEmoji,
                        gridSize,
                    }}
                    overscanCount={10}
                >
                    {ReactionPopupRow}
                </List>
            ) : (
                <div style={{ width: pickerListWidth, height: pickerListHeight }} className="no-results">
                    {emojiDataLoaded ? (
                        <div className="no-results-text">
                            No emoji found <EmojiImage emojiCode="1F614" size={24} />
                        </div>
                    ) : (
                        <div className="no-results-text">
                            <Spinner show />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default DesktopReactionPopupContent;
