// Types
import { ResolvedPos } from '@tiptap/pm/model';

/**
 * Finds the start resolved position of the text node *after* the current position.
 * This means it won't be the same text node as the current position.
 */
export const getStartOfNextTextNode = ($pos: ResolvedPos): ResolvedPos | null => {
    let $foundPos: ResolvedPos | null = null;

    $pos.doc.nodesBetween($pos.end(), $pos.doc.content.size - 2, (node, pos) => {
        if ($foundPos) return false;
        if (node.isText) {
            $foundPos = $pos.doc.resolve(pos);
            return false;
        }

        return true;
    });

    return $foundPos;
};
