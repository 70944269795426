// Lib
import { createStructuredSelector } from 'reselect';
import { pick } from 'lodash/fp';
import { List } from 'immutable';

// Utils
import { getEmojiData } from '../emojiDataUtil';
import { getReactions } from '../../../../common/elements/utils/elementPropertyUtils';
import { getReactionUserId, getReactionEmojiCode } from '../../../element/reactions/reactionUtil';
import {
    getUserReactionsRecentlyUsed,
    getUserReactionsSkinTone,
} from '../../../../common/users/utils/userPropertyUtils';

// Selectors
import { getCurrentUser, getCurrentUserId } from '../../../user/currentUserSelector';
import { getRenderedElements } from '../../../element/selection/selectedElementsSelector';
import { getAllComments } from '../../../element/comment/store/commentsSelector';

// Constants
import { RECENTLY_USED_DEFAULTS } from './reactionPopupConstants';
import { RecentlyUsedEmojis } from '../emojiTypes';

export const getSelectedReactionIds = (state: any, selectedElementIds: string[]): Immutable.List<string> => {
    const elements = state.get('elements');
    const comments = getAllComments(state);
    const currentUserId = getCurrentUserId(state);

    const selectedElements = getRenderedElements(selectedElementIds, elements);
    const selectedComments = Object.values(pick(selectedElementIds, comments));

    const selectedItems = List.of(...selectedElements, ...selectedComments);

    return selectedItems
        .flatMap(getReactions)
        .filter((reaction) => getReactionUserId(reaction) === currentUserId)
        .map(getReactionEmojiCode)
        .toList();
};

const reactionPopupSelector = createStructuredSelector({
    emojiData: getEmojiData,
    recentlyUsed: (state): RecentlyUsedEmojis => {
        const recent = getUserReactionsRecentlyUsed(getCurrentUser(state));

        return recent ? recent.toJS() : RECENTLY_USED_DEFAULTS;
    },
    skinTone: (state) => getUserReactionsSkinTone(getCurrentUser(state)),
});

export default reactionPopupSelector;
