// Lib
import { useMemo } from 'react';
import { Extensions, CommandProps } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import UniqueID from '@tiptap-pro/extension-unique-id';
import { defer, difference } from 'lodash';

// Utils
import randomString from '../../../../common/uid/randomString';

// Custom extensions
import { Heading } from '../../../../common/tiptap/extensions/Heading';
import { KeyboardShortcuts } from '../../../../common/tiptap/extensions/KeyboardShortcuts';
import { SmallText } from '../../../../common/tiptap/extensions/SmallText';
import { TaskList } from '../../../../common/tiptap/extensions/TaskList';
import { TaskItem } from '../../../../common/tiptap/extensions/TaskItem';
import { BulletList } from '../../../../common/tiptap/extensions/list/BulletList';
import { OrderedList } from '../../../../common/tiptap/extensions/list/OrderedList';
import { TextAlign } from '../../../../common/tiptap/extensions/TextAlign';
import { Blockquote } from '../../../../common/tiptap/extensions/Blockquote';
import { CodeBlock } from '../../../../common/tiptap/extensions/CodeBlock';
import { TextIndentationHandler } from '../../../../common/tiptap/extensions/textIndentation/TextIndentationHandler';
import { InlineStyleClearer } from '../../../../common/tiptap/extensions/InlineStyleClearer';
import { UniversalCard } from '../../../../common/tiptap/extensions/universalCard/UniversalCard';
import { ListKeymap } from '../../../../common/tiptap/extensions/list/ListKeymap';
import { ClipperCommands } from '../../../../common/tiptap/extensions/clipper/ClipperCommands';
import useClientCommonTiptapEditorExtensions from '../../../components/tiptapEditor/useClientCommonTiptapEditorExtensions';

// Actions
import { updateCurrentlyEditingElementType } from '../universalCardActions';

// Components
import Checkbox from '../../../components/editor/plugins/checklist/components/Checkbox';

// Types
import { DispatchCreateNewElementFunction } from '../../actions/elementCreateActionTypes';
import { MentionsSuggestionsManager } from '../../../../common/tiptap/extensions/mention/SuggestionsManagerTypes';
import { TiptapContent, TiptapNodeType } from '../../../../common/tiptap/tiptapTypes';

// Constants
import { ELEMENT_CREATION_SOURCES } from '../../../../common/elements/elementConstants';

// Add a unique ID to almost all types of nodes
const UNIQUE_ID_TYPES = difference(Object.values(TiptapNodeType), [
    TiptapNodeType.doc,
    TiptapNodeType.text,
    TiptapNodeType.hardBreak,
    TiptapNodeType.mention,
]);

type UseCardTiptapEditorExtensionsArgs = {
    moveElementsToTrash?: () => void;
    saveContent?: (textContent: TiptapContent, transactionId?: number) => void;
    suggestionsManager?: MentionsSuggestionsManager;
    createNewCard?: DispatchCreateNewElementFunction;
    isEditable?: boolean;
    dispatch?: Function;
    onHighlightsChanged?: Function;
    clickableLinksWhenEditable?: boolean;
    universalCardConversion?: boolean;
};

/**
 * Retrieves the Tiptap extensions for the Card's main editor.
 */
const useCardTiptapEditorExtensions = ({
    moveElementsToTrash,
    saveContent,
    suggestionsManager,
    createNewCard,
    isEditable,
    dispatch,
    onHighlightsChanged,
    clickableLinksWhenEditable,
    universalCardConversion = true,
}: UseCardTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useClientCommonTiptapEditorExtensions({
        saveContent,
        onEmptyBackspace: moveElementsToTrash,
        placeholder: 'Start typing...',
        suggestionsManager,
        isEditable,
        dispatch,
        onHighlightsChanged,
        clickableLinksWhenEditable,
    });

    return useMemo(() => {
        /**
         * When Cmd+Enter is pressed, create a new card.
         */
        const onModEnter = ({ editor }: CommandProps) => {
            // Save the content first, before creating a new element, so that the undo stack is correct
            const currentContent = editor.getJSON() as TiptapContent;
            saveContent?.(currentContent);

            // Need to defer this to prevent the app keyboard shortcut handlers from creating a new element as well.
            // The first new element would get created by this function, then selected, then the keyboardShortcuts
            // handlers would be triggered and another new element would be created.
            defer(() => {
                createNewCard?.({ creationSource: ELEMENT_CREATION_SOURCES.CMD_ENTER });
            });

            return !!createNewCard;
        };

        return [
            Document,
            UniqueID.configure({
                attributeName: 'key',
                types: UNIQUE_ID_TYPES,
                generateID: () => randomString(6),
            }),
            Heading,
            BulletList,
            OrderedList,
            Blockquote,
            // Improves the editing functionality in lists.
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/listkeymap
            ListKeymap,
            // Allows text to be centred
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/textalign
            TextAlign,
            SmallText,
            TaskList,
            CodeBlock,
            TextIndentationHandler,
            ClipperCommands,
            // Clears inline styles when the user presses Enter
            InlineStyleClearer,
            ...commonExtensions,
            createNewCard &&
                KeyboardShortcuts.configure({
                    keyboardShortcuts: {
                        // Create a new card on mod+enter
                        'Mod-Return': onModEnter,
                        'Mod-Enter': onModEnter,
                    },
                }),
            TaskItem.configure({
                nested: true,
                onReadOnlyChecked: (node, checked) => !!isEditable,
                CheckboxComponent: Checkbox,
            }),
            isEditable &&
                universalCardConversion &&
                UniversalCard.configure({ dispatch, updateCurrentlyEditingElementType }),
        ].filter(Boolean) as Extensions;
    }, [commonExtensions, saveContent, createNewCard, isEditable, dispatch, universalCardConversion]);
};

export default useCardTiptapEditorExtensions;
