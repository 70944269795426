import { Trigger, SuggestionMatch } from '@tiptap/suggestion';
import { getMentionMarkAttrsFromNode } from './mentionUtils';

// find any "@whatever" after some whitespace or the start of the string
const MENTION_INITIATION_REGEX = /(^|\s)@([^@\s]*)/gm;

export function findSuggestionMatch({ $position }: Trigger): SuggestionMatch {
    // If we're not in a text node, we can't be in a mention
    const text = $position.nodeBefore?.isText && $position.nodeBefore.text;
    if (!text) return null;

    // If there's already a mention mark at this position, we don't want to create a new one
    const existing = getMentionMarkAttrsFromNode($position.nodeBefore!);
    if (existing) return null;

    // Check if it looks like a mention
    const [match] = [...text.matchAll(MENTION_INITIATION_REGEX)];
    if (!match) return null;

    // Extract the groups from the match
    const [fullMatch, precedingWhitespace, query] = match;

    // Find the position of the match in the text
    const pos = $position.pos;
    const textFrom = pos - text.length;
    const from = textFrom + match.index + precedingWhitespace.length;
    const to = from + fullMatch.length - precedingWhitespace.length;

    // If the $position is located outside the matched substring, it's not a match
    if (pos <= from || pos > to) return null;

    // Mention confirmed; let the plugin know what + where.
    return {
        range: {
            from,
            to,
        },
        query,
        text: fullMatch,
    };
}
