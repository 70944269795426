// Lib
import React from 'react';
import { connect } from 'react-redux';

// Utils
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { openDefaultAssignmentPopup } from '../pills/assignment/taskAssignmentActions';
import { openCurrentlyEditingDueDatePopup } from '../pills/assignment/dueDate/taskDueDateActions';

// Hooks
import { useElementTiptapContent } from '../../../../common/tiptap/conversion/elementConversion/useElementTiptapContent';

// Components
import TaskDraftJsEditor from './TaskDraftJsEditor';
import TaskTiptapEditor, { TaskTiptapEditorProps } from './TaskTiptapEditor';

// Types
import { DocumentType } from '../../../../common/tiptap/conversion/elementConversion/elementConversion';

type TaskEditorWrapperProps = Omit<TaskTiptapEditorProps, 'textContent'> & {
    editorRef: (editor: HTMLDivElement | null) => void;
    shouldFocusOnlyWhenSelected: boolean;

    // NOTE: These are only used in the Draft.js version.
    //  The Tiptap version builds these events itself in the AppUndo extension
    onAppUndo: () => void;
    onAppRedo: () => void;

    setComplete: () => void;
};

const mapDispatchToProps = (dispatch: Function) => ({
    dispatchOpenAssignmentPopup: () => dispatch(openDefaultAssignmentPopup()),
    dispatchOpenDueDatePopup: () => dispatch(openCurrentlyEditingDueDatePopup()),
});

const TaskEditorWrapper = (props: TaskEditorWrapperProps) => {
    const {
        element,
        editorRef,
        isEditable,
        isEditing,
        spellCheck,
        currentEditorKey,
        currentEditorId,
        filterQuery,
        saveContent,
        startEditing,
        stopEditing,
        onKeyboardDelete,
        changeIndentation,
        goToPreviousTask,
        goToNextTask,
        placeholder,
        onEnter,
        onAppUndo,
        onAppRedo,
        createMultipleNewTasksWithContent,
        setComplete,
        toggleComplete,
        dispatchOpenAssignmentPopup,
        dispatchOpenDueDatePopup,
        onStartBackspace,
        onEndDelete,
        shouldFocusOnlyWhenSelected,
    } = props;

    const textContent = getTextContent(element);
    const tiptapContent = useElementTiptapContent(element, textContent, DocumentType.textContent);

    if (tiptapContent) {
        return (
            <TaskTiptapEditor
                element={element}
                textContent={tiptapContent}
                currentEditorKey={currentEditorKey}
                currentEditorId={currentEditorId}
                filterQuery={filterQuery}
                isEditable={isEditable}
                isEditing={isEditing}
                spellCheck={spellCheck}
                placeholder={placeholder || 'Add a task...'}
                saveContent={saveContent}
                startEditing={startEditing}
                stopEditing={stopEditing}
                onEnter={onEnter}
                onKeyboardDelete={onKeyboardDelete}
                changeIndentation={changeIndentation}
                toggleComplete={toggleComplete}
                dispatchOpenDueDatePopup={dispatchOpenDueDatePopup}
                dispatchOpenAssignmentPopup={dispatchOpenAssignmentPopup}
                goToPreviousTask={goToPreviousTask}
                goToNextTask={goToNextTask}
                onStartBackspace={onStartBackspace}
                onEndDelete={onEndDelete}
                createMultipleNewTasksWithContent={createMultipleNewTasksWithContent}
            />
        );
    }

    return (
        <TaskDraftJsEditor
            element={element}
            editorId={getMainEditorId(props)}
            editorKey={getMainEditorKey(props)}
            currentEditorKey={currentEditorKey}
            currentEditorId={currentEditorId}
            editorRef={editorRef}
            isEditable={isEditable}
            isEditing={isEditing}
            placeholder={placeholder || 'Add a task...'}
            textContent={getTextContent(element)}
            filterQuery={filterQuery}
            saveContent={saveContent}
            startEditing={startEditing}
            stopEditing={stopEditing}
            onEmptyBackspace={onKeyboardDelete}
            onStartBackspace={onStartBackspace}
            onEndDelete={onEndDelete}
            changeIndentation={changeIndentation}
            goToPreviousTask={goToPreviousTask}
            goToNextTask={goToNextTask}
            createMultipleNewTasksWithContent={createMultipleNewTasksWithContent}
            spellCheck={spellCheck}
            onAppUndo={onAppUndo}
            onAppRedo={onAppRedo}
            openAssignmentPopup={dispatchOpenAssignmentPopup}
            openDueDatePopup={dispatchOpenDueDatePopup}
            onEnter={onEnter}
            setComplete={setComplete}
            toggleComplete={toggleComplete}
            shouldFocusOnlyWhenSelected={shouldFocusOnlyWhenSelected}
        />
    );
};

export default connect(null, mapDispatchToProps)(TaskEditorWrapper);
