import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ElementFolderIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={28}
        viewBox="0 0 32 28"
        className="Icon element-folder"
        {...props}
    >
        <defs>
            <linearGradient id="ElementFolderIcon__b" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopOpacity={0.04} />
                <stop offset="100%" stopOpacity={0.32} />
            </linearGradient>
            <path
                id="ElementFolderIcon__a"
                d="M4 0h6.667a4 4 0 0 1 2.4.8l2.4 1.8a2 2 0 0 0 1.2.4H28a4 4 0 0 1 4 4v17a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="ElementFolderIcon__c" fill="#fff">
                <use xlinkHref="#ElementFolderIcon__a" />
            </mask>
            <use xlinkHref="#ElementFolderIcon__a" fill="#6CC7FF" />
            <path
                fill="url(#ElementFolderIcon__b)"
                d="M23.096 1.134C24.375 2.378 25.343 3 26 3H4a4 4 0 0 0-4 4V4a4 4 0 0 1 4-4h16.31a4 4 0 0 1 2.788 1.132z"
                mask="url(#ElementFolderIcon__c)"
            />
        </g>
    </svg>
);
const Memo = memo(ElementFolderIcon);
export default Memo;
