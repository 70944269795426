import classNames from 'classnames';
import Icon from '../../components/icons/Icon';
import React from 'react';
import useSheet from '../../mobile/structural/sheet/hooks/useSheet';
import { SheetId } from '../../mobile/structural/sheet/sheetTypes';
import MobileReactionSheet from '../../components/emoji/popup/MobileReactionSheet';

type Props = { elementId: string };

const MobileElementReactionAddButton = (props: Props) => {
    const { elementId } = props;

    const sheetProps = useSheet(SheetId.MobileElementReactionTool, {
        scrollToElementOnOpen: true,
    });
    const { isSheetOpen, dispatchOpenSheet, dispatchCloseSheet } = sheetProps;

    return (
        <div className="sheet-trigger">
            <div className={classNames('add-icon', { active: isSheetOpen })} onClick={dispatchOpenSheet}>
                <Icon name="reaction-add" />
            </div>
            <MobileReactionSheet
                {...props}
                sheetProps={sheetProps}
                closePopup={dispatchCloseSheet}
                elementIds={[elementId]}
            />
        </div>
    );
};

export default MobileElementReactionAddButton;
