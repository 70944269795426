// Types
import {
    ApplicationType,
    BrowserEngineType,
    DeviceFormFactor,
    LegacyHybridUseCase,
    MilanoteApplicationMode,
    OperatingSystem,
    PlatformDetails,
    PlatformType,
} from '../../common/platform/platformTypes';

export const getPlatformDetailsSelector = (state: unknown): PlatformDetails =>
    (state as Immutable.Map<string, object>).get('platform') as PlatformDetails;

export const getApplicationTypeSelector = (state: Immutable.Map<string, object>): ApplicationType =>
    getPlatformDetailsSelector(state).appType;
export const getMilanoteApplicationModeSelector = (state: Immutable.Map<string, object>): MilanoteApplicationMode =>
    getPlatformDetailsSelector(state).appMode;
export const getPlatformTypeSelector = (state: Immutable.Map<string, object>): PlatformType =>
    getPlatformDetailsSelector(state).type;
export const getPlatformFormFactorSelector = (state: Immutable.Map<string, object>): DeviceFormFactor =>
    getPlatformDetailsSelector(state).formFactor;
export const getPlatformOperatingSystemSelector = (state: Immutable.Map<string, object>): OperatingSystem =>
    getPlatformDetailsSelector(state).os;
export const getCapacitorPlatformSelector = (state: Immutable.Map<string, object>): string =>
    getPlatformDetailsSelector(state).capacitorPlatform;
export const getUserAgentSelector = (state: Immutable.Map<string, object>): string =>
    getPlatformDetailsSelector(state).userAgent;
export const getBrowserEngineSelector = (state: Immutable.Map<string, object>): BrowserEngineType =>
    getPlatformDetailsSelector(state).browserEngine;
export const getLegacyHybridUseCaseSelector = (state: Immutable.Map<string, object>): LegacyHybridUseCase | null =>
    getPlatformDetailsSelector(state).legacyHybridUseCase;
export const selectAppVersion = (state: Immutable.Map<string, object>): string | null =>
    getPlatformDetailsSelector(state).appVersion;
export const selectAppBuild = (state: Immutable.Map<string, object>): string | null =>
    getPlatformDetailsSelector(state).appBuild;

export const getPlatformFeaturesSelector = (state: Immutable.Map<string, object>): PlatformDetails['features'] =>
    getPlatformDetailsSelector(state).features;
export const getIsTouchSelector = (state: Immutable.Map<string, object>): boolean =>
    getPlatformFeaturesSelector(state).isTouch;
