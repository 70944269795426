// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ListElement from './ListElement';
import ListDropPreview from './ListDropPreview';
import EmptyListPlaceholder from './EmptyListPlaceholder';

// Constants
import { IN_LIST_MODE } from '../../common/inList/listTypes';

// Styles
import './List.scss';

const ListPresentational = (props) => {
    const {
        childElementIds,
        isHovered,
        isHoveredDeep,
        hoveredChildAcceptsDrop,
        isPresentational,
        inTrash,
        isPreview,
        isFocusedForegroundElement,
        renderHighQualityImages,
        registerListElement,
        deregisterListElement,
        hoveredIndex,
        gridSize,
        getContextZoomScale,
        inListClass,
        currentBoardId,
        depth = 0,
        documentMode,
        deselectAll,
        createShortcutElement,
        createShortcutCard,
        createShortcutFile,
        createShortcutLink,
        createShortcutImageLink,
        permissions,
        listElementProps,
        setParentHoveredChildAcceptsDrop,
        getListIndex,
        listStartChild,
        listEndChild,
        allowElementPlaceholder,
    } = props;

    let listIndex = 0;

    // We should show the drop preview if this list is shallowly hovered (and accepts the drop) or if the list
    //  is hovered deeply and the shallowly hovered child doesn't accept the drop
    const willAcceptDrop = isHovered || (isHoveredDeep && !hoveredChildAcceptsDrop);
    const showDropPreview = willAcceptDrop && hoveredIndex !== null && childElementIds.length > 0;
    const displayElements = showDropPreview
        ? [...childElementIds.slice(0, hoveredIndex), 'preview', ...childElementIds.slice(hoveredIndex)]
        : childElementIds;

    const classes = classNames('List', `depth-${depth}`);

    const renderElements = displayElements.map((elementId) => {
        if (elementId === 'preview') return <ListDropPreview key="dropPreview" />;

        const i = listIndex;
        listIndex++;

        // The listSize can be provided by the OrchestratedList to prevent all elements from re-rendering
        //  as the list of rendered elements grows
        return (
            <ListElement
                {...listElementProps}
                key={elementId}
                elementId={elementId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                inList={inListClass || IN_LIST_MODE.IN_LIST_COLUMN}
                isFocusedForegroundElement={isFocusedForegroundElement}
                inTrash={inTrash}
                isPreview={isPreview}
                renderHighQualityImages={renderHighQualityImages}
                currentBoardId={currentBoardId}
                isPresentational={isPresentational}
                registerListElement={registerListElement}
                deregisterListElement={deregisterListElement}
                deselectAll={deselectAll}
                createShortcutElement={createShortcutElement}
                createShortcutCard={createShortcutCard}
                createShortcutFile={createShortcutFile}
                createShortcutLink={createShortcutLink}
                createShortcutImageLink={createShortcutImageLink}
                isFirstEntry={i === 0}
                getListIndex={getListIndex}
                documentMode={documentMode}
                permissions={permissions}
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                allowElementPlaceholder={allowElementPlaceholder}
            />
        );
    });

    const startChild = listStartChild ? React.cloneElement(listStartChild, { setParentHoveredChildAcceptsDrop }) : null;
    const endChild = listEndChild ? React.cloneElement(listEndChild, { setParentHoveredChildAcceptsDrop }) : null;

    return (
        <>
            {startChild}
            {childElementIds.length > 0 && <div className={classes}>{renderElements}</div>}
            {childElementIds.length === 0 && <EmptyListPlaceholder {...props} isHovered={willAcceptDrop} />}
            {endChild}
        </>
    );
};

ListPresentational.propTypes = {
    listId: PropTypes.string.isRequired,
    currentBoardId: PropTypes.string.isRequired,
    childElementIds: PropTypes.array.isRequired,
    permissions: PropTypes.number,
    connectDropTarget: PropTypes.func,
    isHovered: PropTypes.bool,
    isHoveredDeep: PropTypes.bool,
    hoveredChildAcceptsDrop: PropTypes.bool,
    hoveredIndex: PropTypes.number,
    isPresentational: PropTypes.bool,
    isFocusedForegroundElement: PropTypes.bool,
    renderHighQualityImages: PropTypes.bool,
    isEditable: PropTypes.bool,
    inTrash: PropTypes.bool,
    isPreview: PropTypes.bool,
    registerListElement: PropTypes.func,
    deregisterListElement: PropTypes.func,
    gridSize: PropTypes.number,
    getContextZoomScale: PropTypes.func,
    inListClass: PropTypes.string,
    documentMode: PropTypes.bool,
    deselectAll: PropTypes.func,
    createShortcutElement: PropTypes.func,
    createShortcutCard: PropTypes.func,
    createShortcutFile: PropTypes.func,
    createShortcutLink: PropTypes.func,
    createShortcutImageLink: PropTypes.func,
    listElementProps: PropTypes.object,
    depth: PropTypes.number,

    setParentHoveredChildAcceptsDrop: PropTypes.func,
    listSize: PropTypes.number,
    getListIndex: PropTypes.func,

    listStartChild: PropTypes.node,
    listEndChild: PropTypes.node,
    allowElementPlaceholder: PropTypes.bool,
};

export default ListPresentational;
