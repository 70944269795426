// Lib
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';

// Utils
import { isAlias } from '../../../common/elements/utils/elementTypeUtils';
import { getPhysicalId } from '../../../common/elements/utils/elementPropertyUtils';
import {
    isAsyncEntityCached,
    shouldFetchAsyncResource,
} from '../../utils/services/http/asyncResource/asyncResourceUtils';

// Services
import * as boardService from './boardService';
import workspaceInitialisationMonitoringSingleton from '../../workspace/initialisation/workspaceInitialisationMonitoringSingleton';

// Selectors
import { getBoardResourceEntityState } from './boardSelector';
import { getLocalCacheHydrationTimestamp } from '../../offline/cache/localCacheSelector';
import { getAsyncResourceEntityState } from '../../utils/services/http/asyncResource/asyncResourceSelector';
import { getIsClientPersistenceEnabledForCurrentUser } from '../feature/elementFeatureSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Types
import { LegacyHybridUseCase, MilanoteApplicationMode } from '../../../common/platform/platformTypes';
import { ResourceTypes } from '../../utils/services/http/asyncResource/asyncResourceConstants';

const createDebouncedFetchIdsFunc = (fetchFunc) => {
    let boardFetchIds = [];
    let boardFetchAncestors = false;
    let fetchBoardTimeout;

    return (boardId, loadAncestors, canvasOrder) => (dispatch, getState) => {
        boardFetchIds.push(boardId);
        boardFetchAncestors = boardFetchAncestors || loadAncestors;

        clearTimeout(fetchBoardTimeout);

        fetchBoardTimeout = setTimeout(() => {
            const state = getState();

            const isClientPersistenceEnabled = getIsClientPersistenceEnabledForCurrentUser(state);

            // Preload the board if we haven't fetched it yet or if it's already preloaded.
            // This is to ensure that we don't preload the board if it's already fetched.
            // This might occur if the board was fetched in a previous session / before a rehydration.
            const cachedBoardIds = [];
            const fetchBoardIds = [];

            boardFetchIds.forEach((id) => {
                const boardResource = getAsyncResourceEntityState(state, ResourceTypes.boards, id);
                const shouldCache =
                    isClientPersistenceEnabled && (!boardResource || isAsyncEntityCached(boardResource));

                if (shouldCache) {
                    cachedBoardIds.push(id);
                } else {
                    fetchBoardIds.push(id);
                }
            });

            if (!isEmpty(cachedBoardIds)) {
                dispatch(
                    fetchFunc({
                        cache: true,
                        boardIds: cachedBoardIds,
                        loadAncestors: boardFetchAncestors,
                        excludeSelf: true,
                        canvasOrder,
                    }),
                );
            }

            if (!isEmpty(fetchBoardIds)) {
                dispatch(
                    fetchFunc({
                        cache: false,
                        boardIds: fetchBoardIds,
                        loadAncestors: boardFetchAncestors,
                        excludeSelf: true,
                        canvasOrder,
                    }),
                );
            }

            boardFetchIds = [];
            boardFetchAncestors = false;
        }, 250);
    };
};

const debouncedFetchBoard = createDebouncedFetchIdsFunc(boardService.fetchBoards);

const getIntersectionObserverRoot = (platformDetails) => {
    const useBrowserViewport = platformDetails.legacyHybridUseCase === LegacyHybridUseCase.ANDROID_BOARD_LIST;

    // When null, it will default to the browser viewport, however the threshold & rootMargin won't work
    // This isn't important for the legacy apps
    if (useBrowserViewport) return null;

    // For the new mobile apps we'll use the canvas viewport, or MobilePageBody if we're on a listing page
    if (platformDetails.appMode === MilanoteApplicationMode.mobile) {
        return document.getElementById('canvas-viewport') || document.querySelector('.MobilePageBody');
    }

    return document.querySelector('.App');
};

const getIntersectionObserverConfig = (platformDetails) => {
    const config = {
        root: getIntersectionObserverRoot(platformDetails),
        threshold: 0.25,
    };

    if (platformDetails.appMode === MilanoteApplicationMode.mobile) {
        config.rootMargin = '50% 100% 50% 100%';
    }

    return config;
};

const mapDispatchToProps = (dispatch) => ({
    fetchBoard: (boardId, loadAncestors, canvasOrder) =>
        dispatch(debouncedFetchBoard(boardId, loadAncestors, canvasOrder)),
});

const mapStateToProps = () =>
    createStructuredSelector({
        boardResourceEntity: getBoardResourceEntityState,
        localCacheHydrationTimestamp: getLocalCacheHydrationTimestamp,
        platformDetails: getPlatformDetailsSelector,
    });

class BoardChildrenLoadObserver extends React.Component {
    constructor(props) {
        super(props);

        this.observeDiv = createRef();

        // if browser doesn't support IntersectionObserver, just preload the board children
        if (!window.IntersectionObserver) {
            this.intersectionCallback([{ isIntersecting: true }]);
            return;
        }

        this.observer = new IntersectionObserver(
            this.intersectionCallback,
            getIntersectionObserverConfig(props.platformDetails),
        );
    }

    componentDidMount() {
        this.observeDiv.current && this.observer && this.observer.observe(this.observeDiv.current);
    }

    componentWillUnmount() {
        this.observeDiv.current && this.observer && this.observer.unobserve(this.observeDiv.current);
    }

    intersectionCallback = async ([intersection]) => {
        const { isIntersecting } = intersection;
        const { boardResourceEntity, fetchBoard, element, localCacheHydrationTimestamp, documentMode } = this.props;

        // If the board isn't visible yet, don't worry about fetching it
        if (!isIntersecting) return;

        // If the board is visible on the canvas and it's a fresh page load, we want
        // to make sure we track when the board counts are retrieved
        workspaceInitialisationMonitoringSingleton.registerBoardCountsWaiting();

        // If we have the div with the intersection observer, stop observing as we don't need to action it again
        if (this.observeDiv.current) {
            this.observer && this.observer.unobserve(this.observeDiv.current);
        }

        const shouldFetchBoard = shouldFetchAsyncResource(boardResourceEntity, localCacheHydrationTimestamp);

        if (!shouldFetchBoard) return;

        fetchBoard(getPhysicalId(element), isAlias(element), documentMode);
    };

    render() {
        const { children } = this.props;

        return <div ref={this.observeDiv}>{children}</div>;
    }
}

BoardChildrenLoadObserver.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    element: PropTypes.object,
    fetchBoard: PropTypes.func,
    boardResourceEntity: PropTypes.object,
    localCacheHydrationTimestamp: PropTypes.number,
    documentMode: PropTypes.bool,
    platformDetails: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardChildrenLoadObserver);
