// Lib
import axios from 'axios';
import { first, identity, last } from 'lodash/fp';
import { Capacitor } from '@capacitor/core';
import { Export } from '../../../capacitor_plugins/export';
import { Filesystem, Directory } from '@capacitor/filesystem';

// utils
import requestFileHead from '../../utils/services/http/requestFileHead';
import {
    uploadingElementAttachment,
    resetProgressElementAttachment,
    completedUploadingElementAttachment,
    progressUploadingElementAttachment,
} from './attachmentActions';
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformModernMobileApp } from '../../platform/utils/platformDetailsUtils';

// Selectors
import { getLegacyHybridUseCaseSelector } from '../../platform/platformSelector';

// Constants
import { METHODS } from '../../../common/utils/http/httpConstants';
import { ELEMENT_ATTACHMENT_ACTION_TYPES } from '../../../common/elements/elementConstants';
import { LegacyHybridUseCase } from '../../../common/platform/platformTypes';
import { CAPACITOR_EXPORT_PLUGIN_NAME } from '../../../capacitor_plugins/pluginConstants';

// 1 gigabyte
const MAX_BLOB_DOWNLOAD_SIZE = 1000 * 1000 * 1000;

const downloader = axios.create();

export const getFilenameFromUrl = (url) => {
    const urlWithoutParams = first(url?.split('?'));
    return last(urlWithoutParams?.split('/'));
};

const createLink = (url, filename, target = '_self') => {
    const link = document.createElement('a');
    link.href = url;
    link.target = target;
    link.setAttribute('download', filename || getFilenameFromUrl(url));
    link.rel = 'noopener noreferrer';

    return link;
};

export const triggerURLDownload = (url, filename, target) => {
    const link = createLink(url, filename, target);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const getShouldDoURLDownloadBasedOnHybridUseCase = (legacyHybridUseCase) => {
    switch (legacyHybridUseCase) {
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            return true;
        case LegacyHybridUseCase.IPAD_OS:
        default:
            return false;
    }
};

export const triggerDownload =
    ({ url, filename, onDownloadStart = identity, onDownloadEnd = identity, id }) =>
    async (dispatch, getState) => {
        const downloadFilename = filename || getFilenameFromUrl(url);
        const actionType = ELEMENT_ATTACHMENT_ACTION_TYPES.DOWNLOAD;

        onDownloadStart();

        const state = getState();
        const legacyHybridUseCase = getLegacyHybridUseCaseSelector(state);

        try {
            const { size } = await requestFileHead(url);

            // if file is larger than maximum size,
            // just trigger download normally instead of writing to blob
            if (size > MAX_BLOB_DOWNLOAD_SIZE || getShouldDoURLDownloadBasedOnHybridUseCase(legacyHybridUseCase)) {
                onDownloadEnd();
                return triggerURLDownload(url, downloadFilename, '_blank');
            }

            // reset loading counter
            dispatch(
                resetProgressElementAttachment({
                    id,
                    actionType,
                }),
            );

            // Mark the file as downloading
            dispatch(uploadingElementAttachment({ id, actionType }));

            if (
                isPlatformModernMobileApp(platformSingleton) &&
                Capacitor.isPluginAvailable(CAPACITOR_EXPORT_PLUGIN_NAME)
            ) {
                // Use various Capacitor plugins to download the "export" file.
                await Filesystem.addListener('progress', (progressStatus) => {
                    dispatch(
                        progressUploadingElementAttachment({
                            id,
                            loaded: progressStatus.bytes,
                            total: progressStatus.contentLength,
                            percentageComplete: (progressStatus.bytes / progressStatus.contentLength) * 100,
                            actionType,
                        }),
                    );
                });
                const downloadResult = await Filesystem.downloadFile({
                    path: `milanote-capacitor/export/${downloadFilename}`,
                    directory: Directory.Cache,
                    progress: true,
                    recursive: true,
                    url: url,
                });
                Export.onExportFileUrl({
                    fileUrl: downloadResult.path,
                });
                await Filesystem.removeAllListeners();
            } else {
                const response = await downloader({
                    url,
                    method: METHODS.GET,
                    responseType: 'blob', // important
                    onDownloadProgress: (progressEvent) => {
                        dispatch(
                            progressUploadingElementAttachment({
                                id,
                                loaded: progressEvent.loaded,
                                total: progressEvent.total,
                                percentageComplete: (progressEvent.loaded / progressEvent.total) * 100,
                                actionType,
                            }),
                        );
                    },
                });
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                triggerURLDownload(blobUrl, downloadFilename);
            }

            onDownloadEnd();

            dispatch(completedUploadingElementAttachment({ id, actionType }));

            onDownloadEnd();
            // reset loading counter
            dispatch(
                resetProgressElementAttachment({
                    id,
                    actionType,
                }),
            );
        } catch (error) {
            console.error(error);

            triggerURLDownload(url, downloadFilename, '_blank');

            onDownloadEnd();
            dispatch(completedUploadingElementAttachment({ id, actionType }));

            // reset loading counter
            dispatch(
                resetProgressElementAttachment({
                    id,
                    actionType,
                }),
            );
        }
    };
