// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Utils
import { getElementStyle } from '../../../utils/grid/gridUtils';
import { getIsFeatureEnabled } from '../../feature/elementFeatureUtils';
import { getBackgroundColorClasses, getColorCssValue } from '../../utils/elementColorStyleUtils';
import {
    elementClassNames,
    getDomElementId,
    getElementCardCropInset,
    getElementCardRenderedWidthPx,
    getElementCardWidthGU,
    isElementBelowEditableWidth,
} from '../../utils/elementUtil';
import {
    getBackgroundColor,
    getCaption,
    getColor,
    getDrawingData,
    getDrawingSvg,
    getElementId,
    getShowCaption,
} from '../../../../common/elements/utils/elementPropertyUtils';
import { getShowQuickLineTool } from '../../quickLine/quickLineUtil';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../platform/platformSelector';

// Components
import Caption from '../../../components/caption/Caption';
import ElementResizeHandle from '../../resizing/ElementResizeHandle';
import AttachedCommentsContainer from '../../attachedComment/AttachedCommentsContainer';
import AttachedCommentsDropTarget from '../../dnd/elementDropTargets/AttachedCommentsDropTarget';
import ElementUserTag from '../../../user/userActivity/ElementUserTag';
import ElementActivityIndicator from '../../activity/ElementActivityIndicator';
import CropToGridContainer from '../../../components/layout/CropToGridContainer';
import QuickLineCreationTool from '../../quickLine/QuickLineCreationTool';
import ElementReactions from '../../reactions/ElementReactions';
import ColorBar from '../../../components/colors/ColorBar';
import DrawingSvg from '../DrawingSvg';
import EmptySketch from './EmptySketch';

// Constants
import { ELEMENT_FEATURES } from '../../feature/elementFeatureConstants';
import { SKETCH_BACKGROUND_COLORS } from '../../../../common/colors/colorConstants';
import { MilanoteApplicationMode } from '../../../../common/platform/platformTypes';

// Styles
import './Sketch.scss';

const HIDE_REACTIONS_WIDTH = 16;

const Sketch = (props) => {
    const {
        elementEvents,
        gridSize,
        element,
        permissions,
        currentBoardId,
        isSelected,
        inList,
        isPresentational,
        isEditable,
        isLocked,
        isRemotelyEdited,
        isResizing,
        editedDrawingId,
        setParentHoveredChildAcceptsDrop,
        dispatchDeselectAndNavigateToElement,
        drawingElementRef,
        emptyDrawingRef,
        getContextZoomScale,
        getContextZoomTranslationPx,
        isPresentationModeEnabled,
    } = props;

    const elementId = getElementId(element);

    if (isRemotelyEdited || editedDrawingId === elementId) return null;

    // Captions
    const captionVisible = getShowCaption(element) && !isElementBelowEditableWidth(props);

    const captionContent = getCaption(element);

    // Background colours
    // when dragging a card in an inbox, don't apply the background color,
    // as it should be the light grey instead
    const backgroundColor = getBackgroundColor(element);
    const colorCss = getColorCssValue(backgroundColor);

    const isTransparencyEnabled = getIsFeatureEnabled(ELEMENT_FEATURES.TRANSPARENT)(element);

    const widthGU = getElementCardWidthGU(props);
    const widthPx = getElementCardRenderedWidthPx(props);

    // Resize handle
    const showResizeHandle = !isLocked && isEditable && !inList;

    const isMobile = useSelector(getMilanoteApplicationModeSelector) === MilanoteApplicationMode.mobile;

    const elementStyle = {
        ...getElementStyle(widthGU, gridSize, inList),
        '--ws-element-background-primary': colorCss,
    };

    const handleDoubleClick = () => {
        if (isMobile) return;

        // Don't go into the editing modal in presentation mode, let focus mode do the zooming in
        if (isPresentationModeEnabled) return;

        dispatchDeselectAndNavigateToElement(elementId);
    };

    const hasDrawing = !!getDrawingSvg(element);

    const sketchClassNames = classNames('Sketch drag-handle contrast-shadow', {
        'background-color-target': isSelected,
        'background-color-contrast-target': isSelected,
        'hide-reactions': widthGU < HIDE_REACTIONS_WIDTH,
        ...getBackgroundColorClasses(backgroundColor, true, SKETCH_BACKGROUND_COLORS),
    });

    const classes = elementClassNames(sketchClassNames, {
        ...props,
        transparent: isTransparencyEnabled,
    });

    const cropInset = getElementCardCropInset(inList);

    return (
        <div
            id={getDomElementId(getElementId(element))}
            ref={drawingElementRef}
            className={classes}
            {...elementEvents}
            style={elementStyle}
        >
            <ColorBar colorName={getColor(element)} isSelected={isSelected} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementActivityIndicator {...props} />
            <ElementUserTag {...props} />
            {!hasDrawing ? (
                <EmptySketch ref={emptyDrawingRef} {...props} onDoubleClick={handleDoubleClick} />
            ) : (
                <CropToGridContainer
                    enableCrop={!isResizing}
                    dimensions={getDrawingData(element)}
                    cropInset={cropInset}
                    gridSize={gridSize}
                    widthPx={widthPx}
                >
                    <DrawingSvg {...props} onDoubleClick={handleDoubleClick}>
                        <ElementReactions {...props} />
                    </DrawingSvg>
                </CropToGridContainer>
            )}
            <Caption
                {...props}
                padTop
                placeholder="Add a description"
                textContent={captionContent}
                captionVisible={captionVisible}
            />
            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                isRelative
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                elementId={elementId}
                element={element}
                gridSize={gridSize}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
            {!isPresentational && (
                <ElementResizeHandle {...props} elementId={elementId} showHandle={showResizeHandle} />
            )}
        </div>
    );
};

Sketch.propTypes = {
    currentBoardId: PropTypes.string,
    element: PropTypes.object,
    gridSize: PropTypes.number,
    permissions: PropTypes.number,
    tempSize: PropTypes.object,

    elementEvents: PropTypes.object,

    inList: PropTypes.string,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isPresentational: PropTypes.bool,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    isRemotelyEdited: PropTypes.bool,
    isResizing: PropTypes.bool,
    isPresentationModeEnabled: PropTypes.bool,

    editedDrawingId: PropTypes.string,

    drawingElementRef: PropTypes.object,
    emptyDrawingRef: PropTypes.object,

    setParentHoveredChildAcceptsDrop: PropTypes.func,

    dispatchDeselectAndNavigateToElement: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default Sketch;
