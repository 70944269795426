export const DEFAULT_COLUMN_COUNT = 8;

export const PICKER_ROW_TYPES = {
    TITLE: 0,
    EMOJI: 1,
};

export const RECENT_CATEGORY_ID = 'Frequently used';

export const CATEGORIES = [
    {
        name: 'Frequently used',
        groups: [RECENT_CATEGORY_ID],
    },
    {
        name: 'Smileys & Emotions',
        groups: ['Smileys & Emotion'],
    },
    {
        name: 'People & Body',
        groups: ['People & Body'],
    },
    {
        name: 'Animals & Nature',
        groups: ['Animals & Nature'],
    },
    {
        name: 'Food & Drink',
        groups: ['Food & Drink'],
    },
    {
        name: 'Travel & Places',
        groups: ['Travel & Places'],
    },
    {
        name: 'Activities',
        groups: ['Activities'],
    },
    {
        name: 'Objects',
        groups: ['Objects'],
    },
    {
        name: 'Symbols',
        groups: ['Symbols'],
    },
    {
        name: 'Flags',
        groups: ['Flags'],
    },
];

export const SKIN_TONE_EMOJI_CODE = '1F44D';

export const RECENTLY_USED_DEFAULTS = {
    '1F44D': 4,
    '1F44E': 3,
    '1F60A': 2,
    '1F600': 2,
    '1F642': 1,
    '1F914': 1,
    '1F60D': 1,
    '1F61E': 1,
};
