import { Selection } from '@tiptap/pm/state';

/**
 * Adjusts the selection if the end of the selection falls into another node by 1 position.
 * Otherwise, returns the original selection.
 *
 * This is useful when the selection invisibly includes the next node, resulting in two blocks
 * being joined unintentionally.
 *
 * E.g. If text within a blockquote is selected, but the end selection falls into a following
 *  paragraph outside the blockquote, if we didn't adjust the selection the following paragraph
 *  would get pulled into the blockquote while clipping.
 */
const keepRangeWithinNode = (selection: Selection): { from: number; to: number } => {
    const { from, $to } = selection;

    const $beforeTo = $to.doc.resolve($to.pos - 1);

    if ($to.sameParent($beforeTo)) return selection;

    return { from, to: $to.pos - 1 };
};

export default keepRangeWithinNode;
