// Types
import { Fragment } from '@tiptap/pm/model';
import { Editor } from '@tiptap/core';

// Empty nodes have a size of 2 because of the entering/leaving positions.
// From the [docs](https://prosemirror.net/docs/guide/#doc.indexing):
//  > Entering or leaving a node that is not a leaf node (i.e. supports content) counts as one token
//    So if the document starts with a paragraph, the start of that paragraph counts as position 1.
export const EMPTY_NODE_SIZE = 2;

/**
 * Determines if the Tiptap content fragment is empty - i.e. contains no text content and
 * is a single paragraph node.
 */
export const isTiptapEditorContentFragmentEmpty = (content: Fragment): boolean => {
    if (!content) return true;
    if (content.childCount === 0) return true;
    if (content.childCount !== 1) return false;
    if (!content.firstChild) return true;

    // If the first child is a bullet list or similar, don't consider it empty
    if (content.firstChild.type.name !== 'paragraph') return false;
    if (content.firstChild.textContent !== '') return false;

    // Prevent soft new-lines from being treated as empty
    // When there's a soft new-line, the nodeSize is 3
    if (content.firstChild.nodeSize > EMPTY_NODE_SIZE) return false;

    // NOTE: Need to double-check if this logic is correct when we have mentions or other non-text content
    //  See: https://linear.app/milanote/issue/WEB-11317/double-check-tiptap-isempty-logic-when-mentions-exist

    return true;
};

/**
 * Determines if the Tiptap editor is empty - i.e. contains no text content and
 * is a single paragraph node.
 */
export const isTiptapEditorEmpty = (editor: Editor): boolean =>
    !!editor && isTiptapEditorContentFragmentEmpty(editor.state.doc.content);
