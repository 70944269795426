import { isFunction } from 'lodash/fp';
import platformSingleton from '../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../common/platform/platformTypes';

const shouldGetHybridOnboardingSteps = () => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            return true;
        case LegacyHybridUseCase.IPAD_OS:
        default:
            return false;
    }
};

let getOnboardingStep;
if (process.env.BROWSER) {
    if (shouldGetHybridOnboardingSteps()) {
        getOnboardingStep = require('./config/getOnboardingStepHybrid').default;
    } else {
        getOnboardingStep = require('./config/getOnboardingStep').default;
    }
} else {
    getOnboardingStep = require('./config/getOnboardingStepElectron').default;
}

const hasFunctionSelector = (step) => isFunction(step.getIn(['tip', 'selector']));

export const selectStep = (stepId, action, state) => {
    let step = getOnboardingStep(stepId);

    if (step && hasFunctionSelector(step)) {
        step = step.setIn(['tip', 'selector'], step.getIn(['tip', 'selector'])(action, state));
    }

    return step;
};

export const getNextStepBasedOnAction = (next, action, state) => {
    if (!next) return;

    const nextTo = next(action, state);

    return nextTo && selectStep(nextTo, action, state);
};
