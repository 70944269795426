// Lib
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { defer } from 'lodash';
import classNames from 'classnames';

// Utils
import { getGivenName } from '../../../common/users/userHelper';
import { noLongerEditingElement } from '../../components/editor/milanoteEditor/editorPropComparisons';
import {
    cancelEditingComment,
    preCommitComment,
    startEditingComment,
    stopEditingComment,
} from './store/localCommentActions';
import { getLocalCommentInputIsEditing, getLocalCommentInputText } from './store/commentsInputSelector';
import { commentsDelete, commentsUpdate } from './store/commentActions';
import { finishEditingElement } from '../selection/selectionActions';
import { asObject } from '../../../common/utils/immutableHelper';
import doesEditorJsonHaveText from '../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';
import getEditorJsonText from '../../../common/tiptap/utils/jsonContentUtils/getEditorJsonText';
import { allowTextSelection } from './util/allowCommentTextSelection';
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';
import { addEditorContextSuffix } from '../utils/elementEditorUtils';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';
import { isPlatformPhoneOrMobileMode } from '../../platform/utils/platformDetailsUtils';

// Components
import FriendlyTimestamp from '../../components/datetime/FriendlyTimestamp';
import CommentLine from './CommentLine';
import CommentEditor from './commentEditor/CommentEditorSwitch';
import CommentTools from './CommentTools';
import CommentActivityIndicator from '../activity/CommentActivityIndicator';
import CommentReactions from './CommentReactions';

// Constants
import { PlatformType } from '../../../common/platform/platformTypes';
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import './Comment.scss';

const EditableComment = (props) => {
    const {
        _id,
        element,
        user,
        text,
        createdAt,
        userId,
        currentUserId,
        onEditCommentClick,
        filterQuery,
        isSelected,
        inTrash,
        isDuplicating,
        content,
        permissions,
        currentEditorKey,
        currentEditorId,
        localText,
        saveContent,
        updateComment,
        onEmptyBackspace,
        startEditing,
        stopEditing,
        isEditing,
        isEditable,
        isUpdating,
        isPresentational,
        spellCheck,
        openHyperlinkPopup,
        platformDetails,
    } = props;

    const [keepToolsVisible, setKeepToolsVisible] = useState();
    const editorStateRef = useRef();

    // On iPad, only show the tools while editing a comment.
    // This is so that comments can be selected with a single tap, instead of a double tap due to the hover state to show the tools.
    // On phone, never show the tools because comments can't be edited in-place, they are edited in a dedicated editor.
    const showTools =
        !inTrash &&
        !isDuplicating &&
        (platformDetails.type !== PlatformType.ipad || isUpdating) &&
        !isPlatformPhoneOrMobileMode(platformDetails);

    const editorId = addEditorContextSuffix(props, `${_id}-${ElementType.COMMENT_THREAD_TYPE}`);
    const editorKey = addEditorContextSuffix(props, ElementType.COMMENT_THREAD_TYPE);

    return (
        <CommentLine
            className={classNames('Comment', {
                'text-selectable': isSelected,
                'keep-tools-visible': keepToolsVisible,
                updating: isUpdating,
            })}
            user={user}
            onDoubleClick={inTrash ? null : onEditCommentClick}
        >
            <div className="details-header">
                <div className="title">
                    <span className="user-name">{getGivenName(user) || 'Someone'}</span>
                    <FriendlyTimestamp timestamp={createdAt} showSuffix />
                    <CommentActivityIndicator _id={_id} isSelected={isSelected} />
                </div>
                {showTools && (
                    <CommentTools
                        _id={_id}
                        editorStateRef={editorStateRef}
                        permissions={permissions}
                        userId={userId}
                        currentUserId={currentUserId}
                        startEditing={onEditCommentClick}
                        isUpdating={isUpdating}
                        updateComment={updateComment}
                        setKeepToolsVisible={setKeepToolsVisible}
                        content={content}
                    />
                )}
            </div>
            <div className="editor-container">
                <div className="mousedown-handler" onMouseDown={isSelected ? allowTextSelection : null}>
                    <CommentEditor
                        element={element}
                        hidePostButton
                        editorStateRef={editorStateRef}
                        isUpdating={isUpdating}
                        editorId={editorId}
                        editorKey={editorKey}
                        currentEditorKey={currentEditorKey}
                        currentEditorId={currentEditorId}
                        placeholder={isUpdating ? 'Update your comment...' : 'Write a comment...'}
                        textContent={localText || text}
                        saveContent={saveContent}
                        onSubmit={updateComment}
                        filterQuery={filterQuery}
                        onEmptyBackspace={onEmptyBackspace}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                        isEditing={!isPresentational && isEditing && isUpdating}
                        isEditable={!isPresentational && isEditable && isUpdating}
                        isSingleSelected={props.isSingleSelected}
                        spellCheck={spellCheck}
                        openHyperlinkPopup={openHyperlinkPopup}
                    />
                </div>
            </div>
            <CommentReactions {...props} _id={_id} content={content} />
        </CommentLine>
    );
};

const mapStateToProps = () =>
    createStructuredSelector({
        user: (state, ownProps) => state.getIn(['users', ownProps.userId]),
        isUpdating: getLocalCommentInputIsEditing,
        localText: getLocalCommentInputText,
        platformDetails: getPlatformDetailsSelector,
    });

const mapDispatchToProps = (dispatch) => ({
    dispatchStopEditingComment: ({ _id }) => dispatch(stopEditingComment({ _id })),
    dispatchStartEditingComment: ({ _id, elementId }) => dispatch(startEditingComment({ _id, elementId })),
    dispatchCancelEditingComment: ({ _id }) => dispatch(cancelEditingComment({ _id })),
    dispatchPreCommitContent: ({ _id, text }) => dispatch(preCommitComment({ _id, text })),
    dispatchFinishEditingElement: () => dispatch(finishEditingElement()),

    dispatchCommentsUpdate: ({ _id, text, userId }) => dispatch(commentsUpdate({ _id, text, userId })),
    dispatchDeleteComment: ({ _id, userId }) => dispatch(commentsDelete({ _id, userId })),
});

@connect(mapStateToProps, mapDispatchToProps)
class Comment extends React.Component {
    componentWillReceiveProps(nextProps) {
        // Need to defer because it requires the "localText" to be set, which is performed by
        // the "this.saveContent" function which is executed after componentDidUpdate
        if (nextProps.isUpdating && noLongerEditingElement(this.props, nextProps)) {
            defer(this.stopEditingIfNoChanges);
        }
    }

    onEmptyBackspace = () => {
        const { _id, currentUserId, dispatchFinishEditingElement, dispatchDeleteComment } = this.props;

        // Return element to non editing state
        dispatchFinishEditingElement();
        dispatchDeleteComment({ _id, userId: currentUserId });
    };

    onEditCommentClick = () => {
        this.startEditing();
    };

    startEditing = () => {
        const { startEditing, dispatchStartEditingComment, _id, currentUserId, userId, element } = this.props;

        if (currentUserId !== userId) return;

        dispatchStartEditingComment({ _id, elementId: getElementId(element) });

        const editorId = addEditorContextSuffix(this.props, `${_id}-${ElementType.COMMENT_THREAD_TYPE}`);
        const editorKey = addEditorContextSuffix(this.props, ElementType.COMMENT_THREAD_TYPE);
        startEditing && startEditing({ editorId, editorKey });
    };

    stopEditingIfNoChanges = () => {
        const { text, localText } = this.props;

        const hasChanges =
            doesEditorJsonHaveText(localText) && getEditorJsonText(localText) !== getEditorJsonText(text);

        if (hasChanges) return this.updateComment(localText);

        return this.stopEditing();
    };

    stopEditing = () => {
        const { _id, dispatchCancelEditingComment, dispatchFinishEditingElement } = this.props;
        dispatchCancelEditingComment({ _id });
        dispatchFinishEditingElement();
    };

    saveContent = (text) => {
        const { dispatchPreCommitContent, _id, isUpdating } = this.props;
        isUpdating && dispatchPreCommitContent({ text, _id });
    };

    updateComment = (text) => {
        const { dispatchCommentsUpdate, dispatchFinishEditingElement, currentUserId, _id } = this.props;
        dispatchCommentsUpdate({ _id, text: asObject(text), userId: currentUserId });
        dispatchFinishEditingElement();
    };

    render() {
        const localFunctions = {
            saveContent: this.saveContent,
            updateComment: this.updateComment,
            onEmptyBackspace: this.onEmptyBackspace,
            startEditing: this.startEditing,
            stopEditing: this.stopEditing,
            onEditCommentClick: this.onEditCommentClick,
        };

        return <EditableComment {...this.props} {...localFunctions} />;
    }
}

EditableComment.propTypes = Comment.propTypes = {
    element: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    user: PropTypes.object,
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    localText: PropTypes.object,
    editorRef: PropTypes.func,
    startEditing: PropTypes.func,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isDuplicating: PropTypes.bool,
    inTrash: PropTypes.bool,
    spellCheck: PropTypes.bool,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    saveContent: PropTypes.func,
    createdAt: PropTypes.number,
    currentUserId: PropTypes.string,
    permissions: PropTypes.number,
    platformDetails: PropTypes.object,

    dispatchStartEditingComment: PropTypes.func,
    dispatchStopEditingComment: PropTypes.func,
    dispatchCancelEditingComment: PropTypes.func,
    dispatchPreCommitContent: PropTypes.func,

    dispatchCommentsUpdate: PropTypes.func,
    dispatchDeleteComment: PropTypes.func,

    dispatchFinishEditingElement: PropTypes.func,
};

export default Comment;
