import { useMemo } from 'react';
import { flow, map, reverse, sortBy, take, toPairs } from 'lodash/fp';
import { getEmojisRowData, getUnified } from '../reactionPopupUtil';
import { DEFAULT_COLUMN_COUNT, RECENT_CATEGORY_ID } from '../reactionPopupConstants';
import { Emoji, EmojisRowData, RecentlyUsedEmojis } from '../../emojiTypes';

const orderRecentlyUsed = (columnCount: number) =>
    flow(
        toPairs,
        sortBy('1'),
        reverse,
        take(columnCount * 3),
        map(([emojiCode]) => emojiCode),
    );

const useEmojisRowData = (
    searchFilter: string,
    emojiData: Emoji[],
    recentlyUsed: RecentlyUsedEmojis,
    columnCount = DEFAULT_COLUMN_COUNT,
): EmojisRowData => {
    const emojisRowData = useMemo(() => {
        const allEmojiData = [
            ...orderRecentlyUsed(columnCount)(recentlyUsed)
                .map((recentEmojiCode, index) => {
                    const emoji = emojiData.find((_emoji) => getUnified(_emoji) === recentEmojiCode);

                    return {
                        ...emoji,
                        category: RECENT_CATEGORY_ID,
                        sort_order: index,
                    };
                })
                .filter((emoji) => !!emoji),
            ...emojiData,
        ];

        return getEmojisRowData(allEmojiData, searchFilter, columnCount);
    }, [searchFilter, emojiData, recentlyUsed, columnCount]);

    return emojisRowData;
};

export default useEmojisRowData;
