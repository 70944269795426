import { Extension } from '@tiptap/core';
import { findParentNode } from '@tiptap/react';
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY, TiptapNodeType } from '../../tiptapTypes';
import { LIST_CONTAINER_TYPES } from '../list/tiptapListUtils';
import {
    getPositionAndCountsToRemoveTabChars,
    getPositionAndCountsToInsertTabChars,
    TAB_SPACE_COUNT,
} from './indentationUtils';

import { createTextIndentVisualizerPlugin } from './TextIndentVisualizerPlugin';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        tabHandler: {
            insertTabChars: (posIndex: number, count: number) => ReturnType;
            removeTabChars: (posIndex: number, count: number) => ReturnType;
            increaseIndentation: () => ReturnType;
            decreaseIndentation: () => ReturnType;
        };
    }
}

export const TextIndentationHandler = Extension.create({
    name: 'textIndentationHandler',

    // We need this extension to be trigger after the list extension, otherwise
    //  list indentation won't work correctly
    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY - 10,

    addProseMirrorPlugins() {
        // The visualiser is only useful for working on this extension, so it seems
        // overkill to make it dynamically configurable -- just comment out this return
        // to enable it.
        return [];

        return [createTextIndentVisualizerPlugin()];
    },

    addCommands() {
        return {
            insertTabChars:
                (posIndex: number, charCount = TAB_SPACE_COUNT) =>
                ({ tr }) => {
                    // When multiple "insertTabChars" commands are run in a chain,
                    // the positions referred to by the caller will shift, because characters are
                    // being inserted.
                    // Using the mapping will compensate for these changes
                    const updatedPos = tr.mapping.map(posIndex);

                    const text = ' '.repeat(charCount);

                    tr.insertText(text, updatedPos);
                    return true;
                },
            removeTabChars:
                (posIndex: number, charCount = TAB_SPACE_COUNT) =>
                ({ tr }) => {
                    // Similar to above, compensate for position changes due to previous steps
                    const updatedPos = tr.mapping.map(posIndex);
                    tr.delete(updatedPos, updatedPos + charCount);
                    return true;
                },
            increaseIndentation:
                () =>
                ({ chain, state, editor }) => {
                    const initialSelection = state.selection;

                    const parentListNode = findParentNode((node) =>
                        LIST_CONTAINER_TYPES.has(node.type.name as TiptapNodeType),
                    )(state.selection);

                    // Ignore if the selection is collapsed within a list item
                    // NOTE: We return true here, otherwise the default tab behaviour will be triggered
                    //  and focus will move out of the editor
                    if (state.selection.$from.sameParent(state.selection.$to) && !!parentListNode) return true;

                    const positions = getPositionAndCountsToInsertTabChars(state);

                    // We want it to appear like the anchor and focus points of the selection remain
                    // constant after the tabs are inserted. So we need the selection to grow with the
                    // newly inserted characters - thus we need to figure out when a space is inserted
                    // before each of the anchor and focus points
                    const insertionsBeforeInitialSelectionStart = positions
                        .filter(({ pos }) => pos <= initialSelection.from)
                        .reduce((acc, { count }) => acc + count, 0);
                    const insertionsBeforeInitialSelectionEnd = positions
                        .filter(({ pos }, index) => pos - index * TAB_SPACE_COUNT <= initialSelection.to)
                        .reduce((acc, { count }) => acc + count, 0);

                    // Return selection to the original position
                    const newSelection = {
                        from: initialSelection.from + insertionsBeforeInitialSelectionStart,
                        to: initialSelection.to + insertionsBeforeInitialSelectionEnd,
                    };

                    chain()
                        .forEach(positions, ({ pos, count }, { commands }) => commands.insertTabChars(pos, count))
                        .setTextSelection(newSelection)
                        .run();

                    return true;
                },
            decreaseIndentation:
                () =>
                ({ state, chain }) => {
                    // Reverse the positions so that we remove the spaces from the end of the document first
                    // (saves having to adjust the positions as the document is modified)
                    const positions = getPositionAndCountsToRemoveTabChars(state).reverse();

                    chain()
                        .forEach(positions, ({ pos, count }, { commands }) => commands.removeTabChars(pos, count))
                        .run();

                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            Tab: () => this.editor.commands.increaseIndentation(),
            'Shift-Tab': () => this.editor.commands.decreaseIndentation(),
        };
    },
});
