// TODO (WEB-11690): This should be included in the Tiptap extensions cleanup task
//  These lists are used when creating content outside of the client side editor
//  Their hooks should probably use these lists somehow too
// Lib
import { TextAlign } from './TextAlign';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { TextStyle } from './TextStyle';
import { TextColor } from './TextColor';
import { TextHighlight } from './TextHighlight';
import { OldMention } from './mention/OldMention';
import { TextMention } from './mention/TextMention';
import { TaskList } from './TaskList';
import { Heading } from './Heading';
import { TaskItem } from './TaskItem';
import { Link } from './hyperlink/Link';

export const CAPTION_STATIC_TIPTAP_EXTENSIONS = [
    StarterKit.configure({
        heading: false,
        codeBlock: false,
        blockquote: false,
        bulletList: false,
        orderedList: false,
        horizontalRule: false,
    }),
    TextAlign,
    Underline,
    TextStyle,
    TextColor,
    TextHighlight,
    OldMention,
    TextMention,
    Link,
];

export const CARD_STATIC_TIPTAP_EXTENSIONS = [
    StarterKit.configure({
        heading: false,
        horizontalRule: false,
    }),
    Heading,
    TextAlign,
    Underline,
    TextStyle,
    TextColor,
    TextHighlight,
    OldMention,
    TextMention,
    Link,
    TaskList,
    TaskItem,
];

export const TASK_STATIC_TIPTAP_EXTENSIONS = [
    StarterKit.configure({
        heading: false,
        codeBlock: false,
        blockquote: false,
        bulletList: false,
        orderedList: false,
        horizontalRule: false,
        listItem: false,
    }),
    Underline,
    Link,
];
