import React from 'react';
import classNames from 'classnames';
import PopupTrigger from '../../components/popupPanel/PopupTrigger';
import ElementReactionPopup from './ElementReactionPopup';
import Icon from '../../components/icons/Icon';

import './ElementReactionAddButton.scss';

type DesktopElementReactionAddButtonProps = {
    elementId: string;
    onPopupToggleCb: () => void;
};

const DesktopElementReactionAddButton = ({ elementId, onPopupToggleCb }: DesktopElementReactionAddButtonProps) => {
    const popupId = `reaction-${elementId}`;

    return (
        <>
            <PopupTrigger popupId={popupId} onToggle={onPopupToggleCb}>
                {(isOpen) => (
                    <div className={classNames('add-icon', { active: isOpen })}>
                        <Icon name="reaction-add" />
                    </div>
                )}
            </PopupTrigger>

            <ElementReactionPopup popupId={popupId} elementIds={[elementId]} />
        </>
    );
};

export default DesktopElementReactionAddButton;
