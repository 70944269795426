import { animateScrollPromise } from '../../../utils/animation/animateScrollIntoView';

export let animateScrollToResetScrollExcessPadding = () => {};

/**
 * When animating to element at the bottom of the screen, there are situations where toScrollTop is
 * not accessible as it exceeds the possible scrollable area.
 *
 * In this case, we want to add in extra margin to the bottom of scrollableElement to allow for the scroll
 */
export const applyScrollExcessPadding = (
    scrollableElement: HTMLElement,
    scrollExcessPaddingBottomCSS: string,
): Promise<void> =>
    new Promise((resolve) => {
        if (!scrollableElement) return;

        const maxScrollTop = scrollableElement.scrollHeight - scrollableElement.getBoundingClientRect().height;

        requestAnimationFrame(() => {
            scrollableElement.style.paddingBottom = scrollExcessPaddingBottomCSS;
            resolve();
        });

        // Set animateScrollToResetScrollExcess function to animate back to position that resets the scrollExcess back to 0
        // - This function will be called on finish editing an element
        animateScrollToResetScrollExcessPadding = () => {
            if (!scrollableElement || !scrollableElement.parentElement) return;

            const currentScrollLeft = scrollableElement.scrollLeft;
            const currentScrollTop = scrollableElement.scrollTop;

            if (currentScrollTop <= maxScrollTop) {
                scrollableElement.style.paddingBottom = '';
                return;
            }

            animateScrollPromise({
                scrollableElement,
                interpolationFactor: 0.2,
                fromScrollLeft: currentScrollLeft,
                fromScrollTop: currentScrollTop,

                // Scroll to the bottom of the page (without scrollExcess)
                toScrollLeft: currentScrollLeft,
                toScrollTop: maxScrollTop,
            }).then(() => {
                scrollableElement.style.paddingBottom = '';
            });
        };
    });
