// Lib
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { EditorContent } from '@tiptap/react';

// Utils
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import { getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';

// Hooks
import useTaskTiptapEditorExtensions, { TaskTiptapEditorPasteHandlerArgs } from './useTaskTiptapEditorExtensions';
import useTiptapInnerRef from '../../../../common/tiptap/hooks/useTiptapInnerRef';
import usePoiUpdateHandler from '../../../components/pointsOfInterest/usePoiUpdateHandler';
import useClientElementTiptapEditor from '../../../components/tiptapEditor/useClientElementTiptapEditor';

// Components
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';
import { SearchHighlightObserver } from '../../../../common/tiptap/extensions/SearchHighlighter';

// Types
import { EditorContent as EditorContentType, ImMNElement } from '../../../../common/elements/elementModelTypes';
import { TaskTiptapEditorKeyboardShortcutHandlerArgs } from './useTaskTiptapEditorKeyboardShortcutsExtension';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './TaskTiptapEditor.scss';

export type TaskTiptapEditorProps = TaskTiptapEditorKeyboardShortcutHandlerArgs &
    TaskTiptapEditorPasteHandlerArgs & {
        textContent: TiptapContent | null;

        element: ImMNElement;
        isEditable: boolean;
        isEditing: boolean;
        currentEditorKey: string;
        currentEditorId: string;
        spellCheck: boolean;

        placeholder: string;
        filterQuery: string;

        saveContent: (content: EditorContentType, transactionId?: number) => void;
        startEditing: (args: { editorId: string; editorKey: string }) => void;
        stopEditing: () => void;
    };

const TaskTiptapEditor = (props: TaskTiptapEditorProps): ReactElement => {
    const {
        element,
        textContent,
        saveContent,
        isEditable,
        isEditing,
        currentEditorId,
        startEditing,
        placeholder,
        // Key handlers
        onEnter,
        onKeyboardDelete,
        changeIndentation,
        toggleComplete,
        dispatchOpenDueDatePopup,
        dispatchOpenAssignmentPopup,
        goToPreviousTask,
        goToNextTask,
        onStartBackspace,
        onEndDelete,
        // Paste handler
        createMultipleNewTasksWithContent,
    } = props;

    const dispatch = useDispatch();
    const editorId = getMainEditorId(props);
    const editorKey = getMainEditorKey(props);

    const { innerRef, contentInnerRef } = useTiptapInnerRef();
    const onHighlightsChanged = usePoiUpdateHandler({ contentInnerRef, element });

    const extensions = useTaskTiptapEditorExtensions({
        dispatch,
        placeholder,
        onHighlightsChanged,
        saveContent,
        isEditable,
        onEnter,
        onKeyboardDelete,
        changeIndentation,
        toggleComplete,
        dispatchOpenDueDatePopup,
        dispatchOpenAssignmentPopup,
        goToPreviousTask,
        goToNextTask,
        onStartBackspace,
        onEndDelete,
        createMultipleNewTasksWithContent,
    });

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        extensions,
        persistedContent: textContent,

        editorId,
        editorKey,
        currentEditorId,

        isEditable,
        isEditing,
        // Always treat this editor as single-selected when the task is being edited
        isSingleSelected: isEditing,

        startEditing,
        saveContent,
    });

    return (
        <div className="TaskTiptapEditorContainer" onMouseDown={onMouseDown} onClick={onClick}>
            <SearchHighlightObserver editor={editor} />
            <DraftJsConversionIndicator element={element} textContent={getTextContent(element)} />
            <EditorContent innerRef={innerRef} className="TaskTiptapEditor" editor={editor} />
        </div>
    );
};

export default TaskTiptapEditor;
