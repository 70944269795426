export enum PlatformType {
    windows = 'windows',
    mac = 'mac',
    unix = 'unix',
    linux = 'linux',
    ipad = 'ipad',
    iphone = 'iphone',
    ipod = 'ipod',
    android = 'android',
    unknown = 'unknown',
}

export enum DeviceFormFactor {
    phone = 'phone',
    tablet = 'tablet',
    desktop = 'desktop',
}

export enum OperatingSystem {
    windows = 'windows',
    iOS = 'iOS',
    macOS = 'macOS',
    android = 'android',
    other = 'other',
}

export enum MilanoteApplicationMode {
    desktop = 'desktop',
    mobile = 'mobile',
    mobileLegacy = 'mobile-legacy',
}

export enum LegacyHybridUseCase {
    ANDROID_BOARD_LIST = 'HybridUseCase.ANDROID_BOARD_LIST',
    IPAD_OS = 'HybridUseCase.IPAD_OS',
}

export enum BrowserEngineType {
    safari = 'safari',
    chrome = 'chrome',
    firefox = 'firefox',
    edge = 'edge',
    androidChrome = 'android-chrome',
    iosSafari = 'ios-safari',
    unknown = 'unknown',
}

export enum ApplicationType {
    web = 'web',
    iframe = 'iframe',
    pwa = 'pwa',
    electronMac = 'electron-mac',
    iphoneCapacitor = 'iphone-capacitor',
    iphoneSwift = 'iphone-swift',
    iphonePwa = 'iphone-pwa',
    androidCapacitor = 'android-capacitor',
    androidTabletCapacitor = 'android-tablet-capacitor',
    androidFlutter = 'android-flutter',
    ipadCapacitor = 'ipad-capacitor',
    ipadSwift = 'ipad-swift',
}

export type PlatformDetails = {
    appType: ApplicationType;
    appMode: MilanoteApplicationMode;
    type: PlatformType;
    formFactor: DeviceFormFactor;
    os: OperatingSystem;
    capacitorPlatform: string;
    userAgent: string;
    browserEngine: BrowserEngineType;
    legacyHybridUseCase: LegacyHybridUseCase | null;
    appVersion: string | null;
    appBuild: string | null;
    features: {
        isTouch: boolean;
        isRetina: boolean;
        supportsMouse: boolean;
        supportsWebSockets: boolean;
        supportsWorker: boolean;
        supportsSvg: boolean;
        supportsContentEditable: boolean;
        supportsLocalStorage: boolean;
        supportsIndexedDb: boolean;
    };
};

export const PLATFORM_DETAILS_UPDATE = 'PLATFORM_DETAILS_UPDATE';

export type PlatformDetailsUpdateAction = {
    type: typeof PLATFORM_DETAILS_UPDATE;
    details: Partial<PlatformDetails>;
};
