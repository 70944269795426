// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { isPlatformApple, isPlatformNativeApp } from '../../platform/utils/platformDetailsUtils';

// Components
import ContextMenuItem from './ContextMenuItem';
import ContextMenuDivider from './ContextMenuDivider';
import ColorContextMenuItem from './ColorContextMenuItem';
import SelectedElementsContextMenuItem from './SelectedElementsContextMenuItems';
import ConvertTemplateContextMenuItems from './ConvertTemplateContextMenuItems';
import ElementModificationContextMenuItems from './ElementModificationContextMenuItems';
import ImageAspectRatioContextMenuItem from './ImageAspectRatioContextMenuItem';
import DownloadOriginalContextMenuItem from './contextMenuItems/DownloadOriginalContextMenuItem';

// Types
import { BrowserEngineType } from '../../../common/platform/platformTypes';

const ElementContextMenuItems = (props) => {
    const {
        isVideo,
        selectedElements,
        currentUserId,
        createdUser,
        modifiedUser,
        createdTime,
        modifiedTime,
        singleBoard,
        singleLink,
        singlePopulatedDrawing,
        allImages,
        singleFile,
        showClone,
        showCutCopy,
        showPaste,
        showRename,
        showReplaceImage,
        showGroupInColumn,
        showConnect,
        showConvertToSketch,
        showConvertTemplate,
        showDuplicateAliasToBoard,
        showDevTools,
        showSendToBack,
        showBringToFront,
        showConvertToNote,
        showConvertToDocument,
        showConvertToBoard,
        showColorTool,
        lockToolWillEnableLock,
        triggerMenuItemOnClick,
        cut,
        copy,
        paste,
        deleteElement,
        rename,
        groupInColumn,
        connectWithLines,
        convertToSketch,
        toggleLock,
        bringToFront,
        sendToBack,
        openFileSelect,
        copyLinkToFile,
        copyLinkAddress,
        convertBoardToTemplate,
        revertTemplateToBoard,
        navigateToTemplateTagEditor,
        registerMenuItem,
        logSelectedElement,
        dispatchDuplicateElements,
        dispatchCloneElement,
        dispatchDuplicateAliasToBoard,
        dispatchSetColor,
        convertColumnToBoard,
        convertToNote,
        convertToDocument,
        createShortcut,
        dispatchOpenInNewTab,
        openElementColorPicker,
        downloadSvg,
        platformDetails,
    } = props;

    const contextMenuHandlers = {
        registerMenuItem,
        onMouseUp: triggerMenuItemOnClick,
    };

    const clipboardInsertSupported = !!(navigator.permissions && navigator.clipboard);
    const clipboardCopySupported =
        clipboardInsertSupported && platformDetails.browserEngine !== BrowserEngineType.firefox;

    const supportsOpenInTab = !isPlatformNativeApp(platformDetails);

    return (
        <div>
            {/* Board navigation tools */}

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Open in new tab"
                id="openInNewTab"
                show={singleBoard && supportsOpenInTab}
                onClick={dispatchOpenInNewTab}
            />
            <ContextMenuDivider show={singleBoard && supportsOpenInTab} />

            {/* Clipboard tools */}

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Paste"
                id="paste"
                shortcut={['command', 'v']}
                show={showPaste}
                onClick={paste}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Cut"
                id="cut"
                shortcut={['command', 'x']}
                show={showCutCopy}
                onClick={cut}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Copy"
                id="copy"
                shortcut={['command', 'c']}
                show={showCutCopy}
                onClick={copy}
            />

            {showDuplicateAliasToBoard ? (
                <>
                    <ContextMenuItem
                        {...contextMenuHandlers}
                        title="Duplicate this shortcut"
                        id="duplicate"
                        shortcut={['command', 'd']}
                        onClick={dispatchDuplicateElements}
                        show
                    />
                    <ContextMenuItem
                        {...contextMenuHandlers}
                        title="Duplicate the board it links to"
                        id="shortcut"
                        onClick={dispatchDuplicateAliasToBoard}
                        show
                    />
                </>
            ) : (
                <ContextMenuItem
                    {...contextMenuHandlers}
                    title="Duplicate"
                    id="duplicate"
                    shortcut={['command', 'd']}
                    onClick={dispatchDuplicateElements}
                    show
                />
            )}

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Create Synced Copy"
                id="clone"
                show={showClone}
                onClick={dispatchCloneElement}
            />

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Delete"
                id="delete"
                shortcut={isPlatformApple(platformDetails) ? ['delete'] : ['backspace']}
                show
                onClick={deleteElement}
            />
            <ContextMenuDivider show />

            {/* Element-type-specific tools */}

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Rename"
                id="rename"
                shortcut={['return']}
                show={showRename}
                onClick={rename}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Create a shortcut to this board"
                id="shortcut"
                show={singleBoard && !showDuplicateAliasToBoard}
                onClick={createShortcut}
            />
            <ContextMenuDivider show={showRename} />
            <ContextMenuItem
                {...contextMenuHandlers}
                title={isVideo ? `Replace Preview` : `Replace Image`}
                id="replaceImage"
                show={showReplaceImage}
                onClick={openFileSelect}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Add Image"
                id="addImage"
                show={singleLink && !showReplaceImage}
                onClick={openFileSelect}
            />
            <DownloadOriginalContextMenuItem {...props} contextMenuHandlers={contextMenuHandlers} />
            <ImageAspectRatioContextMenuItem {...props} contextMenuHandlers={contextMenuHandlers} />
            <ContextMenuDivider show={allImages} />
            <ContextMenuItem
                {...contextMenuHandlers}
                title={clipboardInsertSupported ? 'Copy link to file' : 'Open file'}
                id="openFile"
                show={singleFile}
                onClick={copyLinkToFile}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Copy link address"
                id="copyLink"
                show={singleLink && clipboardCopySupported}
                onClick={copyLinkAddress}
            />
            <ContextMenuDivider show={singleFile || (singleLink && clipboardCopySupported)} />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Download SVG"
                id="downloadSvg"
                show={singlePopulatedDrawing}
                onClick={downloadSvg}
            />
            <ContextMenuDivider show={singlePopulatedDrawing} />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Group into Column"
                id="groupIntoColumn"
                show={showGroupInColumn}
                onClick={groupInColumn}
            />
            <ContextMenuDivider show={showGroupInColumn} />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Connect with Lines"
                id="connectWithLines"
                show={showConnect}
                onClick={connectWithLines}
            />
            <ContextMenuDivider show={showConnect} />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Convert to Sketch Card"
                id="convertToSketch"
                show={showConvertToSketch}
                onClick={convertToSketch}
            />
            <ContextMenuDivider show={showConvertToSketch} />

            {/* Lock tools */}

            <ContextMenuItem
                {...contextMenuHandlers}
                title={lockToolWillEnableLock ? 'Lock position' : 'Unlock position'}
                id="toggleLock"
                show
                onClick={toggleLock}
            />

            <ContextMenuDivider show />

            {/* Z-order arrangement tools */}

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Bring to Front"
                id="bringToFront"
                show={showBringToFront}
                onClick={bringToFront}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Send to Back"
                id="sendToBack"
                show={showSendToBack}
                onClick={sendToBack}
            />
            <ContextMenuDivider show={showBringToFront || showSendToBack} />

            {/* Color tools */}

            <ColorContextMenuItem
                selectedElements={selectedElements}
                setColor={dispatchSetColor}
                show={showColorTool}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                show={showColorTool}
                onClick={openElementColorPicker}
                title="Choose Custom Color..."
                id="chooseColor"
            />
            <ContextMenuDivider show={showColorTool} />

            {/* Template tools */}

            <ConvertTemplateContextMenuItems
                show={showConvertTemplate}
                selectedElements={selectedElements}
                convertBoardToTemplate={convertBoardToTemplate}
                revertTemplateToBoard={revertTemplateToBoard}
                navigateToTemplateTagEditor={navigateToTemplateTagEditor}
            />
            <ContextMenuDivider show={showConvertTemplate} />

            {/* Convertion tools */}

            <ContextMenuItem
                {...contextMenuHandlers}
                title="Convert to Note"
                id="convertToNote"
                show={showConvertToNote}
                onClick={convertToNote}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Convert to Document"
                id="convertToDocument"
                show={showConvertToDocument}
                onClick={convertToDocument}
            />
            <ContextMenuItem
                {...contextMenuHandlers}
                title="Convert to Board"
                id="convertColumnToBoard"
                show={showConvertToBoard}
                onClick={convertColumnToBoard}
            />

            <ContextMenuDivider show={showConvertToDocument || showConvertToNote || showConvertToBoard} />

            {/* Timestamp */}
            <ElementModificationContextMenuItems
                currentUserId={currentUserId}
                createdUser={createdUser}
                modifiedUser={modifiedUser}
                createdTime={createdTime}
                modifiedTime={modifiedTime}
            />

            <ContextMenuDivider show={showDevTools} />

            {/* Dev tools */}

            <SelectedElementsContextMenuItem
                {...contextMenuHandlers}
                show={showDevTools}
                selectedElements={selectedElements}
                logSelectedElement={logSelectedElement}
            />
        </div>
    );
};

ElementContextMenuItems.propTypes = {
    elements: PropTypes.object,
    selectedElements: PropTypes.object,
    currentBoardId: PropTypes.string,
    currentUserId: PropTypes.string,
    createdUser: PropTypes.object,
    modifiedUser: PropTypes.object,
    createdTime: PropTypes.number,
    modifiedTime: PropTypes.number,
    templateCollections: PropTypes.object,
    isEditable: PropTypes.bool,
    allColumns: PropTypes.bool,
    allImages: PropTypes.bool,
    singleFile: PropTypes.bool,
    singleImage: PropTypes.bool,
    singleBoard: PropTypes.bool,
    singleLink: PropTypes.bool,
    singlePopulatedDrawing: PropTypes.bool,
    showClone: PropTypes.bool,
    showCutCopy: PropTypes.bool,
    showPaste: PropTypes.bool,
    showRename: PropTypes.bool,
    showReplaceImage: PropTypes.bool,
    showGroupInColumn: PropTypes.bool,
    showConnect: PropTypes.bool,
    showConvertToSketch: PropTypes.bool,
    showConvertTemplate: PropTypes.bool,
    showConvertToBoard: PropTypes.bool,
    showDuplicateAliasToBoard: PropTypes.bool,
    showDevTools: PropTypes.bool,
    showSendToBack: PropTypes.bool,
    showBringToFront: PropTypes.bool,
    showColorTool: PropTypes.bool,
    showConvertToNote: PropTypes.bool,
    showConvertToDocument: PropTypes.bool,
    lockToolWillEnableLock: PropTypes.bool,
    isVideo: PropTypes.bool,
    triggerMenuItemOnClick: PropTypes.func,
    cut: PropTypes.func,
    copy: PropTypes.func,
    paste: PropTypes.func,
    duplicate: PropTypes.func,
    deleteElement: PropTypes.func,
    rename: PropTypes.func,
    replaceImage: PropTypes.func,
    downloadOriginal: PropTypes.func,
    copyLinkToFile: PropTypes.func,
    copyLinkAddress: PropTypes.func,
    groupInColumn: PropTypes.func,
    connectWithLines: PropTypes.func,
    convertToSketch: PropTypes.func,
    bringToFront: PropTypes.func,
    sendToBack: PropTypes.func,
    logSelectedElement: PropTypes.func,
    openFileSelect: PropTypes.func,
    convertBoardToTemplate: PropTypes.func,
    revertTemplateToBoard: PropTypes.func,
    navigateToTemplateTagEditor: PropTypes.func,
    registerMenuItem: PropTypes.func,
    dispatchGetCurrentlyEditingId: PropTypes.func,
    dispatchSaveToClipBoard: PropTypes.func,
    dispatchPasteElements: PropTypes.func,
    dispatchDuplicateElements: PropTypes.func,
    dispatchCloneElement: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    dispatchStartEditingElement: PropTypes.func,
    dispatchGroupInColumn: PropTypes.func,
    dispatchSetColor: PropTypes.func,
    dispatchDeselectAll: PropTypes.func,
    createShortcut: PropTypes.func,
    dispatchDuplicateAliasToBoard: PropTypes.func,
    dispatchOpenInNewTab: PropTypes.func,
    convertToNote: PropTypes.func,
    convertToDocument: PropTypes.func,
    convertColumnToBoard: PropTypes.func,
    openElementColorPicker: PropTypes.func,
    toggleLock: PropTypes.func,
    downloadSvg: PropTypes.func,
    platformDetails: PropTypes.object,
};

export default ElementContextMenuItems;
