// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defer, includes } from 'lodash/fp';
import { withHandlers } from '../../../node_module_clones/recompose';
import { createStructuredSelector } from 'reselect';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { asObject, propIn } from '../../../common/utils/immutableHelper';
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import { gridSizeSelector } from '../../utils/grid/gridSizeSelector';
import { getIsTaskComplete } from './taskSelector';
import { getEventSelectionMode, isAttemptingMultiSelect } from '../selection/selectionUtils';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { getElementId, getLocationParentId } from '../../../common/elements/utils/elementPropertyUtils';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { isRightButton } from '../../utils/domUtil';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { noLonger } from '../../utils/react/propsComparisons';
import { getSelectedElementIds } from '../selection/selectedElementsSelector';

// Actions
import { updateElement, createAndEditElement, createElementAsync } from '../actions/elementActions';
import { setTaskCompletion } from './taskActions';
import {
    getMostRecentTransactionIdThunk,
    getNextUndoTransactionId,
    redoAction,
    undoAction,
} from '../../utils/undoRedo/undoRedoActions';
import { createNextNewTask } from '../taskList/taskListActions';

// Components
import dispatchGetEditorStateConnect from '../../components/editor/store/components/dispatchGetEditorStateConnect';
import TaskPresentationalWrapper from './TaskPresentationalWrapper';

// Constants

import { ElementType } from '../../../common/elements/elementTypes';

const noLongerEditing = noLonger('isEditing');

const mapDispatchToProps = (dispatch) => ({
    dispatchMarkTaskAsComplete: ({ id, isComplete, transactionId }) =>
        dispatch(setTaskCompletion({ id, isComplete, transactionId })),
    dispatchSaveTextContent: ({ id, textContent, transactionId }) =>
        dispatch(updateElement({ id, changes: { textContent }, transactionId })),
    dispatchCreateNewElement: ({ location, currentBoardId, content, creationSource, transactionId }) =>
        dispatch(
            createElementAsync({
                creationSource,
                elementType: ElementType.TASK_TYPE,
                content,
                location,
                currentBoardId,
                select: false,
                transactionId,
            }),
        ),
    dispatchCreateAndEditNewElement: ({
        location,
        currentBoardId,
        content,
        creationSource,
        transactionId,
        selection,
        dispatchSaveEditorSelection,
    }) =>
        dispatch(
            createAndEditElement({
                creationSource,
                elementType: ElementType.TASK_TYPE,
                content,
                location,
                currentBoardId,
                select: false,
                selection,
                dispatchSaveEditorSelection,
                transactionId,
            }),
        ),
    onAppUndo: () => dispatch(undoAction()),
    onAppRedo: () => dispatch(redoAction()),
    dispatchGetMostRecentTransactionId: () => dispatch(getMostRecentTransactionIdThunk()),
    dispatchGetNextUndoTransactionId: () => dispatch(getNextUndoTransactionId()),
    // NOTE: This is used instead of the newElementDecorator to handle the Cmd-Return / Return key events
    dispatchCreateNextNewTask: (args) => dispatch(createNextNewTask(args)),
});

const mapStateToProps = () =>
    createStructuredSelector({
        currentlySelectedElementIds: getSelectedElementIds,
    });

@connect(gridSizeSelector)
@connect(mapStateToProps, mapDispatchToProps)
@withHandlers({
    // NOTE: This is used instead of the newElementDecorator to handle the Cmd-Return / Return key events
    createNewElement:
        ({ elementId, isFocusedForegroundElement, dispatchCreateNextNewTask, onTaskListUpdatedHandler }) =>
        (args) => {
            onTaskListUpdatedHandler && onTaskListUpdatedHandler();

            return dispatchCreateNextNewTask({ ...args, isFocusedForegroundElement, elementId });
        },
    markTaskAsComplete:
        ({ dispatchMarkTaskAsComplete, onTaskListUpdatedHandler }) =>
        (args) => {
            onTaskListUpdatedHandler && onTaskListUpdatedHandler();

            dispatchMarkTaskAsComplete(args);
        },
})
@dispatchGetEditorStateConnect
@dontUpdateForKeys(['dragModifierKeys'])
class TaskContainer extends React.Component {
    componentDidUpdate(prevProps) {
        // Reset the disabling of key handlers when this task is no longer being edited
        if (noLongerEditing(prevProps, this.props) && this.disableKeyHandlers) {
            this.disableKeyHandlers = false;
        }
    }

    /**
     * This is mostly a reimplementation of the ElementContainer's onClick and selectElement.
     * Needed to do it here because the dispatch of the editing action by the MilanoteEditor happens before the
     * dispatch of the select event for the ElementContainer.
     * This would mean that the task would start editing, but then the select action would stop the editing
     * because its ID was different to the currently editing element.
     * Now the selection happens before the editing is dispatched.
     */
    onClick = (event) => {
        const {
            isLocked,
            isEditable,
            isRemotelyActive,
            taskListElementId,
            dispatchHandleSelectionModeSelectElement,
            startEditing,
            isPresentationModeEnabled,
            isFocusedForegroundElement,
            selectParentTaskList,
            shouldFocusOnlyWhenSelected,
            currentlySelectedElementIds,
        } = this.props;

        // If we're in presentation mode then open the focus mode for the parent task list
        if (isPresentationModeEnabled && !isFocusedForegroundElement) return selectParentTaskList(event);

        // The locked selection is already handled by the onMouseDown
        if (!isEditable || isLocked) return;

        // Clicks will be prevented during a resize as we don't want to select the element after resizing
        if (this.preventClick || event.preventSelect) return;

        event && event.stopPropagation();

        if (isRemotelyActive) return;

        const selectionMode = getEventSelectionMode(event);

        dispatchHandleSelectionModeSelectElement(taskListElementId, selectionMode);

        // Don't start editing if doing a multi-select
        if (isAttemptingMultiSelect(event)) return;

        const currentlySelectedElementIdsArray = asObject(currentlySelectedElementIds);

        // Had to add this as task list doesn't seem to have isSelected set to true when selected
        if (
            shouldFocusOnlyWhenSelected &&
            (!currentlySelectedElementIdsArray.includes(taskListElementId) || currentlySelectedElementIds.size > 1)
        )
            return;

        // Start editing
        startEditing({
            editorId: getMainEditorId(this.props),
            editorKey: getMainEditorKey(this.props),
        });
    };

    onMouseDown = (event) =>
        // On right click we need to pass the mouse down event through to the task list
        isRightButton(event) ? this.props.taskListOnMouseDown(event) : this.props.elementEvents.onMouseDown(event);

    onAppUndo = () => {
        const { onAppUndo } = this.props;
        onAppUndo && onAppUndo(this.props);
    };

    onAppRedo = () => {
        const { onAppRedo } = this.props;
        onAppRedo && onAppRedo(this.props);
    };

    /**
     * Handles creating a new task when enter/return is used within a task.
     *
     * @param hasText {Boolean} Does the current task has text.
     * @param insertBefore {Boolean} Do we want a new task created before this task (E.g. return at start)
     * @param mergeTransactions {Boolean} Should we merge the new task creation action with the previous
     *          update action (that occurs automatically in the MilanoteEditor save handlers).
     *          We do want to if we're splitting the current line into two, otherwise, just do the new
     *          task in a new transaction
     * @param newTaskTextContent {Object} Draft JS content for the new task
     * @param newTaskSelection {Object} Draft JS selection for the new task
     * @returns {undefined}
     */
    onEnter = ({ hasText, insertBefore, mergeTransactions, newTaskTextContent, newTaskSelection }) => {
        const { depth, createNewElement, dispatchSaveEditorSelection, dispatchGetMostRecentTransactionId } = this.props;

        const shouldCreateNewElement = hasText || depth === 0;

        if (!shouldCreateNewElement) return this.changeIndentation({ increaseIndentation: false });

        if (this.disableKeyHandlers) return;

        // Due to the defers when creating new elements, when Enter and Tab are pressed quickly it can
        // result in the same task handling both (so it will indent itself before creating a new element
        // below).  This boolean prevents other handlers from executing immediately after an enter is pressed.
        // But we should only set it if we're changing the focus
        if (!insertBefore) {
            this.disableKeyHandlers = true;
        }

        // We only want to get the most recent transaction ID if there's been an update to the
        // current task due to splitting the task into two lines.
        const transactionId = mergeTransactions ? dispatchGetMostRecentTransactionId() : undefined;

        const content = { textContent: newTaskTextContent };

        createNewElement({
            insertBefore,
            content,
            selection: newTaskSelection,
            dispatchSaveEditorSelection,
            transactionId,
        });
    };

    onCheckboxClick = (event) => {
        if (!this.props.isEditable) return;

        if (isRightButton(event)) return;

        event.stopPropagation();

        // Don't prevent default on touch devices - this causes the following touch
        // move after tapping the checkbox to drag the element instead of panning
        if (!platformSingleton.features.isTouch) {
            // For non touch devices this prevents a drag from starting
            event.preventDefault();
        }

        this.toggleComplete();
    };

    onStartBackspace = ({ textContent }) => {
        const { onStartBackspaceHandler, element } = this.props;

        if (this.disableKeyHandlers) return;

        onStartBackspaceHandler({ elementId: getElementId(element), textContent });
    };

    onEndDelete = ({ textContent }) => {
        const { onEndDeleteHandler, element } = this.props;

        if (this.disableKeyHandlers) return;

        onEndDeleteHandler({ elementId: getElementId(element), textContent });
    };

    setComplete = () => {
        const { isEditable, element, markTaskAsComplete } = this.props;
        if (!isEditable) return;

        const elementId = getElementId(element);

        markTaskAsComplete({ id: elementId, isComplete: true });
    };

    toggleComplete = () => {
        const { isEditable, element, markTaskAsComplete, onTaskToggle } = this.props;

        if (this.disableKeyHandlers) return;

        if (!isEditable) return;

        const elementId = getElementId(element);
        const isCurrentlyComplete = getIsTaskComplete(element);

        markTaskAsComplete({ id: elementId, isComplete: !isCurrentlyComplete });

        onTaskToggle && onTaskToggle();
    };

    changeIndentation = ({ increaseIndentation }) => {
        const { element, changeIndentationHandler } = this.props;

        if (this.disableKeyHandlers) return;

        this.editorComponent && this.editorComponent.saveCurrentContent();

        changeIndentationHandler({ elementId: getElementId(element), increaseIndentation });
    };

    goToPreviousTask = (isTiptapEditor) => {
        const { element, goToPreviousTaskHandler, isFocusedForegroundElement } = this.props;

        if (this.disableKeyHandlers) return false;
        if (!goToPreviousTaskHandler) return false;

        return goToPreviousTaskHandler({
            elementId: getElementId(element),
            isFocusedForegroundElement,
            isTiptapEditor,
        });
    };

    goToNextTask = (isTiptapEditor) => {
        const { element, goToNextTaskHandler } = this.props;

        if (this.disableKeyHandlers) return false;
        if (!goToNextTaskHandler) return false;

        return goToNextTaskHandler({ elementId: getElementId(element), isTiptapEditor });
    };

    elementEvents = {
        ...this.props.elementEvents,
        onMouseDown: this.onMouseDown,
        onClick: this.onClick,
        onContextMenu: this.props.taskListOnContextMenu,
    };

    saveContent = (textContent, transactionId) => {
        const { isEditable, element, dispatchSaveTextContent } = this.props;
        if (!isEditable) return;

        dispatchSaveTextContent({ id: getElementId(element), textContent, transactionId });
    };

    moveElementsToTrash = () => {
        const { moveToTrashHandler, element } = this.props;
        moveToTrashHandler && moveToTrashHandler({ elementId: getElementId(element) });
    };

    onKeyboardDelete = ({ forward }) => {
        const { moveToTrashHandler, element } = this.props;
        moveToTrashHandler &&
            moveToTrashHandler({
                elementId: getElementId(element),
                focusAnotherTask: true,
                forward,
            });
    };

    editorRef = (component) => {
        this.editorComponent = component;
    };

    /**
     * This is called by the paste handler.
     *
     * NOTE: Currently this handles both Draft.js and Tiptap content.
     *  The main difference between the two is that Draft.js allows some of the content to be "completed".
     *  This is determined by whether the entire block is strikethrough or not - but this is so rare I
     *  won't worry about replicating for Tiptap, as this is all temporary while task lists aren't a single editor.
     *
     * TODO-REMOVE-DRAFT - Remove "completedBlocks" when Draft.js is being removed
     */
    createMultipleNewTasksWithContent = (jsonContentArray, completedBlocks) => {
        const {
            dispatchCreateNewElement,
            dispatchCreateAndEditNewElement,
            element,
            currentBoardId,
            dispatchGetNextUndoTransactionId,
            elementId,
            getListIndex,
        } = this.props;

        if (!jsonContentArray?.length) return;

        const listIndex = getListIndex(elementId);

        const preUpdateTransactionId = dispatchGetNextUndoTransactionId();

        // Defer to give the task a chance to update itself
        defer(() => {
            // TODO-REMOVE-DRAFT - Remove this next line when Draft.js is being removed
            this.editorComponent && this.editorComponent.saveCurrentContent();

            const postUpdateTransactionId = dispatchGetNextUndoTransactionId();

            const transactionId =
                preUpdateTransactionId === postUpdateTransactionId ? getNewTransactionId() : postUpdateTransactionId;

            jsonContentArray.forEach((textContent, index) => {
                // NOTE: This is Draft specific logic
                const blockKey = propIn(['blocks', 0, 'key'], textContent);
                const isComplete = blockKey && completedBlocks ? includes(blockKey, completedBlocks) : false;
                // (end draft-specific logic)

                const location = getListChildNewLocation({
                    listId: getLocationParentId(element),
                    index: listIndex + 1 + index,
                });

                const createFn =
                    index === jsonContentArray.length - 1 ? dispatchCreateAndEditNewElement : dispatchCreateNewElement;

                createFn({
                    location,
                    currentBoardId,
                    content: {
                        textContent,
                        isComplete,
                    },
                    transactionId,
                });
            });
        });
    };

    render() {
        return (
            <div>
                <TaskPresentationalWrapper
                    {...this.props}
                    editorRef={this.editorRef}
                    elementEvents={this.elementEvents}
                    changeIndentation={this.changeIndentation}
                    goToPreviousTask={this.goToPreviousTask}
                    goToNextTask={this.goToNextTask}
                    saveContent={this.saveContent}
                    onCheckboxClick={this.onCheckboxClick}
                    setComplete={this.setComplete}
                    toggleComplete={this.toggleComplete}
                    createMultipleNewTasksWithContent={this.createMultipleNewTasksWithContent}
                    moveElementsToTrash={this.moveElementsToTrash}
                    onKeyboardDelete={this.onKeyboardDelete}
                    onStartBackspace={this.onStartBackspace}
                    onEndDelete={this.onEndDelete}
                    onAppUndo={this.onAppUndo}
                    onAppRedo={this.onAppRedo}
                    onEnter={this.onEnter}
                />
            </div>
        );
    }
}

TaskContainer.propTypes = {
    elementId: PropTypes.string,
    element: PropTypes.object.isRequired,
    currentBoardId: PropTypes.string.isRequired,
    inList: PropTypes.string,
    gridSize: PropTypes.number,

    dispatchSaveTextContent: PropTypes.func,
    markTaskAsComplete: PropTypes.func,

    dispatchHandleSelectionModeSelectElement: PropTypes.func,
    startEditing: PropTypes.func,

    isLocked: PropTypes.bool,
    isEditable: PropTypes.bool,
    isRemotelyActive: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,
    currentlySelectedElementIds: PropTypes.object,

    elementEvents: PropTypes.object,
    taskListElementId: PropTypes.string,

    dispatchGetMostRecentTransactionId: PropTypes.func,
    dispatchGetNextUndoTransactionId: PropTypes.func,
    dispatchCreateNewElement: PropTypes.func,
    dispatchCreateAndEditNewElement: PropTypes.func,
    createNewElement: PropTypes.func,
    dispatchCreateNextNewTask: PropTypes.func,

    moveToTrashHandler: PropTypes.func,
    changeIndentationHandler: PropTypes.func,
    goToPreviousTaskHandler: PropTypes.func,
    goToNextTaskHandler: PropTypes.func,
    onStartBackspaceHandler: PropTypes.func,
    onEndDeleteHandler: PropTypes.func,
    onTaskListUpdatedHandler: PropTypes.func,

    onAppUndo: PropTypes.func,
    onAppRedo: PropTypes.func,

    taskListOnMouseDown: PropTypes.func,
    taskListOnContextMenu: PropTypes.func,

    depth: PropTypes.number,
    getListIndex: PropTypes.func,

    dispatchSaveEditorSelection: PropTypes.func,
    onTaskToggle: PropTypes.func,

    isPresentationModeEnabled: PropTypes.bool,
    isFocusedForegroundElement: PropTypes.bool,
    selectParentTaskList: PropTypes.func,
};

export default TaskContainer;
