// Lib
import { useMemo } from 'react';
import { Extensions } from '@tiptap/core';

// Extensions
import { OneLineDocument } from '../../../../common/tiptap/extensions/OneLineDocument';
import { TaskPaste } from '../../../../common/tiptap/extensions/TaskPaste';
import { AppUndo } from '../../../../common/tiptap/extensions/AppUndo';

// Actions
import { redoAction, undoAction } from '../../../utils/undoRedo/undoRedoActions';

// Hooks
import useClientCommonTiptapEditorExtensions, {
    UseClientCommonTiptapEditorExtensionsArgs,
} from '../../../components/tiptapEditor/useClientCommonTiptapEditorExtensions';
import useTaskTiptapEditorKeyboardShortcutsExtension, {
    TaskTiptapEditorKeyboardShortcutHandlerArgs,
} from './useTaskTiptapEditorKeyboardShortcutsExtension';

// Types
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

export type TaskTiptapEditorPasteHandlerArgs = {
    createMultipleNewTasksWithContent: (jsonContentArray: TiptapContent[]) => void;
};

type UseTaskTiptapEditorExtensionsArgs = UseClientCommonTiptapEditorExtensionsArgs &
    TaskTiptapEditorKeyboardShortcutHandlerArgs &
    TaskTiptapEditorPasteHandlerArgs;

const useTaskTiptapEditorExtensions = ({
    saveContent,
    isEditable,
    dispatch,
    placeholder,
    onHighlightsChanged,
    onEnter,
    onKeyboardDelete,
    changeIndentation,
    toggleComplete,
    dispatchOpenDueDatePopup,
    dispatchOpenAssignmentPopup,
    goToPreviousTask,
    goToNextTask,
    onStartBackspace,
    onEndDelete,
    createMultipleNewTasksWithContent,
}: UseTaskTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useClientCommonTiptapEditorExtensions({
        saveContent,
        placeholder,
        isEditable,
        dispatch,
        onHighlightsChanged,
    });

    const KeyboardExtension = useTaskTiptapEditorKeyboardShortcutsExtension({
        onEnter,
        onKeyboardDelete,
        changeIndentation,
        toggleComplete,
        dispatchOpenDueDatePopup,
        dispatchOpenAssignmentPopup,
        goToPreviousTask,
        goToNextTask,
        onStartBackspace,
        onEndDelete,
    });

    return useMemo(
        () => [
            OneLineDocument,
            KeyboardExtension,
            AppUndo.extend({}).configure({ dispatch, undoAction, redoAction }),
            TaskPaste.configure({ createMultipleNewTasksWithContent }),
            ...commonExtensions,
        ],
        [commonExtensions, KeyboardExtension],
    );
};

export default useTaskTiptapEditorExtensions;
