// Lib
import { RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import reactionPopupSelector, { getSelectedReactionIds } from '../reactionPopupSelector';

// Actions
import { fetchEmojiData } from '../../emojiDataActions';
import { updateCurrentUser } from '../../../../user/currentUserActions';

// Hooks
import useReactionPopupHandlers, { ReactionPopupHandlers } from './useReactionPopupHandlers';
import useEmojisRowData from './useEmojisRowData';

// Types
import { Emoji, EmojisRowData } from '../../emojiTypes';

// Constants
import { CATEGORIES } from '../reactionPopupConstants';

type ReactionPopupData = ReactionPopupHandlers & {
    currentCategory: string;
    setCurrentCategory: (category: string) => void;
    searchFilter: string;
    setSearchFilter: (filter: string) => void;
    emojiDataLoaded: boolean;
    emojisRowData: EmojisRowData;
    listRef: RefObject<any>;
    dispatchUpdateSkinTone: (skinTone: string) => void;
    emojiData: Emoji[];
    skinTone: string;
    selectedReactionIds: Immutable.List<string>;
};

const useReactionPopupData = (
    elementIds: string[],
    closePopup: () => void,
    getRowHeight: (index: number, emojisRowData: EmojisRowData) => number,
    columnCount?: number,
): ReactionPopupData => {
    const [currentCategory, setCurrentCategory] = useState(CATEGORIES[0].name);
    const [searchFilter, setSearchFilter] = useState('');
    const listRef = useRef(null);

    const dispatch = useDispatch();
    const dispatchFetchEmojiData = () => dispatch(fetchEmojiData());
    const dispatchUpdateSkinTone = (reactionsSkinTone: string) =>
        dispatch(updateCurrentUser({ changes: { settings: { reactionsSkinTone } } }));

    const { emojiData = [], skinTone, recentlyUsed } = useSelector(reactionPopupSelector);
    const selectedReactionIds = useSelector((state) => getSelectedReactionIds(state, elementIds));

    const emojiDataLoaded = emojiData.length > 0;

    useEffect(() => {
        if (!emojiDataLoaded) {
            dispatchFetchEmojiData();
        }
    }, []);

    const emojisRowData = useEmojisRowData(searchFilter, emojiData, recentlyUsed, columnCount);

    const handlers = useReactionPopupHandlers(
        recentlyUsed,
        elementIds,
        closePopup,
        skinTone,
        emojisRowData,
        currentCategory,
        setCurrentCategory,
        getRowHeight,
    );

    return {
        ...handlers,
        currentCategory,
        setCurrentCategory,
        searchFilter,
        setSearchFilter,
        emojiData,
        emojiDataLoaded,
        emojisRowData,
        listRef,
        skinTone,
        dispatchUpdateSkinTone,
        selectedReactionIds,
    };
};

export default useReactionPopupData;
