import { Node } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { TiptapNodeType } from '../../tiptapTypes';
import { Range } from '@tiptap/core';

export const TAB_SPACE_COUNT = 4;

type TabCharChange = {
    pos: number;
    count: number;
};

/**
 * Counts the number of spaces at the start of a string.
 */
const getStartSpaceCount = (text: string) => text.length - text.trimStart().length;

/**
 * Counts the number of spaces at the end of a string.
 * We use a regex here - trimEnd will also count (and therefore delete) \n's, which we don't want.
 *
 * NOTE: The characters listed are all horizontal whitespace characters, such as non-breaking space.
 */
const getEndSpaceCount = (text: string) =>
    // eslint-disable-next-line no-control-regex
    text.length - text.replace(/[ 	\xA0\u1680\u180e\u2000-\u200a\u202f\u205f\u3000]*$/, '').length;

/**
 * Determines the number of spaces directly before the cursor
 */
export const getSpacesBeforeCursor = (state: EditorState): number => {
    // $from is the [resolved position](https://prosemirror.net/docs/ref/#model.ResolvedPos) of the start
    //  of the selection. Resolved positions have more context, rather than simply being indexes, they
    //  allow you to get the node, parent, and other information about the position.
    const { from, $from } = state.selection;

    // Get the current selected node start position
    const nodeStartPosition = $from.start();

    // Get all the text prior to the cursor
    const textBeforeCursor = state.doc.textBetween(nodeStartPosition, from, '', '');

    // Loop through the text before the cursor and count the number of spaces
    return getEndSpaceCount(textBeforeCursor);
};

/**
 * Determines the number of spaces directly after the cursor.
 */
export const getSpacesAfterCursor = (state: EditorState): number => {
    // Almost identical to getSpacesBeforeCursor, but we're looking at the text after the cursor
    const { to, $to } = state.selection;
    const nodeEndPosition = $to.end();
    const textAfterCursor = state.doc.textBetween(to, nodeEndPosition, '', '');
    return getStartSpaceCount(textAfterCursor);
};

/**
 * Determines the number of spaces at the start of a node.
 */
const getSpacesAtLineStart = (node: Node): number => {
    // We only want to check for spaces in the first node, otherwise we might delete the wrong thing
    const text = node.isText ? node.textContent : node.firstChild?.textContent;
    return getStartSpaceCount(text || '');
};

const getSpacesToInsert = (spaceCount: number) => TAB_SPACE_COUNT - (spaceCount % TAB_SPACE_COUNT);
const getSpacesToRemove = (spaceCount: number) => {
    if (spaceCount === 0) return 0;
    return spaceCount % TAB_SPACE_COUNT || TAB_SPACE_COUNT;
};

const getNextChildTextNode = (node: Node, startingOffset: number): Node | null => {
    for (let i = startingOffset; i < node.childCount; i++) {
        const child = node.child(i);

        if (child.isText) return child;
    }

    return null;
};

/**
 * This tab handler only deals with nodes with simple text content inside them,
 * not deeply nested nodes.
 */
const TAB_INDENTABLE_NODES = [
    TiptapNodeType.paragraph,
    TiptapNodeType.heading,
    TiptapNodeType.smallText,
    TiptapNodeType.codeBlock,
];
const isTabIndentableNode = (node: Node) => TAB_INDENTABLE_NODES.includes(node.type.name as TiptapNodeType);

const getTextLineStartPositions = (text: string): TabCharChange[] =>
    [...text.matchAll(/^[^\S\n\r]*/gm)].map((x) => ({ pos: x.index, count: x[0].length }));

const getStartsOfSelectedTextLines = (
    text: string,
    textPosition: number,
    selectionStart: number,
    selectionEnd: number,
): TabCharChange[] => {
    const indentPoints = getTextLineStartPositions(text).map(({ pos, count }) => ({
        pos: pos + textPosition,
        count,
    }));

    // we want the line-start preceding the start cursor as well
    const firstPointBeforeSelection = indentPoints.filter(({ pos }) => pos < selectionStart).slice(-1);
    const linesWithinSelection = indentPoints.filter(({ pos }) => pos >= selectionStart && pos < selectionEnd - 1);

    return [...firstPointBeforeSelection, ...linesWithinSelection];
};

/**
 * Gets the positions of the start of each line (including after hard breaks, or newline characters in code blocks)
 * and the number of spaces at the start of each line.
 */
export const getLineStartPositionsAndSpaceCounts = (state: EditorState, range: Range): TabCharChange[] => {
    const { from, to } = range;

    const tabChanges: TabCharChange[] = [];

    // Find the positions of the top level paragraphs in the selection
    //  - We don't want to add or remove spaces to lists as this isn't what users would expect
    //  - Subtract 1 from the selection end to avoid adding/removing spaces across an invisible node selection boundary
    state.doc.nodesBetween(from, to - 1, (currentNode, position) => {
        // If the node is not indentable (eg a quote block), skip it
        if (!isTabIndentableNode(currentNode)) return false;

        // Codeblocks don't use hard breaks; they're a single string like in a <pre>,
        // so we need to parse it for newline characters and add/remove indents there.
        if (currentNode.type.name === TiptapNodeType.codeBlock) {
            const lineStarts = getStartsOfSelectedTextLines(currentNode.textContent, position, from, to);
            tabChanges.push(...lineStarts);
            return false;
        }

        // Ensure that text with soft line breaks also gets indented/dedented
        // Get the max index within the current node that we can check for new lines
        const maxIndex = Math.min(position + currentNode.nodeSize - 1, to - 1);

        // Subtract 1 to stay within the bounds of this node
        const currentNodeMaxPos = maxIndex - position - 1;

        // This will either be the start of the node (the default) or the last hard break before the selection start
        const spaceCount = getSpacesAtLineStart(currentNode);
        let tabBeforeSelection: TabCharChange = { pos: position, count: spaceCount };

        // We want: The last hard break before the selection start and all the ones in between...
        currentNode.nodesBetween(0, currentNodeMaxPos, (node, nodeOffset, nodeParent, nodeIndex) => {
            if (node.type.name !== TiptapNodeType.hardBreak) return false;

            const nextNode = getNextChildTextNode(currentNode, nodeIndex);

            if (!nextNode) return false;

            const spaceCount = getSpacesAtLineStart(nextNode);
            const currentPosition = position + nodeOffset + 1;

            const tabChange = { pos: currentPosition, count: spaceCount };

            // We only want to add the _last_ hard break before the selection start
            if (currentPosition < from) {
                tabBeforeSelection = tabChange;
            } else {
                // We want to indent the following Text node's text
                tabChanges.push(tabChange);
            }

            return false;
        });

        // Add the last start of line (start of node or hard break) before the selection start
        tabChanges.push(tabBeforeSelection);

        // We don't want to traverse any nodes, only get the top level positions
        return false;
    });

    // We want to insert the text *into* the node, not before it, so add 1 to each position
    return tabChanges.map((change) => ({
        ...change,
        pos: change.pos + 1,
    }));
};

/**
 * Finds all the Tiptap document position indexes where tabs (as spaces)
 * should be inserted when the tab key is pressed.
 */
export const getPositionAndCountsToInsertTabChars = (state: EditorState): TabCharChange[] => {
    const { from } = state.selection;

    // If the selection is empty - insert spaces at the cursor
    if (state.selection.empty) return [{ pos: from, count: TAB_SPACE_COUNT }];

    const lines = getLineStartPositionsAndSpaceCounts(state, state.selection);

    return lines
        .map(({ pos, count }) => ({
            pos,
            count: getSpacesToInsert(count),
        }))
        .filter(({ count }) => count > 0);
};

/**
 * Finds the positions to remove spaces from and the number of spaces to remove at each.
 */
export const getPositionAndCountsToRemoveTabChars = (state: EditorState): TabCharChange[] => {
    // If the selection is empty - remove spaces directly before the cursor,
    //  or, if there is only one, spaces at the start of the line (this is the default behaviour of
    // getLineStartPositionsAndSpaceCounts, so we just fall through to that)
    if (state.selection.empty) {
        const spacesToRemove = Math.min(getSpacesBeforeCursor(state), TAB_SPACE_COUNT);
        if (spacesToRemove > 1) return [{ pos: state.selection.from - spacesToRemove, count: spacesToRemove }];
    }

    const lines = getLineStartPositionsAndSpaceCounts(state, state.selection);

    return lines
        .map(({ pos, count }) => ({
            pos,
            count: getSpacesToRemove(count),
        }))
        .filter(({ count }) => count > 0);
};
