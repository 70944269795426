import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEditingSelector } from '../../selectors/elementSelector';
import { startEditingElement } from '../../selection/selectionActions';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { DOCUMENT_MODAL_EDITOR_KEY } from './documentModalConstants';

const getModalEditorKey = (elementId: string) => `${elementId}-${DOCUMENT_MODAL_EDITOR_KEY}`;

const useTipTapDocumentEditingProps = (elementId: string, element: ImMNElement) => {
    const editorId = getModalEditorKey(elementId);
    const editorKey = DOCUMENT_MODAL_EDITOR_KEY;

    const dispatch = useDispatch();

    const { isEditing, currentEditorKey, currentEditorId } = useSelector((state) =>
        // @ts-ignore The types of createShallowSelector must not be quite right
        isEditingSelector()(state, { elementId, element }),
    );

    const dispatchStartEditing = useCallback(() => {
        dispatch(
            startEditingElement({
                id: elementId,
                editorId,
                editorKey,
                transactionId: undefined,
                canUndo: true,
            }),
        );
    }, [dispatch, elementId, editorId, editorKey]);

    return {
        editorId,
        editorKey,
        isEditing,
        currentEditorKey,
        currentEditorId,
        dispatchStartEditing,
    };
};

export default useTipTapDocumentEditingProps;
