import { Extension } from '@tiptap/core';

/**
 * This extension prevents content from being printed out in Tiptap debug
 * statements, or error logs.
 * This is important for content that is sensitive.
 *
 * Example, without this extension:
 * ```
 * index.js:281 Uncaught RangeError: Position 72 outside of fragment (<heading("My credit card info"),
 *  paragraph("Here is my card with"), paragraph("Potentially sensitive data")>)
 * ```
 *
 * With the extension:
 * ```
 * index.js:281 Uncaught RangeError: Position 72 outside of fragment (<heading, paragraph, paragraph>)
 * ```
 *
 * To improve local development, this extension will not be added in development mode.
 */
export const HideContentDebugger = Extension.create({
    name: 'hideContentDebugger',

    extendNodeSchema(node) {
        return {
            toDebugString() {
                return node.name;
            },
        };
    },
});
