// Lib
import React from 'react';
import { NativeMarket } from '@capacitor-community/native-market';
import { useSelector } from 'react-redux';

// Types
import { ApplicationType, MilanoteApplicationMode } from '../../../common/platform/platformTypes';

// Selectors
import { getMilanoteApplicationModeSelector } from '../platformSelector';

// Components
import FullScreenModal from '../../components/modal/FullScreenModal';
import StyledButton from '../../components/buttons/StyledButton';

// Styles
import './VersionBlockingFullScreenPrompt.scss';

const getShouldFillScreen = (appMode: MilanoteApplicationMode) => appMode === MilanoteApplicationMode.mobile;

const getAppStoreId = (appType: ApplicationType): string | null => {
    switch (appType) {
        case ApplicationType.androidFlutter:
        case ApplicationType.androidCapacitor:
        case ApplicationType.androidTabletCapacitor:
            return 'com.milanote.milanoteApp';
        case ApplicationType.iphoneSwift:
        case ApplicationType.ipadSwift:
        case ApplicationType.iphoneCapacitor:
        case ApplicationType.ipadCapacitor:
            return 'id1433852790';
        default:
            return null;
    }
};

const openPlatformStore = async (appType: ApplicationType) => {
    const appStoreId = getAppStoreId(appType);
    if (!appStoreId) return;
    await NativeMarket.openStoreListing({ appId: appStoreId });
};

const VersionBlockingFullScreenPrompt = (props: { appType: ApplicationType }) => {
    const appMode = useSelector(getMilanoteApplicationModeSelector);

    return (
        <FullScreenModal className="version-blocker" fillScreen={getShouldFillScreen(appMode)}>
            <div className="container">
                <img className="maintenance-image" src="/img/image-system-down%402x.jpg" />
                <h1 className="title">Update required</h1>
                <p className="subtitle">This version of Milanote is no longer supported</p>
                <StyledButton className="primary" onClick={() => openPlatformStore(props.appType)}>
                    Update to the latest version
                </StyledButton>
                <img className="logo" src="/img/milanote-logo-vertical%403x.png" />
            </div>
        </FullScreenModal>
    );
};

export default VersionBlockingFullScreenPrompt;
