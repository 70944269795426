import { SheetActionTypes, SheetCloseAction, SheetOpenAction } from './SheetActionTypes';
import { SheetId, SheetOptions } from './sheetTypes';

export const openSheet = (sheetId: SheetId, sheetKey: string, options?: SheetOptions): SheetOpenAction => ({
    type: SheetActionTypes.SHEET_OPEN,
    sheetId,
    sheetKey,
    options,
});

export const closeSheet = (sheetId: SheetId, sheetKey: string): SheetCloseAction => ({
    type: SheetActionTypes.SHEET_CLOSE,
    sheetId,
    sheetKey,
});

export const updateSheetActiveSnapPoint = (sheetId: SheetId, sheetKey: string, activeSnapPoint: number) => ({
    type: SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT,
    sheetId,
    sheetKey,
    activeSnapPoint,
});
