import { throttle } from 'lodash/fp';
import {
    getCompleteEmojiCode,
    getEmojiPickerRowKey,
    getUnified,
    isTitleRow,
    prepareRecentlyUsedEmojiCodes,
} from '../reactionPopupUtil';
import { updateReaction } from '../../../../element/reactions/reactionActions';
import { updateCurrentUser } from '../../../../user/currentUserActions';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { Emoji, EmojisRowData, RecentlyUsedEmojis } from '../../emojiTypes';

export type ReactionPopupHandlers = {
    getKeyOfItemAtIndex: (index: number) => string;
    getHeightOfItemAtIndex: (index: number) => number;
    handleClickEmoji: (emoji: any) => void;
    handleEmojiListScroll: (event: any) => void;
};

const useReactionPopupHandlers = (
    recentlyUsed: RecentlyUsedEmojis,
    elementIds: string[],
    closePopup: () => void,
    skinTone: string,
    emojisRowData: EmojisRowData,
    currentCategory: string,
    setCurrentCategory: (category: string) => void,
    getRowHeight: (index: number, emojisRowData: EmojisRowData) => number,
) => {
    const dispatch = useDispatch();
    const dispatchAddReaction = ({ elementIds, emojiCode }: { elementIds: string[]; emojiCode: string }) =>
        dispatch(updateReaction({ updateIds: elementIds, emojiCode }));
    const dispatchUpdateRecentlyUsed = (reactionsRecent: RecentlyUsedEmojis) =>
        dispatch(updateCurrentUser({ changes: { settings: { reactionsRecent } } }));

    const getKeyOfItemAtIndex = (index: number) => getEmojiPickerRowKey(emojisRowData[index]);

    const getHeightOfItemAtIndex = useMemo(
        () => (index: number) => getRowHeight(index, emojisRowData),
        [emojisRowData],
    );

    const getTitleAtScrollOffset = (scrollOffset: number) => {
        let height = 0;
        let currentTitle;

        for (let i = 0; i < emojisRowData.length; i++) {
            const row = emojisRowData[i];

            height += getHeightOfItemAtIndex(i);

            if (isTitleRow(row)) {
                currentTitle = row.title;
            }

            if (height >= scrollOffset) return currentTitle;
        }
    };

    const handleClickEmoji = (emoji: Emoji) => {
        dispatchUpdateRecentlyUsed(prepareRecentlyUsedEmojiCodes(getUnified(emoji), recentlyUsed));

        dispatchAddReaction({
            elementIds,
            emojiCode: getCompleteEmojiCode(emoji, skinTone),
        });

        closePopup && closePopup();
    };

    const handleEmojiListScroll = throttle(10, (event) => {
        if (event.scrollUpdateWasRequested) return;

        const scrollOffset = event.scrollOffset === 0 ? 0 : event.scrollOffset + 150;
        const closestTitle = getTitleAtScrollOffset(scrollOffset);

        if (closestTitle && closestTitle !== currentCategory) {
            setCurrentCategory(closestTitle);
        }
    });

    return {
        handleEmojiListScroll,
        handleClickEmoji,
        getKeyOfItemAtIndex,
        getHeightOfItemAtIndex,
    };
};

export default useReactionPopupHandlers;
