// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';

// Utils
import { stopPropagationOnly } from '../../utils/domUtil';

// Actions
import { modalClosed, modalOpened } from './modalActions';

// Components
import Modal from './Modal';
import ModalKeyboardManager from './ModalKeyboardManager';

// Styles
import './FullScreenModal.scss';

const getPathName = get(['location', 'pathname']);

const mapDispatchToProps = (dispatch) => ({
    dispatchModalOpened: (modalName) => dispatch(modalOpened(modalName)),
    dispatchModalClosed: (modalName) => dispatch(modalClosed(modalName)),
});

@connect(null, mapDispatchToProps)
class FullScreenModal extends React.Component {
    componentDidMount() {
        this.props.dispatchModalOpened(this.props.modalName || getPathName(this.props));
    }

    componentWillUnmount() {
        this.props.dispatchModalClosed(this.props.modalName || getPathName(this.props));
    }

    onMouseDown = (event) => {
        this.wasMouseDownTarget = this.fullScreenModalElement === event.target;
    };

    onClick = (event) => {
        if (!this.wasMouseDownTarget) return;

        const { disableClose, close } = this.props;

        !disableClose && close && close(event);

        this.wasMouseDownTarget = false;
    };

    render() {
        const {
            children,
            className,
            overlay,
            close,
            outsideModalChildren,
            padding = true,
            alignTop = false,
            autoWidth = false,
            fillScreen = false,
        } = this.props;

        const classes = classNames('FullScreenModal', className, {
            overlay,
            'auto-width': autoWidth,
            'no-padding': !padding,
            'align-top': alignTop,
            'fill-screen': fillScreen,
        });

        return (
            <div
                ref={(c) => {
                    this.fullScreenModalElement = c;
                }}
                className={classes}
                onMouseDown={this.onMouseDown}
                onClick={this.onClick}
            >
                {outsideModalChildren}

                <div className="modal-positioner">
                    <ModalKeyboardManager {...this.props}>
                        <Modal {...this.props} onClick={stopPropagationOnly} close={close}>
                            {children}
                        </Modal>
                    </ModalKeyboardManager>
                </div>
            </div>
        );
    }
}

FullScreenModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    outsideModalChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    className: PropTypes.string,
    modalName: PropTypes.string,
    overlay: PropTypes.bool,
    close: PropTypes.func,
    disableClose: PropTypes.any,
    fillScreen: PropTypes.bool,

    padding: PropTypes.bool,
    alignTop: PropTypes.bool,
    autoWidth: PropTypes.bool,

    dispatchModalOpened: PropTypes.bool,
    dispatchModalClosed: PropTypes.bool,
};

export default FullScreenModal;
