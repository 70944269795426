// Lib
import React, { useCallback } from 'react';
import { Editor, useEditor } from '@tiptap/react';
import { Extensions } from '@tiptap/core';
import { Selection } from '@tiptap/pm/state';

// Utils
import convertPersistedTextContentToTiptapContent from './utils/convertPersistedTextContentToTiptapContent';

// Hooks
import useTiptapTriggerEditable from './hooks/useTiptapTriggerEditable';
import useTiptapEditorFocus from './hooks/useTiptapEditorFocus';
import useTiptapEditorPersistedContentSync from './hooks/useTiptapEditorPersistedContentSync';
import useSyncTiptapActiveEditorState from './hooks/useSyncTiptapActiveEditorState';
import { usePreservedSelection } from './extensions/VirtualSelection';

// Types
import { TiptapContent } from './tiptapTypes';

// General Milanote Tiptap editor styles
import './styles/MilanoteTiptapEditorStyles.scss';

export type UseTiptapEditorProps = {
    persistedContent?: TiptapContent | null;
    extensions: Extensions;
    editorClassname?: string;

    editorId: string;
    editorKey: string;
    currentEditorId?: string;

    isEditable: boolean;
    isEditing: boolean;
    isSingleSelected: boolean;

    startEditing?: (args: {
        editorId: string;
        editorKey: string;
        editorFocusClientCoords: { x: number; y: number };
    }) => void;

    saveContent?: (content: TiptapContent, transactionId?: number) => void;
    updateActiveEditorStore?: (editor: Editor | null) => void;
};

export type UseTiptapEditorReturn = {
    editor: ReturnType<typeof useEditor>;
    onMouseDown: (event: React.MouseEvent) => void;
    onClick: (event: React.MouseEvent) => void;
};

/**
 * Creates the Tiptap editor instance and sets up the necessary behaviours for it, such as:
 * - Making the editor editable when it is the currently edited editor.
 * - Saving the content on a de-bounce.
 * - Syncing the active editor to the context.
 * - Focusing the editor when it starts editing.
 * - Preventing mousedown events from reaching the Element when the editor is being edited.
 * - Starting editing when the Element is clicked.
 */
const useElementTiptapEditor = ({
    persistedContent,
    extensions,
    editorId,
    editorKey,
    currentEditorId,
    isEditing,
    isEditable,
    isSingleSelected,
    startEditing,
    saveContent,
    updateActiveEditorStore,
    editorClassname = '',
}: UseTiptapEditorProps): UseTiptapEditorReturn => {
    // TODO (WEB-12169): We may want to memoise this to avoid reconverting the content when eg isEditing changes
    const content = convertPersistedTextContentToTiptapContent(persistedContent);

    const editor = useEditor({
        // @ts-ignore Can't import the right Extensions type for some reason...
        extensions,
        content,
        editorProps: {
            attributes: {
                class: editorClassname,
            },
        },
    }) as Editor;

    const isEditingThisEditor = currentEditorId === editorId && isEditing;

    // Only allow the editor to be editable if it is the currently edited editor.
    //  If we don't do this, we won't be able to drag on the editor, it would instead select the text
    useTiptapTriggerEditable(editor, isEditable && isEditingThisEditor);
    // Sync the active editor to the context
    useSyncTiptapActiveEditorState(editor, isEditingThisEditor, updateActiveEditorStore);

    // Ensure the cursor is in the editor while editing
    useTiptapEditorFocus(editor, isEditingThisEditor);

    // Ensure updates made to the content, while it's not being edited (e.g. undo/redo or remote editing),
    // are reflected in the editor
    useTiptapEditorPersistedContentSync(editor, persistedContent, content, isEditingThisEditor);

    // Keep selections visible while popups are open
    usePreservedSelection(editor, isSingleSelected);

    /**
     * Prevent mousedown events from reaching the ElementContainer
     * to prevent the card from exiting editing mode.
     */
    const onMouseDown = useCallback(
        (event: React.MouseEvent) => {
            if (!isEditingThisEditor) return;
            event.stopPropagation();
        },
        [isEditingThisEditor],
    );

    /**
     * Start editing this element when it's clicked.
     */
    const onClick = useCallback(
        (event: React.MouseEvent) => {
            if (!startEditing) return;
            if (!isEditable) return;
            if (!isSingleSelected) return;
            if (isEditingThisEditor) return;

            // Get the resolved editor position/coords of the click event, to ensure we correctly
            // scroll and focus to the correct part of the editor relative to the click event.
            const posAtClick = editor.view.posAtCoords({ left: event.clientX, top: event.clientY });
            const editorFocusPos =
                posAtClick && posAtClick.inside >= 0 ? posAtClick.pos : Selection.atEnd(editor.state.doc).from;

            const editorFocusCoords = editor.view.coordsAtPos(editorFocusPos);

            // Pass in editorFocusClientCoords to startEditing, to make sure app scrolls properly
            // to the editor focus position. See mobileEditingMiddleware.ts.
            const editorFocusClientCoords = { x: editorFocusCoords.left, y: editorFocusCoords.top };
            startEditing({ editorId, editorKey, editorFocusClientCoords });

            editor.commands.focus(editorFocusPos);
        },
        [isSingleSelected, editor, isEditingThisEditor, isEditable, editorId, editorKey],
    );

    return { editor, onMouseDown, onClick };
};

export default useElementTiptapEditor;
