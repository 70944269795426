// Lib
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Range } from '@tiptap/core';
import { Editor, posToDOMRect } from '@tiptap/react';
import { useSelector } from 'react-redux';

// Utils
import globalLogger from '../../logger';
import getParentScrollableSection from '../../utils/dom/getParentScrollableSection';
import { isPlatformPhoneOrMobileMode } from '../../platform/utils/platformDetailsUtils';
import {
    MentionSuggestion,
    mentionSuggestionToAttrs,
} from '../../../common/tiptap/extensions/mention/MentionSuggestion';

// Selectors
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Components
import { TiptapMentionsSuggesterPopup } from '../../element/card/tiptap/TiptapMentionsSuggesterPopup';

// Types
import { Point } from '../../../common/maths/geometry/pointTypes';
import { PlatformDetails } from '../../../common/platform/platformTypes';
import { MentionsSuggestionsManager } from '../../../common/tiptap/extensions/mention/SuggestionsManagerTypes';

type SuggestionCommand = (data: MentionSuggestion) => void;

type SuggestionCallbackProps = {
    editor: Editor;
    range: Range;
    props?: MentionSuggestion;
};
type RenderCallbackProps = SuggestionCallbackProps & {
    query: string;
    command: SuggestionCommand;
};

type SuggestionInitOptions = {
    // These are for the mentions plugin to talk to the outside world
    onMentionCreated: SuggestionCommand;
    onQueryUpdated: (query: string | null) => void;
    setPopupPosition: (position: PopupPosition) => void;
    onEnded: () => void;
    keyEvents: EventTarget;
    platformDetails: PlatformDetails;
};

export type PopupPosition = {
    anchor: 'top' | 'bottom';
    point: Point;
};

const MOBILE_MENTIONS_POPUP_WIDTH = 320;
const MOBILE_MENTIONS_POPUP_MAX_HEIGHT = 175;

const logger = globalLogger.createChannel('suggest');

/**
 * This object serves as a bridge between the main Milanote application state and the internal state of the
 * Tiptap editor. The Mention extension expects an object matching the SuggestionOptions spec as part of its
 * configuration.
 *
 * To help simplify the API, this isn't exposed directly; it should be used via the useSuggestionManager hook below.
 */
const suggestions = ({
    onMentionCreated,
    onQueryUpdated,
    onEnded,
    setPopupPosition,
    keyEvents,
    platformDetails,
}: SuggestionInitOptions): MentionsSuggestionsManager => {
    let storedCommand: SuggestionCommand | null = null;
    let currentQuery: string | null = '';
    let currentSelection: MentionSuggestion | null = null;

    const setQuery = (q: string | null) => {
        currentQuery = q;
        onQueryUpdated?.(q);
    };

    const complete = () => {
        if (!currentSelection) return;

        storedCommand?.(currentSelection);
    };

    const hidePopup = () => {
        setQuery(null);
        currentSelection = null;
    };

    const getMentionsPopupPosition = (editor: Editor, range: Range): PopupPosition => {
        const rangeRect = posToDOMRect(editor.view, range.from, range.from);
        const point = { x: rangeRect.x, y: rangeRect.y + rangeRect.height };

        if (!isPlatformPhoneOrMobileMode(platformDetails)) return { anchor: 'bottom', point };

        const scrollableElement = getParentScrollableSection(editor.options.element as HTMLElement);
        if (!scrollableElement) return { anchor: 'bottom', point };

        const scrollableElementRect = scrollableElement.getBoundingClientRect();
        const scrollableElementScrollExcessPadding =
            // @ts-ignore - value not defined in CSSStyleValue
            scrollableElement.computedStyleMap().get('padding-bottom')?.value || 0;
        let anchor: PopupPosition['anchor'] = 'bottom';

        // On mobile, update position.x such that the initial popup position should be centered on the screen
        point.x = (scrollableElementRect.width - MOBILE_MENTIONS_POPUP_WIDTH) / 2;

        // On mobile, update position.y such that the initial popup position should not be hidden by the keyboard
        if (point.y + MOBILE_MENTIONS_POPUP_MAX_HEIGHT > window.innerHeight - scrollableElementScrollExcessPadding) {
            point.y = rangeRect.top;
            anchor = 'top';
        }

        return { anchor, point };
    };

    return {
        complete: () => {
            complete();
        },

        setSelection: (data: MentionSuggestion) => {
            logger.log('SELECTION', data);
            currentSelection = data;
        },

        command: ({ editor, range, props: suggestion }: SuggestionCallbackProps) => {
            logger.log('COMMAND', { editor, range, suggestion }, range);

            if (!suggestion) return;

            // The end point of the provided range is sometimes inaccurate and will leave
            // stray characters in the text. Using the current cursor position is more reliable.
            // (the provided start position is still accurate)
            const mentionRange = {
                from: range.from,
                to: editor.state.selection.$head.pos,
            };

            const { mentionKey, userId } = mentionSuggestionToAttrs(suggestion);
            const text = suggestion.name;

            editor.chain().createMention(mentionRange, text, userId, mentionKey).focus().run();

            onMentionCreated?.(suggestion);
        },

        render: () => {
            // This is called when the editor component mounts, not when a lookup begins.
            // Subsequent @'s in the same editor will reuse this same object.

            return {
                onStart: ({ editor, range, command }: RenderCallbackProps) => {
                    logger.log('START', range);

                    storedCommand = command;
                    setQuery('');

                    const position = getMentionsPopupPosition(editor, range);

                    setPopupPosition(position);

                    // hide the popup when the user clicks away without completing a mention
                    editor.on('blur', hidePopup);
                },

                onUpdate: (props: RenderCallbackProps) => {
                    logger.log('UPDATE', props, props.range);

                    const { query, editor, range } = props;

                    setQuery(query);

                    const position = getMentionsPopupPosition(editor, range);
                    setPopupPosition(position);
                },

                onKeyDown: ({ event }: { event: KeyboardEvent }) => {
                    // This function's return value indicates whether the keypress should be
                    // handled by the editor as well (ie, should it type the character?)

                    // If no active query, always follow normal editor behaviour
                    if (currentQuery === null) return false;

                    logger.log('KEYDOWN', event.key);

                    if (['Enter', 'Tab'].includes(event.key)) {
                        complete();
                        return true;
                    }

                    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
                        // dispatch as a new event; the EventTarget api doesn't like double-handling
                        keyEvents.dispatchEvent(new KeyboardEvent('keydown', { key: event.key }));
                        return true;
                    }

                    if (event.key === 'Escape') {
                        // (currently fully deselects the element)
                        logger.log('ESCAPE');
                        setQuery(null);
                        event.preventDefault();
                        event.stopPropagation();
                        return true;
                    }

                    return false;
                },

                onExit({ editor }: RenderCallbackProps) {
                    logger.log('EXIT');

                    // clear our local state
                    storedCommand = null;
                    setQuery(null);
                    onEnded?.();

                    editor.off('blur', hidePopup);
                },
            };
        },
    };
};

/**
 * This hook provides a simplified API for the Mention extension's suggestion system.
 * It returns a tuple containing the suggestion manager object to pass to Mention.configure
 * and a ReactNode to render the suggestion popup.
 */
export const useSuggestionsManager: () => [MentionsSuggestionsManager, ReactNode] = () => {
    const [currentQuery, setCurrentQuery] = useState<string | null>(null);
    const [popupPosition, setPopupPosition] = useState<PopupPosition | null>(null);

    const platformDetails = useSelector(getPlatformDetailsSelector);

    // The key events will be coming from inside the Tiptap editor, and we want to dispatch
    // the responses to those keypresses *back* into it. Using an EventTarget lets us avoid
    // having to store refs to elements or complex callback chains.
    const keyEvents = useMemo(() => new EventTarget(), []);

    const suggestion = useMemo(
        () =>
            suggestions({
                onMentionCreated: (mention) => {
                    logger.log('CREATE', mention);
                    setCurrentQuery(null);
                    setPopupPosition(null);
                },
                onQueryUpdated: (q) => {
                    setCurrentQuery(q);
                },
                onEnded: () => {
                    setCurrentQuery(null);
                    setPopupPosition(null);
                },
                setPopupPosition,
                keyEvents,
                platformDetails,
            }),
        [],
    );

    const onSelectUser = useCallback(
        (userDetails: MentionSuggestion) => {
            suggestion.setSelection(userDetails);
            suggestion.complete();
        },
        [suggestion],
    );

    const onPreviewUserChanged = useCallback(
        (userDetails: MentionSuggestion) => {
            suggestion.setSelection(userDetails);
        },
        [suggestion],
    );

    const mentionsPopup = useMemo(
        () =>
            currentQuery !== null ? (
                <TiptapMentionsSuggesterPopup
                    currentSuggestionQuery={currentQuery}
                    position={popupPosition}
                    onSelectUser={onSelectUser}
                    onPreviewUserChanged={onPreviewUserChanged}
                    keyEvents={keyEvents}
                />
            ) : null,
        [currentQuery, popupPosition, onSelectUser],
    );

    return [suggestion, mentionsPopup];
};
