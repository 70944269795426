// Lib
import { createSelector } from 'reselect';
import { getCurrentBoardVisibleDescendantIds } from '../../selectors/currentBoardSelector';
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';

// Utils
import { getText } from '../../../../common/comments/commentModelUtil';
import doesEditorJsonHaveText from '../../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';

export const getLocalCommentInputIsEditing = (state, { _id }) =>
    state.getIn(['app', 'commentsInput', 'map', _id, 'isEditing']);

export const getLocalCommentInputText = (state, { _id }) => state.getIn(['app', 'commentsInput', 'map', _id, 'text']);

export const getCommentsInputMap = (state) => state.getIn(['app', 'commentsInput', 'map']);

const selectUnsavedCommentsOnCurrentBoard = createShallowSelector(
    getCommentsInputMap,
    getCurrentBoardVisibleDescendantIds,
    (comments, currentBoardDescendantIds) =>
        comments
            .filter((comment) => doesEditorJsonHaveText(getText(comment)) || !!comment.get('isEditing'))
            .filter((_, elementId) => currentBoardDescendantIds.includes(elementId)),
);

export const selectUnsavedCommentsOnCurrentBoardCount = createSelector(
    selectUnsavedCommentsOnCurrentBoard,
    (comments) => comments.size || 0,
);
