// Lib
import { useRef, useState } from 'react';
import requestSecondAnimationFrame from '../../../../../common/utils/lib/requestSecondAnimationFrame';

// Hooks
import useSheetInitialisation from './useSheetInitialisation';
import useSheetHandlers, { SheetHandlers } from './useSheetHandlers';
import useSheetSnapPointState from './useSheetSnapPointState';
import useSheetObstructionHandler from './useSheetObstructionHandler';

// Utils
import { setCurrentHeightForAnimation } from '../utils/snapPointUtils';

// Types
import { SheetProps } from '../SheetContainer';

export const MAX_SUFFIX = 'max';

export interface SheetContextState extends SheetHandlers {
    sheetContentRef: React.RefObject<HTMLDivElement>;
    sheetRef: React.RefObject<HTMLDivElement>;
    snapPointsState: number[];
    isSheetMounted: boolean;
    setIsSheetMounted: (isMounted: boolean) => void;
    closeSheetWithTransition: () => void;
}

const useSheetContextState = (props: SheetProps): SheetContextState => {
    const {
        sheetRef,
        sheetContentRef,
        sheetId,
        sheetKey,
        snapPoints = [],
        defaultSnapPoint,
        isSheetOpen,
        dispatchCloseSheet,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        onSheetClose,
        preventDismiss = false,
        onCloseTransitionEnd,
        onOpenTransitionEnd,
    } = props;

    const [isSheetMounted, setIsSheetMounted] = useState(false);
    const cancelInProgressCloseAnimation = useRef<(() => void) | null>(null);

    const { snapPointsState, goToSnapPoint, addSnapPoint } = useSheetSnapPointState(
        snapPoints,
        sheetRef,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        preventDismiss,
    );

    const closeSheetWithTransition = () => {
        if (!isSheetMounted || !sheetRef.current) return;

        if (cancelInProgressCloseAnimation.current) {
            cancelInProgressCloseAnimation.current();
        }

        dispatchCloseSheet();
        onSheetClose?.();

        if (sheetRef.current.style.height === 'auto') {
            setCurrentHeightForAnimation(sheetRef);
        }

        const transitionEndCallback = () => {
            cancelInProgressCloseAnimation.current = null;
            setIsSheetMounted(false);
            onCloseTransitionEnd?.();
        };

        const cancelAnimationFrame = requestSecondAnimationFrame(() => {
            if (!sheetRef.current) return;

            goToSnapPoint(0);

            sheetRef.current.addEventListener('transitionend', transitionEndCallback, { once: true });
        });

        cancelInProgressCloseAnimation.current = () => {
            if (!sheetRef.current) return;

            sheetRef.current.removeEventListener('transitionend', transitionEndCallback);

            cancelAnimationFrame();
            cancelInProgressCloseAnimation.current = null;
        };
    };

    const sheetInitialised = useSheetInitialisation(
        isSheetOpen,
        sheetRef,
        defaultSnapPoint,
        addSnapPoint,
        goToSnapPoint,
        closeSheetWithTransition,
        isSheetMounted,
        cancelInProgressCloseAnimation,
        onOpenTransitionEnd,
    );

    const handlers = useSheetHandlers(
        activeSnapPoint,
        sheetRef,
        sheetContentRef,
        sheetInitialised,
        snapPointsState,
        goToSnapPoint,
        closeSheetWithTransition,
    );

    useSheetObstructionHandler(sheetId, sheetKey, isSheetOpen, snapPoints, goToSnapPoint);

    return {
        ...handlers,
        sheetContentRef,
        sheetRef,
        snapPointsState,
        closeSheetWithTransition,
        isSheetMounted,
        setIsSheetMounted,
    };
};

export default useSheetContextState;
