// Lib
import { get } from 'lodash/fp';

// Utils
import { http } from './services/http';

export const validateAppVersionIsSafe = (currentVersion) =>
    http({ url: `version/validate/${currentVersion}` })
        .then((res) => get(['data', 'safe'], res) || false)
        // Ignore errors during validation
        .catch((err) => true);

// TODO: Consolidate version "validation" and "status check"
export const validateAppVersion = (currentVersion) =>
    http({ url: `version/validate/${currentVersion}` }).then((res) => get(['data'], res) || {});

export const checkVersionBlockedStatus = (currentVersion, appType) =>
    http({
        url: `version/status`,
        params: {
            version: currentVersion,
            appType,
        },
    }).then((res) => get(['data', 'isBlocked'], res) || false);
