/**
 * A helper that simply imports the client-side dependencies required by useCommonTiptapEditorExtensions,
 * so each consumer doesn't have to.
 */
import { togglePopup } from '../popupPanel/popupActions';
import { PopupIds } from '../popupPanel/popupConstants';
import useCommonTiptapEditorExtensions, {
    UseCommonTiptapEditorExtensionsArgs,
} from '../../../common/tiptap/hooks/useCommonTiptapEditorExtensions';

const toggleHyperlinkPopupAction = togglePopup(PopupIds.HYPERLINK);

export type UseClientCommonTiptapEditorExtensionsArgs = Omit<
    UseCommonTiptapEditorExtensionsArgs,
    'toggleHyperlinkPopupAction'
>;

const useClientCommonTiptapEditorExtensions = (args: UseClientCommonTiptapEditorExtensionsArgs) =>
    useCommonTiptapEditorExtensions({ toggleHyperlinkPopupAction, ...args });

export default useClientCommonTiptapEditorExtensions;
