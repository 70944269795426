export enum SheetId {
    MobileCanvasContextMenu = 'MobileCanvasContextMenu',
    MobileContextMenuTool = 'MobileContextMenuTool',
    MobileInboxContainer = 'MobileInboxContainer',
    MobileTextStyleRichTextTool = 'MobileTextStyleRichTextTool',
    MobileColorTool = 'MobileColorTool',
    MobileDocumentModal = 'MobileDocumentModal',
    MobileReactionTool = 'MobileReactionTool',
    MobileElementReactionTool = 'MobileElementReactionTool',
}

export type SheetOptions = {
    scrollToElementOnOpen?: boolean;
    containsElementEditor?: boolean;
};

export type SheetState = {
    active: true;
    sheetId: SheetId;
    activeSnapPoint: number;
    options?: SheetOptions;
};

export type SheetReducerState = {
    [sheetKey in string]?: SheetState;
};
