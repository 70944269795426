// Lib
import { useEffect } from 'react';
import { Editor } from '@tiptap/react';

/**
 * When the editor starts editing, ensure the caret is placed in the editor.
 */
const useTiptapEditorFocus = (editor: Editor | null, isEditing: boolean) => {
    useEffect(() => {
        if (!isEditing) return;

        // NOTE: We're intentionally using the `view.focus()` rather than the `commands.focus()` here
        //  as using the command causes issues on iOS (WEB-11786) - the cursor will jump between the start
        //  of the editor & where the user taps.
        //  As far as I've seen there's no regressions elsewhere, but if there are we might want to conditionally
        //  use the command based on the platform.
        editor?.view.focus();
    }, [isEditing]);
};

export default useTiptapEditorFocus;
