import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getMilanoteApplicationModeSelector } from '../../platform/platformSelector';
import { useSelector } from 'react-redux';
import { stopPropagationOnly } from '../../utils/domUtil';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';
import MobileElementReactionAddButton from './MobileElementReactionAddButton';
import DesktopElementReactionAddButton from './DesktopElementReactionAddButton';

import './ElementReactionAddButton.scss';

const ElementReactionAddButton = ({ className, elementId, onPopupToggleCb }) => {
    const isMobile = useSelector(getMilanoteApplicationModeSelector) === MilanoteApplicationMode.mobile;

    return (
        <div className={classNames('ElementReactionAddButton', className)} onClick={stopPropagationOnly}>
            {isMobile ? (
                <MobileElementReactionAddButton elementId={elementId} />
            ) : (
                <DesktopElementReactionAddButton elementId={elementId} onPopupToggleCb={onPopupToggleCb} />
            )}
        </div>
    );
};

ElementReactionAddButton.propTypes = {
    elementId: PropTypes.string,
    className: PropTypes.string,
    onPopupToggleCb: PropTypes.func,
};

export default ElementReactionAddButton;
