import DefaultTextAlign from '@tiptap/extension-text-align';
import { Editor } from '@tiptap/react';
import { objectIncludes, NodeRange, combineTransactionSteps } from '@tiptap/core';
import { Node, NodeSpec } from '@tiptap/pm/model';
import { PluginKey, Plugin, EditorState, Transaction } from '@tiptap/pm/state';
import { ReplaceAroundStep } from '@tiptap/pm/transform';
import memoizeOne from 'memoize-one';

import { ALIGNMENT_SUPPORTED_NODE_TYPES, TiptapAlignments } from '../tiptapTypes';
import { hasDocChanges, isUndoRedo } from '../utils/transactionUtils';
import { EMPTY_NODE_SIZE } from '../utils/isTiptapEditorEmpty';

const TEXT_ALIGN_SENTINEL_NAME = 'textAlignSentinel';

/**
 * Determines the node types that are allowed to have the textAlign attribute on them.
 */
const getSupportedNodeTypeNames = memoizeOne((editor: Editor): string[] => {
    if (!editor) return [];

    const { schema } = editor;

    const supportedTypes: string[] = [];

    Object.entries(schema.nodes).forEach(([name, nodeType]) => {
        if (!(nodeType as NodeSpec).attrs?.textAlign) return;
        supportedTypes.push(name);
    });

    return supportedTypes;
});

/**
 * Finds the selected nodes that can be center aligned.
 */
const getAlignableSelectedNodeRanges = (state: EditorState | undefined, supportedTypeNames: string[]): NodeRange[] => {
    if (!state) return [];

    const { from, to } = state.selection;

    const alignableSelectedNodes: NodeRange[] = [];

    state.doc.nodesBetween(from, to, (node, pos) => {
        if (node.isText) return false;

        if (!supportedTypeNames.includes(node.type.name)) return false;

        alignableSelectedNodes.push({
            node,
            from: pos,
            to: pos + node.nodeSize,
        });

        // Only want to check top level nodes, so return false to prevent recursion.
        return false;
    });

    return alignableSelectedNodes;
};

/**
 * Finds the selected nodes that can be center aligned.
 */
const getAlignableSelectedNodes = (editor: Editor): Node[] => {
    const supportedTypeNames = getSupportedNodeTypeNames(editor);
    return getAlignableSelectedNodeRanges(editor.state, supportedTypeNames).map((nodeRange) => nodeRange.node);
};

/**
 * We need to filter out nodes that shouldn't be centred, such as lists, when
 * determining if the text is centred.
 */
export const isCenteredTextAlignActive = (editor?: Editor | null) => {
    if (!editor) return false;

    const alignableSelectedNodes = getAlignableSelectedNodes(editor);

    return (
        !!alignableSelectedNodes.length &&
        alignableSelectedNodes.every((node) =>
            objectIncludes(node.attrs, { textAlign: TiptapAlignments.center }, { strict: false }),
        )
    );
};

/**
 * This function gets executed after any transaction is applied to the document.
 *
 * It is responsible for maintaining the text-align attribute when switching between block types, or
 * removing it when switching to a block type that doesn't support it.
 *
 * It does so by examining the steps in the transaction, and looking for ReplaceAroundSteps, then
 * adding a step to add or remove the text-align attribute as necessary.
 */
const appendTransaction =
    (supportedTypeNames: string[]) =>
    (transactions: readonly Transaction[], oldState: EditorState, newState: EditorState) => {
        if (isUndoRedo(transactions)) return null;
        if (!hasDocChanges(transactions, oldState, newState)) return null;

        const hasTextAlignSentinel = transactions.some((transaction) => transaction.getMeta(TEXT_ALIGN_SENTINEL_NAME));

        // If the text align sentinel has been added to a transaction then it's intentionally being aligned
        // so we don't need to adjust anything
        if (hasTextAlignSentinel) return null;

        const transform = combineTransactionSteps(oldState.doc, [...transactions]);

        // Check ReplaceAroundSteps where the text-align attribute needs to be maintained
        const stepsToCheck = transform.steps
            .map((step, index) => {
                if (!(step instanceof ReplaceAroundStep)) return null;

                const prevDoc = transform.docs[index] || oldState.doc;
                const replaceAroundStep = step as ReplaceAroundStep;

                const newNode = replaceAroundStep.slice.content.firstChild;

                // This shouldn't be necessary, but is a safeguard in case it's somehow out of bounds
                if (replaceAroundStep.from > prevDoc.nodeSize - EMPTY_NODE_SIZE) return null;

                const oldNode = prevDoc.nodeAt(replaceAroundStep.from);

                return {
                    replaceAroundStep,
                    oldNode,
                    newNode,
                };
            })
            .filter((stepDetails) => {
                if (!stepDetails) return false;

                const { oldNode, newNode } = stepDetails;

                if (!oldNode || !newNode) return false;

                // If the text align matches what it used to be, then we don't need to sync anything
                if (oldNode.attrs.textAlign === newNode.attrs.textAlign) return false;

                // If the old node doesn't support text align, then we don't need to do anything
                const oldNodeSupportsTextAlign = supportedTypeNames.includes(oldNode.type.name);
                if (!oldNodeSupportsTextAlign) return false;

                return true;
            }) as { replaceAroundStep: ReplaceAroundStep; oldNode: Node; newNode: Node }[];

        // Determine at the start if any steps are changing to a node that doesn't support text-align
        // If so, we can skip any intermediate steps that might switch between
        // This was due to a bug where the mapping to keep the text-align attribute was throwing
        //  and error after a blockquote was added
        const noLongerAllowsTextAlign = stepsToCheck.some(
            ({ newNode }) => !supportedTypeNames.includes(newNode.type.name),
        );

        const stepsToCorrect = stepsToCheck.filter((entry) => {
            const { newNode } = entry;

            const newNodeSupportsTextAlign = supportedTypeNames.includes(newNode.type.name);

            if (!newNodeSupportsTextAlign) return true;

            // Prevent an error when changing from small text to a blockquote
            if (noLongerAllowsTextAlign) return false;

            return true;
        });

        if (stepsToCorrect.length === 0) return null;

        let updatedTransaction: Transaction = newState.tr;

        for (const entry of stepsToCorrect) {
            const { replaceAroundStep, oldNode, newNode } = entry;

            const newNodeSupportsTextAlign = supportedTypeNames.includes(newNode.type.name);

            // If the new node supports text align (and the attributes don't match),
            // then we need to copy the text align attribute across to the new one
            if (newNodeSupportsTextAlign) {
                const mappedFrom = updatedTransaction.mapping.map(replaceAroundStep.from);

                updatedTransaction = updatedTransaction.setNodeMarkup(mappedFrom, undefined, {
                    ...newNode.attrs,
                    textAlign: oldNode.attrs.textAlign,
                });

                continue;
            }

            // If the new node doesn't support text align, then we need to remove the text align attribute

            // We need to do a search in the _new_ document, thus we need to map the positions
            // We also need to make sure we don't go out of bounds
            const mapping = replaceAroundStep.getMap();
            const mappedFrom = Math.min(mapping.map(replaceAroundStep.from), updatedTransaction.doc.nodeSize - 2);
            const mappedTo = Math.min(mapping.map(replaceAroundStep.to), updatedTransaction.doc.nodeSize - 2);

            updatedTransaction.doc.nodesBetween(mappedFrom, mappedTo, (node, pos) => {
                if (node.isText) return false;

                // Continue traversing until we find a node that supports text align
                if (!supportedTypeNames.includes(node.type.name)) return true;

                // Remove the text align attribute as it now exists within a node that doesn't support it
                updatedTransaction = updatedTransaction.setNodeMarkup(pos, undefined, {
                    ...node.attrs,
                    textAlign: undefined,
                });

                return false;
            });
        }

        return updatedTransaction;
    };

export const TextAlign = DefaultTextAlign.extend({
    addOptions() {
        return {
            defaultAlignment: TiptapAlignments.left,
            ...this.parent?.(),
            alignments: [TiptapAlignments.left, TiptapAlignments.center],
            types: ALIGNMENT_SUPPORTED_NODE_TYPES,
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            unsetTextAlign:
                () =>
                ({ editor, chain }) => {
                    const supportedTypeNames = getSupportedNodeTypeNames(editor);

                    return chain()
                        .forEach(supportedTypeNames, (type, { commands }) => {
                            // Adds a sentinel to denote that the text-align attribute was set intentionally
                            commands.setMeta(TEXT_ALIGN_SENTINEL_NAME, true);
                            return commands.resetAttributes(type, 'textAlign');
                        })
                        .run();
                },
            setTextAlign:
                (alignment) =>
                ({ editor, tr, dispatch, state }) => {
                    if (!this.options.alignments.includes(alignment)) return false;

                    const supportedTypeNames = getSupportedNodeTypeNames(editor);
                    const alignableSelectedNodeRanges = getAlignableSelectedNodeRanges(state, supportedTypeNames);

                    if (!alignableSelectedNodeRanges.length) return false;

                    // The command is being run as a "can" check, and there are alignable nodes, so return true
                    if (!dispatch) return true;

                    alignableSelectedNodeRanges.forEach((nodeRange) => {
                        tr.setNodeMarkup(nodeRange.from, undefined, {
                            ...nodeRange.node.attrs,
                            textAlign: alignment,
                        })
                            // Adds a sentinel to denote that the text-align attribute was set intentionally
                            .setMeta(TEXT_ALIGN_SENTINEL_NAME, true);
                    });

                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-\\': () => {
                return isCenteredTextAlignActive(this.editor)
                    ? this.editor.commands.unsetTextAlign()
                    : this.editor.commands.setTextAlign(TiptapAlignments.center);
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },

    addProseMirrorPlugins() {
        const supportedTypeNames = getSupportedNodeTypeNames(this.editor);

        return [
            new Plugin({
                key: new PluginKey('removeTextAlign'),
                // Ensure that the text-align attribute is maintained when switching between block types
                // that support it (e.g. paragraph -> heading), and removed when switching to a block type
                // that doesn't (e.g. paragraph -> bullet_list).
                appendTransaction: appendTransaction(supportedTypeNames),
            }),
        ];
    },
});
