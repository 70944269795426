import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { updateElement } from '../../../../elements/elementActions';
import { ElementEditor } from '../../../../elements/elementEditorConstants';
import { ImMNElement } from '../../../../elements/elementModelTypes';
import { getElementId, getIsTiptapConversionRecentlyPostponed } from '../../../../elements/utils/elementPropertyUtils';
import { getEditorType } from '../../../utils/editorTypeUtils';
import { selectIsTiptapConversionEnabledForCurrentUser } from '../elementConversionSelector';
import { ImMap } from '../../../../utils/immutableHelper';
import { TiptapContent } from '../../../tiptapTypes';
import { RawDraftContentState } from 'draft-js';
import { SERVER_TYPE } from '../../../../../../config/configConstants';

import './DraftJsConversionIndicator.scss';

type DraftJsConversionIndicatorProps = {
    element: ImMNElement;
    textContent:
        | ImMap<TiptapContent>
        | ImMap<RawDraftContentState>
        | TiptapContent
        | RawDraftContentState
        | string
        | null;
};

export const DraftJsConversionIndicator = ({ element, textContent }: DraftJsConversionIndicatorProps) => {
    // NOTE - Avoiding a /client dependency by accessing __clientconf directly
    //  This is a temporary component so I won't worry about a more robust solution
    if (global.__clientconf?.serverType === SERVER_TYPE.production) return null;

    const elementId = getElementId(element);
    const editorType = getEditorType(textContent);
    const recentlyPostponed = getIsTiptapConversionRecentlyPostponed(element);

    const dispatch = useDispatch();
    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            const tiptapConversionPostponeTime = recentlyPostponed ? 0 : Date.now();

            dispatch(
                updateElement({
                    id: elementId,
                    changes: { tiptapConversionPostponeTime },
                } as any), // this is correct but updateElement's inferred types from JS don't really match up
            );
            return false;
        },
        [dispatch, elementId, editorType, recentlyPostponed],
    );

    const allowConversion = useSelector(selectIsTiptapConversionEnabledForCurrentUser);
    if (!allowConversion) return null;

    if (editorType !== ElementEditor.DraftJs) return null;

    const title = recentlyPostponed ? 'Draft JS editor & content' : 'Tiptap editor, Draft JS content';

    return (
        <div
            className={classNames('DraftJsConversionIndicator', { Unpostpone: recentlyPostponed })}
            onClick={onClick}
            title={title}
        />
    );
};
