import { EditorState } from '@tiptap/pm/state';
import { ElementType } from '../../../elements/elementTypes';
import { UniversalCardConverterCommand } from './universalCardTypes';

import { isValidColorFormat } from '../../../colors/colorSpaceUtil';
import { parseColorObject } from '../../../colors/colorObjectUtil';
import { getColorName } from '../../../colors/colorNameUtil';
import convertSimpleStringToTiptapContent from '../../utils/createJsonContentUtils/convertSimpleStringToTiptapContent';

/**
 * Determines if the current editor text is only colour text.
 */
export const isOnlyColorText = (state: EditorState): boolean => {
    if (state.doc.content.childCount !== 1) return false;
    return !!isValidColorFormat(state.doc.textContent);
};

export const attemptColorSwatchConversion: UniversalCardConverterCommand = ({
    state,
    updateCurrentlyEditingElementType,
}) => {
    if (!isOnlyColorText(state)) return null;

    const color = parseColorObject(state.doc.textContent);
    if (!color) return null;

    const colorName = getColorName(color) || '';
    const tiptapCaption = convertSimpleStringToTiptapContent(colorName);

    // TODO Ensure the editor component doesn't force an update event now that we're
    //  changing the component type

    return updateCurrentlyEditingElementType({
        elementType: ElementType.COLOR_SWATCH_TYPE,
        data: {
            originalText: state.doc.textContent,
            color,
            caption: tiptapCaption,
        },
    });
};
