/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

import { get } from 'lodash/fp';
import { now, noLonger } from '../../../utils/react/propsComparisons';

const nowEditing = now('isEditing');
const noLongerEditing = noLonger('isEditing');

export default (DecoratedComponent) => {
    class editorEditingHeightObserver extends React.Component {
        componentDidMount() {
            if (this.props.isEditing) this.attachResizeObserver();
        }

        componentWillReceiveProps(nextProps) {
            if (!this.resizeObserverAttached && nowEditing(this.props, nextProps)) {
                this.attachResizeObserver();
            }

            if (this.resizeObserverAttached && noLongerEditing(this.props, nextProps)) {
                this.removeResizeObserver();
            }
        }

        componentWillUnmount() {
            this.removeResizeObserver();
        }

        attachResizeObserver() {
            this.resizeObserver = new ResizeObserver(this.onEditorResize);

            // TODO-REMOVE-DRAFT getTextDomElement is draft-specific
            this.editorContentsElement = this.editorElement.getTextDomElement?.() || this.editorElement;

            this.resizeObserver.observe(this.editorContentsElement);

            this.resizeObserverAttached = true;
        }

        removeResizeObserver() {
            if (!this.resizeObserverAttached || !this.resizeObserver) return;

            this.resizeObserver.disconnect(this.editorContentsElement);
            this.resizeObserverAttached = false;
        }

        // Note: This stack overflow thread recommends using a requestAnimationFrame to avoid
        //      the loop limit exceeded exception, however I won't change it for now as they
        //      also say the error can be safely ignored, and I don't want to introduce a regression
        //      https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        onEditorResize = (entries) => {
            const { onHeightChange, isResizing } = this.props;

            // Don't do anything
            if (isResizing) return;

            const newEditorHeight = get([0, 'contentRect', 'height'], entries);
            onHeightChange && onHeightChange(newEditorHeight);
        };

        editorRef = (c) => {
            const { editorRef } = this.props;
            this.editorElement = c;
            editorRef && editorRef(c);
        };

        render() {
            return <DecoratedComponent {...this.props} editorRef={this.editorRef} />;
        }
    }

    editorEditingHeightObserver.propTypes = {
        editorRef: PropTypes.func,
        onHeightChange: PropTypes.func,

        isEditing: PropTypes.bool,
        isResizing: PropTypes.bool,
    };

    return editorEditingHeightObserver;
};
