// Lib
import { isString } from 'lodash/fp';

// Utils
import { prop } from '../../utils/immutableHelper';
import {
    isLocationCanvas,
    isLocationInbox,
    isLocationRootBoard,
    isLocationTrash,
    removeTrashSuffix,
} from './elementLocationUtils';

// Property accessors
import {
    getElementId,
    getLocationParentId,
    getAcl,
    getElementLocation,
    getRootBoardProperty,
} from './elementPropertyUtils';

import { isBoard, isNavigableElement, isTaskList } from './elementTypeUtils';
import { ELEMENT_ID_LENGTH } from '../../uid/uidConstants';

// Basic traversal
// As this is potentially "hot" code, rewriting to attempt to boost performance
export const getElement = (elements, elementId) => (elements.get ? elements.get(elementId) : elements[elementId]);
export const getParent = (elements, elementData) => {
    const element = isString(elementData) ? getElement(elements, elementData) : elementData;
    return prop(getLocationParentId(element), elements);
};

// Ancestors
export const getNthPhysicalAncestor = (n) =>
    function getNthAncestorRecursive(elements, startingElementId, currentDepth = 0) {
        const element = getElement(elements, startingElementId);
        if (!element) return null;

        if (currentDepth === n) return element;

        if (isLocationRootBoard(element)) return null;

        return getNthAncestorRecursive(elements, getLocationParentId(element), currentDepth + 1);
    };

export const getGrandparent = getNthPhysicalAncestor(2);

// Finds the first ancestor or itself that matches a predicateFn.
export const searchPhysicalUp = (predicateFn) => (elements, elementId) => {
    // Iterate up the ancestor tree until we find an element which matches the predicate
    const visitedElements = {};
    let element = prop(elementId, elements);

    while (element && getLocationParentId(element) && !predicateFn(element, elements, elementId)) {
        element = getParent(elements, element);

        // prevent infinite loops — bail from loop if element has been visited
        if (visitedElements[getElementId(element)]) break;
        visitedElements[getElementId(element)] = true;
    }

    return predicateFn(element, elements, elementId) ? getElementId(element) : null;
};

/**
 * Finds the closest ancestor element (including itself) which is 'shared' (has an acl).
 * This will be the ID of the channel to send actions to.
 *
 * @return {String} The ID of the sharing context (the element ID of the closest shared ancestor).
 */
export const getSharingContext = searchPhysicalUp((element) => getAcl(element));

// Finds the closest ancestor of a specific type.
export const getClosestAncestor = (predicateFn) =>
    searchPhysicalUp(
        (element, elements, startingElementId) =>
            predicateFn(element, elements, startingElementId) && getElementId(element) !== startingElementId,
    );

export const isElementOrAncestorInTrash = (elements, elementId) =>
    !!searchPhysicalUp(isLocationTrash)(elements, elementId);

// Finds the closest ancestor that's a board.
export const getClosestAncestorBoardId = getClosestAncestor(isBoard);
// Finds the closest element up (including self) that's a board.
export const getClosestUpBoardId = searchPhysicalUp(isBoard);
export const getClosestUpNavigableElementId = searchPhysicalUp(isNavigableElement);
export const getClosestUpTaskListId = searchPhysicalUp(isTaskList);

/**
 * Finds the element (this one or an ancestor) that's the direct child of a board.
 * E.g. If it's an element within a column this will return the column.
 */
export const getClosestUpBoardChildId = searchPhysicalUp((element, elements) => {
    const parentId = getLocationParentId(element);
    const parent = getElement(elements, parentId);
    return isBoard(parent);
});

/**
 * Gets the element ID that exists on the canvas in this element's hierarchy, including this element.
 * If the element is in the board's unsorted notes then no element will be returned.
 */
export const getAncestorCanvasElement = (elements, elementId) => {
    let element = getElement(elements, elementId);

    const visitedElements = {};

    while (element) {
        const currentElementId = getElementId(element);

        // If the first element we check is a board, then we want to check if it or its parent is on the canvas.
        // Otherwise, if the ancestor of the element is a board and we haven't yet found an ancestor on the canvas,
        //  the original element is not within this board's canvas
        if (currentElementId !== elementId && isBoard(element)) return null;

        if (isLocationCanvas(element)) return element;

        const parentId = getLocationParentId(element);
        element = getElement(elements, parentId);

        // prevent infinite loops — bail from loop if element has been visited
        if (visitedElements[getElementId(element)]) {
            console.warn(`Infinite loop in getAncestorCanvasElement for: ${elementId}`);
            break;
        }

        visitedElements[getElementId(element)] = true;
    }

    return null;
};

export const isWithinParentBoardInbox = (elements, elementId) => {
    let element = getElement(elements, elementId);

    if (!element) return false;

    let parentId = getLocationParentId(element);
    let parent = getElement(elements, parentId);

    const visitedElements = {};

    while (parent && !isBoard(parent)) {
        element = parent;
        parentId = getLocationParentId(element);
        parent = getElement(elements, parentId);

        // prevent infinite loops — bail from loop if element has been visited
        if (visitedElements[getElementId(element)]) {
            console.warn(`Infinite loop in isWithinParentBoardInbox for: ${elementId}`);
            break;
        }

        visitedElements[getElementId(element)] = true;
    }

    return !isLocationRootBoard(element) && isLocationInbox(element);
};

// Get all physical ancestors
export const getPhysicalAncestors = (elements, elementId) => {
    const ancestors = [];
    let currentElement = prop(elementId, elements);

    const visitedElements = {
        [elementId]: true,
    };

    while (currentElement) {
        ancestors.push(currentElement);
        let currentElementId = getLocationParentId(currentElement);

        currentElementId =
            currentElementId && currentElementId.length > ELEMENT_ID_LENGTH + 1
                ? removeTrashSuffix(currentElementId)
                : currentElementId;

        // prevent infinite loops
        if (visitedElements[currentElementId]) {
            console.warn(`Infinite loop in getPhysicalAncestors for: ${elementId}`);
            break;
        }

        visitedElements[currentElementId] = true;

        currentElement = getElement(elements, currentElementId);
    }

    return ancestors;
};

/**
 * Gets the actual ancestors in the element's "physical" location.
 * This will only iterate over the 'parentIds' of each ancestor and will not consider aliases.
 */
export const getPhysicalAncestorIds = (elements, elementId) =>
    getPhysicalAncestors(elements, elementId).map(getElementId);

/**
 * Returns true if an elements entire ancestor tree has been retrieved.
 */
export const hasRetrievedAllAncestors = (elements, elementId) => {
    if (!elements) return false;

    let element = getElement(elements, elementId);

    if (!element) return false;

    const visitedAncestors = {};
    let hasRetrievedAncestors = true;
    let prevElement = element;

    while (element && hasRetrievedAncestors) {
        hasRetrievedAncestors = hasRetrievedAncestors && !!getElementLocation(element);
        prevElement = element;
        const parentId = getLocationParentId(element);
        element = getElement(elements, parentId);

        if (visitedAncestors[parentId]) {
            console.warn(`Infinite loop in hasRetrievedAllAncestors for: ${parentId}`);
            break;
        }
        visitedAncestors[parentId] = true;
    }

    const lastElementIsRoot = isLocationRootBoard(prevElement);
    return lastElementIsRoot && hasRetrievedAncestors;
};

// Simple depth calculation (more complex should use the graph utils)
export const getDepth = (elements, fromId, toId) => {
    const visitedElements = {};
    let count = 0;
    // Iterate up the ancestor tree until we find an element which matches the predicate
    let elementId = toId;
    let element = prop(elementId, elements);
    while (element && getLocationParentId(element) && elementId !== fromId) {
        element = getParent(elements, element);
        elementId = getElementId(element);
        count++;

        if (visitedElements[elementId]) break;
        visitedElements[elementId] = true;
    }

    if (elementId !== fromId) return -1;

    return count;
};

// Retrieves the children via an elements array or Immutable map.
// Try to use the getChildrenViaMap if possible instead
export const getChildren = (elements, parentId) =>
    elements.filter((el) => getElementId(el) && getLocationParentId(el) === parentId);

const isRootOrNullOrTrashed = (element) => {
    if (!element) return true;
    if (isLocationTrash(element)) return true;
    return getRootBoardProperty(element);
};
const findRootParentOrNullOrTrash = searchPhysicalUp(isRootOrNullOrTrashed);

export const isOrphaned = (elements, elementId) => {
    const root = findRootParentOrNullOrTrash(elements, elementId);
    return !root;
};

const parentIsMissing = (element, elements) => {
    const parentId = getLocationParentId(element);
    return !getElement(elements, parentId);
};
const findElementWithMissingParent = searchPhysicalUp(parentIsMissing);
/**
 * Finds the element ID for the ancestor that's missing.
 */
export const findMissingAncestorId = (elements, elementId) => {
    const elementWithMissingParent = findElementWithMissingParent(elements, elementId);
    return getLocationParentId(getElement(elements, elementWithMissingParent));
};
