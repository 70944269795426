import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getSelectedTaskIdSelector } from '../../../selector/selectionToolsSelector';
import useSheet from '../../../../../mobile/structural/sheet/hooks/useSheet';

// Components
import Icon from '../../../../../components/icons/Icon';
import ToolbarTool from '../../ToolbarTool';
import MobileReactionSheet from '../../../../../components/emoji/popup/MobileReactionSheet';

// Types
import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

type MobileReactionToolProps = {
    selectedElements: Immutable.List<ImMNElement>;
    name: string;
    gridSize: number;
};

const MobileReactionTool = (props: MobileReactionToolProps): ReactElement => {
    const { selectedElements, name, gridSize } = props;

    const sheetProps = useSheet(SheetId.MobileReactionTool, {
        scrollToElementOnOpen: true,
    });
    const { isSheetOpen, dispatchOpenSheet, dispatchCloseSheet } = sheetProps;

    const elementIds = selectedElements.map(getElementId).toArray();
    const selectedTaskListId = useSelector(getSelectedTaskIdSelector);
    const focusElementIds = selectedTaskListId ? [selectedTaskListId] : elementIds;

    return (
        <>
            <ToolbarTool name={name} isOpen={isSheetOpen} onClick={dispatchOpenSheet}>
                <Icon gridSize={gridSize} name={`toolbar-reaction`} />
            </ToolbarTool>
            <MobileReactionSheet
                {...props}
                sheetProps={sheetProps}
                closePopup={dispatchCloseSheet}
                elementIds={focusElementIds}
            />
        </>
    );
};

export default MobileReactionTool;
