import { EditorState, Plugin, PluginKey, Selection, TextSelection, Transaction } from '@tiptap/pm/state';

import { getMarkRange } from '../../utils/getMarkRange';
import { hasDocChanges, isUndoRedo } from '../../utils/transactionUtils';
import { getContainingMention } from './mentionUtils';
import { ResolvedPos } from '@tiptap/pm/model';

/**
 * This function handles the general algorithm for skipping the cursor across mentions,
 * in both key handlers and appendTransaction.
 */
export const getCursorSkipSelection = (
    currentState: EditorState,
    $nextPos: ResolvedPos,
    isSelecting: boolean,
): Selection | null => {
    // we only care about the cursor going from outside a mention to inside one
    // (if it was already inside one, we allow keyboard navigation within it)
    if (getContainingMention(currentState.selection.$head)) return null;

    const mentionToSkip = getContainingMention($nextPos);
    if (!mentionToSkip) return null;

    // If we're modifying a selection within a mention, we don't need to do anything.
    const isJustMovingCursor = isSelecting && currentState.selection.empty;
    const isSelectingWithinMention = getContainingMention(currentState.selection.$anchor) === mentionToSkip;
    if (isSelectingWithinMention && !isJustMovingCursor) return null;

    // Skip to the other side of the mention.
    const direction = $nextPos.pos - currentState.selection.$head.pos;

    const markType = currentState.schema.marks.textMention;
    const markInfo = getMarkRange($nextPos, markType);
    const { from, to } = markInfo!;

    const $newHead = currentState.doc.resolve(direction > 0 ? to : from);
    const $newAnchor = !isSelecting ? $newHead : currentState.selection.$anchor;

    return TextSelection.between($newAnchor, $newHead);
};

let isAnyKeyPressed = false;

export const createCursorSkipPlugin = () =>
    new Plugin({
        key: new PluginKey('cursorSkip'),
        props: {
            handleDOMEvents: {
                keyup(view, event) {
                    isAnyKeyPressed = false;
                },
                keydown(view, event) {
                    isAnyKeyPressed = true;
                },
            },
        },
        appendTransaction: (transactions: readonly Transaction[], oldState: EditorState, newState: EditorState) => {
            // We only want to respond to keypress events! not onscreen keyboard, not mouse, just keys.
            // The transaction runs in between keyDown and keyUp events, so tracking that is enough
            if (!isAnyKeyPressed) return null;

            if (isUndoRedo(transactions)) return null;
            if (hasDocChanges(transactions, oldState, newState)) return null;

            const selectionChanged = transactions.some((transaction) => transaction.selectionSet);
            if (!selectionChanged) return null;

            const cursorSkipSelection = getCursorSkipSelection(
                oldState,
                newState.selection.$head,
                !newState.selection.empty,
            );

            if (!cursorSkipSelection) return null;

            return newState.tr.setSelection(cursorSkipSelection);
        },
    });
