// Lib
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { defer } from 'lodash';

// Components
import DocumentModalHeader from './DocumentModalHeader';
import DocumentModalSidebar from './DocumentModalSidebar';
import DocumentModalTiptapEditor from './DocumentModalTiptapEditor';

// Hooks
import useTipTapDocumentEditingProps from './useTipTapDocumentEditingProps';

// Constants
import { DOCUMENT_MODAL_TITLE_EDITOR_KEY } from './documentModalConstants';

// Types
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './DocumentModalTiptap.scss';

interface DocumentModalTiptapProps {
    elementId: string;
    element: ImMNElement;
    textContent: TiptapContent;

    permissions: number;

    isEditable: boolean;
    spellCheck: boolean;

    isModalView: boolean;
    isPreview: boolean;

    elementInstanceModalRef: (elem: HTMLDivElement | null) => void;
}

const DocumentModalTiptap = (props: DocumentModalTiptapProps): ReactElement => {
    const { element, elementId, isPreview, isEditable, elementInstanceModalRef } = props;

    const tipTapDocumentEditingProps = useTipTapDocumentEditingProps(elementId, element);
    const { editorKey, isEditing, currentEditorKey, dispatchStartEditing } = tipTapDocumentEditingProps;

    const classes = classNames('DocumentModal DocumentModalTiptap', { preview: isPreview, 'read-only': !isEditable });

    // Delay the start editing action - to allow the title editing to end before switching to
    // the main editor, otherwise the finish editing action would apply to the main content too
    const delayedStartEditingMainContent = () => {
        defer(dispatchStartEditing);
    };

    return (
        <div className={classes} ref={elementInstanceModalRef}>
            {!isPreview && (
                <DocumentModalHeader
                    element={element}
                    elementId={elementId}
                    editorKey={DOCUMENT_MODAL_TITLE_EDITOR_KEY}
                    isEditable={isEditable}
                    onStopEditing={delayedStartEditingMainContent}
                />
            )}
            <div className="document-modal-body">
                {!isPreview && (
                    <DocumentModalSidebar
                        elementId={elementId}
                        element={element}
                        isEditable={isEditable}
                        isEditingDocument={isEditing && currentEditorKey === editorKey}
                        dispatchStartEditing={dispatchStartEditing}
                        permissions={props.permissions}
                    />
                )}
                <div id="document-modal-content" className="content">
                    <DocumentModalTiptapEditor
                        startEditingOnRender
                        element={element}
                        elementId={elementId}
                        textContent={props.textContent}
                        isEditable={isEditable}
                        {...tipTapDocumentEditingProps}
                    />
                </div>
                <div id="document-modal-side" />
            </div>
        </div>
    );
};

export default DocumentModalTiptap;
