import { HYBRID_ACTION_TYPES, HybridLocationChangeImageAction } from '../../hybridMiddlewareConstants';
import { AnyAction } from 'redux';
import { BoardSections } from '../../../../../../common/boards/boardConstants';
import { IdGraph } from '../../../../../../common/dataStructures/graphTypes';
import { ImMNElement, MNElement } from '../../../../../../common/elements/elementModelTypes';
import { asObject } from '../../../../../../common/utils/immutableHelper';
import { elementGraphSelector } from '../../../../../element/selectors/elementGraphSelector';
import { getElementId } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getElements } from '../../../../../element/selectors/elementsSelector';
import { getCurrentVisibleBoardId } from '../../../../../reducers/currentBoardId/currentBoardIdSelector';
import {
    getCanvasElements,
    getSortedChildrenInSectionViaGraph,
} from '../../../../../../common/elements/utils/elementGraphUtils';
import { isColumn, isImage } from '../../../../../../common/elements/utils/elementTypeUtils';
import { sortByScoreProperty } from '../../../../../../common/elements/utils/elementSortUtils';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../../common/platform/platformTypes';

// Creating the Action for Image Elements

const shouldCreateHybridActionForImage = () => {
    switch (platformSingleton.legacyHybridUseCase) {
        case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            return true;
        case LegacyHybridUseCase.IPAD_OS:
            return false;
        default:
            return false;
    }
};

export const createHybridPresentModalImageAction = (
    state: any,
    originalAction: AnyAction,
    imageElement: MNElement,
): HybridLocationChangeImageAction | undefined => {
    if (!shouldCreateHybridActionForImage()) return;

    const elements: Immutable.Map<string, ImMNElement> = getElements(state);
    const elementGraph: IdGraph = elementGraphSelector(state) as any;
    const currentBoardId: string = getCurrentVisibleBoardId(state);

    const canvasElements: ImMNElement[] = getCanvasElements({
        elements,
        elementGraph,
        parentId: currentBoardId,
    }).toArray();

    const inboxElements: ImMNElement[] = getSortedChildrenInSectionViaGraph({
        elements,
        elementGraph,
        parentId: currentBoardId,
        section: BoardSections.INBOX,
        sortFn: sortByScoreProperty,
    }).toArray();

    let allElements = canvasElements.concat(inboxElements);

    const columnChildren: Array<[string, ImMNElement[]]> = allElements.filter(isColumn).map((column) => [
        getElementId(column),
        getSortedChildrenInSectionViaGraph({
            elements,
            elementGraph,
            parentId: getElementId(column),
            section: BoardSections.INBOX,
            sortFn: sortByScoreProperty,
        }).toArray(),
    ]);
    columnChildren.forEach(([columnId, children]) => {
        allElements = allElements.splice(
            allElements.findIndex((element) => getElementId(element) === columnId),
            0,
            ...children,
        );
    });

    const canvasImages = allElements.filter(isImage).map((el) => asObject(el) as MNElement);

    return {
        ...originalAction,
        imageElement: imageElement,
        currentBoardImageElements: canvasImages,
        type: HYBRID_ACTION_TYPES.PRESENT_MODAL_IMAGE,
    };
};
