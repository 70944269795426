// Lib
import React, { ReactElement, useEffect, useState } from 'react';
import VersionBlockingFullScreenPrompt from './VersionBlockingFullScreenPrompt';
import { checkVersionBlockedStatus } from '../../utils/versionService';
import { getApplicationTypeSelector, selectAppVersion } from '../platformSelector';
import { useSelector } from 'react-redux';

const VersionBlockingManager = (): ReactElement | null => {
    const appType = useSelector(getApplicationTypeSelector);
    const appVersion = useSelector(selectAppVersion);

    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await checkVersionBlockedStatus(appVersion, appType);
            setIsBlocked(response);
        })();
    }, [appType, appVersion]);

    if (!isBlocked) return null;

    return <VersionBlockingFullScreenPrompt appType={appType} />;
};

export default VersionBlockingManager;
