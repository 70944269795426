export const DATA_TRANSFER_ITEM_KINDS = {
    FILE: 'file',
};

/**
 * @deprecated - Use mimeConstants - DataTransferTypes instead
 */
export const DATA_TRANSFER_TYPES = {
    PLAIN_TEXT: 'text/plain',
    RICH_TEXT: 'text/rtf',
    HTML: 'text/html',
    FILES: 'Files',
    ELEMENTS: 'application/milanote-elements',
    URI_LIST: 'text/uri-list',
    JSON: 'application/json',
};

export const ELEMENT_CLIPBOARD_UNDO = 'ELEMENT_CLIPBOARD_UNDO';
export const ELEMENT_CLIPBOARD_SAVE = 'ELEMENT_CLIPBOARD_SAVE';
export const ELEMENT_CLIPBOARD_PASTE = 'ELEMENT_CLIPBOARD_PASTE';
export const PASTE_OFFSET_Y = 2;
export const PASTE_OFFSET_X = 2;

export enum ClipboardOperation {
    cut = 'cut',
    copy = 'copy',
}
