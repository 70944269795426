// Lib
import { createSelector } from 'reselect';
import { uniq } from 'lodash/fp';

// Utils
import { getFirstSelectedBackgroundColor, getFirstSelectedColor, getSelectedElementType } from './colorToolUtils';
import { getColorDefaultHexValue } from '../../../../../../common/colors/elementColorFormatUtil';
import { getColorCssValue } from '../../../../../element/utils/elementColorStyleUtils';
import { isCard, isColumn, isSketch } from '../../../../../../common/elements/utils/elementTypeUtils';
import { getElementType } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { asObject } from '../../../../../../common/utils/immutableHelper';
import { getSelectedElements } from '../../../../../element/selection/selectedElementsSelector';

// Constants
import { DEFAULT_ELEMENT_COLOR } from '../../../../../../common/colors/colorConstants';
import { getBackgroundPredefinedColorMapForType } from '../../../../../../common/colors/colorPresetsUtil';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { getMilanoteApplicationModeSelector } from '../../../../../platform/platformSelector';
import { MilanoteApplicationMode } from '../../../../../../common/platform/platformTypes';

export type ColorToolSelectorReturnType = {
    name: string;
    elementType: string;
    supportBackgroundColor: boolean;
    selectedColor: string | null;
    selectedColorHex: string;
    selectedBackgroundColorName: string;
    selectedBackgroundColor: string | null;
    selectedBackgroundColorHex: string;
};

export const colorToolSelector = createSelector(
    (state: Immutable.Map<string, object>, ownProps) => getSelectedElements(state),
    (state: Immutable.Map<string, object>, ownProps: { name: string }) => ownProps.name,
    (state: Immutable.Map<string, object>, ownProps) => getMilanoteApplicationModeSelector(state),
    (selectedElements, name, appMode): ColorToolSelectorReturnType => {
        const selectedColorName = getFirstSelectedColor(selectedElements);
        const selectedColor = getColorCssValue(selectedColorName);
        const selectedColorHex = getColorDefaultHexValue(selectedColorName) || DEFAULT_ELEMENT_COLOR;

        const selectedElementType = getSelectedElementType(selectedElements);
        const backgroundPredefinedColorMap = getBackgroundPredefinedColorMapForType(selectedElementType);

        const selectedBackgroundColorName = getFirstSelectedBackgroundColor(selectedElements);
        const selectedBackgroundColor = getColorCssValue(selectedBackgroundColorName, backgroundPredefinedColorMap);
        const selectedBackgroundColorHex =
            getColorDefaultHexValue(selectedBackgroundColorName) || DEFAULT_ELEMENT_COLOR;

        const supportBackgroundColor = selectedElements.every(
            (el: MNElement) => isCard(el) || isSketch(el) || isColumn(el),
        );
        const selectedTypes = uniq(asObject(selectedElements.map(getElementType)));

        return {
            name: selectedTypes.length > 1 || appMode === MilanoteApplicationMode.mobile ? 'Color' : name,
            elementType: getElementType(selectedElements?.first()),
            supportBackgroundColor,
            selectedColor,
            selectedColorHex,
            selectedBackgroundColorName,
            selectedBackgroundColor,
            selectedBackgroundColorHex,
        };
    },
);
