// Lib
import { useMemo } from 'react';
import Document from '@tiptap/extension-document';

// Custom extensions
import { TextAlign } from '../../../../common/tiptap/extensions/TextAlign';
import { InlineStyleClearer } from '../../../../common/tiptap/extensions/InlineStyleClearer';
import useClientCommonTiptapEditorExtensions, {
    UseClientCommonTiptapEditorExtensionsArgs,
} from '../../tiptapEditor/useClientCommonTiptapEditorExtensions';

// Types
import { Extensions } from '@tiptap/core';

type UseCaptionTiptapEditorExtensionsArgs = UseClientCommonTiptapEditorExtensionsArgs & {
    saveCaptionContent: (content: object) => void;
};

/**
 * The extensions required to make the caption Tiptap editors work.
 */
const useCaptionTiptapEditorExtensions = ({
    placeholder,
    onEmptyBackspace,
    saveCaptionContent,
    suggestionsManager,
    isEditable,
    dispatch,
    onHighlightsChanged,
}: UseCaptionTiptapEditorExtensionsArgs): Extensions => {
    const commonExtensions = useClientCommonTiptapEditorExtensions({
        saveContent: saveCaptionContent,
        onEmptyBackspace,
        placeholder,
        suggestionsManager,
        isEditable,
        dispatch,
        onHighlightsChanged,
    });

    return useMemo(
        () => [
            Document,
            // Allows text to be centred
            //  See: https://tiptap.dev/docs/editor/extensions/functionality/textalign
            TextAlign,
            // Clears inline styles when the user presses Enter
            InlineStyleClearer,
            ...commonExtensions,
        ],
        [commonExtensions],
    );
};

export default useCaptionTiptapEditorExtensions;
