// Lib
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defer } from 'lodash';

// Types
import { Editor } from '@tiptap/react';
import { TiptapContent, TiptapEditorContent } from '../tiptapTypes';
import { ImMap, propIn } from '../../utils/immutableHelper';

const selectIsUndoing = propIn(['undoRedo', 'isUndoing']);
const selectIsRedoing = propIn(['undoRedo', 'isRedoing']);

/**
 * When changes to the persisted content occur, this will reflect those
 * changes in the editor, provided the editor isn't currently being edited or it's
 * as a result of an undo/redo.
 *
 * When it's being edited, the internal state of the editor will be used instead.
 */
const useTiptapEditorPersistedContentSync = (
    editor: Editor,
    persistedContent: ImMap<TiptapContent> | TiptapContent | string | null | undefined,
    persistedContentObj: TiptapEditorContent,
    isEditing: boolean,
) => {
    const isUndoingOrRedoing = useSelector((state) => selectIsUndoing(state) || selectIsRedoing(state));

    useEffect(() => {
        // Don't update the editor if it's currently being edited - unless it's due to a redo/undo
        if (isEditing && !isUndoingOrRedoing) return;

        // We need to defer this, in case the persisted content update is a result
        // of another editor update (while the editor isn't being edited), e.g.
        // during a clipper operation.
        // Otherwise, it will result in a mismatched transaction because a new chain will
        // be started while the other one is still in progress.
        defer(() => {
            editor.commands.syncFromExternalStore(persistedContentObj);
        });

        // Only use the persistedContent as a dependency, because the other
        //  properties will only be checked when the persistedContent changes.
    }, [persistedContent]);
};

export default useTiptapEditorPersistedContentSync;
