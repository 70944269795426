// Lib
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

// Utils
import { createTaskJsonContent } from '../utils/createJsonContentUtils/createJsonContent';

// Types
import { TiptapContent, TiptapContentNode, TiptapNodeType } from '../tiptapTypes';
import { DataTransferTypes } from '../../media/mimeConstants';

const createDocFromParagraph = (paragraph: TiptapContentNode): TiptapContent => ({
    type: TiptapNodeType.doc,
    content: [paragraph],
});

interface TaskPasteOptions {
    createMultipleNewTasksWithContent: (jsonContentArray: TiptapContent[]) => void;
}

/**
 * This extension handles pastes within Tasks - ensuring that multiple lines will
 * be added as separate tasks (because a single task is a single Tiptap editor at this time).
 */
export const TaskPaste = Extension.create<TaskPasteOptions>({
    name: 'taskPaste',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('taskPasteHandler'),
                props: {
                    handlePaste: (view, event) => {
                        if (!event.clipboardData) return false;

                        const text = event.clipboardData.getData(DataTransferTypes.PLAIN_TEXT);
                        const html = event.clipboardData.getData(DataTransferTypes.HTML);

                        const pastedTaskContent = createTaskJsonContent(html, text);

                        if (!pastedTaskContent) return false;

                        // If there's only one line of text then we leave it for the default handler
                        if (pastedTaskContent.content.length < 2) return false;

                        // Otherwise, create documents for any paragraphs past the first & then use them
                        // as text content for the new tasks
                        const newTaskDocs = pastedTaskContent.content.slice(1).map(createDocFromParagraph);

                        this.options.createMultipleNewTasksWithContent(newTaskDocs);

                        // NOTE: Returning false allows the default paste handling to happen,
                        //  which is what we want, because we want the first line of the pasted content
                        //  to be added to the currently edited task
                        return false;
                    },
                },
            }),
        ];
    },
});
