// Lib
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Utils
import { getIsLowContrastColorWithInterface } from '../../../../../../../common/colors/colorComparisonUtil';
import { getColorCssValue } from '../../../../../../element/utils/elementColorStyleUtils';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../../../../platform/platformSelector';

// Components
import Button from '../../../../../../components/buttons/Button';
import DualColorIcon from '../DualColorIcon';

// Constants
import { COLOR_POPUP_MODE } from './colorPopupConstants';
import { MilanoteApplicationMode } from '../../../../../../../common/platform/platformTypes';
import { Color } from '../../../../../../../common/colors/colorConstants';

// Styles
import './ColorButton.scss';

type ColorButtonProps = {
    selectedType: string;
    selectedStatus: string | null;
    className?: string;
    colorPopupMode: string;
    color: string | Color;
    secondaryColor?: string;
    isActive: boolean;
    isContextMenu?: boolean;
    isDarkModeTheme: boolean;
    setColor: (color: string | null, secondaryColor?: string | null) => void;
    disabled?: boolean;
    preventUpdateSecondaryColor?: boolean;
};

const ColorButton = ({
    color,
    secondaryColor,
    isActive,
    setColor,
    selectedType,
    selectedStatus,
    isContextMenu,
    className,
    colorPopupMode,
    isDarkModeTheme,
    preventUpdateSecondaryColor,
}: ColorButtonProps) => {
    const applicationMode = useSelector(getMilanoteApplicationModeSelector);

    const isColorObject = color && typeof color === 'object';
    const colorCss = isColorObject ? color.css : getColorCssValue(color);

    const onColorClick = () => {
        if (!setColor) return;

        const colorName = isColorObject ? color.name : color;

        // For scenarios like updating the color bar, ensure the secondary color is not updated
        if (preventUpdateSecondaryColor) {
            setColor(colorName);
            return;
        }

        // Update secondary to the provided secondaryColor or null, undefined will NOT be updated
        setColor(colorName, secondaryColor || null);
    };

    const defaultColorIsLowContrast = applicationMode === MilanoteApplicationMode.mobile ? true : !isDarkModeTheme;

    const isLowContrastColor = colorCss
        ? getIsLowContrastColorWithInterface(colorCss, isDarkModeTheme)
        : defaultColorIsLowContrast;

    const isHighContrastColor = colorCss
        ? getIsLowContrastColorWithInterface(colorCss, !isDarkModeTheme)
        : !defaultColorIsLowContrast;

    const classes = classNames('ColorButton', className, selectedType, selectedStatus, colorPopupMode, {
        active: isActive,
        'no-color': !colorCss,
        'low-contrast-color': isLowContrastColor,
        'high-contrast-color': isHighContrastColor,
        'remove-color': !colorCss && colorPopupMode === COLOR_POPUP_MODE.COLOR_BAR,
    });

    return (
        <Button
            className={classes}
            style={{ '--ui-color-button-background': colorCss } as React.CSSProperties}
            onClickFn={onColorClick}
            onContextMenu={isContextMenu ? onColorClick : undefined}
            onMouseUp={isContextMenu ? onColorClick : undefined}
        >
            {secondaryColor && <DualColorIcon secondaryColor={secondaryColor} selectedType={selectedType} />}
            <div className="selection-border" />
        </Button>
    );
};

export default ColorButton;
