// Lib
import * as jsondiffpatch from 'jsondiffpatch';

const diffPatcher = jsondiffpatch.create({
    objectHash: (obj: any, index: number) =>
        // try to find a property which uniquely identifies this object
        obj.key || obj.attrs?.key || `index: ${index}`,
});

export const getContentDiff = (previousState: object, currentState: object): jsondiffpatch.Delta | undefined =>
    diffPatcher.diff(previousState, currentState);

export const patchContentWithDiff = (previousState: object, newChanges: object): object =>
    diffPatcher.patch(previousState, newChanges);
