// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { pure } from '../../../../node_module_clones/recompose';
import { isString, isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';

// Editor store
import textEditorStoreConnector from '../store/components/textEditorStoreConnector';
import { getIsRedoing, getIsUndoing } from '../../../utils/undoRedo/undoRedoSelector';

// Utils
import logger from '../../../logger/logger';
import rICThrottle from '../../../../common/utils/lib/rICThrottle';
import { asObject, prop } from '../../../../common/utils/immutableHelper';
import { OrderedSet } from 'immutable';
import forceBlur from '../customRichUtils/forceBlur';
import {
    noLongerEditingElement,
    stayedEditingElement,
    textContentHasChanged,
    noLongerEditingThisEditor,
    startedEditingThisElement,
    editorStateHasChanged,
    currentlyEditingThisEditor,
    filterQueryHasChanged,
    nowEditingElement,
} from './editorPropComparisons';
import { isAttemptingMultiSelect } from '../../../element/selection/selectionUtils';
import getDynamicDecoratorSpecs from '../plugins/utils/getDynamicDecoratorSpecs';
import isEmptyEditor from '../customRichUtils/isEmptyEditor';
import getPlaceholderClasses from '../customRichUtils/getPlaceholderClasses';
import { getHasLiveCollaborators } from '../../../remoteActivity/liveCollaboration/liveCollaborationSelector';
import * as typingBuffer from '../../../../common/tiptap/utils/typingBufferSingleton';
import replaceSelectionWithText from '../customRichUtils/replaceSelectionWithText';
import prepareEditorRawContent from './prepareEditorRawContent';
import applyInlineStylesThroughoutContent from '../customRichUtils/applyInlineStylesThroughoutContent';
import getFirstEntityInSelection from '../store/reducers/getFirstEntityInSelection';
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/elementConversion/previewComponents/DraftJsConversionIndicator';
import { getIsTiptapConversionRecentlyPostponed } from '../../../../common/elements/utils/elementPropertyUtils';
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';
import { getIsFeatureEnabledForCurrentUser } from '../../../element/feature/elementFeatureSelector';

// Plugins
import createCompositeDecorator from '../plugins/utils/createCompositeDecorator';
import forceFocus from '../customRichUtils/forceFocus';

// Constants
import { DEFAULT_SAVE_TIMEOUT } from '../textConstants';
import { TIMES } from '../../../../common/utils/timeUtil';
import { EditorChangeType } from '../draftjsConstants';
import { ENTITIES } from '../richText/richTextConstants';

// Styles
import 'draft-js/dist/Draft.css';
import './MilanoteEditor.scss';

/**
 * Due to the way that event dispatching has changed in React 17, we need to manually track mouse up events
 * that occur outside the window to ensure that we can recover from a selection that was started in the editor.
 *
 * We then dispatch an event inside the Milanote root so that the Draft event handlers will pick it up.
 */
const handleMouseupOutsideOfWindow = (e) => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    const isOutOfBounds = Math.min(e.offsetX, e.offsetY) < 0 || e.offsetX > vw || e.offsetY > vh;

    if (!isOutOfBounds) return;

    // Dispatch on the Milanote root element
    const root = document.getElementById('app');

    const clientX = Math.min(vw, Math.max(0, e.clientX));
    const clientY = Math.min(vh, Math.max(0, e.clientY));

    const mouseEvent = new MouseEvent('mouseup', { clientX, clientY });

    root.dispatchEvent(mouseEvent);
};

const mapStateToProps = createStructuredSelector({
    hasLiveCollaborators: getHasLiveCollaborators,
    isUndoingOrRedoing: (state) => getIsUndoing(state) || getIsRedoing(state),
    allowConversion: getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion),
});

@pure
@connect(mapStateToProps)
@textEditorStoreConnector
class MilanoteEditor extends React.Component {
    // Placing these methods first as they're usually called during construction */
    /* eslint-disable react/sort-comp */
    getDecorator = (props) => {
        // FIXME Clean this up
        const dynamicDecorators = getDynamicDecoratorSpecs(props.plugins, props);

        return createCompositeDecorator(props.plugins, dynamicDecorators, {
            getEditorState: this.getEditorState,
            setEditorState: this.setDraftPluginsEditorState,
        });
    };

    buildFromTextContent = (props) => {
        const { textContent } = props;

        if (isString(textContent)) {
            return EditorState.createWithContent(ContentState.createFromText(textContent), this.getDecorator(props));
        }

        const rawData = { entityMap: {}, blocks: [], ...asObject(textContent) };

        if (isEmpty(rawData.blocks)) {
            return EditorState.createEmpty(this.getDecorator(props));
        }

        return EditorState.createWithContent(
            convertFromRaw(prepareEditorRawContent(rawData)),
            this.getDecorator(props),
        );
    };

    buildEditorState = (props) => {
        const { textContent } = props;

        return textContent ? this.buildFromTextContent(props) : EditorState.createEmpty(this.getDecorator(props));
    };

    getEditorState = () => this.state.editorState;

    setDraftPluginsEditorState = (updatedEditorState) =>
        this.editor && this.editor.onChange
            ? this.editor.onChange(updatedEditorState)
            : this.onChange(updatedEditorState);
    /* eslint-enable react/sort-comp */

    constructor(props) {
        super(props);

        const editorState = this.buildEditorState(this.props);

        // These counts are used to determine whether enough changes have been made to force an update to the server
        this.lastSavedContent = editorState.getCurrentContent();
        this.saveOnUnmount = true;

        this.changeCount = 0;

        this.state = {
            editorState,
        };
    }

    componentDidMount() {
        const { editorRef } = this.props;
        if (typeof editorRef === 'function') {
            editorRef(this);
        } else if (editorRef?.hasOwnProperty('current')) {
            editorRef.current = this;
        }

        if (this.props.isEditing && this.editor) {
            const { savedSelection, editorId } = this.props;
            const { editorState } = this.state;

            // If we have a saved selection then force focus onto this instead of the end
            let updatedEditorState = savedSelection
                ? forceFocus(editorState, savedSelection)
                : EditorState.moveFocusToEnd(editorState);

            // Clear the saved selection so it doesn't hang around
            savedSelection && this.props.dispatchSaveEditorSelection({ editorId, selection: null });

            // If there's text in the typing buffer, insert it into the new editor
            if (typingBuffer.hasChars()) {
                updatedEditorState = replaceSelectionWithText(typingBuffer.getChars())(updatedEditorState);
                typingBuffer.clear();
            }

            this.editor.focus();
            this.onChange(updatedEditorState);

            window.addEventListener('beforeunload', this.onBeforeUnload);
        }
    }

    /**
     * Checks the next props to see if the component has changed from editing to not-editing, or vice versa.
     * If changing to editing the component will gain focus.
     * If changing to not-editing the component will fire the update action to save any changes.
     *
     * If there are changes to the components text content this will also update the editorState with those changes.
     */
    componentWillReceiveProps(nextProps, nextState) {
        if (nowEditingElement(this.props, nextProps)) {
            document.addEventListener('mouseup', handleMouseupOutsideOfWindow);
        } else if (noLongerEditingElement(this.props, nextProps)) {
            document.removeEventListener('mouseup', handleMouseupOutsideOfWindow);
        }

        if (stayedEditingElement(this.props, nextProps) && editorStateHasChanged(this.props, nextProps)) {
            this.setEditorState(nextProps.editorState);
        }

        // If we're undoing and the text content changes, we want to handle that
        if (nextProps.isUndoingOrRedoing && textContentHasChanged(this.props, nextProps)) {
            let editorState = this.buildEditorState(nextProps);

            if (nextProps.isEditing) {
                editorState = EditorState.moveFocusToEnd(editorState);
            }

            this.lastSavedContent = editorState.getCurrentContent();
            this.setEditorState(editorState);
            return;
        }

        if (noLongerEditingThisEditor(this.props, nextProps)) {
            if (nextProps.savedSelection) {
                this.props.dispatchSaveEditorSelection({ editorId: this.props.editorId, selection: null });
            }

            // If the editor is in composition mode, then it hasn't committed the recent composition to the
            // editor state, so we don't want to save the current content as it's not updated yet.
            // Instead, we rely on the onCompositionEnd event to save the content (within DraftEditorCompositionHandler).
            // NOTE: There's a chance that this.saveCurrentContent invocation is unnecessary even when not in
            //  composition mode, but this logic has been in place for almost 6 years, so I'm tentative to remove it
            if (!this.state.editorState.isInCompositionMode()) {
                // First we want to force the resolution of the current content, then we want to save it
                this.saveCurrentContent();
            }
        }

        if (stayedEditingElement(this.props, nextProps) || noLongerEditingElement(this.props, nextProps)) {
            return;
        }

        // FIXME See if the filter query part can be rewritten using new decorator stuff
        if (textContentHasChanged(this.props, nextProps) || filterQueryHasChanged(this.props, nextProps)) {
            let editorState = this.buildEditorState(nextProps);

            if (nextProps.isEditing) {
                editorState = EditorState.moveFocusToEnd(editorState);
            }

            this.lastSavedContent = editorState.getCurrentContent();
            this.setEditorState(editorState);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) return true;

        if (this.props.isEditing !== nextProps.isEditing) return true;

        if (this.props.localEditorState !== nextProps.localEditorState) return true;

        return !!nextProps.isEditing;
    }

    componentDidUpdate(oldProps) {
        const { editorState } = this.state;
        let selectedEditorState;

        // If editor is empty, and we have a default style override that's not already set, then set it in the editor state
        const { defaultStyleOverride } = this.props;
        if (defaultStyleOverride && isEmptyEditor(editorState) && !editorState.getInlineStyleOverride()) {
            selectedEditorState = EditorState.setInlineStyleOverride(editorState, OrderedSet(defaultStyleOverride));
        }

        if (startedEditingThisElement(oldProps, this.props)) {
            // If we have a saved selection then force focus onto this instead of the end
            selectedEditorState = EditorState.moveFocusToEnd(editorState);

            window.addEventListener('beforeunload', this.onBeforeUnload);
        }

        if (this.props.savedSelection && oldProps.savedSelection !== this.props.savedSelection) {
            const { savedSelection, editorId } = this.props;
            selectedEditorState = forceFocus(editorState, savedSelection);

            // Clear the saved selection so it doesn't hang around
            this.props.dispatchSaveEditorSelection({ editorId, selection: null });
        }

        if (selectedEditorState) {
            // The focus() is required by Firefox because it will not focus the editor DOM element if it's read
            // only.  Thus we need to wait for the editor to become editable, then force focus to the editor and
            // finally move the cursor to the end of the text area.
            this.editor.focus();
            this.onChange(selectedEditorState);
        }

        if (noLongerEditingThisEditor(oldProps, this.props)) {
            window.removeEventListener('beforeunload', this.onBeforeUnload);

            this.props.editorStateChanged({
                editorId: null,
                originalEditorId: null,
                editorState: EditorState.createEmpty(),
            });
        }

        // Blurring the editor will cause its current state to be force-saved, however if in composition mode
        // the current text content will not be saved to the editor state until the composition is complete.
        // So to prevent losing that content we don't force-blur and instead just rely on the
        // DraftEditorCompositionHandler to perform its delayed resolution
        if (noLongerEditingElement(oldProps, this.props) && !editorState.isInCompositionMode()) {
            this.forceEditorBlur();
        }
    }

    /**
     * Ensure all timers and updates are complete or flushed when unmounting component.
     */
    componentWillUnmount() {
        // Force save (and clear of timers) on unmount
        this.saveOnUnmount && this.saveCurrentContent();

        window.removeEventListener('beforeunload', this.onBeforeUnload);
        document.removeEventListener('mouseup', handleMouseupOutsideOfWindow);
    }

    /**
     * Saves the new editor state to the internal state of this component, and also fires a Redux action to update
     * the current editors editorState.
     *
     * This is used so that updates made by the user while typing will be reflected in the view.
     */
    onChange = (editorState) => {
        if (!!this.props.localEditorState) return;

        if (!this.props.isEditable) return;

        this.changeCount++;

        // The content has changed restart the debounce
        this.clearDebounceTimer();

        const currentContent = this.state.editorState.getCurrentContent();
        const newContent = editorState.getCurrentContent();

        const lastSavedBlockCount = this.lastSavedContent.getBlockMap().size;
        const newBlockCount = newContent.getBlockMap().size;

        // If the content has not changed a focus change has occurred
        // and if the content is not the same as the last saved content then it should be saved.
        // Or if a new line has been created or deleted then it should be saved.
        const contentHasChanged = currentContent !== newContent;
        const shouldSave =
            lastSavedBlockCount !== newBlockCount ||
            !this.props.isEditing ||
            (this.props.hasLiveCollaborators && this.changeCount > 10);
        if (shouldSave) {
            this.saveData(editorState);
        } else if (contentHasChanged) {
            this.changeDebounceTimer = setTimeout(
                () => this.saveData(editorState),
                this.props.hasLiveCollaborators ? TIMES.SECOND : DEFAULT_SAVE_TIMEOUT,
            );
        }

        if (!this.props.isEditing) return;

        this.props.editorStateChanged({
            editorId: this.props.editorId,
            originalEditorId: this.props.originalEditorId,
            editorState,
        });
        this.props.onChange && this.props.onChange({ editorId: this.props.editorId, editorState });
    };

    /**
     * Prevent the mouse down event from bubbling to the canvas so that the 'edit complete' action doesn't fire.
     */
    onMouseDown = (event) => {
        if (this.props.isEditing) event.stopPropagation();
        this.props.onMouseDown && this.props.onMouseDown(event);
    };

    onClick = (event) => {
        if (!this.props.isEditable) return;

        if (this.props.isEditing) event.stopPropagation();
        if (!this.props.shouldFocusOnlyWhenSelected) {
            this.focusOnEditor(event);
        } else if (this.props.shouldFocusOnlyWhenSelected && this.props.isSingleSelected) {
            this.focusOnEditor(event);
        }
        this.props.onClick && this.props.onClick(event);
    };

    onBeforeUnload = () => {
        this.saveCurrentContent();
    };

    getProps = () => this.props;

    getTextDomElement = () => this.textEditorNode;

    /**
     * This method uses the "div[data-contents='true']" element within the draft editor to get the actual
     * bounding rectangle of the text, as opposed to the area taken up by the container elements.
     * This is used within the cardSaveHeightResizeDecorator to determine if there's extra space in the
     * card or whether the height is being limited.
     */
    getBoundingClientRect = () => {
        const editorContentsElement = this.getTextDomElement();

        if (!editorContentsElement) {
            logger.warn("Couldn't find the editor contents element");
            return {};
        }

        return editorContentsElement.getBoundingClientRect();
    };

    setEditorState = (editorState) => {
        this.setState({ editorState });
    };

    clearDebounceTimer = () => {
        if (this.changeDebounceTimer) clearTimeout(this.changeDebounceTimer);
        this.saveDataOnIdle.cancel();
    };

    saveData = (editorState) => {
        this.changeCount = 0;

        // If a timer is still running clear it
        this.clearDebounceTimer();

        const currentContent = editorState.getCurrentContent();
        // Don't save if the last saved content is the same as the currently saved content
        if (this.lastSavedContent === currentContent) return;
        this.lastSavedContent = currentContent;

        const { saveContent } = this.props;

        const textContent = convertToRaw(currentContent);

        // Defer the saving of content until CPU time is available
        if (saveContent) {
            saveContent(textContent);
        } else {
            // If there's no "saveContent" function, then update the editor state directly as
            //  we might have been in composition mode, thus the last editor state wasn't
            //  directly persisted.
            // This works in conjunction with the "finishEditing" composition mode logic
            //  within the MilanoteCellEditor for tables
            this.setEditorState(editorState);
        }
    };

    saveDataOnIdle = rICThrottle(this.saveData);

    saveSelection = () => {
        const { editorState } = this.state;
        const { dispatchSaveEditorSelection, editorId } = this.props;

        const selection = editorState.getSelection();

        dispatchSaveEditorSelection({ editorId, selection });
    };

    /**
     * Fires a redux action when this editor becomes focused so that the application can keep track of the editor
     * that's currently active.
     */
    focusOnEditor = (event) => {
        const { editorId, editorKey } = this.props;
        if (isAttemptingMultiSelect(event) || currentlyEditingThisEditor(this.props)) {
            this.editor && this.editor.focus();
            return;
        }

        this.props.startEditing && this.props.startEditing({ editorId, editorKey });

        this.props.onFocus && this.props.onFocus(event);
    };

    // This is needed because draft is not blurring automatically when focus is moved to another editor
    forceEditorBlur = () => {
        // This doesn't seem to do anything as far as setting the editor state, it just feels correct
        this.editor.blur();
        const updatedEditorState = forceBlur(this.state.editorState);
        // Don't need all the onChange checks for this state
        this.setEditorState(updatedEditorState);
    };

    // Remove highlighting from cloned elements to maintain consistency in selection and highlighting
    // FIX-ME: This is a temporary fix to remove highlighting from cloned elements. This should be fixed as part of
    // this ticket - https://github.com/Milanote/milanote/issues/15053
    updateCloneCardEditorState = () => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();

        let entityKey = getFirstEntityInSelection(editorState);
        const firstEntity = entityKey && contentState.getEntity(entityKey);
        const firstEntityType = firstEntity && prop('type', firstEntity);
        // only update if the first entity is a link
        if (firstEntityType === ENTITIES.LINK) {
            contentState.mergeEntityData(entityKey, { highlight: false });
            return EditorState.set(editorState, { currentContent: contentState });
        }
        return null;
    };

    /**
     * Editor state can be passed in here to ensure the update happens immediately, rather than
     * waiting for the state update cycle to complete (which could be asynchronous)
     */
    saveCurrentContent = (editorState) => {
        this.saveData(editorState || this.state.editorState);
    };

    preventSaveOnUnmount = () => {
        this.saveOnUnmount = false;

        // If a timer is still running clear it
        this.clearDebounceTimer();
    };

    editorContainerRef = (component) => {
        this.editorParent = component;
        // TODO-REMOVE-DRAFT: data-contents=true is draft-specific and should be removed eventually
        this.textEditorNode = component?.querySelector("div[data-contents='true'], .tiptap");
    };

    /**
     * I'm not entirely sure why this works, but if I don't do this, this.editor is null in the
     * "setDraftPluginsEditorState" method.
     */
    saveEditorRef = (component) => {
        this.editor = component;
    };

    getEditor() {
        return this.editor;
    }

    getCurrentEditorState() {
        return this.state.editorState;
    }

    setPlainText(text, inlineStyles) {
        let newEditorState = EditorState.moveFocusToEnd(this.buildFromTextContent({ textContent: text }));

        const newContentState = applyInlineStylesThroughoutContent(newEditorState.getCurrentContent(), inlineStyles);
        newEditorState = EditorState.push(newEditorState, newContentState, EditorChangeType.CHANGE_INLINE_STYLE);

        this.onChange(newEditorState);
    }

    render() {
        const {
            isEditing,
            isEditable,
            className,
            placeholder,
            children,
            spellCheck,
            isClone,
            element,
            allowConversion,
            textContent,
        } = this.props;
        const { editorState } = this.state;

        // The Milanote editorKey property was accidentally overwriting Draft's internal editorKey property
        // which meant that copy / pastes between editors were thought to have occurred within a single
        // editor, and as such Draft was using the editor's internal clipboard instead of the actual clipboard.
        // TODO This should be fixed in the future by using a shared clipboard & paste handler, but this would
        // need to handle shared entities etc.
        const { localEditorState, ...otherProps } = this.props;
        delete otherProps.editorKey;
        delete otherProps.editorRef;

        const showPlaceholder = placeholder && isEmptyEditor(editorState);
        const placeholderClasses = showPlaceholder && getPlaceholderClasses(editorState);

        const classes = {
            editing: currentlyEditingThisEditor(this.props),
            postponed: allowConversion && getIsTiptapConversionRecentlyPostponed(element),
        };

        let editorStateToUse = localEditorState || editorState;

        if (isClone) {
            editorStateToUse = this.updateCloneCardEditorState() || editorStateToUse;
        }

        return (
            <div
                className={classNames('Editor', placeholderClasses, className, classes)}
                ref={this.editorContainerRef}
                tabIndex="-1"
                onMouseDown={this.onMouseDown}
                onClick={this.onClick}
            >
                <Editor
                    ref={this.saveEditorRef}
                    {...otherProps}
                    placeholder={showPlaceholder ? placeholder : ''}
                    preventSaveOnUnmount={this.preventSaveOnUnmount}
                    saveCurrentContent={this.saveCurrentContent}
                    defaultBlockRenderMap
                    editorState={editorStateToUse}
                    onChange={this.onChange}
                    onFocus={this.focusOnEditor}
                    readOnly={!(isEditable && isEditing)}
                    spellCheck={spellCheck}
                />
                {children && children(editorState)}
                <DraftJsConversionIndicator element={this.props.element} textContent={textContent} />
            </div>
        );
    }
}

MilanoteEditor.propTypes = {
    plugins: PropTypes.array,

    element: PropTypes.object,
    allowConversion: PropTypes.bool,
    editorState: PropTypes.object,
    localEditorState: PropTypes.object,
    savedSelection: PropTypes.object,
    editorId: PropTypes.string.isRequired,
    originalEditorId: PropTypes.string,
    editorKey: PropTypes.string.isRequired,
    textContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    spellCheck: PropTypes.bool,
    isUndoingOrRedoing: PropTypes.bool,
    editorStateChanged: PropTypes.func,
    saveContent: PropTypes.func,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    placeholder: PropTypes.string,
    editorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    defaultStyleOverride: PropTypes.array,
    isClone: PropTypes.bool,

    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,

    children: PropTypes.func,
    hasLiveCollaborators: PropTypes.bool,
    dispatchSaveEditorSelection: PropTypes.func,
};

export default MilanoteEditor;
