// Types
import { Editor } from '@tiptap/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';

// Selectors
import getGridSize from '../../../utils/grid/gridSizeSelector';

// Utils
import rafThrottle from '../../../../common/utils/lib/rafThrottle';
import { addMargins } from '../../../../common/maths/geometry/rect';
import animateScrollIntoView from '../../../utils/animation/animateScrollIntoView';
import getSelectionRect from '../../../utils/dom/getSelectionRect';
import {
    shouldScroll,
    translateDOMRectIntoScrollableElementCoordinates,
} from '../../../utils/dom/scrollableElementUtils';

// Types
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';

const MINIMUM_REMAINING_SPACE_GRID_UNITS = 7;
const HEIGHT_PADDING_GRID_UNITS = 6;

const useKeepModalEditorScrolledIntoView = (editor: Editor | null, isEditing: boolean, isMobile?: boolean) => {
    const gridSize = useSelector(getGridSize);

    useEffect(() => {
        if (isMobile) return;
        if (!editor) return;
        if (!isEditing) return;

        let mounted = true;
        let heightInitialised = false;
        let cancelScrollAnimation: Function;

        /**
         * Find the DocumentModalTiptapEditor element that contains the editor.
         */
        const getScrollableContainer = () => editor.view.dom.closest('.DocumentModalTiptapEditor');

        /**
         * When loading the modal, if the contents is larger than the modal size, we don't
         * want to focus the editor, otherwise it will scroll to the bottom of the document
         * and the user experience will feel janky.
         */
        const onHeightInitialised = (height: number) => {
            heightInitialised = true;

            const scrollableContainer = getScrollableContainer();

            if (!scrollableContainer) return;

            const scrollableContainerHeight = scrollableContainer.getBoundingClientRect().height;

            // If the height is within the viewable area, leave the document as editing
            if (height < scrollableContainerHeight - HEIGHT_PADDING_GRID_UNITS * gridSize) return;

            // Otherwise, blur the editor and scroll to the top
            editor.commands.blur();
            scrollableContainer.scrollTop = 0;
            return;
        };

        /**
         * When the height of the editor changes, ensure the editing caret stays within view.
         * If it doesn't, animate the scroll to keep it in view.
         */
        const onHeightChange = rafThrottle(([entry]: ResizeObserverEntry[]) => {
            if (!mounted) return;

            // Don't scroll while the height is being initialised on the first render
            if (!heightInitialised) return onHeightInitialised(entry.contentRect.height);

            const selectionLineRect = getSelectionRect();
            const scrollableContainer = getScrollableContainer();

            if (!selectionLineRect) return;
            if (!scrollableContainer) return;

            const selectionRectWithMargins = addMargins(
                {
                    top: 0,
                    left: 0,
                    bottom: MINIMUM_REMAINING_SPACE_GRID_UNITS * gridSize,
                    right: 0,
                },
                selectionLineRect as Rect,
            );

            const targetRect = translateDOMRectIntoScrollableElementCoordinates(
                scrollableContainer,
                selectionRectWithMargins,
            );

            if (!shouldScroll(scrollableContainer, targetRect)) return;

            cancelScrollAnimation = animateScrollIntoView({
                scrollableElement: scrollableContainer,
                targetRect,
                interpolationFactor: 0.2,
            });
        });

        const resizeObserver = new ResizeObserver(onHeightChange);
        resizeObserver.observe(editor.view.dom);

        return () => {
            resizeObserver.disconnect();
            mounted = false;

            cancelScrollAnimation?.();
        };
    }, [editor, isEditing]);
};

export default useKeepModalEditorScrolledIntoView;
