// Lib
import { Range } from '@tiptap/core';
import { Selection } from '@tiptap/pm/state';

// Utils
import keepRangeWithinNode from '../../utils/selection/keepRangeWithinNode';
import expandRangeToContainNodes from '../../utils/selection/expandRangeToContainNodes';
import isSelectionSpanningEntireNodes from '../../utils/selection/isSelectionSpanningEntireNodes';

const getClippingRange = (selection: Selection): Range => {
    // If the selection spans the entire nodes, we should delete the nodes, rather than just the text
    const shouldExpandToContainNodes = isSelectionSpanningEntireNodes(selection);

    return shouldExpandToContainNodes ? expandRangeToContainNodes(selection) : keepRangeWithinNode(selection);
};

export default getClippingRange;
