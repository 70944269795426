export const ZOOM_PROPS = {
    MIN_SCALE: 0.1,
    // If you're zooming out it must at least reach 80%
    MIN_SCALE_MAX: 0.8,
    MAX_SCALE: 3,

    // Scale speed
    SCALE_SPEED_FACTOR: 0.0025,
    INTERPOLATION_FACTOR: 0.25,

    // No longer used, but keeping them around as a reminder in case we want to bring it back
    SCALE_OUT_DELTA_LIMIT: 0.8,
    SCALE_IN_DELTA_LIMIT: 1.2,
};

export const ZOOM_MODES = {
    ZOOM_TO_FIT: 'zoom-to-fit',
};
