// Lib
import React from 'react';
import { NodeViewWrapper, NodeViewContent, ReactNodeViewRenderer, wrappingInputRule } from '@tiptap/react';
import DefaultTaskItem, { inputRegex } from '@tiptap/extension-task-item';

// Types
import { NodeViewProps } from '@tiptap/core';
import { TaskItemOptions } from '@tiptap/extension-task-item/src/task-item';

interface MilanoteTaskItemOptions extends TaskItemOptions {
    CheckboxComponent?: React.ComponentType<{ checked?: boolean; onClick?: (event: React.MouseEvent) => void }>;
}

const TaskItemDisplay = (props: NodeViewProps) => {
    const { node, updateAttributes, extension } = props;
    const { CheckboxComponent } = extension.options;

    if (!CheckboxComponent) {
        console.warn('TaskItem.CheckboxComponent is required for TaskItemDisplay');
        return null;
    }

    const onClick = (event: React.MouseEvent) => {
        // Prevent the editor from gaining focus if it's not already being edited
        // and prevent the caret from moving
        event.stopPropagation();
        updateAttributes({ checked: !node.attrs.checked });
    };

    return (
        <NodeViewWrapper as="li" data-checked={node.attrs.checked}>
            <CheckboxComponent checked={node.attrs.checked} onClick={onClick} />
            <NodeViewContent className="task-item-content" />
        </NodeViewWrapper>
    );
};

export const TaskItem = DefaultTaskItem.extend<MilanoteTaskItemOptions>({
    addNodeView() {
        return ReactNodeViewRenderer(TaskItemDisplay);
    },

    /**
     * This is a hack :( to get around the issue where the input rule is triggered
     * prior to the UniversalCard's "Enter" keyboard shortcut handler when the user
     * presses enter on the first line.
     *
     * So we're overriding the `find` method to check if the editor is on the first line
     * prior to attempting to match the content.
     */
    addInputRules() {
        return [
            wrappingInputRule({
                find: (text: string) => {
                    // Don't handle this if we're on the first line
                    if (this.editor.state.doc.content.childCount === 1) return null;

                    const match = inputRegex.exec(text);

                    if (!match) return null;

                    return {
                        ...match,
                        text,
                    };
                },
                type: this.type,
                getAttributes: (match) => ({
                    checked: match[match.length - 1] === 'x',
                }),
            }),
        ];
    },
});
