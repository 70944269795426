// Types
import { Selection } from '@tiptap/pm/state';

// Utils
import isVisibleSelection from './isVisibleSelection';

// Types
import { ResolvedPos } from '@tiptap/pm/model';

/**
 * Determines whether the current position is at the start or end of a block.
 */
const isAtBlockBoundary = ($pos: ResolvedPos): boolean => {
    const isAtBlockStart = $pos.parentOffset === 0;
    const isAtBlockEnd = $pos.parentOffset === $pos.parent.nodeSize - 2;
    return isAtBlockStart || isAtBlockEnd;
};
/**
 * Determines if the current selection encapsulates the text of an entire node.
 * E.g. We can use this to determine if the entire node should be removed, or just the text, while clipping.
 */
const isSelectionSpanningEntireNodes = (selection: Selection): boolean => {
    const { $from, $to } = selection;

    // If the selection is collapsed, or not visible, it's not spanning entire nodes.
    if (!isVisibleSelection(selection)) return false;

    // The start & end must either be at the start of a parent block (parentOffset = 0), or
    // at the end of a parent block (parentOffset = parent.nodeSize - 2) - e.g. when the
    // selection spills to the previous block
    if (!isAtBlockBoundary($from)) return false;
    if (!isAtBlockBoundary($to)) return false;

    // Otherwise - the selection is spanning entire nodes
    return true;
};

export default isSelectionSpanningEntireNodes;
