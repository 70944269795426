// Selectors
import { getElements } from '../../../selectors/elementsSelector';
import { getMilanoteApplicationModeSelector } from '../../../../platform/platformSelector';

// Actions
import { setElementTypeAndUpdateElement } from '../../../actions/elementActions';

// Utils
import { getElement } from '../../../../../common/elements/utils/elementTraversalUtils';
import { getDrawingData } from '../../../../../common/elements/utils/elementPropertyUtils';
import { convertAnnotationDataToSketchData } from '../../utils/drawingTransformationUtils';
import { getNewTransactionId } from '../../../../utils/undoRedo/undoRedoTransactionManager';
import { isSketch } from '../../../../../common/elements/utils/elementTypeUtils';

// Constants
import { ANNOTATION_MODE_START, ANNOTATION_MODE_END } from './annotationConstants';
import { SKETCH_DEFAULT_WIDTH } from '../../../../../common/drawings/sketches/sketchConstants';
import { SKETCH_BACKGROUND_COLORS } from '../../../../../common/colors/colorConstants';
import { ElementType } from '../../../../../common/elements/elementTypes';
import { MilanoteApplicationMode } from '../../../../../common/platform/platformTypes';

export const startAnnotationMode =
    ({ elementId } = {}) =>
    (dispatch, getState) => {
        const state = getState();

        const isMobile = getMilanoteApplicationModeSelector(state) === MilanoteApplicationMode.mobile;

        if (isMobile) return;

        return dispatch({ type: ANNOTATION_MODE_START, elementId });
    };

export const endAnnotationMode = () => ({ type: ANNOTATION_MODE_END });

/**
 * Converts an annotation drawing card into a sketch drawing card.
 */
export const convertToSketch =
    ({ elementId, transactionId = getNewTransactionId() }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);
        const element = getElement(elements, elementId);
        const drawingData = getDrawingData(element);

        if (!drawingData || isSketch(element)) return;

        const updatedDrawingData = convertAnnotationDataToSketchData(drawingData);

        dispatch(
            setElementTypeAndUpdateElement({
                id: elementId,
                elementType: ElementType.SKETCH_TYPE,
                changes: {
                    transparent: {
                        enabled: false,
                    },
                    background: {
                        color: SKETCH_BACKGROUND_COLORS.WHITE.name,
                    },
                    width: SKETCH_DEFAULT_WIDTH,
                    drawing: updatedDrawingData,
                },
                transactionId,
            }),
        );
    };
