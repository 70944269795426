// Lib
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Components
import EditableTitle from '../../components/editableTitle/EditableTitle';
import BoardIcon from './boardIcon/BoardIcon';
import ContainerElementChildCount from '../containerElement/ContainerElementChildCount';
import BoardEmptyContentsTag from './BoardEmptyContentsTag';
import IconView from '../../components/displayMode/IconView';
import BoardDropTarget from './BoardDropTarget';
import LineDropTarget from '../dnd/elementDropTargets/LineDropTarget';

// Selectors
import { getDuplicateLoading } from '../duplicate/elementDuplicateSelector';
import { makeContainerElementChildrenTypesSelector } from '../containerElement/containerElementSelector';
import { getBoardFetchError, getIsBoardFetched } from './boardSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';
import { getIsSidebarPrototypeEnabled } from '../../workspace/sidebar/sidebarSelector';

// Utils
import { getMainEditorKey } from '../utils/elementEditorUtils';
import { getElementStyle } from '../../utils/grid/gridUtils';
import { isRealUserId } from '../../../common/users/userHelper';
import { isPlatformPhoneOrMobileMode } from '../../platform/utils/platformDetailsUtils';
import {
    getAcl,
    getColor,
    getElementId,
    getIcon,
    getIsPublicEditEnabled,
} from '../../../common/elements/utils/elementPropertyUtils';
import {
    isTemplateBoard,
    isTemplatePublicParentBoard,
    getTemplateStatusText,
} from '../../../common/templates/templateUtil';
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import { isAccessDenied } from '../../../common/permissions/permissionUtil';
import { isTransientBoardFetchError } from './boardErrorUtils';
import { prop } from '../../../common/utils/immutableHelper';

// Constants
import { DEFAULT_ICON_VIEW_WIDTH } from '../../../common/elements/elementDisplayModeConstants';

// Styles
import './Board.scss';

const isSharedSelector = () =>
    createSelector(
        (state, props) => props.linkedBoard || props.element,
        (element) => {
            if (getIsPublicEditEnabled(element)) return true;

            const acl = getAcl(element);
            if (!acl || !acl.size) return false;

            return acl.filter((v, userId) => isRealUserId(userId) && !isAccessDenied(prop(userId, acl))).size > 0;
        },
    );

const makeBoardSelector = () =>
    createSelector(
        makeContainerElementChildrenTypesSelector(),
        isSharedSelector(),
        getIsBoardFetched,
        getBoardFetchError,
        getDuplicateLoading,
        getPlatformDetailsSelector,
        getIsSidebarPrototypeEnabled,
        (
            childrenTypes,
            isShared,
            isBoardFetched,
            boardFetchError,
            isBoardDuplicating,
            platformDetails,
            isSidebarPrototypeEnabled,
        ) => ({
            childrenTypes,
            isShared,
            isBoardFetched,
            boardFetchError,
            isBoardDuplicating,
            platformDetails,
            isSidebarPrototypeEnabled,
        }),
    );

export const getBoardIconSize = ({ inList, documentMode }) => {
    if (documentMode) return 5;
    if (inList) return 6;
    return 7.2;
};

const BoardIconElement = React.memo(function BoardIconElement(props) {
    const {
        element,
        children,
        isAlias,
        isLinkAlias,
        isShared,
        getContextZoomScale,
        getContextZoomTranslationPx,
        platformDetails,
        isSidebarPrototypeEnabled,
    } = props;

    const color = getColor(element);
    const icon = getIcon(element);
    const iconSize = getBoardIconSize(props);

    return (
        <BoardIcon
            {...props}
            size={iconSize}
            element={element}
            color={color}
            icon={icon}
            isShared={isShared}
            isAlias={isAlias}
            isLinkAlias={isLinkAlias}
            showActivityIndicator={!isPlatformPhoneOrMobileMode(platformDetails)}
            getContextZoomScale={getContextZoomScale}
            getContextZoomTranslationPx={getContextZoomTranslationPx}
            isSidebarPrototypeEnabled={isSidebarPrototypeEnabled}
        >
            {children}
        </BoardIcon>
    );
});

BoardIconElement.propTypes = {
    element: PropTypes.object.isRequired,
    children: PropTypes.element,
    gridSize: PropTypes.number.isRequired,
    isAlias: PropTypes.bool,
    isLinkAlias: PropTypes.bool,
    isShared: PropTypes.bool,
    documentMode: PropTypes.bool,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
    platformDetails: PropTypes.object.isRequired,
    isSidebarPrototypeEnabled: PropTypes.bool,
};

const BoardContainerTypeTag = ({
    inTrash,
    isBoardFetched,
    element,
    elementId,
    childrenTypes,
    boardFetchError,
    isBoardDuplicating,
}) => {
    if (isTemplateBoard(element) || isTemplatePublicParentBoard(element)) {
        return <div className="template-tag">{getTemplateStatusText(element)}</div>;
    }

    if (inTrash) return null;

    const canShowCounts = (isBoardFetched && !isTransientBoardFetchError(boardFetchError)) || isBoardDuplicating;
    const checkBoardAccess =
        // If the board doesn't have children, is fetched and doesn't have a transient error, show the counts
        (isEmpty(childrenTypes) && canShowCounts) ||
        // Or there's a board fetch error and it isn't transient
        (boardFetchError && !isTransientBoardFetchError(boardFetchError));

    if (checkBoardAccess) {
        return (
            <BoardEmptyContentsTag
                elementId={elementId}
                element={element}
                isBoardDuplicating={isBoardDuplicating}
                childrenTypes={childrenTypes}
                canShowCount={canShowCounts}
            />
        );
    }

    return (
        <ContainerElementChildCount
            childrenTypes={childrenTypes}
            isBoardDuplicating={isBoardDuplicating}
            show={canShowCounts}
            isBoard
        />
    );
};

BoardContainerTypeTag.propTypes = {
    inTrash: PropTypes.bool,
    isBoardFetched: PropTypes.bool,
    boardFetchError: PropTypes.object,
    isBoardDuplicating: PropTypes.bool,
    element: PropTypes.object,
    elementId: PropTypes.string,
    childrenTypes: PropTypes.object,
};

const Board = (props) => {
    const {
        gridSize,
        inList,
        isSingleSelected,
        element,
        onTitleEditDone,
        elementEvents,
        isPresentationModeEnabled,
        platformDetails,
        isSidebarPrototypeEnabled,
    } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [isLineEdgeHovered, setIsLineEdgeHovered] = useState(false);
    const [showNavigationFeedback, setShowNavigationFeedback] = useState(false);

    const classes = elementClassNames(
        'Board',
        {
            ...props,
            isLineEdgeHovered,
            isHovered,
            canDrop: true,
        },
        {
            BoardFolder: isSidebarPrototypeEnabled,
        },
    );

    const editorKey = getMainEditorKey(props);

    return (
        <div
            id={getDomElementId(getElementId(element))}
            className={classes}
            style={getElementStyle(DEFAULT_ICON_VIEW_WIDTH, gridSize, inList)}
            {...elementEvents}
        >
            <IconView
                className="board-content"
                iconElement={<BoardIconElement {...props} showNavigationFeedback={showNavigationFeedback} />}
                titleElement={
                    <EditableTitle
                        {...props}
                        elementId={getElementId(element)}
                        initialValue="New Board"
                        selectFirst
                        isSelected={isSingleSelected}
                        selectAllOnEdit
                        onUpdate={onTitleEditDone}
                        editDelay={platformDetails.features.isTouch ? 0 : 100}
                        editorKey={editorKey}
                    />
                }
                secondaryElement={!isPresentationModeEnabled && <BoardContainerTypeTag {...props} />}
            />
            <BoardDropTarget
                {...props}
                setIsHovered={setIsHovered}
                setShowNavigationFeedback={setShowNavigationFeedback}
            />

            <LineDropTarget {...props} setLineHoverEffect={setIsLineEdgeHovered} />
        </div>
    );
};

Board.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    inTrash: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    onHeadingClick: PropTypes.func,
    updateTitle: PropTypes.func,
    navigateToBoard: PropTypes.func,
    connectDropTarget: PropTypes.func,
    connectLineEdgeDropTarget: PropTypes.func,
    onTitleEditDone: PropTypes.func,
    setLineHoverEffect: PropTypes.func,
    setElementHoverEffect: PropTypes.func,
    className: PropTypes.string,
    documentMode: PropTypes.bool,
    isBoardFetched: PropTypes.bool,
    isBoardDuplicating: PropTypes.bool,
    elementEvents: PropTypes.object,
    canConnectLineEdge: PropTypes.bool,
    isPresentationModeEnabled: PropTypes.bool,
    platformDetails: PropTypes.object.isRequired,
    isSidebarPrototypeEnabled: PropTypes.bool,
};

export default connect(makeBoardSelector)(Board);
