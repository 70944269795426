/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import {
    getCardDimensionPropertiesPx,
    getEditorOverflowDistance,
    getIsAutoHeight,
    getIsEditorOverThreshold,
} from '../cardSizeUtil';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';
import { sendAmplitudeEvent } from '../../../analytics/amplitudeService';
import { getIsFeatureEnabled } from '../../feature/elementFeatureUtils';

// Components
import measurementsConnect from '../../../components/measurementsStore/measurementsConnect';

// Selectors
import { getMeasurementsMap } from '../../../components/measurementsStore/elementMeasurements/elementMeasurementsSelector';

// Actions
import { navigateToElement } from '../../../reducers/navigationActions';
import { getNextUndoElementUpdateTransactionId } from '../../../utils/undoRedo/undoRedoActions';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../../common/elements/elementConstants';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../../common/analytics/statsConstants';
import { EVENT_TYPE_NAMES } from '../../../../common/analytics/amplitudeEventTypesUtil';
import { EDITOR_SINGLE_LINE_HEIGHT_GRID_UNITS } from '../../../../common/cards/cardConstants';
import { ELEMENT_FEATURES } from '../../feature/elementFeatureConstants';

const SINGLE_LINE_CARD_MIN_HEIGHT_GRID_UNITS = 5.8;
const MULTI_LINE_CARD_MIN_HEIGHT_GRID_UNITS = 8.6;
const CARD_MIN_WIDTH = ELEMENT_DEFAULT_WIDTH / 2;
const SNAP_THRESHOLD = 2;

const snapSize = (size) => {
    const { width, height } = size;

    const defaultWidthDiff = Math.abs(width - ELEMENT_DEFAULT_WIDTH);

    return {
        ...size,
        // Subtract 0.2 for the borders
        height: Math.round(height) - 0.2,
        width: defaultWidthDiff < SNAP_THRESHOLD ? ELEMENT_DEFAULT_WIDTH : width,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigateToElement: ({ elementId }) => dispatch(navigateToElement({ elementId })),
    dispatchGetNextUndoTransactionId: () => dispatch(getNextUndoElementUpdateTransactionId()),
});

const mapEditorDispatchToProps = (dispatch) => ({
    getCurrentElementHeight: (elementId) =>
        dispatch((_dispatch, getState) => {
            const state = getState();
            const measurementsMap = getMeasurementsMap(state);
            return measurementsMap.getIn([elementId, 'height']);
        }),
});

// Determined via experimentation, not math
const AUTO_HEIGHT_MIN = 0;
const AUTO_HEIGHT_MAX = 44;

const getChangesForOverflowAmount = (overflowAmount, currentContainerHeight) => {
    if (overflowAmount <= AUTO_HEIGHT_MAX && overflowAmount >= AUTO_HEIGHT_MIN) {
        // container roughly matches the text - set auto height mode
        return {
            minHeight: null,
            maxHeight: null,
            autoHeight: true,
        };
    }

    if (overflowAmount > 0) {
        // text is overflowing (as the user intends) - set max height to
        // stop the container growing to fit it.
        return {
            minHeight: null,
            maxHeight: currentContainerHeight,
            autoHeight: false,
        };
    }

    // text has extra space - set min height so it doesn't collapse
    return {
        minHeight: currentContainerHeight,
        maxHeight: null,
        autoHeight: false,
    };
};

export default (DecoratedComponent) => {
    @connect(null, mapDispatchToProps)
    @measurementsConnect(null, mapEditorDispatchToProps)
    class CardSaveHeightResizeDecorator extends React.Component {
        constructor(props) {
            super(props);

            this.initialised = false;
            this.initialElementHeight = null;
            this.initialContainerHeight = null;

            this.editorHeight = null;

            this.containerElement = null;
            this.editorElement = null;

            this.state = {
                tempContainerHeight: null,
            };
        }

        containerRef = (node) => {
            this.containerElement = node;
        };

        editorRef = (node) => {
            this.editorElement = node;
        };

        getCurrentContainerHeight = () => {
            const { getContextZoomScale } = this.props;

            const zoomScale = getContextZoomScale();

            return this.containerElement.getBoundingClientRect().height / zoomScale;
        };

        getEditorHeight = () => {
            // TODO-REMOVE-DRAFT: On removal restructure this component's logic to not require subtracting padding
            const isTiptap = this.editorElement?.classList?.contains('tiptap') ?? false;
            const padding = isTiptap ? 3.8 * this.props.gridSize : 0;
            return this.editorElement.getBoundingClientRect().height - padding;
        };

        initialiseComponentHeights = () => {
            const { element, getCurrentElementHeight } = this.props;

            this.initialised = true;

            this.initialElementHeight = getCurrentElementHeight(getElementId(element));
            this.initialContainerHeight = this.getCurrentContainerHeight();
        };

        getMinimumContainerHeight = (currentEditorHeight) => {
            const { gridSize, element } = this.props;

            const isTransparencyEnabled = getIsFeatureEnabled(ELEMENT_FEATURES.TRANSPARENT)(element);

            // Because we don't want the "read more" to cover the single line of text, we need to figure out
            // if there is more than one line of text and change the minimum possible container height.
            const singleLineEditorHeight = EDITOR_SINGLE_LINE_HEIGHT_GRID_UNITS * gridSize;
            const multipleEditorLines = currentEditorHeight > singleLineEditorHeight;

            return isTransparencyEnabled || !multipleEditorLines
                ? SINGLE_LINE_CARD_MIN_HEIGHT_GRID_UNITS * gridSize
                : MULTI_LINE_CARD_MIN_HEIGHT_GRID_UNITS * gridSize;
        };

        getNewContainerHeight = ({ height }, currentEditorHeight) => {
            const { gridSize } = this.props;

            const minimumContainerHeight = this.getMinimumContainerHeight(currentEditorHeight);

            // Container Height = Element Height - Constant
            // Constant = Initial Element Height - Initial Container Height
            // Therefore:
            // New Container Height     = Desired Element Height - (Initial Element Height - Initial Container Height)
            //                          = Desired Element Height - Initial Element Height + Initial Container Height
            const desiredElementHeightPx = height * gridSize;

            return Math.max(
                desiredElementHeightPx - this.initialElementHeight + this.initialContainerHeight,
                minimumContainerHeight,
            );
        };

        setContainerSizeWhileResizing = (size) => {
            const { setTempElementSize } = this.props;

            if (!size) {
                setTempElementSize(size);
                return;
            }

            if (!this.initialised) this.initialiseComponentHeights();

            const newHeight = this.getNewContainerHeight(size, this.editorHeight);
            this.setState({ tempContainerHeight: newHeight });

            const snappedSize = snapSize(size);
            setTempElementSize(snappedSize);
        };

        onResizeComplete = (size) => {
            if (!this.initialised) this.initialiseComponentHeights();

            const { setElementSize, dispatchUpdateElement, dispatchStopEditing, element, gridSize, isEditing, inList } =
                this.props;

            this.initialised = false;

            const snappedSize = snapSize(size);

            this.editorHeight = this.getEditorHeight();

            this.containerHeight = this.getNewContainerHeight(snappedSize, this.editorHeight);
            const containerHeightGridUnits = this.containerHeight / gridSize;

            // Use the newly saved max height as the max height to compare against
            const pastThreshold = getEditorOverflowDistance(this.containerHeight, this.editorHeight, gridSize);
            const changes = getChangesForOverflowAmount(pastThreshold, containerHeightGridUnits);

            // If the element is in a list, don't update its width
            if (!inList) {
                changes.width = Math.max(Math.round(snappedSize.width), CARD_MIN_WIDTH);
            }

            const elementId = getElementId(element);
            dispatchUpdateElement({ id: elementId, changes });

            this.setState({ tempContainerHeight: null });

            // Stop editing if resizing into a height limited size
            if (changes.maxHeight && isEditing) dispatchStopEditing(elementId);

            setElementSize(size);

            sendAmplitudeEvent({
                eventType: EVENT_TYPE_NAMES.ELEMENT_UPDATE,
                userProperties: {
                    [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.RESIZE_CARD]: true },
                },
            });
        };

        handleDoubleClick = () => {
            const { element, dispatchUpdateElement, gridSize } = this.props;

            this.editorHeight = this.getEditorHeight();

            const { minHeight, maxHeight } = getCardDimensionPropertiesPx({ element, gridSize });
            const autoHeight = getIsAutoHeight(element);

            const canToggle =
                // If the editor height is less than the min height, then we may want to toggle the state of auto height
                (minHeight && this.editorHeight < minHeight) ||
                // If the editor height is greater than the max height, then we may want to toggle the auto height
                (maxHeight && getIsEditorOverThreshold(maxHeight, this.editorHeight, gridSize));

            if (!canToggle) return;

            return dispatchUpdateElement({
                id: getElementId(element),
                changes: {
                    autoHeight: !autoHeight,
                },
            });
        };

        render() {
            const { tempContainerHeight } = this.state;

            return (
                <DecoratedComponent
                    {...this.props}
                    cardEditorRef={this.editorRef}
                    containerRef={this.containerRef}
                    tempContainerHeight={tempContainerHeight}
                    handleDoubleClick={this.handleDoubleClick}
                    setElementSize={this.onResizeComplete}
                    setTempElementSize={this.setContainerSizeWhileResizing}
                />
            );
        }
    }

    CardSaveHeightResizeDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        setElementSize: PropTypes.func.isRequired,
        setTempElementSize: PropTypes.func.isRequired,
        getCurrentElementHeight: PropTypes.func,
        dispatchUpdateElement: PropTypes.func,
        dispatchNavigateToElement: PropTypes.func,
        dispatchStopEditing: PropTypes.func,
        gridSize: PropTypes.number,
        getContextZoomScale: PropTypes.func,
        isEditing: PropTypes.bool,
        inList: PropTypes.string,

        dispatchGetNextUndoTransactionId: PropTypes.func,
    };

    return CardSaveHeightResizeDecorator;
};
