// Lib
import { Capacitor } from '@capacitor/core';

// Utils
import { calculateGridSize, getStoredGridSize } from '../../utils/grid/gridUtils';
import { getAppModeFromHybridUseCase } from './platformAppModeUtils';
import localStorageService from '../../utils/services/localStorage/localStorage';
import {
    getUserAgent,
    isChromeUserAgent,
    isEdgeUserAgent,
    isFirefoxUserAgent,
    isTabletUserAgent,
} from './platformBrowserUtils';
import { getIsElectronMac, isDesktopPwa, isInIFrame, isIphonePwa } from './platformAppTypeUtils';
import {
    getIsRetinaPlatform,
    getIsTouchPlatform,
    getPlatformSupportsMouse,
    getPlatformSupportsWebSockets,
    platformSupportsContentEditable,
    platformSupportsIndexedDb,
    platformSupportsLocalStorage,
    platformSupportsSvg,
    platformSupportsWebWorkers,
} from './platformFeatureDetectionUtils';

// Types
import {
    ApplicationType,
    BrowserEngineType,
    DeviceFormFactor,
    LegacyHybridUseCase,
    MilanoteApplicationMode,
    OperatingSystem,
    PlatformDetails,
    PlatformType,
} from '../../../common/platform/platformTypes';
import { GRID } from '../../utils/grid/gridConstants';
import getClientConfig from '../../utils/getClientConfig';

declare global {
    interface Window {
        safari?: unknown;
    }
}

/**
 * Gets the platform type based on the user agent and app version.
 */
// TODO-MOBILE - Double check this logic - this is a reimplementation of the logic in the browser.ts file
const getPlatformType = (userAgent?: string, appVersion?: string): PlatformType => {
    if (!userAgent) return PlatformType.unknown;

    if (userAgent.match(/iPad/i)) return PlatformType.ipad;
    if (userAgent.match(/iPhone/i)) return PlatformType.iphone;
    if (userAgent.match(/iPod/i)) return PlatformType.ipod;
    if (userAgent.match(/Android/i)) return PlatformType.android;

    if (!appVersion) return PlatformType.unknown;

    if (appVersion.indexOf('Win') !== -1) return PlatformType.windows;
    if (appVersion.indexOf('Mac') !== -1) {
        // From iPadOS 13 (released in 2019), Safari on iPad is a "desktop-class browser" (Apple's term)
        // The implication there is that the user agent sent from Safari on iPad is Mac instead of iPad.
        // So to differentiate iPad and Mac, check for touch (at the time of commit, there are no touch screen Macs)
        if (getIsTouchPlatform()) return PlatformType.ipad;
        return PlatformType.mac;
    }
    if (appVersion.indexOf('X11') !== -1) return PlatformType.unix;
    if (appVersion.indexOf('Linux') !== -1) return PlatformType.linux;

    return PlatformType.unknown;
};

/**
 * Gets the form factor of the device - phone, tablet, or desktop.
 */
const getFormFactor = (
    userAgent: string,
    platformType: PlatformType,
    screenWidth: number,
    screenHeight: number,
    isTouch: boolean,
    legacyHybridUseCase: LegacyHybridUseCase | null,
): DeviceFormFactor => {
    if (platformType === PlatformType.ipad) return DeviceFormFactor.tablet;

    if (platformType === PlatformType.iphone || platformType === PlatformType.ipod) return DeviceFormFactor.phone;

    if (legacyHybridUseCase === LegacyHybridUseCase.IPAD_OS) return DeviceFormFactor.tablet;

    if (legacyHybridUseCase) return DeviceFormFactor.phone;

    if (isTabletUserAgent(userAgent)) return DeviceFormFactor.tablet;

    // On Android, we prevent all orientations except portrait (in native code) when largest length is less than 600,
    // so we use that here to "determine" the experience to present.
    if (platformType === PlatformType.android)
        return screenWidth < 600 ? DeviceFormFactor.phone : DeviceFormFactor.tablet;

    return DeviceFormFactor.desktop;
};

/**
 * Gets the platform as reported by Capacitor.
 */
const getCapacitorPlatform = (): string => Capacitor.getPlatform();

/**
 * Gets the Milanote application mode to initialise the app in.
 */
const getInitialAppMode = (
    formFactor: DeviceFormFactor,
    hybridUseCase: LegacyHybridUseCase | null,
): MilanoteApplicationMode => {
    if (hybridUseCase) return getAppModeFromHybridUseCase(hybridUseCase);

    if (formFactor === DeviceFormFactor.phone) return MilanoteApplicationMode.mobileLegacy;

    // If we already in mobile mode, we should stick to it
    if (getStoredGridSize()?.name === GRID.MOBILE.name) return MilanoteApplicationMode.mobileLegacy;

    return MilanoteApplicationMode.desktop;
};

/**
 * Determines the initial operating system based on the platform type.
 */
const getInitialOperatingSystem = (platformType: PlatformType): OperatingSystem => {
    switch (platformType) {
        case PlatformType.windows:
            return OperatingSystem.windows;
        case PlatformType.mac:
            return OperatingSystem.macOS;
        case PlatformType.iphone:
        case PlatformType.ipod:
        case PlatformType.ipad:
            return OperatingSystem.iOS;
        case PlatformType.android:
            return OperatingSystem.android;
        default:
            return OperatingSystem.other;
    }
};

/**
 * Determines the browser engine type (e.g. Chrome, Safari) based on the user agent, or other
 * properties we're aware of.
 *
 * NOTE: This terminology isn't accurate - the browser engine should be "WebKit" for Safari
 *   or "Blink" for Chrome, but using "safari" and "chrome" will be much clearer for most developers.
 */
const getInitialBrowserEngineType = (
    userAgent: string,
    safari: unknown,
    platformType: PlatformType,
    legacyHybridAppUseCase: LegacyHybridUseCase | null,
): BrowserEngineType => {
    if (safari) return BrowserEngineType.safari;

    if (platformType === PlatformType.iphone) return BrowserEngineType.iosSafari;
    if (platformType === PlatformType.ipad) return BrowserEngineType.iosSafari;
    if (platformType === PlatformType.android && isChromeUserAgent(userAgent)) return BrowserEngineType.androidChrome;

    if (isChromeUserAgent(userAgent)) return BrowserEngineType.chrome;
    if (isFirefoxUserAgent(userAgent)) return BrowserEngineType.firefox;
    if (isEdgeUserAgent(userAgent)) return BrowserEngineType.edge;

    if (legacyHybridAppUseCase === LegacyHybridUseCase.IPAD_OS) return BrowserEngineType.iosSafari;
    if (legacyHybridAppUseCase === LegacyHybridUseCase.ANDROID_BOARD_LIST) return BrowserEngineType.androidChrome;

    return BrowserEngineType.unknown;
};

const LEGACY_HYBRID_USE_CASE_KEY = 'milanote.hybridUseCase';
const getLegacyHybridUseCase = () => localStorageService.getItem(LEGACY_HYBRID_USE_CASE_KEY);

/**
 * Determines the application type of the app.
 * E.g. If it's running in a "web" browser, or an "electron-mac" app, etc.
 */
const getInitialApplicationType = (
    legacyHybridUseCase: LegacyHybridUseCase | null,
    capacitorPlatform: string,
    platformType: PlatformType,
    formFactor: DeviceFormFactor,
): ApplicationType => {
    if (getIsElectronMac()) return ApplicationType.electronMac;
    if (isIphonePwa()) return ApplicationType.iphonePwa;
    if (isDesktopPwa()) return ApplicationType.pwa;

    if (legacyHybridUseCase === LegacyHybridUseCase.IPAD_OS) return ApplicationType.ipadSwift;
    if (legacyHybridUseCase === LegacyHybridUseCase.ANDROID_BOARD_LIST) return ApplicationType.androidFlutter;

    if (capacitorPlatform === 'ios') {
        if (platformType === PlatformType.ipad) return ApplicationType.ipadCapacitor;
        return ApplicationType.iphoneCapacitor;
    }
    if (capacitorPlatform === 'android') {
        if (formFactor == DeviceFormFactor.tablet) return ApplicationType.androidTabletCapacitor;
        return ApplicationType.androidCapacitor;
    }

    if (isInIFrame()) return ApplicationType.iframe;

    return ApplicationType.web;
};

const getAppVersionInfo = (appType: ApplicationType): { appVersion: string | null; appBuild: string | null } => {
    const milanoteCapacitor = (global as any).__milanoteCapacitor;

    switch (appType) {
        case ApplicationType.androidCapacitor:
        case ApplicationType.androidTabletCapacitor:
        case ApplicationType.iphoneCapacitor:
        case ApplicationType.ipadCapacitor:
            if (milanoteCapacitor == null) return { appVersion: null, appBuild: null };
            return {
                appVersion: milanoteCapacitor.getAppVersion() as string,
                appBuild: milanoteCapacitor.getAppBuild() as string,
            };
        default:
            return { appVersion: getClientConfig().appVersion ?? null, appBuild: null };
    }
};

/**
 * This determines the initial state of the platform details singleton and redux store.
 */
export const getInitialPlatformDetails = (): PlatformDetails => {
    const safari = global.window?.safari;
    const userAgent = getUserAgent();
    const browserAppVersion = global.navigator?.appVersion;

    const isTouch = getIsTouchPlatform();
    // Assume a desktop screen size if it doesn't exist
    const screenWidth = global.window?.screen?.width || 2000;
    const screenHeight = global.window?.screen?.height || 1000;

    const legacyHybridUseCase = getLegacyHybridUseCase() as LegacyHybridUseCase | null;
    const capacitorPlatform = getCapacitorPlatform();
    const platformType = getPlatformType(userAgent, browserAppVersion);
    const formFactor = getFormFactor(userAgent, platformType, screenWidth, screenHeight, isTouch, legacyHybridUseCase);
    const appType = getInitialApplicationType(legacyHybridUseCase, capacitorPlatform, platformType, formFactor);
    const browserEngine = getInitialBrowserEngineType(userAgent, safari, platformType, legacyHybridUseCase);

    const { appVersion, appBuild } = getAppVersionInfo(appType);

    return {
        appType,
        // Start with desktop mode and switch to other modes after the user is fetched / device details are known
        appMode: getInitialAppMode(formFactor, legacyHybridUseCase),
        type: platformType,
        formFactor,
        os: getInitialOperatingSystem(platformType),
        capacitorPlatform,
        // This is the old way we were determining the app mode,
        // we should be able to remove this in the future
        legacyHybridUseCase,
        userAgent,
        browserEngine,
        appVersion,
        appBuild,
        features: {
            isTouch,
            isRetina: getIsRetinaPlatform(),
            supportsMouse: getPlatformSupportsMouse(),
            supportsWebSockets: getPlatformSupportsWebSockets(),
            supportsSvg: platformSupportsSvg(),
            supportsContentEditable: platformSupportsContentEditable(),
            supportsLocalStorage: platformSupportsLocalStorage(),
            supportsIndexedDb: platformSupportsIndexedDb(),
            supportsWorker: platformSupportsWebWorkers(),
        },
    };
};
