// Lib
import chroma, { Color } from 'chroma-js';
import { isString } from 'lodash/fp';

// Utils
import { asObject, ImmutableMap, prop } from '../utils/immutableHelper';
import { getPredefinedColorOrBackground } from './colorPresetsUtil';
import { hexToRgb, inferColorSpace, parseHslText, parseRgbText, rgbToHex } from './colorSpaceUtil';

// Constants
import { COLOR_SPACE } from './colorConstants';

export type ColorObjectRgb = {
    space: 'RGB';
    r: number;
    g: number;
    b: number;
};

export type ColorObjectHex = {
    space: 'HEX';
    value: string;
    r: number;
    g: number;
    b: number;
};

export type ColorObjectHsl = {
    space: 'HSL';
    h: number;
    s: number;
    l: number;
};

export type ColorObject = ColorObjectRgb | ColorObjectHex | ColorObjectHsl;

export const getColorObjectSpace = (colorObject: ColorObject | ImmutableMap | string | null): string | undefined =>
    prop('space', colorObject);

const getHexColorObject = (hexCode: string | null): ColorObjectHex | null => {
    if (!hexCode) return null;

    const rgb = hexToRgb(hexCode);

    if (!rgb) return null;

    const [r, g, b] = rgb;
    const fullHexCode = rgbToHex([r, g, b]);

    return {
        space: 'HEX',
        value: fullHexCode,
        r,
        g,
        b,
    };
};

const getRgbColorObject = ([r, g, b]: [number, number, number]): ColorObjectRgb => ({
    space: 'RGB',
    r,
    g,
    b,
});

const getHslColorObject = ([h, s, l]: [number, number, number]): ColorObjectHsl => {
    // Set 0 as default value of h, since chroma-js is able to return NaN hue values
    // when color is black or white
    const hRounded = Math.round(h || 0);
    const sRounded = Math.round(s);
    const lRounded = Math.round(l);

    return {
        space: 'HSL',
        h: hRounded,
        s: sRounded,
        l: lRounded,
    };
};

export const getChromaColor = (color: ColorObject | ImmutableMap | null): Color | null => {
    const colorObj: ColorObject = asObject(color || {});

    switch (colorObj?.space) {
        case 'HEX':
            return chroma(colorObj.value);
        case 'RGB':
            return chroma([colorObj.r, colorObj.g, colorObj.b]);
        case 'HSL':
            // @ts-ignore - chroma-js types are incorrect
            return chroma({ h: colorObj.h, s: colorObj.s / 100, l: colorObj.l / 100 });
        default:
            return null;
    }
};

export const getColorObjectFromText = (text: string): ColorObject | null => {
    if (!text) return null;

    const colorMapEntry = getPredefinedColorOrBackground(text);
    if (colorMapEntry) return getHexColorObject(colorMapEntry.hex);

    const colorSpace = inferColorSpace(text);

    switch (colorSpace) {
        case COLOR_SPACE.HEX:
            return getHexColorObject(text);
        case COLOR_SPACE.RGB: {
            const rgb = parseRgbText(text) as [number, number, number] | null;
            return rgb ? getRgbColorObject(rgb) : null;
        }
        case COLOR_SPACE.HSL: {
            const hsl = parseHslText(text) as [number, number, number] | null;
            return hsl ? getHslColorObject(hsl) : null;
        }
        default:
            return null;
    }
};

export const convertToColorSpace = (colorObject: ColorObject | null, newColorSpace: string): ColorObject | null => {
    const prevColorSpace = getColorObjectSpace(colorObject);

    if (prevColorSpace === newColorSpace) return colorObject;

    const chromaColor = getChromaColor(colorObject);
    if (!chromaColor) return null;

    switch (newColorSpace) {
        case COLOR_SPACE.HEX: {
            const hex = chromaColor.hex();
            return getHexColorObject(hex);
        }
        case COLOR_SPACE.RGB: {
            const rgb = chromaColor.rgb();
            return getRgbColorObject(rgb);
        }
        case COLOR_SPACE.HSL: {
            const [h, s, l] = chromaColor.hsl();
            return getHslColorObject([h, s * 100, l * 100]);
        }
        default:
            return null;
    }
};

/**
 * Take in either a color object or string (predefined color or valid color value), and returns
 * a color object in the format of `newColorSpace` provided.
 *
 * If `newColorSpace` not given, use inferred color space.
 */
export const parseColorObject = (color: ColorObject | string, newColorSpace?: string): ColorObject | null => {
    // if not a color object, convert it to one first
    const colorObject = isString(color) ? getColorObjectFromText(color) : color;

    if (!newColorSpace) return colorObject;

    return convertToColorSpace(colorObject, newColorSpace);
};

export const getColorObjectDisplayValue = (color: ColorObject | string): string | null => {
    const colorSpace = getColorObjectSpace(color);
    switch (colorSpace) {
        case COLOR_SPACE.HEX:
            return prop('value', color);
        case COLOR_SPACE.RGB:
            return `${prop('r', color)},${prop('g', color)},${prop('b', color)}`;
        case COLOR_SPACE.HSL:
            return `${prop('h', color)},${prop('s', color)}%,${prop('l', color)}%`;
        default:
            return null;
    }
};

export const getColorObjectCssValue = (color: ColorObject | ImmutableMap | string | null): string | null => {
    const colorSpace = getColorObjectSpace(color);
    switch (colorSpace) {
        case COLOR_SPACE.HEX:
            return prop('value', color);
        case COLOR_SPACE.RGB:
            return `rgb(${prop('r', color)},${prop('g', color)},${prop('b', color)})`;
        case COLOR_SPACE.HSL:
            return `hsl(${prop('h', color)},${prop('s', color)}%,${prop('l', color)}%)`;
        default:
            return null;
    }
};
