import { AnyAction } from 'redux';
import { ELEMENT_EDIT_START } from '../../../../../../common/elements/selectionConstants';
import platformSingleton from '../../../../../platform/platformSingleton';
import { LegacyHybridUseCase } from '../../../../../../common/platform/platformTypes';
import { ElementType } from '../../../../../../common/elements/elementTypes';

export const shouldForwardElementEditStartActionToNextMiddleware = (state: any, action: AnyAction): boolean => {
    if (action.type !== ELEMENT_EDIT_START) return true; // Not the expected action type, so forward it anyway.
    if (action.editorKey === ElementType.COMMENT_THREAD_TYPE) {
        switch (platformSingleton.legacyHybridUseCase) {
            case LegacyHybridUseCase.ANDROID_BOARD_LIST:
            case LegacyHybridUseCase.IPAD_OS:
            default:
                return true;
        }
    }
    // In general, we should never hit this line —- unless ElementTypes.COMMENT_THREAD_TYPE, ELEMENT_EDIT_START actions
    // should be transformed into HYBRID_ELEMENT_EDIT_START actions.
    return true;
};
