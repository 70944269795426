import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Node } from '@tiptap/pm/model';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { createUsableUrl } from './createUsableUrl';
import { findMatchingTextRanges } from '../../utils/findMatchingTextRanges';
import { URL_REGEX } from '../../../validation/validationConstants';

const NAME = 'decorationAutolink';

export const findAutolinkTextRanges = (doc: Node) => {
    const { schema } = doc.type;
    // Nodes that cannot contain autolinks
    const forbiddenNodes = [schema.nodes.codeBlock];
    // marks that cannot contain autolinks
    const forbiddenMarks = [schema.marks.link, schema.marks.code, schema.marks.textMention];
    return findMatchingTextRanges(doc, URL_REGEX, forbiddenMarks, forbiddenNodes).filter(
        ({ text }) => !text.includes('..') && !text.startsWith('.'),
    );
};

export const decorationAutolink = (protocol: string) =>
    new Plugin({
        key: new PluginKey(NAME),
        state: {
            init: () => DecorationSet.empty,

            apply: (transaction, oldState) => {
                const { doc } = transaction;

                if (!transaction.docChanged) return oldState;

                const foundMatches = findAutolinkTextRanges(doc);

                return DecorationSet.create(
                    doc,
                    foundMatches.map((m) => {
                        const href = createUsableUrl(m.text, protocol);
                        return Decoration.inline(m.from, m.to, {
                            nodeName: 'a',
                            class: 'autolink',
                            target: '_blank',
                            rel: 'noopener noreferrer nofollow',
                            href,
                        });
                    }),
                );
            },
        },

        props: {
            decorations(state) {
                return this.getState(state);
            },
        },
    });
