// Lib
import { Extensions } from '@tiptap/core';
import { generateJSON } from '@tiptap/html';

// Constants
import { TiptapContent } from '../../tiptapTypes';
import {
    CAPTION_STATIC_TIPTAP_EXTENSIONS,
    CARD_STATIC_TIPTAP_EXTENSIONS,
    TASK_STATIC_TIPTAP_EXTENSIONS,
} from '../../extensions/extensionLists';

const createJsonContent =
    (extensions: Extensions) =>
    (html: string, text: string): TiptapContent | null => {
        if (!text && !html) return null;

        if (html) return generateJSON(html, extensions) as TiptapContent;

        return generateJSON(text, extensions) as TiptapContent;
    };

export const createCardJsonContent = createJsonContent(CARD_STATIC_TIPTAP_EXTENSIONS);
export const createCaptionJsonContent = createJsonContent(CAPTION_STATIC_TIPTAP_EXTENSIONS);
export const createTaskJsonContent = createJsonContent(TASK_STATIC_TIPTAP_EXTENSIONS);
