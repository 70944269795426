// Lib
import { ResolvedPos } from '@tiptap/pm/model';

/**
 * Finds the end resolved position of the text node *before* the current position.
 * This means it won't be the same text node as the current position.
 */
export const getEndOfPreviousTextNode = ($pos: ResolvedPos): ResolvedPos | null => {
    let foundPos: number | null = null;

    $pos.doc.nodesBetween(1, $pos.start(), (node, pos) => {
        if (node.isText) {
            foundPos = pos + node.nodeSize;
            return false;
        }

        return true;
    });

    return foundPos ? $pos.doc.resolve(foundPos) : null;
};
