import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { getPositionAndCountsToRemoveTabChars, getPositionAndCountsToInsertTabChars } from './indentationUtils';

import './TextIndentationVisualiserStyles.scss';

export const createTextIndentVisualizerPlugin = () =>
    new Plugin({
        key: new PluginKey('textIndentationHandler'),
        state: {
            init: () => DecorationSet.empty,

            apply: (transaction, oldSet, oldState, newState) => {
                const { selection, doc } = transaction;

                if (selection.empty) return DecorationSet.empty;

                const indents = getPositionAndCountsToInsertTabChars(newState);
                const dedents = getPositionAndCountsToRemoveTabChars(newState);

                console.info(`%c========================`, 'color: magenta');
                console.info(`%c indents`, 'color: magenta', JSON.stringify(indents));
                console.info(`%c dedents`, 'color: magenta', JSON.stringify(dedents));

                // Apply the decoration to the selection
                return DecorationSet.create(doc, [
                    ...indents.map(({ pos, count }) => Decoration.inline(pos, pos + count, { class: 'will-indent' })),
                    ...dedents.map(({ pos, count }) => Decoration.inline(pos, pos + count, { class: 'will-dedent' })),
                ]);
            },
        },

        props: {
            decorations(state) {
                return this.getState(state);
            },
        },
    });
