// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import LogoSpinner from './LogoSpinner';

const PageLoaderMain = ({ logoAnimate = true, ...rest }) => (
    <div className="PageLoaderMain WorkspacePositioner">
        <main className="Main">
            <LogoSpinner {...rest} animate={logoAnimate} />
        </main>
    </div>
);

PageLoaderMain.propTypes = {
    logoAnimate: PropTypes.bool,
};

export default PageLoaderMain;
