import { produce } from 'immer';
import { SheetAction, SheetActionTypes } from './SheetActionTypes';
import { SheetReducerState } from './sheetTypes';

const INITIAL_STATE: SheetReducerState = {};

export default (state = INITIAL_STATE, action: SheetAction) => {
    switch (action.type) {
        case SheetActionTypes.SHEET_OPEN:
            return produce(state, (draft) => {
                const { sheetId, sheetKey, options } = action;

                if (!sheetId) return;

                draft[sheetKey] = {
                    active: true,
                    sheetId,
                    activeSnapPoint: 0,
                    options,
                };
            });
        case SheetActionTypes.SHEET_CLOSE:
            return produce(state, (draft) => {
                const { sheetKey } = action;

                delete draft[sheetKey];
            });
        case SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT:
            return produce(state, (draft) => {
                const { sheetKey } = action;

                const sheet = draft[sheetKey];
                if (!sheet) return;

                sheet.activeSnapPoint = action.activeSnapPoint;
            });
        default:
            return state;
    }
};
