import { combineTransactionSteps } from '@tiptap/core';
import { EditorState, Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { ReplaceStep, Step } from '@tiptap/pm/transform';

import { getMarkRange } from '../../utils/getMarkRange';
import { hasDocChanges, isUndoRedo } from '../../utils/transactionUtils';
import { getContainingMention } from './mentionUtils';

export const createDeletingPlugin = () =>
    new Plugin({
        key: new PluginKey('textMentionDeleting'),
        appendTransaction: (transactions: readonly Transaction[], oldState: EditorState, newState: EditorState) => {
            if (isUndoRedo(transactions)) return null;
            if (!hasDocChanges(transactions, oldState, newState)) return null;

            // If the cursor is within a mention, or if there's a multi-character selection, that's outside our scope
            const startedOutsideMention = !getContainingMention(oldState.selection.$head);
            if (!startedOutsideMention || !oldState.selection.empty) return null;

            const markType = newState.schema.marks.textMention;

            const initialTransform = combineTransactionSteps(oldState.doc, [...transactions]);
            const updatedTransform = initialTransform.steps.reduce<Transaction>(
                (transaction: Transaction, step: Step, index: number) => {
                    if (!(step instanceof ReplaceStep)) return transaction;

                    const isDeletion = step.slice.size === 0; // (ie we're replacing some content with an empty string)
                    if (!isDeletion) return transaction;

                    const doc = initialTransform.docs[index];
                    const markInfo = getMarkRange(doc.resolve(step.from), markType);

                    if (!markInfo) return transaction;

                    // we're deleting a character at the start/end of a mention, delete the whole mention
                    return transaction.delete(
                        initialTransform.mapping.map(markInfo.from),
                        initialTransform.mapping.map(markInfo.to),
                    );
                },
                newState.tr,
            );

            return updatedTransform;
        },
    });

export const createBreakingPlugin = () =>
    new Plugin({
        key: new PluginKey('textMentionBreaking'),
        view: (instance) => {
            // run a no-edits transaction on new instances to flush any invalid marks
            // (eg from clipping)
            const metaTransaction = instance.state.tr.setMeta('textMentionBreaking', true);
            instance.updateState(instance.state.apply(metaTransaction));
            return {};
        },
        appendTransaction: (transactions: readonly Transaction[], oldState: EditorState, newState: EditorState) => {
            if (isUndoRedo(transactions)) return null;
            const hasMeta = transactions.some((t) => t.getMeta('textMentionBreaking'));
            if (!hasMeta && !hasDocChanges(transactions, oldState, newState)) return null;

            const markType = newState.schema.marks.textMention;

            // find any mentions in the doc whose text doesn't match their originalText attribute
            const brokenMarks: any[] = [];
            newState.doc.nodesBetween(0, newState.doc.nodeSize - 2, (node, pos) => {
                if (!node.isText) return true;

                const text = node.text;
                const marks = node.marks
                    .filter((m) => m.type === markType && m.attrs.originalText !== text)
                    .map((mark) => ({ from: pos, to: pos + node.nodeSize, mark }));
                brokenMarks.push(...marks);
                return false;
            });

            if (!brokenMarks.length) return null;

            // remove any marks that are broken, or add originalText to those that are missing it
            // (missing originalText is from draftjs conversions or legacy marks)
            // we don't need to map positions, as mark removal doesn't alter them
            return brokenMarks.reduce<Transaction>((transaction, { from, to, mark }) => {
                if (mark.attrs.originalText) return transaction.removeMark(from, to, mark);

                const markText = newState.doc.textBetween(from, to);
                const attrs = { ...mark.attrs, originalText: markText };
                const newMark = markType.create(attrs);
                return transaction.removeMark(from, to, mark).addMark(from, to, newMark);
            }, newState.tr);
        },
    });
