import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FolderIcon from './FolderIcon';

import './ElementFolderIcon.scss';

const ElementFolderIcon = ({ children, iconElement, style, gridSize, className }) => {
    return (
        <div className={classNames('ElementFolderIcon', className)} style={style}>
            <div className="drag-preview-shadow" />

            <FolderIcon gridSize={gridSize} />

            {children}

            {iconElement && <div className="icon">{iconElement}</div>}
        </div>
    );
};

ElementFolderIcon.propTypes = {
    className: PropTypes.string,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    documentMode: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    iconElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ElementFolderIcon;
