// Utils
import platformSingleton from '../../../platform/platformSingleton';
import { focusFakeInput } from '../../../utils/ipad/ipadUtils';
import { animateScrollPromise } from '../../../utils/animation/animateScrollIntoView';
import { translateDOMRectIntoScrollableElementCoordinates } from '../../../utils/dom/scrollableElementUtils';
import { isPlatformIos } from '../../../platform/utils/platformDetailsUtils';
import getParentScrollableSection from '../../../utils/dom/getParentScrollableSection';
import { prop } from '../../../../common/utils/immutableHelper';
import { animateScrollToResetScrollExcessPadding, applyScrollExcessPadding } from './scrollExcessUtils';
import {
    getContentDomElement,
    getTargetScrollToElement,
    ScrollFocus,
    ScrollFocusClientCoords,
} from './scrollToElementUtils';
import { getSnapPointSheetHeight } from '../../structural/sheet/utils/snapPointUtils';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../platform/platformSelector';
import { getSelectedElementIds } from '../../../element/selection/selectedElementsSelector';
import { getCurrentlyEditingId } from '../../../element/selection/currentlyEditingSelector';
import { getSheetState } from '../../structural/sheet/sheetSelectors';

// Constants
import {
    ELEMENT_EDIT_COMPLETE,
    ELEMENT_EDIT_START,
    ELEMENTS_DESELECT_ALL,
    ELEMENTS_DESELECTED,
} from '../../../../common/elements/selectionConstants';
import {
    DOCUMENT_MODAL_EDITOR_KEY,
    DOCUMENT_MODAL_TITLE_EDITOR_KEY,
} from '../../../element/document/modal/documentModalConstants';

// Types
import { UnknownAction } from 'redux';
import { ReduxStore } from '../../../types/reduxTypes';
import { MilanoteApplicationMode } from '../../../../common/platform/platformTypes';
import { SheetActionTypes, SheetUpdateActiveSnapPointAction } from '../../structural/sheet/SheetActionTypes';
import { Rect } from '../../../../common/maths/geometry/rect/rectTypes';

type ScrollToElementOptions = {
    scrollableElement: HTMLElement;
    elementRect: Rect;
    scrollFocus: ScrollFocus;
    scrollExcessPaddingBottomCSS: string;
};

export const PREVENT_SCROLL_TO_ELEMENT_EDITOR_KEYS = [DOCUMENT_MODAL_TITLE_EDITOR_KEY, DOCUMENT_MODAL_EDITOR_KEY];

const getElementScrollInfo = (elementId: string): { scrollableElement: HTMLElement; elementRect: Rect } | undefined => {
    const domElement = getContentDomElement(elementId);
    if (!domElement) return;

    const scrollableElement = getParentScrollableSection(domElement);
    if (!scrollableElement) return;

    const elementRect = translateDOMRectIntoScrollableElementCoordinates(
        scrollableElement,
        domElement.getBoundingClientRect(),
    );

    return { scrollableElement, elementRect };
};

const getScrollToElementOptionsForAction = (state: any, action: UnknownAction): ScrollToElementOptions | undefined => {
    const applicationMode = getMilanoteApplicationModeSelector(state);
    if (applicationMode !== MilanoteApplicationMode.mobile) return;

    if (action.type === ELEMENT_EDIT_START) {
        const elementId = action.id as string;
        const elementScrollInfo = getElementScrollInfo(elementId);
        if (!elementScrollInfo) return;

        const editorFocusClientCoords = action.editorFocusClientCoords as ScrollFocusClientCoords | undefined;

        return {
            ...elementScrollInfo,
            scrollFocus: editorFocusClientCoords || 'start',
            scrollExcessPaddingBottomCSS: 'calc(var(--keyboard-height) + var(--modern-mobile-toolbar-height))',
        };
    }

    if (action.type === SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT) {
        const { sheetKey, activeSnapPoint } = action as SheetUpdateActiveSnapPointAction;

        // Only scroll to element when the sheet is opening and sheetState.scrollToElement is true
        const sheetState = getSheetState(sheetKey)(state);
        if (!sheetState || sheetState.activeSnapPoint !== 0 || !sheetState.options?.scrollToElementOnOpen) return;

        const selectedElementId = prop(0, getSelectedElementIds(state));
        const scrollElementInfo = getElementScrollInfo(selectedElementId);
        if (!scrollElementInfo) return;

        return {
            ...scrollElementInfo,
            scrollFocus: 'start',
            scrollExcessPaddingBottomCSS: `${getSnapPointSheetHeight(activeSnapPoint)}px`,
        };
    }
};

export default (store: ReduxStore) => (next: Function) => async (action: UnknownAction) => {
    const state = store.getState();

    // We need the focus event to happen immediately on iOS otherwise the element won't become editable
    // We don't want to do it on Android otherwise the keyboard might flash up then disappear then show again
    if (action.type === ELEMENT_EDIT_START && isPlatformIos(platformSingleton)) {
        const mobileWorkspaceElement = document.querySelector('.MobileWorkspace');
        focusFakeInput({ x: 0, y: 0 }, mobileWorkspaceElement);
    }

    // When the user edits an element and keyboard/sheet shows up, we want to:
    // 1. Apply scroll excess padding to the bottom of the scrollable element to allow the
    //   bottom portion of the scrollable element to be accessible
    // 2. Scroll to the element
    const scrollToElementOptions = getScrollToElementOptionsForAction(state, action);
    if (scrollToElementOptions) {
        const { scrollableElement, elementRect, scrollFocus, scrollExcessPaddingBottomCSS } = scrollToElementOptions;

        await applyScrollExcessPadding(scrollableElement, scrollExcessPaddingBottomCSS);

        // For some elements, we are using a different kind of editor so scrolling to the element is not applicable
        if (PREVENT_SCROLL_TO_ELEMENT_EDITOR_KEYS.includes(action.editorKey as string)) return next(action);

        const targetScroll = getTargetScrollToElement(scrollableElement, elementRect, scrollFocus);

        await animateScrollPromise({
            scrollableElement,
            fromScrollLeft: scrollableElement.scrollLeft,
            fromScrollTop: scrollableElement.scrollTop,
            toScrollLeft: targetScroll.left,
            toScrollTop: targetScroll.top,
            interpolationFactor: 0.2,
        });
    }

    // When the user finishes editing an element, we want to animate back to the position
    // that resets the scrollExcess back to 0
    if (
        action.type === ELEMENT_EDIT_COMPLETE ||
        action.type === ELEMENTS_DESELECTED ||
        action.type === ELEMENTS_DESELECT_ALL ||
        (action.type === SheetActionTypes.SHEET_CLOSE && !getCurrentlyEditingId(state))
    ) {
        animateScrollToResetScrollExcessPadding();
    }

    next(action);
};
