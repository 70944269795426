// Types
import { Selection } from '@tiptap/pm/state';

/**
 * Determines whether the current selection is visible, in the sense that it's
 * no collapsed & it's not invisibly spanning two adjacent nodes.
 *
 * If the selection starts at the end of a text block & ends at the start of the next
 * text block, then it won't be visible to the user.
 *
 * This can occur if the cursor is at the end of a block and the user holds shift and
 * presses the right arrow key, for example.
 */
const isVisibleSelection = (selection: Selection): boolean => {
    const { $from, $to } = selection;

    if (selection.empty) return false;

    const isStartAtBlockEnd = $from.parentOffset === $from.parent.nodeSize - 2;
    const isEndAtBlockStart = $to.parentOffset === 0;

    if (!isStartAtBlockEnd || !isEndAtBlockStart) return true;

    const sharedDepth = $from.sharedDepth($to.pos);
    const fromIndex = $from.index(sharedDepth);
    const toIndex = $to.index(sharedDepth);

    // This should only happen if the selection is collapsed, so it shouldn't be
    // possible due to the above checks, but keeping it for completeness
    if (fromIndex === toIndex) return false;

    // The selection spans from the end of one block to the start of the next
    // In this case we're not covering an entire node
    if (fromIndex + 1 === toIndex) return false;

    return true;
};

export default isVisibleSelection;
