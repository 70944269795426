// Lib
import { useEffect } from 'react';
import { Editor, EditorEvents } from '@tiptap/react';

// Utils
import rafThrottle from '../../utils/lib/rafThrottle';

/**
 * When this editor becomes active, set it as the active editor in the TiptapActiveEditorContext.
 */
export const useSyncTiptapActiveEditorState = (
    editor: Editor | null,
    isActive: boolean,
    updateActiveEditorStore?: (editor: Editor | null) => void,
) => {
    useEffect(() => {
        if (!editor) return;
        if (!isActive) return;
        if (!updateActiveEditorStore) return;

        updateActiveEditorStore(editor);

        const updateActiveEditor = ({ editor }: EditorEvents['update']) => updateActiveEditorStore(editor);

        const throttledUpdateActiveEditor = rafThrottle(updateActiveEditor);

        editor.on('transaction', throttledUpdateActiveEditor);
        editor.on('update', throttledUpdateActiveEditor);
        editor.on('selectionUpdate', throttledUpdateActiveEditor);

        return () => {
            editor.off('transaction', throttledUpdateActiveEditor);
            editor.off('update', throttledUpdateActiveEditor);
            editor.off('selectionUpdate', throttledUpdateActiveEditor);

            // Theoretically this could clobber the active editor when quickly
            // switching between editors, but we've never seen it in practice.
            updateActiveEditorStore(null);

            throttledUpdateActiveEditor.cancel();
        };
    }, [editor, isActive]);
};

export default useSyncTiptapActiveEditorState;
